export enum AsiointiLedgerAccountCategory {
  VARAT = 'VARAT',
  VELAT = 'VELAT',
  TULOT = 'TULOT',
  MENOT = 'MENOT',
  MUU = 'MUU',
}

export enum AsiointiLedgerAccountSubCategory {
  PANKKITALLETUKSET_JA_VAKUUTUSSAASTAMINEN = 'PANKKITALLETUKSET_JA_VAKUUTUSSAASTAMINEN',
  ARVOPAPERIT = 'ARVOPAPERIT',
  RAHASTO_OSUUDET = 'RAHASTO_OSUUDET',
  ASUNTO_OSAKKEET_JA_MUUT_HUONEISTOT = 'ASUNTO_OSAKKEET_JA_MUUT_HUONEISTOT',
  KIINTEISTOT = 'KIINTEISTOT',
  OSUUDET_KUOLINPESISSA = 'OSUUDET_KUOLINPESISSA',
  SAATAVAT = 'SAATAVAT',
  IRTAIMISTO = 'IRTAIMISTO',
  MUUT_VARAT = 'MUUT_VARAT',
  PANKKILAINAT = 'PANKKILAINAT',
  MUUT_VELAT = 'MUUT_VELAT',
  TAKAUKSET_JA_VASTUUT_MUIDEN_VELOISTA = 'TAKAUKSET_JA_VASTUUT_MUIDEN_VELOISTA',
  ELAKETULOT = 'ELAKETULOT',
  PALKKATULOT = 'PALKKATULOT',
  ETUUDET = 'ETUUDET',
  TYOTTOMYYSTURVA = 'TYOTTOMYYSTURVA',
  SATUNNAISET_TULOT = 'SATUNNAISET_TULOT',
  VUOKRATULOT = 'VUOKRATULOT',
  OSINKOTULOT = 'OSINKOTULOT',
  KORKOTULOT = 'KORKOTULOT',
  MYYNTITULOT = 'MYYNTITULOT',
  MUUT_PAAOMATULOT = 'MUUT_PAAOMATULOT',
  KIRJANPITOARVON_KOROTUS = 'KIRJANPITOARVON_KOROTUS',
  VAROJA_HALTUUN = 'VAROJA_HALTUUN',
  KAYTTOVARAT = 'KAYTTOVARAT',
  ELINKUSTANNUKSET = 'ELINKUSTANNUKSET',
  HOITO_JA_ASUMISPALVELUMAKSUT = 'HOITO_JA_ASUMISPALVELUMAKSUT',
  VUOKRAT_JA_VASTIKKEET = 'VUOKRAT_JA_VASTIKKEET',
  ELATUSAPU = 'ELATUSAPU',
  PANKIN_PERIMAT_MAKSUT = 'PANKIN_PERIMAT_MAKSUT',
  VEROT = 'VEROT',
  TYONANTAJAKULUT = 'TYONANTAJAKULUT',
  VELANHOITOMENOT = 'VELANHOITOMENOT',
  EDUNVALVONNAN_KULUT = 'EDUNVALVONNAN_KULUT',
  MUUT_MENOT_TILIKAUDELLA = 'MUUT_MENOT_TILIKAUDELLA',
  VELKAA_HALTUUN = 'VELKAA_HALTUUN',
  SIIRTOTILIT = 'SIIRTOTILIT',
}

export enum AsiointiLedgerAccountType {
  EDUNVALVONTATILI = 'EDUNVALVONTATILI',
  KAYTTOTILI = 'KAYTTOTILI',
  VUOKRAVAKUUSTILI = 'VUOKRAVAKUUSTILI',
  OMAISUUDEN_HOITOTILI = 'OMAISUUDEN_HOITOTILI',
  SIJOITUSTILI = 'SIJOITUSTILI',
  ERAANTYVA_SIJOITUSTILI = 'ERAANTYVA_SIJOITUSTILI',
  VAKUUTUSSAASTOTILI = 'VAKUUTUSSAASTOTILI',
  OSAKKEET = 'OSAKKEET',
  OSUUDET = 'OSUUDET',
  JOUKKOVELKAKIRJALAINAT = 'JOUKKOVELKAKIRJALAINAT',
  RAHASTO_OSUUDET = 'RAHASTO_OSUUDET',
  ASUNTO_OSAKKEET_JA_MUUT_HUONEISTOT = 'ASUNTO_OSAKKEET_JA_MUUT_HUONEISTOT',
  KIINTEISTOT = 'KIINTEISTOT',
  OSUUDET_KUOLINPESISSA = 'OSUUDET_KUOLINPESISSA',
  SAATAVAT = 'SAATAVAT',
  IRTAIMISTO = 'IRTAIMISTO',
  MUUT_VARAT = 'MUUT_VARAT',
  ASUNTOLAINA = 'ASUNTOLAINA',
  OPINTOLAINA = 'OPINTOLAINA',
  MUU_LAINA = 'MUU_LAINA',
  ELATUSAPUVELKA = 'ELATUSAPUVELKA',
  VELKAJARJESTELYVELKA = 'VELKAJARJESTELYVELKA',
  MUU_VELKA = 'MUU_VELKA',
  TAKAUKSET_JA_VASTUUT_MUIDEN_VELOISTA = 'TAKAUKSET_JA_VASTUUT_MUIDEN_VELOISTA',
  TYOELAKE = 'TYOELAKE',
  KANSANELAKE = 'KANSANELAKE',
  ULKOMAAN_ELAKE = 'ULKOMAAN_ELAKE',
  PALKAT = 'PALKAT',
  PALKKIOT = 'PALKKIOT',
  TYOOSUUSRAHAT = 'TYOOSUUSRAHAT',
  OMAISHOIDON_TUKI = 'OMAISHOIDON_TUKI',
  ELAKKEENSAAJAN_ASUMISTUKI = 'ELAKKEENSAAJAN_ASUMISTUKI',
  YLEINEN_ASUMISTUKI = 'YLEINEN_ASUMISTUKI',
  VAMMAISTUKI = 'VAMMAISTUKI',
  KELAN_KUNTOUTUSRAHA = 'KELAN_KUNTOUTUSRAHA',
  KUNTOUTUSTUKI = 'KUNTOUTUSTUKI',
  SAIRAUSPAIVARAHA = 'SAIRAUSPAIVARAHA',
  TOIMEENTULOTUKI = 'TOIMEENTULOTUKI',
  KOTIHOIDON_TUKI = 'KOTIHOIDON_TUKI',
  ELATUSAPU = 'ELATUSAPU',
  ELATUSTUKI = 'ELATUSTUKI',
  VANHEMPAINRAHA = 'VANHEMPAINRAHA',
  LAPSILISA = 'LAPSILISA',
  OPINTOTUKI = 'OPINTOTUKI',
  ULKOMAAN_ETUUS = 'ULKOMAAN_ETUUS',
  MUU_ETUUS = 'MUU_ETUUS',
  TYOMARKKINATUKI = 'TYOMARKKINATUKI',
  PERUSPAIVARAHA = 'PERUSPAIVARAHA',
  ANSIOSIDONNAINEN_TYOTTOMYYSPAIVARAHA = 'ANSIOSIDONNAINEN_TYOTTOMYYSPAIVARAHA',
  KOTOUTUMISTUKI_MAAHANMUUTTAJALLE = 'KOTOUTUMISTUKI_MAAHANMUUTTAJALLE',
  MUU_TYOTTOMYYSTURVAETUUS = 'MUU_TYOTTOMYYSTURVAETUUS',
  KELAN_LAAKEKORVAUKSET = 'KELAN_LAAKEKORVAUKSET',
  LAAKEKORVAUKSET_MUU = 'LAAKEKORVAUKSET_MUU',
  SAIRASVAKUUTUSKORVAUKSET = 'SAIRASVAKUUTUSKORVAUKSET',
  HENKIVAKUUTUSKORVAUKSET = 'HENKIVAKUUTUSKORVAUKSET',
  VAHINKOVAKUUTUSKORVAUKSET = 'VAHINKOVAKUUTUSKORVAUKSET',
  VAHINGONKORVAUKSET = 'VAHINGONKORVAUKSET',
  MAKSUKATOSTA_JOHTUVAT_PALAUTUKSET = 'MAKSUKATOSTA_JOHTUVAT_PALAUTUKSET',
  MAKSUN_PALAUTUKSET = 'MAKSUN_PALAUTUKSET',
  VERONPALAUTUKSET = 'VERONPALAUTUKSET',
  KATEISVAROJA_HALTUUN = 'KATEISVAROJA_HALTUUN',
  LAHJAT_PERINNOT_TASINKO = 'LAHJAT_PERINNOT_TASINKO',
  MUUT_TULOT = 'MUUT_TULOT',
  VIRHETULO_KORJATAAN_MYOHEMMIN = 'VIRHETULO_KORJATAAN_MYOHEMMIN',
  VIRHEMAKSUN_KORJAUS = 'VIRHEMAKSUN_KORJAUS',
  VUOKRATULOT = 'VUOKRATULOT',
  OSINKOTULOT = 'OSINKOTULOT',
  KORKOTULOT = 'KORKOTULOT',
  METSANMYYNTI = 'METSANMYYNTI',
  IRTAIMEN_MYYNTI = 'IRTAIMEN_MYYNTI',
  ARVOPAPERIMYYNTI = 'ARVOPAPERIMYYNTI',
  MERKINTAOSUUKSIEN_MYYNTI = 'MERKINTAOSUUKSIEN_MYYNTI',
  HUONEISTON_MYYNTI = 'HUONEISTON_MYYNTI',
  KIINTEISTON_MYYNTI = 'KIINTEISTON_MYYNTI',
  MUUT_PAAOMATULOT = 'MUUT_PAAOMATULOT',
  KIRJANPITOARVON_KOROTUS = 'KIRJANPITOARVON_KOROTUS',
  VAROJA_HALTUUN = 'VAROJA_HALTUUN',
  KAYTTOVARAT = 'KAYTTOVARAT',
  APTEEKKIOSTOT = 'APTEEKKIOSTOT',
  ATERIAPALVELU = 'ATERIAPALVELU',
  ENERGIA_JA_VESI = 'ENERGIA_JA_VESI',
  KODIN_HANKINNAT = 'KODIN_HANKINNAT',
  KUNNALLINEN_KOTIHOITO_KOTISAIRAANHOITO = 'KUNNALLINEN_KOTIHOITO_KOTISAIRAANHOITO',
  MATKAT = 'MATKAT',
  SUORATOISTOPALVELU = 'SUORATOISTOPALVELU',
  KIINTEISTON_KULUT = 'KIINTEISTON_KULUT',
  MUUT_OSTOKSET = 'MUUT_OSTOKSET',
  PALVELUT = 'PALVELUT',
  PUHELIN_INTERNET = 'PUHELIN_INTERNET',
  RUOKAKAUPPA = 'RUOKAKAUPPA',
  SAIRAANKULJETUS = 'SAIRAANKULJETUS',
  VAKUUTUKSET = 'VAKUUTUKSET',
  VAPAA_AIKA_JA_HARRASTUSKULUT = 'VAPAA_AIKA_JA_HARRASTUSKULUT',
  KELA_KORVATTAVAT_MATKAKULUT = 'KELA_KORVATTAVAT_MATKAKULUT',
  KELA_KORVATTAVAT_SAIRAANHOITOKULUT = 'KELA_KORVATTAVAT_SAIRAANHOITOKULUT',
  MUUT_ELINKUSTANNUKSET = 'MUUT_ELINKUSTANNUKSET',
  ASUMISPALVELU = 'ASUMISPALVELU',
  MAKSUKATTOA_KERRYTTAVA_HOITO = 'MAKSUKATTOA_KERRYTTAVA_HOITO',
  MUUT_HOITOMAKSUT = 'MUUT_HOITOMAKSUT',
  PITKAAIKAISHOITO = 'PITKAAIKAISHOITO',
  ASUNTOVUOKRA = 'ASUNTOVUOKRA',
  YHTIOVASTIKE = 'YHTIOVASTIKE',
  MUUT_VUOKRAT = 'MUUT_VUOKRAT',
  KOTITALOUSVAHENNYSKELPOISET_MENOT = 'KOTITALOUSVAHENNYSKELPOISET_MENOT',
  ELATUSAPU_LAPSELLE = 'ELATUSAPU_LAPSELLE',
  ELATUSAPU_PUOLISOLLE = 'ELATUSAPU_PUOLISOLLE',
  ARVO_OSUUSTILIN_SAILYTYSMAKSU = 'ARVO_OSUUSTILIN_SAILYTYSMAKSU',
  PALVELUMAKSUT = 'PALVELUMAKSUT',
  TALLELOKEROMAKSUT = 'TALLELOKEROMAKSUT',
  ARVONLISAVERO = 'ARVONLISAVERO',
  ENNAKKOVERO = 'ENNAKKOVERO',
  JAANNOSVERO = 'JAANNOSVERO',
  KIINTEISTOVERO = 'KIINTEISTOVERO',
  LUOVUTUSVOITTOVERO = 'LUOVUTUSVOITTOVERO',
  LAHDEVERO = 'LAHDEVERO',
  METSANHOITOMAKSU = 'METSANHOITOMAKSU',
  PERINTOVERO = 'PERINTOVERO',
  MUUT_VEROT_KOIRAVERO_AJONEUVOVERO = 'MUUT_VEROT_KOIRAVERO_AJONEUVOVERO',
  PALKKA = 'PALKKA',
  VUOSILOMAKORVAUS = 'VUOSILOMAKORVAUS',
  SOSIAALITURVAMAKSUT = 'SOSIAALITURVAMAKSUT',
  ELAKETURVAMAKSUT = 'ELAKETURVAMAKSUT',
  ENNAKKOPIDATYS = 'ENNAKKOPIDATYS',
  MUUT_TYONANTAJAKULUT = 'MUUT_TYONANTAJAKULUT',
  SOPIMUKSEN_MUKAISET_KOROT = 'SOPIMUKSEN_MUKAISET_KOROT',
  VIIVASTYSKOROT_JA_PERINTAKULUT = 'VIIVASTYSKOROT_JA_PERINTAKULUT',
  EDUNVALVOJAN_PALKKIO = 'EDUNVALVOJAN_PALKKIO',
  EDUNVALVOJAN_KULUKORVAUS = 'EDUNVALVOJAN_KULUKORVAUS',
  TILINTARKASTUS = 'TILINTARKASTUS',
  PAATOKSET = 'PAATOKSET',
  MUUT_MAKSUT = 'MUUT_MAKSUT',
  ASIANTUNTIJAPALKKIOT = 'ASIANTUNTIJAPALKKIOT',
  ETUUDEN_PALAUTUS = 'ETUUDEN_PALAUTUS',
  KORJAUSKUSTANNUKSET = 'KORJAUSKUSTANNUKSET',
  MAKSUPALAUTUKSET = 'MAKSUPALAUTUKSET',
  METSA_JA_MAATALOUSKIINTEISTON_KULUT = 'METSA_JA_MAATALOUSKIINTEISTON_KULUT',
  TIEMAKSU = 'TIEMAKSU',
  VAHINGONKORVAUS = 'VAHINGONKORVAUS',
  VALITYSPALKKIOT = 'VALITYSPALKKIOT',
  MUUT_TILIKAUDEN_MENOT = 'MUUT_TILIKAUDEN_MENOT',
  HUONEISTON_OSTO = 'HUONEISTON_OSTO',
  KIINTEISTON_OSTO = 'KIINTEISTON_OSTO',
  VIRHETULON_KORJAUS = 'VIRHETULON_KORJAUS',
  VIRHEMAKSU_KORJATAAN_MYOHEMMIN = 'VIRHEMAKSU_KORJATAAN_MYOHEMMIN',
  KIRJANPITOARVON_ALENNUS_MENOKOODI = 'KIRJANPITOARVON_ALENNUS_MENOKOODI',
  PANKIN_VIRHE = 'PANKIN_VIRHE',
  VELKAA_HALTUUN = 'VELKAA_HALTUUN',
  TILIOIMATON_TAPAHTUMA = 'TILIOIMATON_TAPAHTUMA',
  ALKUSALDON_KORJAUS = 'ALKUSALDON_KORJAUS',
}
