import { useContext } from 'react'
import { MobXProviderContext } from 'mobx-react'
import { observable } from 'mobx'
import { AsiointiUser } from 'edunvalvonta-asiointi/src/vtj/asiointi/authentication/holhous-asiointi-user.type'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'

export type AsiointiUserStore = {
  readonly user: AsiointiUser | null
  tilintarkastusActivated: boolean
  lang: HolhousAsiointiLanguageCode
}

export function createAsiointiUserStore(data: {
  user: AsiointiUser | null
  tilintarkastusActivated: boolean
  lang: HolhousAsiointiLanguageCode
}): AsiointiUserStore {
  return observable(data)
}

export function useAsiointiUserStore(): AsiointiUserStore {
  const ctx = useContext(MobXProviderContext)
  if (!ctx || !ctx.asiointiUserStore) {
    throw new Error('asiointiUserStore not provided')
  }
  return ctx.asiointiUserStore
}

export function useAsiointiUser(): AsiointiUser {
  const userStore = useAsiointiUserStore()
  if (!userStore.user) {
    throw new Error('No user')
  }
  return userStore.user
}
