import {
  Block,
  Button,
  Heading,
  RadioButton,
  RadioButtonGroup,
  StatusText,
  Text,
} from 'suomifi-ui-components'
import React, { useState } from 'react'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { observer } from 'mobx-react'

import { useTranslation } from 'react-i18next'
import { useAutoFocus } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/auto-focus'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { FieldValues, FormProvider, SubmitHandler } from 'react-hook-form'
import FormSimpleListInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormSimpleListInput'
import { action, runInAction } from 'mobx'
import { mkOmaisuusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import styled from 'styled-components'
import {
  OMAISUUSLUETTELO_ROUTES,
  OmaisuusluetteloRoutePath,
  TilintarkastusForm,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { useNavigate } from 'react-router-dom'
import { KayttoOmaisuus } from 'tilintarkastus-common/src/vtj/types/asiointi-account-data.type'
import { getOmaisuusluettelo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/omaisuusluettelo.store'

const OmassaKaytossaOmaisuusPage: React.FC<{
  form: TilintarkastusForm
}> = observer(({ form }) => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const tili = getOmaisuusluettelo()
  const headingRef = useAutoFocus<HTMLHeadingElement>()
  const [errorSelection, setErrorSelection] = useState(false)
  let formError = false

  const onSave: SubmitHandler<FieldValues> = (data) => {
    formError = false
    runInAction(() => {
      tili.kaytossaOlevaOmaisuus = Object.values(data).map((value) => ({
        value,
      }))
    })
  }

  const onError = () => {
    formError = true
  }

  const validateAndSave = async (navigateTo?: OmaisuusluetteloRoutePath) => {
    if (tili.hasKaytossaOlevaOmaisuus === undefined) {
      setErrorSelection(true)
      return
    }

    if (tili.hasKaytossaOlevaOmaisuus === 'true') {
      await form.handleSubmit(onSave, onError)()
    } else if (tili.hasKaytossaOlevaOmaisuus === 'false') {
      runInAction(() => {
        tili.kaytossaOlevaOmaisuus = []
      })
    }

    if (!formError && navigateTo) {
      navigate(navigateTo)
    }
  }

  return (
    <FormProvider {...form}>
      <ResponsiveBorderedContentBlock>
        <Text> {t('phase')} 3/9 </Text>
        <Block mt="s" />
        <Heading variant="h2" ref={headingRef}>
          {t('kaytossaOlevaOmaisuusHeading')}
        </Heading>
        <Block mt="xs" />
        <Text>{t('kaytossaOlevaOmaisuusInfo1')}</Text>
        <Block mt="xs" />
        <Text>{t('kaytossaOlevaOmaisuusInfo2')}</Text>
        <DividerLine mt="l" />
        <Block mt="l" />
        <section>
          <Heading variant="h3" ref={headingRef}>
            {t('kaytossaOlevaOmaisuusHeading2')}
          </Heading>
          <Block mt="xs" />
          <RadioButtonGroup
            data-test-id={mkOmaisuusTestId('omaisuus-button-group')}
            value={tili.hasKaytossaOlevaOmaisuus}
            onChange={action((value) => {
              tili.hasKaytossaOlevaOmaisuus = value
              setErrorSelection(false)
            })}
            name={mkOmaisuusTestId('onko-omaisuutta')}
            labelText={<Text>{t('onkoHenkilollaOmaisuutta')}</Text>}
          >
            <RadioButton value={'false'}>{t('no')}</RadioButton>
            <RadioButton value={'true'}>{t('yes')}</RadioButton>
          </RadioButtonGroup>
          <StatusText status="error">
            {errorSelection ? t('valitseOnkoHenkilollaOmaisuutta') : ''}
          </StatusText>

          <Block mt="xl" />

          <InputContainer hidden={tili.hasKaytossaOlevaOmaisuus !== 'true'}>
            <FormSimpleListInput
              dataTestIdPrefix={mkOmaisuusTestId('kaytto-omaisuus')}
              description={t('kaytossaOlevaOmaisuusListInfo')}
              heading={t('kaytossaOlevaOmaisuusListHeading')}
              state={tili.kaytossaOlevaOmaisuus as KayttoOmaisuus[]}
            />
          </InputContainer>
        </section>

        <DividerLine mt="l" />
        <Block mt="l" />

        <ButtonContainer>
          <Button onClick={() => validateAndSave()} variant="secondary">
            {t('tallennaJaJatkaMyohemmin')}
          </Button>

          <ButtonGroupContainer>
            <Button
              variant="secondary"
              onClick={() =>
                validateAndSave(
                  OMAISUUSLUETTELO_ROUTES.TOIMINTAKYKY_JA_ASUMISTIEDOT
                )
              }
            >
              {t('previous')}
            </Button>
            <Block ml="s" />
            <Button
              onClick={() => validateAndSave(OMAISUUSLUETTELO_ROUTES.VARAT)}
            >
              {t('next')}
            </Button>
          </ButtonGroupContainer>
        </ButtonContainer>
      </ResponsiveBorderedContentBlock>
    </FormProvider>
  )
})

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const ButtonGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const InputContainer = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
`

export default OmassaKaytossaOmaisuusPage
