import { pick, mapValues } from 'lodash'
import {
  ApiResponse,
  executeBackendClient,
} from 'holhous-common/src/vtj/ui/api/microfrontend-backend-api-call'
import {
  AsiointiApplication,
  LupaAsiointiAttachment,
  AsiointiBatch,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/asiointi-batch.type'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import { languageCodeToLupaLanguage } from 'lupa-backend/src/vtj/language.util'
import { OpinionType } from 'lupa-backend/src/vtj/elsa/person/person-enums'
import { getExpectedAsiakirjaTypes } from 'lupa-backend/src/vtj/asiointi/lupa/asiointi-lupa-info'

export const sendAsiointiBatch = (
  batch: AsiointiBatch,
  languageCode: HolhousAsiointiLanguageCode
): Promise<ApiResponse<void>> =>
  executeBackendClient((client) => {
    const data = {
      sourceBatchId: trim(batch.sourceBatchId),
      description: trim(batch.description),
      arguments: trim(batch.arguments),
      languageId: languageCodeToLupaLanguage(languageCode),
      persons: batch.persons
        .map((person) => ({
          ...pick(
            person,
            'applicationRoleId',
            'hetu',
            'firstnames',
            'lastname',
            'streetAddress',
            'streetAddressExtra',
            'postalCode',
            'postOffice',
            'countryId',
            'email',
            'phone',
            'opinionTypeId'
          ),
          attachments:
            person.opinionTypeId ===
            OpinionType.PAAMIES_ALLE_15V_MIELIPIDETTA_EI_SELVITETTY
              ? []
              : person.attachments.map(toAttachmentData),
        }))
        .map((person) => mapValues(person, trim)),
      applications: batch.applications.map((application) => ({
        lupaTypeId: application.typeId,
        containsEstate: application.containsEstate,
        attachments: application.attachments
          .filter(getAttachmentFilter(application))
          .map(toAttachmentData),
      })),
    }
    return client.post('/luvat/asiointi/api/v1/application-batch', data)
  })

const getAttachmentFilter = (application: AsiointiApplication) => {
  const expectedAsiakirjaTypeIds = new Set(
    getExpectedAsiakirjaTypes(application)
  )
  return (attachment: LupaAsiointiAttachment) =>
    expectedAsiakirjaTypeIds.has(attachment.asiakirjaTypeId)
}

const toAttachmentData = (attachment: LupaAsiointiAttachment) =>
  mapValues(pick(attachment, 'sourceFileId', 'asiakirjaTypeId'), trim)

const trim = <T>(value: T): string | null | T => {
  if (typeof value === 'string') {
    const trimmedValue = value.trim()
    return trimmedValue.length > 0 ? trimmedValue : null
  } else {
    return value
  }
}
