import React from 'react'
import { observer } from 'mobx-react'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { Block, Paragraph } from 'suomifi-ui-components'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import KayttovaratForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/yleiskatsaus/KayttovaratForm'
import { FormProvider } from 'react-hook-form'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import { mkYleiskatsausTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import MuutToimetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/yleiskatsaus/MuutToimetForm'
import VerotiedotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/yleiskatsaus/VerotiedotForm'
import AsuminenForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/AsuminenForm'
import HallintaoikeudetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/yleiskatsaus/HallintaoikeudetForm'
import SaannollisetTulotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/yleiskatsaus/SaannollisetTulotForm'
import SopimuksetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/yleiskatsaus/SopimuksetForm'
import VakuutuksetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/yleiskatsaus/VakuutuksetForm'
import {
  PAATOSTILI_ROUTES,
  PAATOSTILI_STEPS,
  TilintarkastusForm,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'

const YleiskatsausPage: React.FC<{
  form: TilintarkastusForm
}> = observer(({ form }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  return (
    <>
      <FormProvider {...form}>
        <ResponsiveBorderedContentBlock
          data-test-id={mkYleiskatsausTestId('container')}
        >
          <PageStepHeading
            heading={t('yleiskatsaus')}
            steps={PAATOSTILI_STEPS}
            createDataTestId={mkYleiskatsausTestId}
          />
          <Block mt={verticalMarginToken} />
          <Paragraph>
            {t('ohjeYleiskatsausLisaaTiedotTilanteesta')}{' '}
            {t('ohjeYleiskatsausLisaaLiittyvatAsiakirjat')}
          </Paragraph>
          <DividerLine mt={verticalMarginToken} />
          <Block mt={verticalMarginToken} />
          <AsuminenForm />
          <DividerLine mt={verticalMarginToken} />
          <Block mt={verticalMarginToken} />
          <KayttovaratForm />
          <DividerLine mt={verticalMarginToken} />
          <Block mt={verticalMarginToken} />
          <SaannollisetTulotForm />
          <DividerLine mt={verticalMarginToken} />
          <Block mt={verticalMarginToken} />
          <SopimuksetForm />
          <DividerLine mt={verticalMarginToken} />
          <Block mt={verticalMarginToken} />
          <VakuutuksetForm />
          <DividerLine mt={verticalMarginToken} />
          <Block mt={verticalMarginToken} />
          <VerotiedotForm />
          <DividerLine mt={verticalMarginToken} />
          <Block mt={verticalMarginToken} />
          <HallintaoikeudetForm />
          <DividerLine mt={verticalMarginToken} />
          <Block mt={verticalMarginToken} />
          <MuutToimetForm />
          <DividerLine mt={verticalMarginToken} />
          <Block mt={verticalMarginToken} />
          <FormNavigationBar
            previous={PAATOSTILI_ROUTES.TEE_PAATOSTILI}
            next={PAATOSTILI_ROUTES.OMAISUUS_JA_VELAT}
          />
        </ResponsiveBorderedContentBlock>
      </FormProvider>
    </>
  )
})

export default YleiskatsausPage
