/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkOmaisuusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block, Heading, Paragraph, Text } from 'suomifi-ui-components'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import { getTilirivit } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { FrontKuolinpesaOsakkuus } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { FormListInputExpander } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonDateValidator,
  commonEuroWithDecimalsOrUndefinedValidator,
  commonPercentageWithDecimalsOrUndefinedValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/react-form-validation.util'
import { observable } from 'mobx'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import {
  formatDateAsLocal,
  formatNumberWithComma,
  parseLocalDate,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import { TiliAsiointiAsiakirjatyyppi } from 'tilintarkastus-common/src/vtj/types/asiointi-account-data.type'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import FormDateInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDateInput'
import { AsiointiLedgerAccountCategory } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'
import { AsiointiLedgerAccountAssetsType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-assets-enum'

const KuolinpesaOsakkuudetForm: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const omaisuus = getTilirivit()

  const mkNewEntry = (): FrontKuolinpesaOsakkuus =>
    observable({
      id: crypto.randomUUID(),
      tiliointinumero: '',
      tyyppi: AsiointiLedgerAccountAssetsType.OSUUDET_KUOLINPESISSA,
      vainajanEtunimi: '',
      vainajanSukunimi: '',
      vainajanKuolinpaiva: undefined,
      osakkuudenOsuusProsentteina: undefined,
      arvoTilikaudenAlussaEur: undefined,
      arvoTilikaudenLopussaEur: undefined,
      asiakirjat: [],
    })

  return (
    <section data-test-id={mkOmaisuusTestId('kuolinpesaOsakkuudet-form')}>
      <Heading variant="h2">{t('kuolinpesaOsakkuudetHeading')}</Heading>
      <Block mt={verticalMarginToken} />
      <Paragraph>{t('ohjeIlmoitaKuolinpesaOsakkuudet')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={KuolinpesaOsakkuudetListItem}
        state={omaisuus.varat.osuudetKuolinpesissa}
        asiointiLedgerAccountCategory={AsiointiLedgerAccountCategory.VARAT}
        dataTestIdPrefix={mkOmaisuusTestId('kuolinpesaOsakkuudet-form')}
        ModalContentComponent={KuolinpesaOsakkuudetModal}
        newEntry={mkNewEntry}
        translationPrefix="kuolinpesaOsakkuudet"
      />
    </section>
  )
}

const KuolinpesaOsakkuudetModal: FC<FormModalProps<FrontKuolinpesaOsakkuus>> =
  observer(({ createDataTestId, entry, setEntry }) => {
    const [t] = useTranslation()
    return (
      <div>
        <FormTextInput
          data-test-id={createDataTestId('modal-vainajanEtunimi')}
          labelText={t('vainajanEtunimi')}
          value={entry.vainajanEtunimi}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) =>
            setEntry({ ...entry, vainajanEtunimi: value })
          }
        />

        <FormTextInput
          data-test-id={createDataTestId('modal-vainajanSukunimi')}
          labelText={t('vainajanSukunimi')}
          value={entry.vainajanSukunimi}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) =>
            setEntry({ ...entry, vainajanSukunimi: value })
          }
        />

        <FormDateInput
          labelText={t('vainajanKuolinpaiva')}
          data-test-id={createDataTestId('modal-vainajanKuolinpaiva')}
          value={
            entry.vainajanKuolinpaiva
              ? formatDateAsLocal(entry.vainajanKuolinpaiva)
              : entry.vainajanKuolinpaiva
          }
          updateValue={(value) => {
            setEntry({
              ...entry,
              vainajanKuolinpaiva: value ? parseLocalDate(value) : value,
            })
          }}
          validate={commonDateValidator(t)}
        />

        <FormNumberInput
          data-test-id={createDataTestId('modal-osakkuudenOsuusProsentteina')}
          labelText={t('osakkuudenOsuusProsentteina')}
          required
          digitMode="two"
          value={entry.osakkuudenOsuusProsentteina}
          validate={commonPercentageWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) =>
            setEntry({
              ...entry,
              osakkuudenOsuusProsentteina: value,
            })
          }
        />

        <FormNumberInput
          data-test-id={createDataTestId('modal-arvoTilikaudenAlussa')}
          labelText={t('arvoTilikaudenAlussaEuroina')}
          digitMode="two"
          required
          value={entry.arvoTilikaudenAlussaEur}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) =>
            setEntry({
              ...entry,
              arvoTilikaudenAlussaEur: value,
            })
          }
        />

        <FormNumberInput
          data-test-id={createDataTestId('modal-arvoTilikaudenLopussa')}
          labelText={t('arvoTilikaudenLopussaEuroina')}
          digitMode="two"
          required
          value={entry.arvoTilikaudenLopussaEur}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) => {
            setEntry({
              ...entry,
              arvoTilikaudenLopussaEur: value,
            })
          }}
        />

        <FormAttachmentFileBox
          observableAttachments={entry.asiakirjat}
          asiakirjaTypeId={TiliAsiointiAsiakirjatyyppi.MUU_LIITE}
          data-test-id={createDataTestId('modal-asiakirja')}
          text={t('kuolinpesaOsakkuudetAsiakirjaInfo')}
          title={t('asiakirjat')}
        />
      </div>
    )
  })

const KuolinpesaOsakkuudetListItem: FC<
  FormElementProps<FrontKuolinpesaOsakkuus>
> = observer(({ createDataTestId, entry, onEdit, onRemove }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const osakkuusProsentti = `${formatNumberWithComma(
    entry.osakkuudenOsuusProsentteina!
  )} %`

  const arvoLopussaEur = `${formatNumberWithComma(
    entry.arvoTilikaudenLopussaEur!
  )} €`
  const arvoAlussaEur = `${formatNumberWithComma(
    entry.arvoTilikaudenAlussaEur!
  )} €`

  return (
    <FormListInputExpander
      heading={`${entry.vainajanEtunimi} ${entry.vainajanSukunimi}`}
      headingRight={arvoLopussaEur}
      createDataTestId={createDataTestId}
      onEdit={onEdit}
      onRemove={onRemove}
      showSubHeadingWhileOpen
    >
      <Heading variant="h5">{t('vainajanKuolinpaiva')}</Heading>
      <Text>{formatDateAsLocal(entry.vainajanKuolinpaiva!)}</Text>
      <Block mt={verticalMarginToken} />

      <Heading variant="h5">{t('osakkuudenOsuusProsentteina')}</Heading>
      <Text>{osakkuusProsentti}</Text>
      <Block mt={verticalMarginToken} />

      <Heading variant="h5">{t('arvoTilikaudenAlussaEuroina')}</Heading>
      <Text>{arvoAlussaEur}</Text>
      <Block mt={verticalMarginToken} />

      {entry.asiakirjat.length > 0 && (
        <>
          <Block mt={verticalMarginToken} />
          <Heading variant="h5">{t('asiakirjat')}</Heading>
          <AttachmentList attachments={entry.asiakirjat} />
          <Block mt={verticalMarginToken} />
        </>
      )}
      <Block mt={verticalMarginToken} />
    </FormListInputExpander>
  )
})

export default observer(KuolinpesaOsakkuudetForm)
