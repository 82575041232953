import keyMirror from 'keymirror'

export const VireilletulokanavaClass = keyMirror({
  KANSALAINEN: null,
  VIRKAILIJA: null,
  JARJESTELMAINTEGRAATIO: null,
})
export type VireilletulokanavaClassId = (typeof VireilletulokanavaClass)[keyof typeof VireilletulokanavaClass]

export const allVireilletulokanavaClassIds = Object.keys(VireilletulokanavaClass) as VireilletulokanavaClassId[]

export const Vireilletulokanava = keyMirror({
  ITSEPALVELU: null,
  ELOMAKE_KANSALAINEN: null,
  ELSA: null,
  TOIVO: null,
  EDVARD: null,
  KELA_LUOVA: null,
  NEUVOLA_KALI: null,
  ELOMAKE_JARJESTELMA: null,
  VAESTOTIETOJARJESTELMA: null,
  POSTI: null,
})
export type VireilletulokanavaId = (typeof Vireilletulokanava)[keyof typeof Vireilletulokanava]

export const allVireilletulokanavaIds = Object.keys(Vireilletulokanava) as VireilletulokanavaId[]

// validated with koodisto test against database that vireilletulokanava belongs to one class and one class only
export const VireilletulokanavaClassVireilletulokanavas: {
  [k in VireilletulokanavaClassId]: VireilletulokanavaId[]
} = {
  [VireilletulokanavaClass.KANSALAINEN]: [Vireilletulokanava.ITSEPALVELU, Vireilletulokanava.ELOMAKE_KANSALAINEN],
  [VireilletulokanavaClass.VIRKAILIJA]: [Vireilletulokanava.ELSA, Vireilletulokanava.TOIVO],
  [VireilletulokanavaClass.JARJESTELMAINTEGRAATIO]: [
    Vireilletulokanava.EDVARD,
    Vireilletulokanava.KELA_LUOVA,
    Vireilletulokanava.NEUVOLA_KALI,
    Vireilletulokanava.ELOMAKE_JARJESTELMA,
    Vireilletulokanava.VAESTOTIETOJARJESTELMA,
    Vireilletulokanava.POSTI,
  ],
}
