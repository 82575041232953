import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Block, Heading, Paragraph, Text } from 'suomifi-ui-components'
import { mkYleiskatsausTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { FormListInputExpander } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import { getPaatostili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonDateValidator,
  commonEuroWithDecimalsOrUndefinedValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/react-form-validation.util'
import FormDateInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDateInput'
import FormDropdown from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDropdown'
import { FrontSaannollinenTulo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { observable } from 'mobx'
import {
  formatDateAsLocal,
  formatNumberWithComma,
  parseLocalDate,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import { AsiointiSaannollinenTuloTyyppi } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import { TiliAsiointiAsiakirjatyyppi } from 'tilintarkastus-common/src/vtj/types/asiointi-account-data.type'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { AsiointiLedgerAccountIncomeType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-income-enum'

const SaannollisetTulotForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const account = getPaatostili()

  return (
    <section data-test-id={mkYleiskatsausTestId('tulot-form')}>
      <Heading variant="h3">{t('saannollisetTulotHeading')}</Heading>
      <Block mt={verticalMarginToken} />
      <Paragraph>{t('ohjeIlmoitaSaannollisetTulot')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={SaannollisetTulotListItemContent}
        state={account.saannollisetTulot}
        dataTestIdPrefix={mkYleiskatsausTestId('tulot-form')}
        ModalContentComponent={SaannollisetTulotModalContent}
        newEntry={() =>
          observable({
            id: crypto.randomUUID(),
            tyyppi: undefined,
            maksaja: '',
            nettosumma: 0,
            beginDate: undefined,
            endDate: undefined,
            asiakirjat: [],
          })
        }
        translationPrefix="tulot"
      />
    </section>
  )
})

export default SaannollisetTulotForm

const SaannollisetTulotModalContent = ({
  entry,
  setEntry,
  createDataTestId,
}: FormModalProps<FrontSaannollinenTulo>) => {
  const [t] = useTranslation()

  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const tulotyypit = Object.values(AsiointiSaannollinenTuloTyyppi).reduce(
    (
      result: { name: string; value: AsiointiLedgerAccountIncomeType }[],
      tyyppi
    ) => {
      return [...result, { name: t(`incomeType_${tyyppi}`), value: tyyppi }]
    },
    []
  )

  return (
    <>
      <FormDropdown
        labelText={t('tyyppi')}
        data-test-id={createDataTestId('modal-tyyppi')}
        value={entry.tyyppi}
        items={tulotyypit}
        required
        updateValue={(value) => {
          setEntry({
            ...entry,
            tyyppi: value as AsiointiLedgerAccountIncomeType,
          })
        }}
      />
      <Block mt={verticalMarginToken} />

      <FormTextInput
        data-test-id={createDataTestId('modal-maksaja')}
        labelText={t('maksaja')}
        value={entry.maksaja}
        required
        validate={commonXssValidator(t)}
        updateValue={(value) => setEntry({ ...entry, maksaja: value })}
      />

      <FormNumberInput
        data-test-id={createDataTestId('modal-nettosumma')}
        labelText={t('nettosummaKuukaudessaEuroina')}
        value={entry.nettosumma}
        validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
        required
        updateValue={(value) =>
          setEntry({
            ...entry,
            nettosumma: !value ? 0 : value,
          })
        }
      />

      <FormDateInput
        labelText={t('alkamispaivamaara')}
        data-test-id={createDataTestId('modal-alkamispaivamaara')}
        value={
          entry.beginDate ? formatDateAsLocal(entry.beginDate) : entry.beginDate
        }
        updateValue={(value) => {
          setEntry({
            ...entry,
            beginDate: value ? parseLocalDate(value) : value,
          })
        }}
        validate={commonDateValidator(t)}
      />
      <FormDateInput
        labelText={t('paattymispaivamaara')}
        data-test-id={createDataTestId('modal-paattymispaivamaara')}
        value={entry.endDate ? formatDateAsLocal(entry.endDate) : entry.endDate}
        updateValue={(value) => {
          setEntry({
            ...entry,
            endDate: value ? parseLocalDate(value) : value,
          })
        }}
        validate={commonDateValidator(t)}
      />
      <FormAttachmentFileBox
        observableAttachments={entry.asiakirjat}
        asiakirjaTypeId={TiliAsiointiAsiakirjatyyppi.MUU_LIITE}
        data-test-id={createDataTestId('modal-asiakirja')}
        title={t('asiakirjat')}
        text={t('ohjeSaannolisenTulonAsiakirjat')}
      />
    </>
  )
}

const SaannollisetTulotListItemContent = ({
  entry,
  createDataTestId,
  onEdit,
  onRemove,
}: FormElementProps<FrontSaannollinenTulo>) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  return (
    <FormListInputExpander
      heading={t(`incomeType_${entry.tyyppi}`)}
      headingRight={`${formatNumberWithComma(entry.nettosumma)} € / kk`}
      createDataTestId={createDataTestId}
      onEdit={onEdit}
      onRemove={onRemove}
    >
      <Heading variant="h5">{t('maksaja')}</Heading>
      <Text>{entry.maksaja}</Text>
      <Block mt={verticalMarginToken} />

      <Heading variant="h5">{t('jakso')}</Heading>
      <Text>
        {`${(entry.beginDate && formatDateAsLocal(entry.beginDate)) || ''} - ${
          entry.endDate ? formatDateAsLocal(entry.endDate) : ''
        }`}
      </Text>
      {entry.asiakirjat.length > 0 && (
        <>
          <Block mt={verticalMarginToken} />
          <Heading variant="h5">{t('etuusJaElakepaatokset')}</Heading>
          <AttachmentList attachments={entry.asiakirjat} />
          <Block mt={verticalMarginToken} />
        </>
      )}
      <Block mt={verticalMarginToken} />
    </FormListInputExpander>
  )
}
