import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { Block } from 'suomifi-ui-components'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { Perustiedot } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/yhteenveto/Perustiedot'
import { Yleiskatsaus } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/yhteenveto/Yleiskatsaus'
import { Omaisuus } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/yhteenveto/Omaisuus'
import {
  PAATOSTILI_ROUTES,
  PAATOSTILI_STEPS,
  PaatostiliFormStates,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { FormSubmitBar } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormSubmitBar'
import { useForm, FormProvider } from 'react-hook-form'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import {
  AsiointiFeatureFlag,
  isAsiointiUiFeatureEnabled,
} from 'holhous-common/src/vtj/asiointi-feature-flag'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'

const YhteenvetoPage: React.FC<{
  forms: PaatostiliFormStates
}> = observer(({ forms }) => {
  const [t] = useTranslation()

  const juuriForm = useForm()

  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  return (
    <>
      <FormProvider {...juuriForm}>
        <ResponsiveBorderedContentBlock
          data-test-id={mkYhteenvetoTestId('container')}
        >
          <PageStepHeading
            heading={t('yhteenvetoJaLahetys')}
            steps={PAATOSTILI_STEPS}
            createDataTestId={mkYhteenvetoTestId}
          />
          <Block mt={verticalMarginToken} />
          <DividerLine mt={verticalMarginToken} />
          <Perustiedot showValtakirja />
          {isAsiointiUiFeatureEnabled(AsiointiFeatureFlag.YHTEENVETOSIVU) && (
            <>
              <DividerLine mt={verticalMarginToken} />
              <Yleiskatsaus />
              <DividerLine mt={verticalMarginToken} />
              <Omaisuus />
              <Block mt={verticalMarginToken} />
              Tulot ja menot
              <Block mt={verticalMarginToken} />
              Palkkio ja kulut
              <Block mt={verticalMarginToken} />
              Yhteystiedot
            </>
          )}
          <DividerLine mt={verticalMarginToken} />
          <FormSubmitBar
            forms={forms}
            next={PAATOSTILI_ROUTES.VALMIS}
            previous={PAATOSTILI_ROUTES.YHTEYSTIEDOT}
          />
        </ResponsiveBorderedContentBlock>
      </FormProvider>
    </>
  )
})

export default YhteenvetoPage
