import { action, flow, observable } from 'mobx'
import { TilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store.type'
import { AsiointiLocale } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import { CancellablePromise } from 'mobx/dist/api/flow'
import {
  FrontOmaisuusTilirivit,
  FrontTilirivit,
  Lomake,
  Omaisuusluettelo,
  Paatostili,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { AsiointiPrivatePersonRole } from 'tilintarkastus-common/src/vtj/tili-account-enums'
import { COUNTRY_CODE_FINLAND } from 'holhous-common/src/vtj/country/country.util'
import type { EvtvVaatimus } from 'edunvalvonta-asiointi/src/vtj/asiointi/evtv-asiointi/evtv-api.type'
import { AsiointiUser } from 'edunvalvonta-asiointi/src/vtj/asiointi/authentication/holhous-asiointi-user.type'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import { TIEDOKSIANTO_PERSON_ID } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tiedoksianto.store'
import { TiliDraft } from 'edunvalvonta-asiointi/src/vtj/asiointi/draft/draft-api.type'
import { saveTiliAsDraft } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/draft-store'
import { isPaatostili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { DeepPartial } from 'edunvalvonta-asiointi/src/vtj/asiointi/types'

let store: TilintarkastusStore | undefined

export const initTilintarkastusStore = (
  user: AsiointiUser,
  evtvVaatimus: EvtvVaatimus,
  tiliDraft: TiliDraft['tili'] | undefined,
  lang: HolhousAsiointiLanguageCode
): TilintarkastusStore => {
  if (
    tiliDraft &&
    evtvVaatimus.seurantaAsiavaatimusId !==
      tiliDraft.tili.seurantaAsiaVaatimusId
  ) {
    throw new Error(
      `Mismatch between evtv ${evtvVaatimus.seurantaAsiavaatimusId} and päätöstili tili draft ${tiliDraft?.tili.seurantaAsiaVaatimusId}`
    )
  }

  const lomake =
    evtvVaatimus.asiaType === 'PAATOSTILI'
      ? createEmptyPaatostiliForm(user, evtvVaatimus, tiliDraft, lang)
      : createEmptyOmaisuusluetteloForm(user, evtvVaatimus, tiliDraft, lang)

  store = observable({
    lomake,
    visitedSteps: new Set<string>(),
    submitStatus: 'none',
    submitError: undefined,
    accountingPeriodStartDate: evtvVaatimus.accountingPeriodStartDate,
  } as TilintarkastusStore)
  return store
}

// For testing purposes only
export const initTilintarkastusStoreWithData = (
  data: TilintarkastusStore
): TilintarkastusStore => {
  store = observable(data)
  return store
}

export const getTilintarkastusStore = (): TilintarkastusStore => {
  if (store) {
    return store
  } else {
    throw new Error('TilintarkastusStore not initialized')
  }
}

export const isTilintarkastusStoreInitialized = (
  seurantaAsiavaatimusId: number
): boolean => {
  if (store && store.lomake) {
    return store.lomake.tili.seurantaAsiaVaatimusId === seurantaAsiavaatimusId
  }
  return false
}

export const runTilintarkastusStoreFlow = <T>(
  fn: (store: TilintarkastusStore) => Generator<any, T, any> // eslint-disable-line @typescript-eslint/no-explicit-any
): CancellablePromise<T> => {
  const store = getTilintarkastusStore()
  return flow<T, [TilintarkastusStore]>(fn)(store)
}

export const runTilintarkastusStoreAction = <T>(
  fn: (store: TilintarkastusStore) => T
): T => {
  const store = getTilintarkastusStore()
  return action(fn)(store)
}

export const saveDraft = async (): Promise<void> => {
  const { submitStatus, lomake } = getTilintarkastusStore()
  if (submitStatus === 'submitted') {
    return
  }
  await saveTiliAsDraft(lomake)
}

export const createEmptyOmaisuusluetteloForm = (
  user: AsiointiUser,
  evtvVaatimus: EvtvVaatimus,
  previouslySavedForm: TiliDraft['tili'] | undefined,
  lang: HolhousAsiointiLanguageCode
): Lomake => {
  const paatostili = createEmptyPaatostiliForm(
    user,
    evtvVaatimus,
    previouslySavedForm,
    lang
  )
  return {
    // TODO mitä tästä pitää poistaa? Miten kannattaa koostaa?
    ...paatostili,
    tili: {
      ...paatostili.tili,
      tilirivit: {
        varat: {
          talletukset: [],
          saatavat: [],
          arvopaperit: [],
          huoneistot: [],
          irtaimistot: [],
          kiinteistot: [],
          muutVarat: [],
          osuudetHenkiloYhtiossaJaYrityksissa: [],
          osuudetKuolinpesissa: [],
        },
        velat: {
          pankkilainat: [],
          takaukset: [],
          muutVelat: [],
        },
      },
      asiaType: 'OMAISUUSLUETTELO',
      kaytossaOlevaOmaisuus: [],
    },
  }
}

export const createEmptyPaatostiliForm = (
  user: AsiointiUser,
  evtvVaatimus: EvtvVaatimus,
  previouslySavedForm: TiliDraft['tili'] | undefined,
  lang: HolhousAsiointiLanguageCode
): Lomake => {
  if (previouslySavedForm && !isPaatostili(previouslySavedForm.tili)) {
    throw new Error('Not a Päätöstili')
  }

  const [previousTiedoksiantoPerson] =
    previouslySavedForm?.otherPersons?.privatePersons?.filter(
      (p) => p?.id === TIEDOKSIANTO_PERSON_ID
    ) || []

  const paatostili: Paatostili = {
    isMuutMerkittavatToimet: false,
    muutMerkittavatToimet: undefined,
    isAnnettuKayttovaroja: true,
    kayttovaraKaytanto: '',
    asuinpaikat: [],
    saannollisetTulot: [],
    verotustiedot: [],
    sopimukset: [],
    vakuutukset: [],
    tilirivit: {
      varat: {
        talletukset: [],
        saatavat: [],
        arvopaperit: [],
        huoneistot: [],
        irtaimistot: [],
        kiinteistot: [],
        muutVarat: [],
        osuudetHenkiloYhtiossaJaYrityksissa: [],
        osuudetKuolinpesissa: [],
      },
      velat: {
        pankkilainat: [],
        takaukset: [],
        muutVelat: [],
      },
      tulot: {
        elakkeet: [],
        palkat: [],
        etuudet: [],
        tyottomyysturva: [],
        vuokratulot: [],
        osinkotulot: [],
        korkotulot: [],
        myyntitulot: [],
        muutTulot: [],
      },
      menot: {
        kayttovarat: [],
        elinkustannukset: [],
        palvelumaksut: [],
        vuokratVastikkeet: [],
        elatusavut: [],
        pankinPerimatMaksut: [],
        verot: [],
        tyonantajakulut: [],
        velanhoitomenot: [],
        edunvalvonnankulut: [],
        muutMenot: [],
      },
    },
    palkkio: {
      veloitatkoKulukorvauksia: true,
      veloitatkoPalkkiota: true,
      kulukorvausMaara: 0,
      kululaskelma: [],
      palkkioMaara: 0,
    },
    seurantaAsiaVaatimusId: evtvVaatimus.seurantaAsiavaatimusId,
    asiaType: 'PAATOSTILI',
    isHallintaoikeuksia: false,
    mitenHallintaoikeuksistaOnSovittu: undefined,
    hallintaoikeusSopimukset: [],
    edunvalvojanValtakirja: [],
  }

  return {
    locale: AsiointiLocale.fi, // TODO language pitäis olla dynaaminen? Aseta vasta ennen lähetystä?
    otherPersons: {
      privatePersons: [
        {
          id: TIEDOKSIANTO_PERSON_ID,
          role: AsiointiPrivatePersonRole.YKSITYISHENKILO,
          email: previousTiedoksiantoPerson?.email || '',
          firstnames: previousTiedoksiantoPerson?.firstnames || '',
          lastname: previousTiedoksiantoPerson?.lastname || '',
          phone: previousTiedoksiantoPerson?.phone || '',
          address: {
            streetAddress:
              previousTiedoksiantoPerson?.address?.streetAddress || '',
            postOffice: previousTiedoksiantoPerson?.address?.postOffice || '',
            postalCode: previousTiedoksiantoPerson?.address?.postalCode || '',
            countryId:
              previousTiedoksiantoPerson?.address?.countryId ||
              COUNTRY_CODE_FINLAND,
          },
          isAssetsRecipient: true,
        },
      ],
      asiointiCaretakers: [],
      businesses: [],
    },
    caretaker: {
      firstnames: previouslySavedForm?.caretaker?.firstnames ?? user.firstnames,
      lastname: previouslySavedForm?.caretaker?.lastname ?? user.lastname,
      email: previouslySavedForm?.caretaker?.email || user.email || '', // käyttäjä saa poistaa userin arvon
      phone: previouslySavedForm?.caretaker?.phone || user.email || '', // käyttäjä saa poistaa userin arvon
      address: {
        streetAddress:
          previouslySavedForm?.caretaker?.address?.streetAddress ??
          user.streetAddress?.[lang] ??
          '',
        postOffice:
          previouslySavedForm?.caretaker?.address?.postOffice ??
          user.postOffice?.[lang] ??
          '',
        postalCode:
          previouslySavedForm?.caretaker?.address?.postalCode ??
          user.postalCode ??
          '',
        countryId:
          previouslySavedForm?.caretaker?.address?.countryId ??
          user.countryCode ??
          COUNTRY_CODE_FINLAND,
      },
    },
    tili:
      previouslySavedForm && isPaatostili(previouslySavedForm.tili)
        ? mergeEmptyAndPreviousLomake(paatostili, previouslySavedForm.tili)
        : paatostili,
  }
}

const mergeEmptyAndPreviousLomake = <T extends Paatostili | Omaisuusluettelo>(
  emptyAccount: T,
  draftTili: DeepPartial<T>
): T => {
  const { tilirivit, palkkio, ...safePreviousValues } = draftTili

  const sharedFields = {
    // perusasiat
    ...emptyAccount,
    ...safePreviousValues,

    // Kun kaikki draft-tyypin arvot ovat partial,
    //  niin varmistetaan että object keys pysyvät ennallaan
    tilirivit: tilirivit
      ? mergeTilirivit(emptyAccount.tilirivit, tilirivit, emptyAccount.asiaType)
      : emptyAccount.tilirivit,
    // Staattiset säännöt
    seurantaAsiaVaatimusId: emptyAccount.seurantaAsiaVaatimusId,
    asiaType: emptyAccount.asiaType,
  }

  if (isPaatostili(emptyAccount)) {
    return {
      ...sharedFields,
      palkkio: palkkio ? { ...emptyAccount, ...palkkio } : emptyAccount.palkkio,
    }
  } else {
    return {
      ...sharedFields,
    }
  }
}

const mergeTilirivit = (
  emptyTilirivit: FrontTilirivit | FrontOmaisuusTilirivit,
  draftTilirivit: DeepPartial<FrontTilirivit | FrontOmaisuusTilirivit>,
  asiaType: 'OMAISUUSLUETTELO' | 'PAATOSTILI'
): FrontTilirivit | FrontOmaisuusTilirivit => {
  const commonTilirivit = {
    varat: {
      ...emptyTilirivit.varat,
      ...draftTilirivit.varat,
    },
    velat: {
      ...emptyTilirivit.velat,
      ...draftTilirivit.velat,
    },
  } as FrontOmaisuusTilirivit
  if (asiaType === 'PAATOSTILI') {
    return {
      ...commonTilirivit,
      menot: {
        ...emptyTilirivit.menot,
        ...draftTilirivit.menot,
      },
      tulot: {
        ...emptyTilirivit.tulot,
        ...draftTilirivit.tulot,
      },
    } as FrontTilirivit
  } else {
    return commonTilirivit
  }
}
