import fp from 'lodash/fp'
import {
  AsiakirjaType,
  AsiakirjaOriginType,
} from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import { MimeType } from 'common/src/vtj/MimeType.enum'
import keyMirror from 'keymirror'
import { asiakirjaMetadataDeprecated } from 'asia-common/src/vtj/koodisto/asiakirja-metadata'
import { AsiaServiceArea } from 'asia-common/src/vtj/koodisto/asia-enums'
import {
  LupaApplicationType,
  LupaApplicationTypeId,
} from 'lupa-backend/src/vtj/elsa/lupa/lupa-enums'

export const LupaAsiakirjaType = fp.pick(
  [
    'ARVIO_OMAISUUDESTA',
    'ASUMISOIKEUSSOPIMUS_LUOVUTUSHINTALASKELMA_JA_LUOPUMISILMOITUS_TAI_KAUPPAKIRJA',
    'AVIOEHTOSOPIMUS',
    'ESISOPIMUS',
    'ESITTELIJAN_ERIAVA_MIELIPIDE',
    'ILMOITUS_ASIAN_RAUKEAMISESTA',
    'ISANNOITSIJANTODISTUS',
    'KIINTEISTOREKISTERIN_OTE',
    'KUULEMISKIRJE',
    'LAAKARINLAUSUNTO',
    'LAINHUUTOTODISTUS',
    'LUPAHAKEMUS',
    'MAANVUOKRASOPIMUS',
    'MUU_LIITE',
    'MUU_SELVITYS_PAAMIEHEN_MIELIPITEEN_SELVITTAMISESTA',
    'MUU_SELVITYS_PERUSTEESTA_OLLA_KUULEMATTA_PAAMIESTA',
    'MUU_VIRASTON_LAATIMA_ASIAKIRJA',
    'MUU_VIRASTON_SAAMA_ASIAKIRJA',
    'PAATOS',
    'PAAMIEHEN_LAUSUNTO',
    'PERUKIRJA',
    'RASITUSTODISTUS',
    'SELVITYS_LAINANHOITOKYVYSTA',
    'SELVITYS_OMAISUUDEN_MYYNNISTA',
    'SOPIMUS',
    'SOPIMUSLUONNOS',
    'TAYDENNYS',
    'TAYDENNYSPYYNTO',
    'TESTAMENTTI',
    'TILITAPAHTUMAT',
    'VALITUSOSOITUS',
    'VASTAUS_KUULEMISEEN',
    'VUOKRAOIKEUSTODISTUS',
    'VALTAKIRJA',
  ],
  AsiakirjaType
)
export type LupaAsiakirjaTypeId =
  (typeof LupaAsiakirjaType)[keyof typeof LupaAsiakirjaType]

// FIXME: Use asiaTypeMetadata from asia-common `asia-type-metadata.ts`
// Asia types can have different asiakirja metadata and using old service-area mapping can lead to errors in asianhallinta sync
export const lupaAsiakirjaMetadata = asiakirjaMetadataDeprecated[
  AsiaServiceArea.LUVAT
].filter((a) => {
  return fp.contains(a.asiakirjaTypeId, fp.keys(LupaAsiakirjaType))
})

// Asiakirja types that applicants, e.g. public caretakers using Edvard,
// can send as an attachments of any type of application.
const CommonAttachmentAsiakirjaType = fp.pick(
  [
    'ARVIO_OMAISUUDESTA',
    'AVIOEHTOSOPIMUS',
    'ESISOPIMUS',
    'SOPIMUS',
    'SOPIMUSLUONNOS',
    'ISANNOITSIJANTODISTUS',
    'PAAMIEHEN_LAUSUNTO',
    'LAAKARINLAUSUNTO',
    'LAINHUUTOTODISTUS',
    'MUU_SELVITYS_PAAMIEHEN_MIELIPITEEN_SELVITTAMISESTA',
    'MUU_SELVITYS_PERUSTEESTA_OLLA_KUULEMATTA_PAAMIESTA',
    'KIINTEISTOREKISTERIN_OTE',
    'PERUKIRJA',
    'RASITUSTODISTUS',
    'MUU_LIITE',
    'VUOKRAOIKEUSTODISTUS',
    'TESTAMENTTI',
    'VALTAKIRJA',
  ],
  LupaAsiakirjaType
)

// Attachment types and types that are used in the handling phase.
const CommonLupaAsiakirjaType = {
  ...CommonAttachmentAsiakirjaType,
  ...fp.pick(
    [
      'LUPAHAKEMUS',
      'MUU_VIRASTON_LAATIMA_ASIAKIRJA',
      'MUU_VIRASTON_SAAMA_ASIAKIRJA',
      'PAATOS',
      'VALITUSOSOITUS',
      'ESITTELIJAN_ERIAVA_MIELIPIDE',
      'ILMOITUS_ASIAN_RAUKEAMISESTA',
      'TAYDENNYS',
      'TAYDENNYSPYYNTO',
      'KUULEMISKIRJE',
      'VASTAUS_KUULEMISEEN',
    ],
    LupaAsiakirjaType
  ),
}

export const PerinnonjakoApplicationAttachmentType = {
  ...CommonAttachmentAsiakirjaType,
  ...fp.pick(
    ['SELVITYS_OMAISUUDEN_MYYNNISTA', 'TILITAPAHTUMAT'],
    LupaAsiakirjaType
  ),
}
export const PerinnonjakoAsiakirjaType = {
  ...CommonLupaAsiakirjaType,
  ...PerinnonjakoApplicationAttachmentType,
}
export const perinnonjakoAsiakirjaMetadata = lupaAsiakirjaMetadata.filter((a) =>
  fp.contains(a.asiakirjaTypeId, fp.keys(PerinnonjakoAsiakirjaType))
)

export const MyyntiApplicationAttachmentType = {
  ...CommonAttachmentAsiakirjaType,
  ...fp.pick(
    [
      'ASUMISOIKEUSSOPIMUS_LUOVUTUSHINTALASKELMA_JA_LUOPUMISILMOITUS_TAI_KAUPPAKIRJA',
      'MAANVUOKRASOPIMUS',
    ],
    LupaAsiakirjaType
  ),
}
export const MyyntiAsiakirjaType = {
  ...CommonLupaAsiakirjaType,
  ...MyyntiApplicationAttachmentType,
}

export const myyntiAsiakirjaMetadata = lupaAsiakirjaMetadata.filter((a) =>
  fp.contains(a.asiakirjaTypeId, fp.keys(MyyntiAsiakirjaType))
)

export const MuuLupaApplicationAttachmentType = {
  ...CommonAttachmentAsiakirjaType,
  ...fp.pick(
    [
      'ASUMISOIKEUSSOPIMUS_LUOVUTUSHINTALASKELMA_JA_LUOPUMISILMOITUS_TAI_KAUPPAKIRJA',
      'MAANVUOKRASOPIMUS',
      'SELVITYS_LAINANHOITOKYVYSTA',
      'SELVITYS_OMAISUUDEN_MYYNNISTA',
      'TILITAPAHTUMAT',
    ],
    LupaAsiakirjaType
  ),
}
export const MuuLupaAsiakirjaType = {
  ...CommonLupaAsiakirjaType,
  ...MuuLupaApplicationAttachmentType,
}
export const muuLupaAsiakirjaMetadata = lupaAsiakirjaMetadata.filter((a) =>
  fp.contains(a.asiakirjaTypeId, fp.keys(MuuLupaAsiakirjaType))
)

export const applicationAttachmentTypeEnumByLupaType = {
  [LupaApplicationType.PERINNONJAKO]: PerinnonjakoApplicationAttachmentType,
  [LupaApplicationType.MYYNTI]: MyyntiApplicationAttachmentType,
  [LupaApplicationType.MUU]: MuuLupaApplicationAttachmentType,
}

export const asiakirjaTypeEnumByLupaType = {
  [LupaApplicationType.PERINNONJAKO]: PerinnonjakoAsiakirjaType,
  [LupaApplicationType.MYYNTI]: MyyntiAsiakirjaType,
  [LupaApplicationType.MUU]: MuuLupaAsiakirjaType,
}

export const asiakirjaMetadataByLupaType = {
  [LupaApplicationType.PERINNONJAKO]: perinnonjakoAsiakirjaMetadata,
  [LupaApplicationType.MYYNTI]: myyntiAsiakirjaMetadata,
  [LupaApplicationType.MUU]: muuLupaAsiakirjaMetadata,
}

export const LupaAsiakirjaMimeType = fp.pick(
  ['PDF', 'ODT', 'DOCX', 'JPEG', 'PNG', 'TXT', 'XLS'],
  MimeType
)
export type LupaAsiakirjaMimeTypeId =
  (typeof LupaAsiakirjaMimeType)[keyof typeof LupaAsiakirjaMimeType]
export const allowedLupaAsiakirjaMimeTypes: MimeType[] = [
  LupaAsiakirjaMimeType.PDF,
]

export const LupaAsiakirjaPublicityClass = keyMirror({
  JULKINEN: null,
  OSITTAIN_SALASSAPIDETTAVA: null,
  SALAINEN: null,
})
export type LupaAsiakirjaPublicityClassId =
  (typeof LupaAsiakirjaPublicityClass)[keyof typeof LupaAsiakirjaPublicityClass]

export const LupaAsiakirjaDeliveryMethod = keyMirror({
  VERKKOSOVELLUS: null,
  INTEGRAATIO: null,
  KIRJE: null,
  SAHKOPOSTI: null,
  PUHELIN: null,
})
export type LupaAsiakirjaDeliveryMethodId =
  (typeof LupaAsiakirjaDeliveryMethod)[keyof typeof LupaAsiakirjaDeliveryMethod]

export const LupaAsiakirjaRetention = keyMirror({
  PYSYVA: null,
})
export type LupaAsiakirjaRetentionId =
  (typeof LupaAsiakirjaRetention)[keyof typeof LupaAsiakirjaRetention]

export const LupaAsiakirjaOriginType = fp.pick(
  ['SAAPUNUT', 'LAADITTU'],
  AsiakirjaOriginType
)
export type LupaAsiakirjaOriginTypeId =
  (typeof LupaAsiakirjaOriginType)[keyof typeof LupaAsiakirjaOriginType]

export const LupaAsiakirjaSection = keyMirror({
  COMPOSED: null,
  MUU_LUPA_STATEMENTS_AND_OWNERSHIP: null,
  MUU_LUPA: null,
  MYYNTI: null,
  OTHER_INFO: null,
  OWNERSHIP: null,
  PERINNONJAKO_ESTATE_ASSETS: null,
  PRINCIPAL_OPINIONS: null,
  OBJECT_OF_SALE_AND_OWNERSHIP: null,
  ACTION: null,
  PERINNONJAKO: null,
})

export type LupaAsiakirjaSectionId =
  (typeof LupaAsiakirjaSection)[keyof typeof LupaAsiakirjaSection]

const perinnonjakoDocumentTypes: LupaAsiakirjaTypeId[] = [
  PerinnonjakoAsiakirjaType.LUPAHAKEMUS,
  PerinnonjakoAsiakirjaType.SOPIMUS,
  PerinnonjakoAsiakirjaType.ESISOPIMUS,
  PerinnonjakoAsiakirjaType.SOPIMUSLUONNOS,
  PerinnonjakoAsiakirjaType.AVIOEHTOSOPIMUS,
  PerinnonjakoAsiakirjaType.PERUKIRJA,
  PerinnonjakoAsiakirjaType.TESTAMENTTI,
]

const myyntiDocumentTypes: LupaAsiakirjaTypeId[] = [
  MyyntiAsiakirjaType.LUPAHAKEMUS,
  MyyntiAsiakirjaType.SOPIMUS,
  MyyntiAsiakirjaType.ESISOPIMUS,
  MyyntiAsiakirjaType.SOPIMUSLUONNOS,
  MyyntiAsiakirjaType.ASUMISOIKEUSSOPIMUS_LUOVUTUSHINTALASKELMA_JA_LUOPUMISILMOITUS_TAI_KAUPPAKIRJA,
]

const muuLupaDocumentTypes: LupaAsiakirjaTypeId[] = [
  MuuLupaAsiakirjaType.LUPAHAKEMUS,
  MuuLupaAsiakirjaType.SOPIMUS,
  MuuLupaAsiakirjaType.ESISOPIMUS,
  MuuLupaAsiakirjaType.SOPIMUSLUONNOS,
  MuuLupaAsiakirjaType.ASUMISOIKEUSSOPIMUS_LUOVUTUSHINTALASKELMA_JA_LUOPUMISILMOITUS_TAI_KAUPPAKIRJA,
  MuuLupaAsiakirjaType.MAANVUOKRASOPIMUS,
]

export const lupaSpecificSections: Record<
  LupaApplicationTypeId,
  {
    actionSubsection: LupaAsiakirjaSectionId
    otherSections: LupaAsiakirjaSectionId[]
  }
> = {
  PERINNONJAKO: {
    actionSubsection: LupaAsiakirjaSection.PERINNONJAKO,
    otherSections: [LupaAsiakirjaSection.PERINNONJAKO_ESTATE_ASSETS],
  },
  MYYNTI: {
    actionSubsection: LupaAsiakirjaSection.MYYNTI,
    otherSections: [
      LupaAsiakirjaSection.OBJECT_OF_SALE_AND_OWNERSHIP,
      LupaAsiakirjaSection.OWNERSHIP,
    ],
  },
  MUU: {
    actionSubsection: LupaAsiakirjaSection.MUU_LUPA,
    otherSections: [
      LupaAsiakirjaSection.MUU_LUPA_STATEMENTS_AND_OWNERSHIP,
      LupaAsiakirjaSection.OWNERSHIP,
    ],
  },
}

export const sectionLupaAsiakirjaTypes: Record<
  LupaAsiakirjaSectionId,
  LupaAsiakirjaTypeId[]
> = {
  [LupaAsiakirjaSection.MUU_LUPA_STATEMENTS_AND_OWNERSHIP]: [
    MuuLupaApplicationAttachmentType.ARVIO_OMAISUUDESTA,
    MuuLupaApplicationAttachmentType.ISANNOITSIJANTODISTUS,
    MuuLupaApplicationAttachmentType.KIINTEISTOREKISTERIN_OTE,
    MuuLupaApplicationAttachmentType.LAINHUUTOTODISTUS,
    MuuLupaApplicationAttachmentType.RASITUSTODISTUS,
    MuuLupaApplicationAttachmentType.SELVITYS_LAINANHOITOKYVYSTA,
    MuuLupaApplicationAttachmentType.SELVITYS_OMAISUUDEN_MYYNNISTA,
    MuuLupaApplicationAttachmentType.TILITAPAHTUMAT,
    MuuLupaApplicationAttachmentType.VUOKRAOIKEUSTODISTUS,
  ],
  [LupaAsiakirjaSection.MYYNTI]: myyntiDocumentTypes,
  [LupaAsiakirjaSection.OWNERSHIP]: [
    LupaAsiakirjaType.AVIOEHTOSOPIMUS,
    LupaAsiakirjaType.PERUKIRJA,
    LupaAsiakirjaType.TESTAMENTTI,
  ],
  [LupaAsiakirjaSection.PERINNONJAKO_ESTATE_ASSETS]: [
    LupaAsiakirjaType.ARVIO_OMAISUUDESTA,
    LupaAsiakirjaType.ISANNOITSIJANTODISTUS,
    LupaAsiakirjaType.KIINTEISTOREKISTERIN_OTE,
    LupaAsiakirjaType.LAINHUUTOTODISTUS,
    LupaAsiakirjaType.RASITUSTODISTUS,
    LupaAsiakirjaType.SELVITYS_OMAISUUDEN_MYYNNISTA,
    LupaAsiakirjaType.TILITAPAHTUMAT,
    LupaAsiakirjaType.VUOKRAOIKEUSTODISTUS,
  ],
  [LupaAsiakirjaSection.OTHER_INFO]: [
    LupaAsiakirjaType.VALTAKIRJA,
    LupaAsiakirjaType.MUU_LIITE,
    LupaAsiakirjaType.MUU_VIRASTON_SAAMA_ASIAKIRJA,
    LupaAsiakirjaType.TAYDENNYS,
  ],
  [LupaAsiakirjaSection.PRINCIPAL_OPINIONS]: [
    LupaAsiakirjaType.PAAMIEHEN_LAUSUNTO,
    LupaAsiakirjaType.LAAKARINLAUSUNTO,
    LupaAsiakirjaType.MUU_SELVITYS_PAAMIEHEN_MIELIPITEEN_SELVITTAMISESTA,
    LupaAsiakirjaType.MUU_SELVITYS_PERUSTEESTA_OLLA_KUULEMATTA_PAAMIESTA,
    LupaAsiakirjaType.VASTAUS_KUULEMISEEN,
  ],
  [LupaAsiakirjaSection.OBJECT_OF_SALE_AND_OWNERSHIP]: [
    LupaAsiakirjaType.ARVIO_OMAISUUDESTA,
    LupaAsiakirjaType.ISANNOITSIJANTODISTUS,
    LupaAsiakirjaType.KIINTEISTOREKISTERIN_OTE,
    LupaAsiakirjaType.LAINHUUTOTODISTUS,
    LupaAsiakirjaType.MAANVUOKRASOPIMUS,
    LupaAsiakirjaType.RASITUSTODISTUS,
    LupaAsiakirjaType.VUOKRAOIKEUSTODISTUS,
  ],
  [LupaAsiakirjaSection.MUU_LUPA]: muuLupaDocumentTypes,
  [LupaAsiakirjaSection.ACTION]: fp.uniq(
    perinnonjakoDocumentTypes
      .concat(myyntiDocumentTypes)
      .concat(muuLupaDocumentTypes)
  ),
  [LupaAsiakirjaSection.PERINNONJAKO]: perinnonjakoDocumentTypes,
  [LupaAsiakirjaSection.COMPOSED]: [
    LupaAsiakirjaType.ESITTELIJAN_ERIAVA_MIELIPIDE,
    LupaAsiakirjaType.ILMOITUS_ASIAN_RAUKEAMISESTA,
    LupaAsiakirjaType.KUULEMISKIRJE,
    LupaAsiakirjaType.MUU_VIRASTON_LAATIMA_ASIAKIRJA,
    LupaAsiakirjaType.PAATOS,
    LupaAsiakirjaType.TAYDENNYSPYYNTO,
  ],
}

export const unsectionedLupaAsiakirjaTypes: LupaAsiakirjaTypeId[] = [
  LupaAsiakirjaType.VALITUSOSOITUS,
]
