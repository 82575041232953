import _ from 'lodash'
import keyMirror from 'keymirror'
import { ViestintaTypeId } from 'asia-common/src/vtj/koodisto/viestinta-enums'
import { AsiakirjaTypeId } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import { HuomioTypeId } from 'asia-common/src/vtj/koodisto/huomio-enums'

export const InternalToimenpideType = keyMirror({
  VIREILLETULO: null,
  VALMISTELU: null,
  PAATOKSENTEKO: null,
})
export type InternalToimenpideTypeId = (typeof InternalToimenpideType)[keyof typeof InternalToimenpideType]
export const DueDateToimenpideType = keyMirror({
  TAYDENTAMINEN: null,
  KUULEMINEN: null,
  SELVITYSPYYNTO: null,
  LAUSUNTOPYYNTO: null,
  LYKKAYS: null,
  LISATIETOPYYNTO: null,
})
export type DueDateToimenpideTypeId = (typeof DueDateToimenpideType)[keyof typeof DueDateToimenpideType]
export const allDueDateToimenpideTypeIds = _.keys(DueDateToimenpideType) as DueDateToimenpideTypeId[]
export const TiedoksiantoToimenpideType = keyMirror({
  TIEDOKSIANTO: null,
})
export type TiedoksiantoToimenpideTypeId = (typeof TiedoksiantoToimenpideType)[keyof typeof TiedoksiantoToimenpideType]
export const allTiedoksiantoToimenpideTypeIds = _.keys(TiedoksiantoToimenpideType) as ToimenpideTypeId[]
export const ExternalToimenpideType = keyMirror({
  ...DueDateToimenpideType,
  ...TiedoksiantoToimenpideType,
})
export type ExternalToimenpideTypeId = (typeof ExternalToimenpideType)[keyof typeof ExternalToimenpideType]
export const allExternalToimenpideTypeIds = _.keys(ExternalToimenpideType) as ExternalToimenpideTypeId[]
export const ToimenpideType = keyMirror({
  ...InternalToimenpideType,
  ...ExternalToimenpideType,
})
export type ToimenpideTypeId = (typeof ToimenpideType)[keyof typeof ToimenpideType]
export const allToimenpideTypeIds = _.keys(ToimenpideType) as ToimenpideTypeId[]

export const ToimenpideStatus = keyMirror({
  AVOIN: null,
  LUKITTU: null,
  SULJETTU: null,
})
export type ToimenpideStatusId = (typeof ToimenpideStatus)[keyof typeof ToimenpideStatus]

export const ToimenpideHuomioPhase = keyMirror({
  ODOTTAA: null,
  SAAPUNUT: null,
  MAARAAIKA: null,
})
export type ToimenpideHuomioPhaseId = (typeof ToimenpideHuomioPhase)[keyof typeof ToimenpideHuomioPhase]

export type ToimenpideHuomioMetadata = {
  [ToimenpideHuomioPhase.ODOTTAA]: HuomioTypeId
  [ToimenpideHuomioPhase.SAAPUNUT]?: HuomioTypeId
  [ToimenpideHuomioPhase.MAARAAIKA]: HuomioTypeId
  showInHuomioFilter: boolean
}

export type ToimenpideMetadata = {
  toimenpideTypeId: ExternalToimenpideTypeId
  internalViestintaTypeIds: ViestintaTypeId[]
  externalViestintaTypeIds: ViestintaTypeId[]
  prioritizedLaadittuAsiakirjaTypeIds: AsiakirjaTypeId[]
  prioritizedSaapunutAsiakirjaTypeIds: AsiakirjaTypeId[]
  // TODO https://jira.dvv.fi/browse/OIVA-1180
  //   automationSaaapunutAsiakirjaTypeId: AsiakirjaTypeId | null
  huomioMetadata: ToimenpideHuomioMetadata | null
}
