import {
  Block,
  Heading,
  InlineAlert,
  suomifiDesignTokens,
} from 'suomifi-ui-components'
import React from 'react'
import { centeredWidthLimited } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { observer } from 'mobx-react'

import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import styled from 'styled-components'
import { Outlet } from 'react-router-dom'
import FormNavigationWizard from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationWizard'
import {
  PAATOSTILI_ROUTES,
  PAATOSTILI_STEPS,
  PaatostiliFormStates,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import {
  AsiointiFeatureFlag,
  isAsiointiUiFeatureEnabled,
} from 'holhous-common/src/vtj/asiointi-feature-flag'

const PaatostiliApplicationPage: React.FC<{
  forms: PaatostiliFormStates
}> = observer(({ forms }) => {
  const [t] = useTranslation()

  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  return (
    <Main>
      <Heading variant="h1">{t('paatostili')}</Heading>

      <Block mt={verticalMarginToken} />

      <MainContent>
        <NavigationAndNotifications>
          <FormNavigationWizard
            forms={forms}
            routes={PAATOSTILI_ROUTES}
            steps={PAATOSTILI_STEPS}
          />
          {!isAsiointiUiFeatureEnabled(AsiointiFeatureFlag.TALLENNUS) && (
            <>
              <Block mt={verticalMarginToken} />
              <InlineAlert
                status="warning"
                labelText={t('eiVoiTallentaaHeading')}
              >
                {t('eiVoiTallentaaInfotext')}
              </InlineAlert>
            </>
          )}
        </NavigationAndNotifications>
        <Block mt={verticalMarginToken} />
        <ApplicationDetails>
          <Outlet />
        </ApplicationDetails>
      </MainContent>
    </Main>
  )
})

export default PaatostiliApplicationPage

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${suomifiDesignTokens.spacing.m};

  @media screen and ${device.tablet} {
    flex-direction: row;
    gap: ${suomifiDesignTokens.spacing.s};
  }
`

const Main = styled.main`
  ${centeredWidthLimited};

  padding: ${suomifiDesignTokens.spacing.s} 0;

  @media ${device.tablet} {
    padding: ${suomifiDesignTokens.spacing.xl} ${suomifiDesignTokens.spacing.xl};
  }
`

const ApplicationDetails = styled.section`
  flex: 1;
`
const NavigationAndNotifications = styled.div`
  margin: 0 ${suomifiDesignTokens.spacing.s};

  @media ${device.tablet} {
    max-width: 330px;
    margin: 0;
  }
`
