import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import {
  AsiaPersonRole,
  AsiaPersonType,
  RatkaisuType,
  AsiaStatus,
  AsiaHandlerRole,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import {
  InternalToimenpideType,
  ToimenpideHuomioPhase,
  ToimenpideType,
} from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import { Retention } from 'asia-common/src/vtj/koodisto/retention-enums'
import { PublicityClass } from 'asia-common/src/vtj/koodisto/restriction-enums'

export const vanhemmuudenKumoaminenMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    {
      asiakirjaTypeId: AsiakirjaType.ASIAN_SIIRTO_LASTENVALVOJALLE,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      toivoAsiakirjaTypeIds: ['b18c6dd1-6496-4e3c-bd6b-4a34d2c07ef7'],
      defaultToivoAsiakirjaTypeId: 'b18c6dd1-6496-4e3c-bd6b-4a34d2c07ef7',
    },
    {
      asiakirjaTypeId: AsiakirjaType.HOITOTODISTUS,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      toivoAsiakirjaTypeIds: ['5fbd61d5-2bd9-40b9-b83b-2f5b24073054'],
      defaultToivoAsiakirjaTypeId: '5fbd61d5-2bd9-40b9-b83b-2f5b24073054',
    },
    {
      asiakirjaTypeId: AsiakirjaType.TAYDENNYS,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      toivoAsiakirjaTypeIds: ['816c3b57-d8d5-41f3-9661-d1baf5b9b9ab'],
      defaultToivoAsiakirjaTypeId: '816c3b57-d8d5-41f3-9661-d1baf5b9b9ab',
    },
    {
      asiakirjaTypeId: AsiakirjaType.TAYDENNYSPYYNTO,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      toivoAsiakirjaTypeIds: ['9f989997-1a1d-4cd2-92a9-28e0ee608b50'],
      defaultToivoAsiakirjaTypeId: '9f989997-1a1d-4cd2-92a9-28e0ee608b50',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_LIITE,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      toivoAsiakirjaTypeIds: ['1cc59614-eeb1-40d4-b78b-8764dffb7084'],
      defaultToivoAsiakirjaTypeId: '1cc59614-eeb1-40d4-b78b-8764dffb7084',
    },
    {
      asiakirjaTypeId: AsiakirjaType.PAATOS,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      toivoAsiakirjaTypeIds: ['1ea4519e-50fc-4015-b60d-5e9ee168c4fe'],
      defaultToivoAsiakirjaTypeId: '1ea4519e-50fc-4015-b60d-5e9ee168c4fe',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SAANTITODISTUS,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      toivoAsiakirjaTypeIds: ['5936361f-117f-464f-9bf7-50c5e886891c'],
      defaultToivoAsiakirjaTypeId: '5936361f-117f-464f-9bf7-50c5e886891c',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SIVIILISAATYTODISTUS,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      toivoAsiakirjaTypeIds: ['b3b5d200-9b5a-4a1c-87f1-8467ab91059c'],
      defaultToivoAsiakirjaTypeId: 'b3b5d200-9b5a-4a1c-87f1-8467ab91059c',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SYNTYMATODISTUS,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      toivoAsiakirjaTypeIds: ['4e10adaa-a907-4aca-a247-1a6a5fbe1589'],
      defaultToivoAsiakirjaTypeId: '4e10adaa-a907-4aca-a247-1a6a5fbe1589',
    },
    {
      asiakirjaTypeId: AsiakirjaType.VANHEMMUUDEN_TUNNUSTAMISASIAKIRJA_SAHKOINEN,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      toivoAsiakirjaTypeIds: ['dacba2eb-570d-4726-a8b8-33203e8d3f3c'],
      defaultToivoAsiakirjaTypeId: 'dacba2eb-570d-4726-a8b8-33203e8d3f3c',
    },
    {
      asiakirjaTypeId: AsiakirjaType.VANHEMMUUDEN_TUNNUSTAMISASIAKIRJA_FYYSINEN,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      toivoAsiakirjaTypeIds: ['a62f565a-cc0c-4ed5-a661-a7e36be235bb'],
      defaultToivoAsiakirjaTypeId: 'a62f565a-cc0c-4ed5-a661-a7e36be235bb',
    },
    {
      asiakirjaTypeId: AsiakirjaType.VANHEMMUUDEN_TUNNUSTAMISEN_KUULEMISASIAKIRJA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      toivoAsiakirjaTypeIds: ['a25c0e79-bf29-49cf-a190-c44a5023d8ec'],
      defaultToivoAsiakirjaTypeId: 'a25c0e79-bf29-49cf-a190-c44a5023d8ec',
    },
    {
      asiakirjaTypeId: AsiakirjaType.VANHEMMUUDEN_TUNNUSTAMISEN_KIISTAMINEN_TAI_PERUUTUS,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      toivoAsiakirjaTypeIds: ['3e6e86af-22ae-4c50-b076-89b4857adbf0'],
      defaultToivoAsiakirjaTypeId: '3e6e86af-22ae-4c50-b076-89b4857adbf0',
    },
    {
      asiakirjaTypeId: AsiakirjaType.VANHEMMUUDEN_SELVITTAMISPOYTAKIRJA_LIITTEINEEN,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      toivoAsiakirjaTypeIds: ['4c88cd5e-9340-4ca0-b62b-aea1c22ffcc5'],
      defaultToivoAsiakirjaTypeId: '4c88cd5e-9340-4ca0-b62b-aea1c22ffcc5',
    },
  ],
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.SIVIILISAATY_PUUTTUU,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.SYNTYMAILMOITUS_PUUTTUU,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [],
    organizationTypeIds: [],
  },
  personRoleMetadata: [
    AsiaPersonRole.SYNNYTTAJA,
    AsiaPersonRole.VANHEMMUUDEN_TUNNUSTAJA,
    AsiaPersonRole.LAPSI,
    AsiaPersonRole.OIKEUDENOMISTAJA,
    AsiaPersonRole.LASTENVALVOJA,
    AsiaPersonRole.AVIOMIES,
    AsiaPersonRole.MUU,
  ],
  personTypeMetadata: [AsiaPersonType.IHMINEN],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.RATKAISTU,
    manualRatkaisuTypeIds: [
      RatkaisuType.RATKAISTU,
      RatkaisuType.MYONTEINEN_PAATOS,
      RatkaisuType.KIELTEINEN_PAATOS,
      RatkaisuType.SIIRRETTY_LASTENVALVOJALLE,
      RatkaisuType.RAUENNUT,
    ],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: true,
    mandatorySpecifier: false,
    specifierTypeIds: [],
  },
  toimenpideMetadata: [
    {
      toimenpideTypeId: ToimenpideType.TAYDENTAMINEN,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      huomioMetadata: {
        [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_TAYDENNYSTA,
        [ToimenpideHuomioPhase.SAAPUNUT]: HuomioType.TAYDENNYS_SAAPUNUT,
        [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT,
        showInHuomioFilter: true,
      },
    },
    {
      toimenpideTypeId: ToimenpideType.LISATIETOPYYNTO,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      huomioMetadata: {
        [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_LISATIETOA,
        [ToimenpideHuomioPhase.SAAPUNUT]: HuomioType.LISATIETO_SAAPUNUT,
        [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.LISATIEDON_MAARAAIKA_YLITTYNYT,
        showInHuomioFilter: true,
      },
    },
  ],
  vireilletulokanavaMetadata: [Vireilletulokanava.ELSA],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.KASITTELYSSA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.KASITTELYSSA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
}
