import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { Block, Heading, Text } from 'suomifi-ui-components'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { getPaatostili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/holhous-asiointi-user-store'
import {
  Contents,
  YhteenvetoSubBlock,
  YhteenvetoSubBlockContents,
  YhteenvetoSubBlocks,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/yhteenveto/yhteenveto.util'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import {
  getAsuinpaikatContent,
  getHallintaoikeudetContent,
  getKayttovaratContent,
  getMuutMerkittavatToimetContent,
  getSaannollisetTulot,
  getSopimukset,
  getVakuutukset,
  getVerotiedot,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/yhteenveto/yleiskatsaus.util'

export const Yleiskatsaus: React.FC = observer(() => {
  const [t] = useTranslation()
  const account = getPaatostili()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  const lang = useAsiointiUserStore().lang

  const subBlocks: YhteenvetoSubBlock[] = [
    getAsuinpaikatContent(account, t, lang),
    getKayttovaratContent(account, t),
    getSaannollisetTulot(account, t),
    getSopimukset(account, t),
    getVakuutukset(account, t),
    getVerotiedot(account, t),
    getHallintaoikeudetContent(account, t),
    getMuutMerkittavatToimetContent(account, t),
  ].filter((el) => el.content.length)

  if (!subBlocks.length) return null

  return (
    <Block mt={verticalMarginToken}>
      <Heading variant="h3">{t('yleiskatsaus')}</Heading>
      <Block mt="l" />
      <YhteenvetoSubBlocks data-test-id={mkYhteenvetoTestId('yleiskatsaus')}>
        {subBlocks.map((subBlock) => (
          <YhteenvetoSubBlockContents
            key={`yleiskatsaus-block-${subBlock.heading}`}
          >
            <Heading
              variant="h4"
              data-test-id={mkYhteenvetoTestId(
                `yleiskatsaus-block-${subBlock.heading}`
              )}
            >
              {subBlock.heading}
            </Heading>
            {subBlock.content.map((c, idx) => (
              <Block
                data-test-id={mkYhteenvetoTestId(
                  `yleiskatsaus-content-${subBlock.heading}-${idx}`
                )}
                key={`yleiskatsaus-content-${subBlock.heading}-${idx}}`}
              >
                <Contents>
                  {c.heading && <Text variant="bold">{c.heading}</Text>}
                  {typeof c.contentRows === 'string' && (
                    <Text>{c.contentRows}</Text>
                  )}
                  {c.attachments && (
                    <>
                      <AttachmentList attachments={c.attachments} />
                    </>
                  )}
                </Contents>
              </Block>
            ))}
          </YhteenvetoSubBlockContents>
        ))}
      </YhteenvetoSubBlocks>
    </Block>
  )
})
