import {
  getTilintarkastusStore,
  runTilintarkastusStoreAction,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import {
  Omaisuusluettelo,
  EvtvAsiaTili,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { AsiointiPropertyInventoryDataInput } from 'tilintarkastus-common/src/vtj/types/asiointi-account-data.type'

export const getOmaisuusluettelo = (): Omaisuusluettelo => {
  const lomake = getTilintarkastusStore().lomake
  assertIsOmaisuusluettelo(lomake.tili)
  return lomake.tili as Omaisuusluettelo
}

export const isOmaisuusluettelo = (
  tili: Pick<EvtvAsiaTili, 'asiaType'>
): tili is Omaisuusluettelo => {
  return tili.asiaType === 'OMAISUUSLUETTELO'
}

const assertIsOmaisuusluettelo = (tili: EvtvAsiaTili) => {
  if (!isOmaisuusluettelo(tili)) {
    throw new Error('Not a omaisuusluettelo')
  }
}

export type UpdateOmaisuusluetteloValues = Partial<
  Pick<
    AsiointiPropertyInventoryDataInput,
    | 'isAnnettuKayttovaroja'
    | 'kayttovaraKaytanto'
    | 'isMuutMerkittavatToimet'
    | 'muutMerkittavatToimet'
    | 'asuinpaikat'
    | 'isHallintaoikeuksia'
    | 'mitenHallintaoikeuksistaOnSovittu'
    | 'sopimukset'
    | 'vakuutukset'
    | 'tilirivit'
  >
>

export const updateOmaisuusluetteloValues = (
  values: UpdateOmaisuusluetteloValues
): void =>
  runTilintarkastusStoreAction(({ lomake }) => {
    assertIsOmaisuusluettelo(lomake.tili)
    Object.assign(lomake.tili, values)
  })
