import {
  AsiaTypeId,
  AsiaType,
  AsiaPersonRoleId,
  RatkaisuMetadata,
  AsiaPersonTypeId,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { AsiakirjaMetadata } from 'asia-common/src/vtj/koodisto/asiakirja-metadata'
import { muuLupaMetadata } from 'asia-common/src/vtj/koodisto/asia-type/luvat/muu-lupa-metadata'
import { myyntiLupaMetadata } from 'asia-common/src/vtj/koodisto/asia-type/luvat/myyntilupa-metadata'
import { perinnonjakoMetadata } from 'asia-common/src/vtj/koodisto/asia-type/luvat/perinnonjako-metadata'
import { etunimenmuutosMetadata } from 'asia-common/src/vtj/koodisto/asia-type/nimiasiat/etunimenmuutos-metadata'
import { sukunimenmuutosPaluuAiempaanMetadata } from 'asia-common/src/vtj/koodisto/asia-type/nimiasiat/sukunimenmuutos-paluu-aiempaan-metadata'
import { sukunimiyhdistelmanMuutosMetadata } from 'asia-common/src/vtj/koodisto/asia-type/nimiasiat/sukunimiyhdistelman-muutos-metadata'
import { sukunimenmuutosLuokittelematonMetadata } from 'asia-common/src/vtj/koodisto/asia-type/nimiasiat/sukunimenmuutos-luokittelematon-metadata'
import { perhehakemusMetadata } from 'asia-common/src/vtj/koodisto/asia-type/nimiasiat/perhehakemus-metadata'
import { asuinpaikkatodistusMetadata } from 'asia-common/src/vtj/koodisto/asia-type/todistukset/asuinpaikkatodistus-metadata'
import { avioliittotodistusMetadata } from 'asia-common/src/vtj/koodisto/asia-type/todistukset/avioliittotodistus-metadata'
import { elossaolotodistusMetadata } from 'asia-common/src/vtj/koodisto/asia-type/todistukset/elossaolotodistus-metadata'
import { siviilisaatytodistusMetadata } from 'asia-common/src/vtj/koodisto/asia-type/todistukset/siviilisaatytodistus-metadata'
import { syntymatodistusMetadata } from 'asia-common/src/vtj/koodisto/asia-type/todistukset/syntymatodistus-metadata'
import { vuositiliMetadata } from 'asia-common/src/vtj/koodisto/asia-type/tilintarkastus/vuositili-metadata'
import { paatostiliMetadata } from 'asia-common/src/vtj/koodisto/asia-type/tilintarkastus/paatostili-metadata'
import { ensitiliMetadata } from 'asia-common/src/vtj/koodisto/asia-type/tilintarkastus/ensitili-metadata'
import { omaisuusluetteloMetadata } from 'asia-common/src/vtj/koodisto/asia-type/tilintarkastus/omaisuusluettelo-metadata'
import { tunnustettuEnnenSyntymaaMetadata } from 'asia-common/src/vtj/koodisto/asia-type/vanhemmuuden-vahvistaminen/tunnustettu-ennen-syntymaa-metadata'
import { tunnustettuSyntymanJalkeenMetadata } from 'asia-common/src/vtj/koodisto/asia-type/vanhemmuuden-vahvistaminen/tunnustettu-syntyman-jalkeen-metadata'
import { vanhemmuudenKumoaminenMetadata } from 'asia-common/src/vtj/koodisto/asia-type/vanhemmuuden-vahvistaminen/vanhemmuuden-kumoaminen-metadata'
import { kotikunnanRekisterointiMetadata } from 'asia-common/src/vtj/koodisto/asia-type/ulkomaalaisen-rekisterointi/kotikunnan-rekisterointi-metadata'
import { ensirekisterointiMetadata } from 'asia-common/src/vtj/koodisto/asia-type/ulkomaalaisen-rekisterointi/ensirekisterointi-metadata'
import { edunvalvontavaltuutuksenVahvistaminenMetadata } from 'asia-common/src/vtj/koodisto/asia-type/edunvalvontavaltuutus/edunvalvontavaltuutuksen-vahvistaminen-metadata'
import { edunvalvontavaltuutuksenPeruuttamisenVahvistaminenMetadata } from 'asia-common/src/vtj/koodisto/asia-type/edunvalvontavaltuutus/edunvalvontavaltuutuksen-peruuttamisen-vahvistaminen-metadata'
import { selvitystehtavaMetadata } from 'asia-common/src/vtj/koodisto/asia-type/edunvalvontavaltuutus/selvitystehtava-metadata'
import { siviilisaadynRekisterointiKotimainenMetadata } from 'asia-common/src/vtj/koodisto/asia-type/elamantapahtumien-rekisterointi/siviilisaadyn-rekisterointi-kotimainen-metadata'
import { siviilisaadynRekisterointiUlkomainenMetadata } from 'asia-common/src/vtj/koodisto/asia-type/elamantapahtumien-rekisterointi/siviilisaadyn-rekisterointi-ulkomainen-metadata'
import { kuolemanRekisterointiKotimainenMetadata } from 'asia-common/src/vtj/koodisto/asia-type/elamantapahtumien-rekisterointi/kuoleman-rekisterointi-kotimainen-metadata'
import { kuolemanRekisterointiUlkomainenMetadata } from 'asia-common/src/vtj/koodisto/asia-type/elamantapahtumien-rekisterointi/kuoleman-rekisterointi-ulkomainen-metadata'
import { AsiakirjaTypeId } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import { SpecifierMetadata } from 'asia-common/src/vtj/koodisto/specifier-enums'
import { OrganizationMetadata } from 'asia-common/src/vtj/koodisto/organization-enums'
import { todistusAsumistiedoistaMetadata } from 'asia-common/src/vtj/koodisto/asia-type/todistukset/todistus-asumistiedoista'
import { todistusHenkiloJaPerhesuhdetiedoistaMetadata } from 'asia-common/src/vtj/koodisto/asia-type/todistukset/todistus-henkilo-ja-perhesuhdetiedoista-metadata'
import { todistusKuolemastaMetadata } from 'asia-common/src/vtj/koodisto/asia-type/todistukset/todistus-kuolemasta-metadata'
import { HuomioMetadata, HuomioTypeId } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { VireilletulokanavaId } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import { AsiaTypeAsiaStatusMetadata } from 'asia-common/src/vtj/koodisto/asia-status-metadata'
import { edunvalvojanMaaraaminenIlmoituksellaMetadata } from 'asia-common/src/vtj/koodisto/asia-type/edunvalvontavaltuutus/edunvalvojan-maaraaminen-ilmoituksella'
import { kotimaanMuuttoMetadata } from 'asia-common/src/vtj/koodisto/asia-type/muutot/kotimaan-muutto-metadata'
import { lapsenRekistrointiKotimainenMetadata } from 'asia-common/src/vtj/koodisto/asia-type/elamantapahtumien-rekisterointi/lapsen-rekisterointi-kotimainen'
import { lapsenRekistrointiUlkomainenMetadata } from 'asia-common/src/vtj/koodisto/asia-type/elamantapahtumien-rekisterointi/lapsen-rekisterointi-ulkomainen'
import { kansalaisuusJaUlkomaatiedotMetadata } from 'asia-common/src/vtj/koodisto/asia-type/ulkomaalaisen-rekisterointi/kansalaisuus-ja-ulkomaatiedot-metadata'
import { avioliitonEsteidenTutkintaMetadata } from 'asia-common/src/vtj/koodisto/asia-type/todistukset/avioliiton-esteiden-tutkinta-metadata'
import {
  ToimenpideHuomioPhase,
  ToimenpideHuomioPhaseId,
  ToimenpideMetadata,
} from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import { RestrictionMetadata } from 'asia-common/src/vtj/koodisto/restriction-enums'
import { RetentionMetadata } from 'asia-common/src/vtj/koodisto/retention-enums'

export type AsiaMetadata = {
  asiakirjaMetadata: AsiakirjaMetadata[]
  huomioMetadata: HuomioMetadata[]
  organizationMetadata: OrganizationMetadata
  personRoleMetadata: AsiaPersonRoleId[]
  personTypeMetadata: AsiaPersonTypeId[]
  ratkaisuMetadata: RatkaisuMetadata
  specifierMetadata: SpecifierMetadata
  toimenpideMetadata: ToimenpideMetadata[]
  vireilletulokanavaMetadata: VireilletulokanavaId[]
  asiaStatusMetadata: AsiaTypeAsiaStatusMetadata[]
  restrictionMetadata?: RestrictionMetadata
  retentionMetadata?: RetentionMetadata
}

export type AsiaTypeMetadata = {
  [k in AsiaTypeId]: AsiaMetadata
}

export const asiaTypeMetadata: AsiaTypeMetadata = {
  [AsiaType.ASUINPAIKKATODISTUS]: asuinpaikkatodistusMetadata,
  [AsiaType.AVIOLIITTOTODISTUS]: avioliittotodistusMetadata,
  [AsiaType.EDUNVALVONTAVALTUUTUKSEN_VAHVISTAMINEN]: edunvalvontavaltuutuksenVahvistaminenMetadata,
  [AsiaType.EDUNVALVONTAVALTUUTUKSEN_PERUUTTAMISEN_VAHVISTAMINEN]:
    edunvalvontavaltuutuksenPeruuttamisenVahvistaminenMetadata,
  [AsiaType.ENSIREKISTEROINTI]: ensirekisterointiMetadata,
  [AsiaType.ENSITILI]: ensitiliMetadata,
  [AsiaType.ELOSSAOLOTODISTUS]: elossaolotodistusMetadata,
  [AsiaType.ETUNIMENMUUTOS]: etunimenmuutosMetadata,
  [AsiaType.KOTIKUNNAN_REKISTEROINTI]: kotikunnanRekisterointiMetadata,
  [AsiaType.KUOLEMAN_REKISTEROINTI_KOTIMAINEN]: kuolemanRekisterointiKotimainenMetadata,
  [AsiaType.KUOLEMAN_REKISTEROINTI_ULKOMAINEN]: kuolemanRekisterointiUlkomainenMetadata,
  [AsiaType.MUU_LUPA]: muuLupaMetadata,
  [AsiaType.MYYNTILUPA]: myyntiLupaMetadata,
  [AsiaType.OMAISUUSLUETTELO]: omaisuusluetteloMetadata,
  [AsiaType.PAATOSTILI]: paatostiliMetadata,
  [AsiaType.PERHEHAKEMUS]: perhehakemusMetadata,
  [AsiaType.PERINNONJAKO]: perinnonjakoMetadata,
  [AsiaType.SELVITYSTEHTAVA]: selvitystehtavaMetadata,
  [AsiaType.SIVIILISAADYN_REKISTEROINTI_KOTIMAINEN]: siviilisaadynRekisterointiKotimainenMetadata,
  [AsiaType.SIVIILISAADYN_REKISTEROINTI_ULKOMAINEN]: siviilisaadynRekisterointiUlkomainenMetadata,
  [AsiaType.SIVIILISAATYTODISTUS]: siviilisaatytodistusMetadata,
  [AsiaType.SUKUNIMENMUUTOS_LUOKITTELEMATON]: sukunimenmuutosLuokittelematonMetadata,
  [AsiaType.SUKUNIMENMUUTOS_PALUU_AIEMPAAN]: sukunimenmuutosPaluuAiempaanMetadata,
  [AsiaType.SUKUNIMIYHDISTELMAN_MUUTOS]: sukunimiyhdistelmanMuutosMetadata,
  [AsiaType.SYNTYMATODISTUS]: syntymatodistusMetadata,
  [AsiaType.TUNNUSTETTU_ENNEN_SYNTYMAA]: tunnustettuEnnenSyntymaaMetadata,
  [AsiaType.TUNNUSTETTU_SYNTYMAN_JALKEEN]: tunnustettuSyntymanJalkeenMetadata,
  [AsiaType.VANHEMMUUDEN_KUMOAMINEN]: vanhemmuudenKumoaminenMetadata,
  [AsiaType.VUOSITILI]: vuositiliMetadata,
  [AsiaType.TODISTUS_ASUMISTIEDOISTA]: todistusAsumistiedoistaMetadata,
  [AsiaType.TODISTUS_HENKILO_JA_PERHESUHDETIEDOISTA]: todistusHenkiloJaPerhesuhdetiedoistaMetadata,
  [AsiaType.TODISTUS_KUOLEMASTA]: todistusKuolemastaMetadata,
  [AsiaType.EDUNVALVOJAN_MAARAAMINEN_ILMOITUKSELLA]: edunvalvojanMaaraaminenIlmoituksellaMetadata,
  [AsiaType.KOTIMAAN_MUUTTO]: kotimaanMuuttoMetadata,
  [AsiaType.LAPSEN_REKISTEROINTI_KOTIMAINEN]: lapsenRekistrointiKotimainenMetadata,
  [AsiaType.LAPSEN_REKISTEROINTI_ULKOMAINEN]: lapsenRekistrointiUlkomainenMetadata,
  [AsiaType.KANSALAISUUS_JA_ULKOMAATIEDOT]: kansalaisuusJaUlkomaatiedotMetadata,
  [AsiaType.AVIOLIITON_ESTEIDEN_TUTKINTA]: avioliitonEsteidenTutkintaMetadata,
}

export const getAsiakirjaMetadata = (asiaTypeId: AsiaTypeId, asiakirjaTypeId: AsiakirjaTypeId): AsiakirjaMetadata => {
  return asiaTypeMetadata[asiaTypeId].asiakirjaMetadata.find(
    (a) => a.asiakirjaTypeId === asiakirjaTypeId
  ) as AsiakirjaMetadata
}

export const getSupportedHuomioTypeIds = (
  asiaTypeIds: AsiaTypeId[],
  getToimenpideHuomios = true,
  showInHuomioFilterRequired = false
): HuomioTypeId[] => [
  ...new Set(
    asiaTypeIds.flatMap((at) => [
      ...asiaTypeMetadata[at].huomioMetadata.map((h) => h.huomioTypeId),
      ...(getToimenpideHuomios ? getAsiaTypeToimenpideHuomioTypeIds(at, showInHuomioFilterRequired) : []),
    ])
  ),
]

export const getAsiaTypeToimenpideHuomioTypeIds = (
  at: AsiaTypeId,
  showInHuomioFilterRequired = false
): HuomioTypeId[] =>
  asiaTypeMetadata[at].toimenpideMetadata
    .filter((t) => t.huomioMetadata && (!showInHuomioFilterRequired || t.huomioMetadata.showInHuomioFilter))
    .flatMap((t) => [
      t.huomioMetadata?.[ToimenpideHuomioPhase.ODOTTAA],
      t.huomioMetadata?.[ToimenpideHuomioPhase.SAAPUNUT],
      t.huomioMetadata?.[ToimenpideHuomioPhase.MAARAAIKA],
    ])
    .filter(Boolean) as HuomioTypeId[]

export const getAllToimenpideHuomioPhaseTypeIds = (toimenpideHuomioPhase: ToimenpideHuomioPhaseId): HuomioTypeId[] =>
  [
    ...new Set(
      Object.values(asiaTypeMetadata)
        .flatMap((a) =>
          a.toimenpideMetadata?.flatMap((t) => (t.huomioMetadata ? t.huomioMetadata[toimenpideHuomioPhase] : undefined))
        )
        .filter(Boolean)
    ),
  ] as HuomioTypeId[]
