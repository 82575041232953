import React, { useState } from 'react'
import styled from 'styled-components'
import {
  suomifiDesignTokens,
  Text,
  IconClose,
  RouterLink,
  ExternalLink,
} from 'suomifi-ui-components'
import { useTranslation } from 'react-i18next'
import Cookie from 'js-cookie'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import { CenteredWidthLimitedDiv } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { cookiePageUrl } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/links'
import {
  HeaderButton,
  headerLinkStyle,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/header/header-components'
import { mkFrameTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-frame-test-id'

export const BANNER_DISMISSED_COOKIE_NAME =
  'edunvalvonta-cookie-banner-dismissed'

const CookieBanner: React.FC<{ isDismissed?: boolean }> = (props) => {
  const [t] = useTranslation()
  const { tablet } = useDeviceContext()
  const [isDismissed, setBannerDismissed] = useState<boolean>(
    // The prop is because of SSR. If the server doesn't know if the banner is dismissed or not, a wrong state blinks in the UI.
    props.isDismissed ?? !!Cookie.get(BANNER_DISMISSED_COOKIE_NAME)
  )

  if (isDismissed) {
    return null
  }

  const dismissCookieBanner = () => {
    Cookie.set(BANNER_DISMISSED_COOKIE_NAME, 'true', { expires: 365 })
    setBannerDismissed(true)
  }

  return (
    <Background
      role="region"
      aria-label={t('evasteilmoitus')}
      data-test-id={mkFrameTestId('evastebanner')}
    >
      <Content>
        <div>
          <BannerText smallScreen={!tablet}>
            {t('kaytammePalvelunToiminnanKannaltaValttamattomiaEvasteita')}
          </BannerText>{' '}
          <CookiePageLink labelNewWindow={t('avautuuUuteenValilehteen')}>
            <BannerText smallScreen={!tablet}>
              {t('lueLisaaPalvelunEvasteista')}
            </BannerText>
          </CookiePageLink>
        </div>
        <Spacer />
        <DismissButton
          onClick={dismissCookieBanner}
          aria-label={t('sulje') + ' ' + t('evasteilmoitus').toLowerCase()}
        />
      </Content>
    </Background>
  )
}

export default CookieBanner

const Background = styled.div`
  grid-area: banner;
  background-color: ${suomifiDesignTokens.colors.brandBase};
`

const Content = styled(CenteredWidthLimitedDiv).attrs(() => ({
  paddingX: true,
  paddingY: 's',
}))`
  display: flex;
  flex-direction: row;
`

const Spacer = styled.div`
  flex: 1;
`

const BannerText = styled(Text).attrs(() => ({
  color: 'whiteBase',
}))``

const CookiePageLink = styled(RouterLink).attrs(() => ({
  href: cookiePageUrl,
  underline: 'initial',
  hideIcon: true,
  asComponent: ExternalLink,
}))`
  ${headerLinkStyle};
`

const DismissButton = styled(HeaderButton).attrs(() => ({
  icon: <IconClose />,
}))`
  && {
    padding: ${suomifiDesignTokens.spacing.insetS};
    width: 30px;
    height: 30px;
    border: none;

    .fi-icon {
      margin: 0;
      width: 16px;
      height: 16px;

      @media ${device.tablet} {
        width: 18px;
        height: 18px;
      }
    }
  }
`
