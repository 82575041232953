import fp from 'lodash/fp'
import { AsiointiLupaTypeId } from 'lupa-backend/src/vtj/asiointi/lupa/asiointi-lupa-enums'
import {
  NumberNullable,
  StringNullable,
} from 'lupa-backend/src/vtj/common.type'
import {
  LupaApplicationRole,
  OpinionTypeId,
} from 'lupa-backend/src/vtj/elsa/person/person-enums'
import { LupaAsiointiAsiakirjaTypeId } from 'lupa-backend/src/vtj/asiointi/asiakirja/asiointi-asiakirja-enums'
import { LupaLanguageId } from 'lupa-backend/src/vtj/common-enums'
import { ContainsEstate } from 'lupa-backend/src/vtj/elsa/lupa/lupa-enums'

export interface AsiointiApplicationBatchRequest {
  sourceBatchId: string
  description: string
  arguments: string
  languageId: LupaLanguageId
  applications: AsiointiApplication[]
  persons: AsiointiApplicationPerson[]
}

export interface AsiointiApplicationBatch {
  asiointiApplicationBatchId: number
  sourceHetu: string
  sourceBatchId: string
  applicationDataId: number
  asiaGroupId: NumberNullable
  createdAt: Date
  updatedAt: Date
}

export interface AsiointiApplication {
  lupaTypeId: AsiointiLupaTypeId
  attachments: AsiointiApplicationFile[]
  containsEstate:
    | typeof ContainsEstate.LIITTYY_KUOLINPESA
    | typeof ContainsEstate.EI_KUOLINPESAA
}

export interface AsiointiApplicationPerson {
  applicationRoleId: AsiointiApplicationRoleId
  hetu: string
  firstnames: string
  lastname: string
  streetAddress: string
  streetAddressExtra: StringNullable
  postalCode: string
  postOffice: string
  countryId: string
  email: StringNullable
  phone: StringNullable
  opinionTypeId: OpinionTypeId
  attachments: AsiointiApplicationFile[]
}

export interface AsiointiApplicationFile {
  sourceFileId: string
  asiakirjaTypeId: LupaAsiointiAsiakirjaTypeId
}

export interface AsiointiFileAsiakirja {
  asiointiFileId: number
  asiakirjaId: number
  createdAt: Date
  updatedAt: Date
}

export interface AsiointiApplicationBatchApplication {
  applicationBatchId: number
  applicationId: number
  createdAt: Date
  updatedAt: Date
}

export const AsiointiApplicationRole = fp.pick(
  ['PAAMIES', 'HAKIJA', 'ASIAMIES'],
  LupaApplicationRole
)

export type AsiointiApplicationRoleId =
  (typeof AsiointiApplicationRole)[keyof typeof AsiointiApplicationRole]
