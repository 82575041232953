import keyMirror from 'keymirror'
import { pick } from 'lodash'
export const ViestintaType = keyMirror({
  TOIVO_POSTITUS: null,
  MANUAALINEN: null,
  EDVARD: null,
})
export type ViestintaTypeId = (typeof ViestintaType)[keyof typeof ViestintaType]

export const allViestintaTypeIds = Object.keys(ViestintaType) as ViestintaTypeId[]

export const ExternalViestintaType = pick(ViestintaType, ViestintaType.EDVARD)
export const allExternalViestintaTypeIds = Object.keys(ExternalViestintaType) as ViestintaTypeId[]

export const ViestintaToimitusType = keyMirror({
  SUOMI_FI_VIESTIT: null,
  KIRJEPOSTI: null,
  EMAIL: null,
  SMS: null,
})
export type ViestintaToimitusTypeId = (typeof ViestintaToimitusType)[keyof typeof ViestintaToimitusType]

export const allViestintaToimitusTypeIds = Object.keys(ViestintaToimitusType) as ViestintaToimitusTypeId[]

export const ViestintaViestintaToimitusTypes: {
  [k in ViestintaTypeId]: ViestintaToimitusTypeId[]
} = {
  [ViestintaType.TOIVO_POSTITUS]: [ViestintaToimitusType.SUOMI_FI_VIESTIT, ViestintaToimitusType.KIRJEPOSTI],
  [ViestintaType.MANUAALINEN]: [
    ViestintaToimitusType.KIRJEPOSTI,
    ViestintaToimitusType.EMAIL,
    ViestintaToimitusType.SMS,
  ],
  [ViestintaType.EDVARD]: [],
}

export const ViestintaStatus = keyMirror({
  ODOTTAA_LAHETYSTA: null,
  LAHETYKSESSA: null,
  LAHETETTY: null,
  EPAONNISTUNUT: null,
})
export type ViestintaStatusId = (typeof ViestintaStatus)[keyof typeof ViestintaStatus]
