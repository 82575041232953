import {
  Block,
  Button,
  IconArrowLeft,
  IconArrowRight,
  IconRemove,
  suomifiDesignTokens,
} from 'suomifi-ui-components'
import React, { useState } from 'react'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { observer } from 'mobx-react'
import { mkAlasivuNavigationTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  OmaisuusluetteloRoutePath,
  PaatostiliRoutePath,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { useFormContext } from 'react-hook-form'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import { saveDraft } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import styled from 'styled-components'
import {
  AsiointiFeatureFlag,
  isAsiointiUiFeatureEnabled,
} from 'holhous-common/src/vtj/asiointi-feature-flag'
import AbortDialog from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/AbortDialog'
import SaveDialog from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/SaveDialog'

const FormNavigationBar: React.FC<{
  previous?: PaatostiliRoutePath | OmaisuusluetteloRoutePath
  next?: PaatostiliRoutePath | OmaisuusluetteloRoutePath
}> = observer(({ previous, next }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const navigate = useNavigate()
  const { handleSubmit } = useFormContext()
  const openFrontPage = () => window.location.assign('/?tili=true') // Hard reload to remove application batch from memory
  const [isAbortDialogVisible, setAbortDialogVisible] = useState(false)
  const [isSaveDialogVisible, setSaveDialogVisible] = useState(false)
  // TODO hae mun path jossa olen ja selvitä mikä on seuraava ja edellinen, poista argumentit

  const onAbort = () => {
    setAbortDialogVisible(false)
    openFrontPage()
  }

  const onSaveAndExit = () => {
    void saveDraft()
    setSaveDialogVisible(false)
    openFrontPage()
  }

  return (
    <Block mt={verticalMarginToken}>
      <AbortDialog
        visible={isAbortDialogVisible}
        onAbort={onAbort}
        onClose={() => {
          setAbortDialogVisible(false)
        }}
      />
      <SaveDialog
        visible={isSaveDialogVisible}
        onSave={onSaveAndExit}
        onClose={() => {
          setSaveDialogVisible(false)
        }}
      />
      <NavButtonContainer>
        {isAsiointiUiFeatureEnabled(AsiointiFeatureFlag.TALLENNUS) ? (
          <NavButton
            onClick={() => setSaveDialogVisible(true)}
            variant="secondary"
          >
            {t('tallennaJaPalaaEtusivulle')}
          </NavButton>
        ) : (
          <NavButton
            data-test-id={mkAlasivuNavigationTestId('abort-button')}
            variant="secondaryLight"
            icon={<IconRemove />}
            onClick={() => setAbortDialogVisible(true)}
          >
            {t('keskeyta')}
          </NavButton>
        )}

        <NavButtonGroupContainer>
          {previous && (
            <NavButton
              data-test-id={mkAlasivuNavigationTestId(
                'previous-step-link-button'
              )}
              variant="secondary"
              icon={<IconArrowLeft />}
              onClick={() => {
                void saveDraft()
                navigate(previous)
              }}
            >
              {t('previous')}
            </NavButton>
          )}
          <Block ml="xs" />
          {next && (
            <NavButton
              data-test-id={mkAlasivuNavigationTestId('next-step-link-button')}
              iconRight={<IconArrowRight />}
              onClick={async () =>
                handleSubmit(() => {
                  void saveDraft()
                  navigate(next)
                })()
              }
            >
              {t('next')}
            </NavButton>
          )}
        </NavButtonGroupContainer>
      </NavButtonContainer>
    </Block>
  )
})

export default FormNavigationBar

export const NavButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${suomifiDesignTokens.spacing.xs};
`
export const NavButtonGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const NavButton = styled(Button)`
  padding: ${suomifiDesignTokens.spacing.xs};
  min-width: 80px;

  @media screen and ${device.tablet} {
    padding-inline: ${suomifiDesignTokens.spacing.m};
  }
`
