import React, { useState } from 'react'
import { observer } from 'mobx-react'
import FormTextInput, {
  FormTextInputProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  DigitMode,
  formatNumberAsEuroAndCents,
  parseStringAsNumberWithDigits,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui//input-output.util'
import { ValidateResult } from 'react-hook-form'

const FormNumberInput: React.FC<
  {
    value: number | undefined
    updateValue: (value: number | undefined) => void
    digitMode?: DigitMode
    validate: (value: string | undefined | null) => ValidateResult
  } & Omit<
    FormTextInputProps,
    'value' | 'defaultValue' | 'inputMode' | 'updateValue'
  >
> = observer(
  ({ value, updateValue, digitMode = 'two', validate, ...props }) => {
    const [controlledValue, setControlledValue] = useState(
      value && value >= 0 ? formatNumberAsEuroAndCents(value, digitMode) : ''
    )
    return (
      <FormTextInput
        {...{
          ...props,
          inputMode: 'decimal',
          validate: validate,
          value: controlledValue,
          updateValue: (newValue) => {
            setControlledValue(newValue)
            updateValue(
              newValue === ''
                ? undefined
                : parseStringAsNumberWithDigits(newValue, digitMode)
            )
          },
        }}
      />
    )
  }
)

export default FormNumberInput
