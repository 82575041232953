import { FileIcon } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/file/FileIcon'
import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { Text } from 'suomifi-ui-components'
import { AsiakirjaLiite } from 'tilintarkastus-common/src/vtj/types/asiointi-account-data.type'

const AttachmentList: React.FC<{ attachments: AsiakirjaLiite[] }> = observer(
  ({ attachments }) => {
    return (
      <>
        {attachments.map((liite) => (
          <FileRow key={liite.sourceFileId}>
            <FileIconContainer>
              <FileIcon mimeType={liite.mimeType} />
            </FileIconContainer>
            <Text>{liite.filename}</Text>
          </FileRow>
        ))}
      </>
    )
  }
)

export default AttachmentList

const FileRow = styled.div`
  display: flex;
  flex-direction: row;
`
const FileIconContainer = styled.div`
  padding: 2px; // Artesanal, aesthetically pleasing alignment with file name
`
