import React, { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import {
  Block,
  Button,
  Heading,
  IconFeedback,
  IconLaptopContent,
  IconLinkExternal,
  IconMoney,
  LoadingSpinner,
  Paragraph,
  suomifiDesignTokens,
  Text,
} from 'suomifi-ui-components'
import {
  useAsiointiUser,
  useAsiointiUserStore,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/holhous-asiointi-user-store'
import FeatureNotification from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/common/FeatureNotification'
import {
  mkLandingTestId,
  mkTiliSectionId,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-frame-test-id'
import styled from 'styled-components'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { useTranslation } from 'react-i18next'
import { getFeedbackUrl } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/links'
import { CenteredWidthLimitedDiv } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { useLocation } from 'react-router-dom'
import { isTiliAsiointiEnabled } from 'holhous-common/src/vtj/asiointi-feature-flag'
import {
  fetchEvtvDataIntoStore,
  useEvtv,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/evtv-asiointi-store'
import { HelpSection } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/common/HelpSection'
import { ColoredInlineAlert } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/common/ColoredInlineAlert'
import { runInAction } from 'mobx'
import Tilivelvollisuus from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/Tilivelvollisuus'

const UserLandingPage: React.FC = observer(() => {
  const user = useAsiointiUser()
  const [t] = useTranslation()
  const location = useLocation()
  const hasTiliQueryParam = location.search.includes('tili=true')
  const userStore = useAsiointiUserStore()

  if (
    isTiliAsiointiEnabled() &&
    hasTiliQueryParam &&
    !userStore.tilintarkastusActivated
  ) {
    runInAction(() => {
      userStore.tilintarkastusActivated = true
    })
  }

  return (
    <CenteredWidthLimitedDiv paddingX paddingY>
      <Heading variant="h1">
        {t('overviewHeader', { userName: user.firstname })}
      </Heading>
      <Block mt="m" />
      <FeatureNotification />
      <Block mt="xs" />
      <LupaSection />

      {isTiliAsiointiEnabled() && userStore.tilintarkastusActivated && (
        <>
          <Block mt="s" />
          <TiliSection />
        </>
      )}

      <Block mt="s" />
      <FeedbackSection />

      <Block mt="s" />
      <HelpSection />
    </CenteredWidthLimitedDiv>
  )
})

const TiliSection = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const user = useAsiointiUser()
  const [isLoading, setLoading] = useState(false)
  const [initialized, setInit] = useState(false)
  const vaatimukset = useEvtv()

  const fetchAndUpdateEvtv = useCallback(async () => {
    setLoading(true)
    await fetchEvtvDataIntoStore()
    setLoading(false)
    setInit(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!vaatimukset) {
      void fetchAndUpdateEvtv()
    }
    setInit(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.hetu])

  return (
    <TiliInlineAlert
      status="neutral"
      ariaLiveMode="off"
      smallScreen={!isTablet}
      data-test-id={mkTiliSectionId('tili-korttikehys')}
    >
      <MoneyIcon />
      <Block mt="m" />
      <Heading variant="h3" as="h2">
        {t('tilinTekeminen')}
      </Heading>

      <Block mt="m" />
      <Text>{t('evTiliKuvaus1')}</Text>
      <Block mt="m" />
      <Text>{t('evTiliKuvaus2')}</Text>
      <Block mt="m" />

      <div data-test-id={mkTiliSectionId('logged-in-user')}>
        {!initialized || isLoading ? (
          <LoadingSpinner text={t('ladataan')} textAlign="right" />
        ) : (
          <Tilivelvollisuus />
        )}
      </div>
    </TiliInlineAlert>
  )
})

const LupaSection: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet

  return (
    <LupaInlineAlert
      status="neutral"
      ariaLiveMode="off"
      smallScreen={!isTablet}
    >
      <LaptopIcon />
      <Heading variant="h3">{t('overviewLupaInfoHeader')}</Heading>
      <Block mt="s" />
      <Text>{t('landingLuvatKuvaus1')}</Text>
      <Block mt="s" />
      <Text>{t('landingLuvatKuvaus2')}</Text>
      <Block mt="m" />

      <OverviewButton
        data-test-id={mkLandingTestId('haeLupaa')}
        onClick={() => (window.location.href = '/luvat/hakemus/hae')}
        fullWidth={false}
        role="link"
        variant="default"
      >
        {t('overviewLupaInfoActionButton')}
      </OverviewButton>
    </LupaInlineAlert>
  )
}

const FeedbackSection: React.FC = observer(() => {
  const [t] = useTranslation()
  const userStore = useAsiointiUserStore()
  const isTablet = useDeviceContext().tablet

  return (
    <FeedbackInlineAlert
      status="neutral"
      ariaLiveMode="off"
      smallScreen={!isTablet}
    >
      <FeedbackIcon />
      <Heading variant="h3" as="h2">
        {t('overviewFeedbackHeader')}
      </Heading>
      <Block mt="m" />
      <Paragraph mb="m">
        <Text>{t('overviewFeedbackDescription')}</Text>
      </Paragraph>

      <OverviewButton
        data-test-id={mkLandingTestId('annaPalautetta')}
        onClick={() => window.open(getFeedbackUrl(userStore.lang), '_blank')}
        fullWidth={false}
        iconRight={<IconLinkExternal />}
        role="link"
        variant="default"
      >
        {t('overviewFeedbackActionButton')}
      </OverviewButton>
    </FeedbackInlineAlert>
  )
})

const MoneyIcon = styled(IconMoney).attrs(() => ({
  highlightColor: suomifiDesignTokens.colors.accentTertiaryDark1,
}))`
  height: 70px;
  width: 70px;
`
const LaptopIcon = styled(IconLaptopContent)`
  height: 70px;
  width: 70px;
`

const FeedbackIcon = styled(IconFeedback).attrs(() => ({
  highlightColor: suomifiDesignTokens.colors.accentSecondary,
}))`
  height: 70px;
  width: 70px;
`

const LupaInlineAlert = ColoredInlineAlert(
  suomifiDesignTokens.colors.accentBase
)
const FeedbackInlineAlert = ColoredInlineAlert(
  suomifiDesignTokens.colors.accentSecondary
)

export const OverviewButton = styled(Button)`
  && {
    ${suomifiDesignTokens.typography.bodySemiBoldSmall}
    border: 1px solid;
    border-radius: 2px;

    @media ${device.tablet} {
      width: fit-content;
    }
  }
`

const TiliInlineAlert = ColoredInlineAlert(
  suomifiDesignTokens.colors.accentTertiary
)

export default UserLandingPage
