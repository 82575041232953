import React from 'react'
import { FormProvider } from 'react-hook-form'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkYhteystiedotTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block } from 'suomifi-ui-components'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import TiedoksiantoForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/yhteystiedot/TiedoksiantoForm'
import OmatTiedotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/yhteystiedot/OmatTiedotForm'
import {
  PAATOSTILI_ROUTES,
  PAATOSTILI_STEPS,
  TilintarkastusForm,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'

const YhteystiedotPage: React.FC<{
  form: TilintarkastusForm
}> = observer(({ form }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  return (
    <>
      <FormProvider {...form}>
        <ResponsiveBorderedContentBlock
          data-test-id={mkYhteystiedotTestId('container')}
        >
          <PageStepHeading
            heading={t('yhteystiedot')}
            steps={PAATOSTILI_STEPS}
            createDataTestId={mkYhteystiedotTestId}
          />
          <Block mt={verticalMarginToken} />
          <DividerLine mt={verticalMarginToken} />
          <Block mt={verticalMarginToken} />
          <OmatTiedotForm />
          <Block mt={verticalMarginToken} />
          <DividerLine mt={verticalMarginToken} />
          <Block mt={verticalMarginToken} />
          <TiedoksiantoForm />
          <Block mt={verticalMarginToken} />
          <DividerLine mt={verticalMarginToken} />
          <Block mt={verticalMarginToken} />
          <FormNavigationBar
            previous={PAATOSTILI_ROUTES.PALKKIO}
            next={PAATOSTILI_ROUTES.YHTEENVETO}
          />
        </ResponsiveBorderedContentBlock>
      </FormProvider>
    </>
  )
})

export default YhteystiedotPage
