import { executeBackendClient } from 'holhous-common/src/vtj/ui/api/microfrontend-backend-api-call'
import {
  Drafts,
  TiliDraft,
  TiliDraftRequest,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/draft/draft-api.type'

export const getDrafts = async (): Promise<Drafts> => {
  const apiResponse = await executeBackendClient((client) =>
    client.get('/asiointi/api/v1/draft')
  )

  if (apiResponse.isOk) {
    return apiResponse.data
  } else {
    throw new Error(
      'Failed getting draft data: ' + JSON.stringify(apiResponse.errorData)
    )
  }
}

export const saveTiliDraft = async (
  tiliDraft: TiliDraftRequest
): Promise<TiliDraft> => {
  const apiResponse = await executeBackendClient((client) =>
    client.post('/asiointi/api/v1/draft/tili', tiliDraft)
  )
  if (apiResponse.isOk) {
    return apiResponse.data
  } else {
    throw new Error(
      'Failed saving tili draft data: ' + JSON.stringify(apiResponse.errorData)
    )
  }
}
