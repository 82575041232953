import { Block, Paragraph } from 'suomifi-ui-components'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { observer } from 'mobx-react'
import {
  PAATOSTILI_ROUTES,
  PAATOSTILI_STEPS,
  TilintarkastusForm,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { mkJuurisivuTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { useTranslation } from 'react-i18next'
import { useValittuEvtvVaatimus } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/evtv-asiointi-store'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Valtakirja } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/Valtakirja'
import { TilivelvollisuusType } from 'tilintarkastus-common/src/vtj/types/edunvalvontasuhde/edunvalvontasuhde-enums'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import { Perustiedot } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/yhteenveto/Perustiedot'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'

const AloitusPage: React.FC<{ form: TilintarkastusForm }> = observer(
  ({ form }) => {
    const [t] = useTranslation()
    const evtvVaatimus = useValittuEvtvVaatimus()

    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'

    return (
      <FormProvider {...form}>
        <ResponsiveBorderedContentBlock
          data-test-id={mkJuurisivuTestId('container')}
        >
          <PageStepHeading
            heading={t('aloitus')}
            steps={PAATOSTILI_STEPS}
            createDataTestId={mkJuurisivuTestId}
          />
          <Block mt={verticalMarginToken} />
          <Paragraph>{t('paatostiliJuurisivuOhje')}</Paragraph>
          <Block mt={verticalMarginToken} />
          <DividerLine mt={verticalMarginToken} />
          <Perustiedot key="perustiedot" />
          <Block mt={verticalMarginToken} />
          {evtvVaatimus.tilivelvollisuusType ===
            TilivelvollisuusType.TILIVELVOLLISUUS_YHDESSA && <Valtakirja />}
          <DividerLine mt={verticalMarginToken} />
          <FormNavigationBar next={PAATOSTILI_ROUTES.YLEISKATSAUS} />
        </ResponsiveBorderedContentBlock>
        <Block mt={verticalMarginToken} />
      </FormProvider>
    )
  }
)

export default AloitusPage
