import keyMirror from 'keymirror'

export const EdunvalvontasuhdeType = keyMirror({
  EDUNVALVONTA_MAARATTY: null,
  EDUNVALVONTA_LAKIMAARAINEN: null,
  EDUNVALVONTAVALTUUTUS: null,
  EDUNVALVONTAVALTUUTUS_TOISSIJAINEN: null,
  VARAVALTUUTUS: null,
  EDUNVALVOJAN_SIJAISUUS: null,
  TUNTEMATON: null,
})

export type EdunvalvontasuhdeTypeId =
  (typeof EdunvalvontasuhdeType)[keyof typeof EdunvalvontasuhdeType]

export const edunvalvontasuhdeSijaisuusTypes: EdunvalvontasuhdeTypeId[] = [
  EdunvalvontasuhdeType.VARAVALTUUTUS,
  EdunvalvontasuhdeType.EDUNVALVOJAN_SIJAISUUS,
]

export const TilivelvollisuusType = keyMirror({
  TILIVELVOLLISUUS_YKSIN: null,
  TILIVELVOLLISUUS_YHDESSA: null,
  OMAISUUSLUETTELO_YKSIN: null,
  OMAISUUSLUETTELO_YHDESSA: null,
  TUNTEMATON: null,
})
export type TilivelvollisuusTypeId =
  (typeof TilivelvollisuusType)[keyof typeof TilivelvollisuusType]

export const tilivelvollisuusOmaisuusluetteloTypes: TilivelvollisuusTypeId[] = [
  TilivelvollisuusType.OMAISUUSLUETTELO_YKSIN,
  TilivelvollisuusType.OMAISUUSLUETTELO_YHDESSA,
]

export const tilivelvollisuusTilivelvollisuusTypes: TilivelvollisuusTypeId[] = [
  TilivelvollisuusType.TILIVELVOLLISUUS_YKSIN,
  TilivelvollisuusType.TILIVELVOLLISUUS_YHDESSA,
]

export const VaativuusType = keyMirror({
  YKSINKERTAINEN: null,
  NORMAALI: null,
  TYOLAS: null,
  TUNTEMATON: null,
})
export type VaativuusTypeId = (typeof VaativuusType)[keyof typeof VaativuusType]

export const LopettamisperusteTyyppi = keyMirror({
  VALTUUTTAJAN_KUOLEMA: null,
  VALTUUTETUN_KUOLEMA: null,
  PAAMIEHEN_KUOLEMA: null,
  EDUNVALVOJAN_KUOLEMA: null,
  VALTUUTETTU_LUOPUNUT_TEHTAVASTAAN: null,
  VALTUUTTAJAN_PERUUTUSHAKEMUS: null,
  EDUNVALVOJAN_MAARAAMINEN_SAMAAN_TEHTAVAAN: null,
  EDUNVALVOJAN_MAARAAMINEN_JOHTUEN_VALTUUTETUN_LAIMINLYONNEISTA: null,
  VALTAKIRJA_JULISTETTU_PATEMATTOMAKSI: null,
  UUSI_VALTUUTUS_RISTIRIIDASSA_EDELLISEN_KANSSA: null,
  MAARAAJAN_VOIMASSA_OLEVA_VALTUUTUS: null,
  VALTUUTTAJAN_KONKURSSI: null,
})

export type LopettamisperusteTyyppiId =
  (typeof LopettamisperusteTyyppi)[keyof typeof LopettamisperusteTyyppi]

export const LopettamisperusteUIValinnat = keyMirror({
  VALTUUTETTU_LUOPUNUT_TEHTAVASTAAN: null,
  VALTUUTTAJAN_PERUUTUSHAKEMUS: null,
  EDUNVALVOJAN_MAARAAMINEN_SAMAAN_TEHTAVAAN: null,
  EDUNVALVOJAN_MAARAAMINEN_JOHTUEN_VALTUUTETUN_LAIMINLYONNEISTA: null,
  VALTAKIRJA_JULISTETTU_PATEMATTOMAKSI: null,
  UUSI_VALTUUTUS_RISTIRIIDASSA_EDELLISEN_KANSSA: null,
  MAARAAJAN_VOIMASSA_OLEVA_VALTUUTUS: null,
  VALTUUTTAJAN_KONKURSSI: null,
})

export type LopettamisperusteUIValinnatId =
  (typeof LopettamisperusteUIValinnat)[keyof typeof LopettamisperusteUIValinnat]
