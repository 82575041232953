import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { groupBy } from 'lodash'
import {
  Block,
  Expander,
  ExpanderContent,
  ExpanderTitleButton,
  Heading,
  InlineAlert,
  LoadingSpinner,
  Paragraph,
  suomifiDesignTokens,
  Text,
  VisuallyHidden,
} from 'suomifi-ui-components'
import { action } from 'mobx'
import {
  ChildTestId,
  mkHakemusTestId,
  mkSummaryHakemusTestId,
  mkYhteenvetoHenkiloTestId,
  mkYhteenvetoLiiteryhmaTestId,
  mkYhteenvetoLiiteTestId,
  mkYhteenvetoTestId,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-asiointi-test-id'
import { LupaApplicationStep } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-application-routes'
import CurrentStepDisplay from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/common/CurrentStepDisplay'
import { getStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store'
import {
  AsiointiApplication,
  LupaAsiointiAttachment,
  AsiointiPerson,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/asiointi-batch.type'
import {
  LupaApplicationRole,
  OpinionType,
} from 'lupa-backend/src/vtj/elsa/person/person-enums'
import { FileIcon } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/file/FileIcon'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import {
  applicationCompareFn,
  AsiointiLupaEstateSpecification,
  asiointiLupaInfos,
  getExpectedAsiakirjaTypes,
} from 'lupa-backend/src/vtj/asiointi/lupa/asiointi-lupa-info'
import { StringNullable } from 'lupa-backend/src/vtj/common.type'
import { COUNTRY_CODE_FINLAND } from 'holhous-common/src/vtj/country/country.util'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { BillingClass } from 'lupa-backend/src/vtj/elsa/billing/billing-enums'
import { AsiointiLupaProduct } from 'lupa-backend/src/vtj/asiointi/product-catalog/asiointi-product-catalog-api.type'
import {
  BulletList,
  ResponsiveBorderedContentBlock,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import ValidationErrorsSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/validation/ValidationErrorsSummary'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import {
  AsiointiApplicationRole,
  AsiointiApplicationRoleId,
} from 'lupa-backend/src/vtj/asiointi/application/asiointi-application.type'
import { ContainsEstate } from 'lupa-backend/src/vtj/elsa/lupa/lupa-enums'

const SummaryPage: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const { batch, validationFailedForSteps } = getStore()
  return (
    <section data-test-id={mkHakemusTestId(LupaApplicationStep.SUMMARY)}>
      <ResponsiveBorderedContentBlock>
        <CurrentStepDisplay heading={t('yhteenveto')} />
        <Block mt="m" />
        <Paragraph>{t('tarkistaVielaHakemuksenTiedotJaLiitteet')}</Paragraph>
        {!!validationFailedForSteps.size && (
          <>
            <Block mt="m" />
            <ValidationErrorsSummary batch={batch} />
          </>
        )}
        <Block mt="xl" />
        <HakijatJaAsiamiehetExpander />
        <ExpanderSpacer />
        <PaamiehetExpander />
        <ExpanderSpacer />
        <PerustiedotExpander />
        <ApplicationExpanders />
        <DividerLine mt={isTablet ? 'xl' : 'm'} mb={isTablet ? 'xl' : 'm'} />
        <Hinnasto />
      </ResponsiveBorderedContentBlock>
      <Block mt="xl" />
      <SubmitNotification />
    </section>
  )
})

const ExpanderSpacer: React.FC = () => {
  const isTablet = useDeviceContext().tablet
  return <Block mt={isTablet ? 'xl' : undefined} />
}

export default SummaryPage

const HakijatJaAsiamiehetExpander: React.FC = observer(() => {
  const [t] = useTranslation()
  return (
    <StyledExpander defaultOpen>
      <ExpanderTitleButton asHeading="h3">{t('hakijat')}</ExpanderTitleButton>
      <ExpanderContent>
        <SummaryPersonList
          roles={[
            AsiointiApplicationRole.HAKIJA,
            AsiointiApplicationRole.ASIAMIES,
          ]}
        />
      </ExpanderContent>
    </StyledExpander>
  )
})

const StyledExpander = styled(Expander)`
  border: 1px solid ${suomifiDesignTokens.colors.depthLight2};
  border-radius: 2px;
  box-shadow: 0 2px 4px 1px rgba(41, 41, 41, 0.2);

  & > .fi-expander_content.fi-expander_content--open {
    border-bottom: 1px solid ${suomifiDesignTokens.colors.depthLight2};
    border-radius: 2px;
  }
`

export const SummaryPersonList: React.FC<{
  roles: AsiointiApplicationRoleId[]
}> = observer(({ roles }) => {
  const { batch } = getStore()
  return (
    <>
      {batch.persons
        .filter((p) => roles.includes(p.applicationRoleId))
        .map((person, index) => (
          <React.Fragment key={person.personId}>
            {index > 0 && <Block mt="s" />}
            <SummaryPerson person={person} index={index} />
          </React.Fragment>
        ))}
    </>
  )
})

const SummaryPerson: React.FC<{
  person: AsiointiPerson
  index: number
}> = observer(({ person, index }) => {
  const [t, i18n] = useTranslation()
  const { countries } = getStore()

  const fullname = joinNonEmpty(', ', person.lastname, person.firstnames)
  const postalCodeAndOffice = joinNonEmpty(
    ' ',
    person.postalCode,
    person.postOffice
  )
  const country = countries.find((c) => c.countryId === person.countryId)
  const address = joinNonEmpty(
    ', ',
    person.streetAddress,
    person.streetAddressExtra,
    postalCodeAndOffice,
    country && country.countryId !== COUNTRY_CODE_FINLAND
      ? country.shortName[i18n.language as HolhousAsiointiLanguageCode]
      : null
  )

  const attachments =
    person.opinionTypeId ===
    OpinionType.PAAMIES_ALLE_15V_MIELIPIDETTA_EI_SELVITETTY
      ? []
      : person.attachments
  // Expecting a person to have max one type of attachments, but sort them deterministically just in case.
  const sortedAttachments = attachments
    .slice()
    .sort((a1, a2) => a1.asiakirjaTypeId.localeCompare(a2.asiakirjaTypeId))

  const sectionType: 'paamies' | 'hakija-tai-asiamies' =
    person.applicationRoleId === LupaApplicationRole.PAAMIES
      ? 'paamies'
      : 'hakija-tai-asiamies'
  const mkTestId = (...ids: ChildTestId[]) =>
    mkYhteenvetoHenkiloTestId(sectionType, index, ...ids)

  return (
    <Block variant="section" data-test-id={mkTestId()}>
      <Block>
        <Text variant="bold" smallScreen data-test-id={mkTestId('nimi')}>
          {fullname}
        </Text>{' '}
        <Text color="depthDark1" smallScreen>
          (
          <span data-test-id={mkTestId('rooli')}>
            {t(
              `applicationRole-${person.applicationRoleId}`
            ).toLocaleLowerCase()}
          </span>
          )
        </Text>
      </Block>
      <Block mt={person.email ? 'xxs' : undefined}>
        <Text smallScreen data-test-id={mkTestId('email')}>
          {person.email}
        </Text>
      </Block>
      <Block mt={person.email ? 'xxs' : undefined}>
        <Text smallScreen data-test-id={mkTestId('puhelinnumero')}>
          {person.phone}
        </Text>
      </Block>
      <Block mt={person.hetu ? 'xxs' : undefined}>
        <Text smallScreen data-test-id={mkTestId('hetu')}>
          {person.hetu}
        </Text>
      </Block>
      <Block mt={address ? 'xxs' : undefined}>
        <Text smallScreen data-test-id={mkTestId('osoite')}>
          {address}
        </Text>
      </Block>
      <Block
        mt={person.attachments.length > 0 ? 'xxs' : undefined}
        data-test-id={mkTestId('liitteet')}
      >
        <Attachments
          attachments={sortedAttachments}
          parentType={sectionType}
          parentIndex={index}
        />
      </Block>
    </Block>
  )
})

const joinNonEmpty = (separator: string, ...values: StringNullable[]): string =>
  [...values]
    .map((value) => (typeof value === 'string' ? value.trim() : value))
    .filter((value) => Boolean(value))
    .join(separator)

const PaamiehetExpander: React.FC = observer(() => {
  const [t] = useTranslation()
  return (
    <StyledExpander defaultOpen>
      <ExpanderTitleButton asHeading="h3">{t('paamiehet')}</ExpanderTitleButton>
      <ExpanderContent>
        <SummaryPersonList roles={[AsiointiApplicationRole.PAAMIES]} />
      </ExpanderContent>
    </StyledExpander>
  )
})

const PerustiedotExpander: React.FC = observer(() => {
  const [t] = useTranslation()
  return (
    <StyledExpander defaultOpen>
      <ExpanderTitleButton asHeading="h3">
        {t('hakemuksenPerustiedot')}
      </ExpanderTitleButton>
      <ExpanderContent>
        <SummaryPerustiedot />
      </ExpanderContent>
    </StyledExpander>
  )
})

export const SummaryPerustiedot: React.FC = observer(() => {
  const [t] = useTranslation()
  const { batch } = getStore()

  return (
    <>
      <Text variant="bold" smallScreen>
        {t('kuvaileMihinHaetLupaa')}
      </Text>
      <Block mt="xxs" />
      <Paragraph data-test-id={mkYhteenvetoTestId('kuvaus')}>
        <TextWithNewlines smallScreen>{batch.description}</TextWithNewlines>
      </Paragraph>
      <Block mt="s" />
      <Text variant="bold" smallScreen>
        {t('perustelutOikeustoimelle')}
      </Text>
      <Block mt="xxs" />
      <Paragraph data-test-id={mkYhteenvetoTestId('perustelut')}>
        <TextWithNewlines smallScreen>{batch.arguments}</TextWithNewlines>
      </Paragraph>
    </>
  )
})

const TextWithNewlines = styled(Text)`
  white-space: pre-line;
`

const ApplicationExpanders: React.FC = observer(() => {
  return (
    <div data-test-id={mkYhteenvetoTestId('hakemukset')}>
      {getSortedSummaryApplications().map((application, index) => (
        <React.Fragment key={application.typeId}>
          <ExpanderSpacer />
          <SummaryApplicationExpander application={application} index={index} />
        </React.Fragment>
      ))}
    </div>
  )
})

export const SummaryApplicationExpander: React.FC<{
  application: AsiointiApplication
  index: number
}> = observer(({ application, index }) => {
  const [t] = useTranslation()

  const mkTestId = (...ids: ChildTestId[]) =>
    mkSummaryHakemusTestId(index, ...ids)

  return (
    <StyledExpander defaultOpen>
      <ExpanderTitleButton
        asHeading="h3"
        data-test-id={mkTestId()}
        data-lupa-type={application.typeId}
      >
        <span data-test-id={mkTestId('tyyppi')}>
          {t(`asiointiLupaType-${application.typeId}`)}
        </span>
      </ExpanderTitleButton>
      <ExpanderContent>
        <SummaryApplication application={application} index={index} />
      </ExpanderContent>
    </StyledExpander>
  )
})

export const getSortedSummaryApplications = (): AsiointiApplication[] =>
  getStore().batch.applications.slice().sort(applicationCompareFn)

export const SummaryApplication: React.FC<{
  application: AsiointiApplication
  index: number
}> = observer(({ application, index }) => {
  const [t] = useTranslation()

  const lupaTypeInfo = asiointiLupaInfos[application.typeId]
  const expectedAsiakirjaTypes = getExpectedAsiakirjaTypes(application)
  const sortedAttachments = application.attachments
    .filter((attachment) =>
      expectedAsiakirjaTypes.includes(attachment.asiakirjaTypeId)
    )
    .sort((a1, a2) => {
      const order1 = expectedAsiakirjaTypes.indexOf(a1.asiakirjaTypeId)
      const order2 = expectedAsiakirjaTypes.indexOf(a2.asiakirjaTypeId)
      return order1 - order2
    })

  const mkTestId = (...ids: ChildTestId[]) =>
    mkSummaryHakemusTestId(index, ...ids)

  return (
    <>
      {lupaTypeInfo.containsEstate ===
        AsiointiLupaEstateSpecification.OPTIONAL && (
        <Block mb={'s'}>
          <Paragraph>
            <Text variant="bold" smallScreen>
              {t('onkoLupaAsianOsapuolenaKuolipesa')}
            </Text>
            <br />
            <Text smallScreen data-test-id={mkTestId('kuolinpesa')}>
              {application.containsEstate === ContainsEstate.LIITTYY_KUOLINPESA
                ? t('kylla')
                : t('ei')}
            </Text>
          </Paragraph>
        </Block>
      )}
      <Block data-test-id={mkTestId('liitteet')}>
        {application.attachments.length > 0 ? (
          <Attachments
            attachments={sortedAttachments}
            parentType="hakemus"
            parentIndex={index}
          />
        ) : (
          <Text smallScreen>{t('eiLisattyjaLiitteita')}</Text>
        )}
      </Block>
    </>
  )
})

const Attachments: React.FC<{
  attachments: LupaAsiointiAttachment[]
  parentType: 'hakija-tai-asiamies' | 'paamies' | 'hakemus'
  parentIndex: number
}> = observer(({ attachments, parentType, parentIndex }) => {
  const [t] = useTranslation()
  const groupedAttachments = groupBy(attachments, 'asiakirjaTypeId')

  return (
    <>
      {Object.entries(groupedAttachments).map(
        ([asiakirjaTypeId, attachments], groupIndex) => (
          <Block
            variant="section"
            key={asiakirjaTypeId}
            data-test-id={mkYhteenvetoLiiteryhmaTestId(
              parentType,
              parentIndex,
              groupIndex
            )}
          >
            {groupIndex > 0 && <Block mt="s" />}
            <Block>
              <Text
                variant="bold"
                smallScreen
                data-test-id={mkYhteenvetoLiiteryhmaTestId(
                  parentType,
                  parentIndex,
                  groupIndex,
                  'asiakirjatyyppi'
                )}
              >
                {t(`asiointiAsiakirjaType-${asiakirjaTypeId}`)}
              </Text>
            </Block>
            <Block
              data-test-id={mkYhteenvetoLiiteryhmaTestId(
                parentType,
                parentIndex,
                groupIndex,
                'tiedostot'
              )}
            >
              {attachments.map((attachment, attachmentIndex) => (
                <Block key={attachment.sourceFileId} mt="xxs">
                  <SummaryFileIcon mimeType={attachment.mimeType} />
                  <Text
                    smallScreen
                    data-test-id={mkYhteenvetoLiiteTestId(
                      parentType,
                      parentIndex,
                      groupIndex,
                      attachmentIndex,
                      'tiedostonimi'
                    )}
                  >
                    {attachment.filename}
                  </Text>
                </Block>
              ))}
            </Block>
          </Block>
        )
      )}
    </>
  )
})

const SummaryFileIcon = styled(FileIcon)`
  margin-right: ${suomifiDesignTokens.spacing.insetS};

  @media print {
    display: none;
  }
`

const Hinnasto: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const { productCatalog } = getStore()
  const sortedProducts = productCatalog.products.slice().sort((p1, p2) => {
    if (p1.billingClassId === BillingClass.MUU) {
      return 1
    } else if (p2.billingClassId === BillingClass.MUU) {
      return -1
    } else {
      return p1.priceEurocents - p2.priceEurocents
    }
  })
  const muuLupaProduct = productCatalog.products.find(
    (product) => product.billingClassId === BillingClass.MUU
  ) as AsiointiLupaProduct

  return (
    <>
      <Heading variant="h3">{t('hinnasto')}</Heading>
      <Block mt="m">
        {t('hinnastoBulletsHeader')}:
        <BulletList>
          <li>
            {t('hinnastoBulletMuutLuvatJaOmaisudenhoitosuunnitelma', {
              priceEuros: getDisplayPriceEuros(muuLupaProduct),
            })}
          </li>
          <li>{t('hinnastoBulletOsitusJaPerinnonjakoLuvat')}</li>
        </BulletList>
      </Block>
      <Block mt="m" mb="m">
        <Paragraph>{t('hinnastoKuolinpesaMaksu')}</Paragraph>
      </Block>
      {isTablet ? (
        <PaatostenHinnatTable products={sortedProducts} />
      ) : (
        <VisuallyHidden>
          <PaatostenHinnatTable products={sortedProducts} />
        </VisuallyHidden>
      )}
      {!isTablet && (
        <PaatostenHinnatAriaHiddenSmallScreen products={sortedProducts} />
      )}
    </>
  )
})

const PaatostenHinnatTable: React.FC<{ products: AsiointiLupaProduct[] }> =
  observer(({ products }) => {
    const [t] = useTranslation()
    const mkTestId = (index: number, ...ids: ChildTestId[]) =>
      mkYhteenvetoTestId('hinnasto', 'tuote', index, ...ids)

    return (
      <HinnastoTable>
        <caption>{t('paatostenHinnat')}</caption>
        <thead>
          <tr>
            <th>{t('lupatyyppi')}</th>
            <th>{t('hinta')}</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr key={product.billingClassId} data-test-id={mkTestId(index)}>
              <td data-test-id={mkTestId(index, 'lupatyyppi')}>
                {t(`billingClass-${product.billingClassId}`)}
              </td>
              <td className="price" data-test-id={mkTestId(index, 'hinta')}>
                {getDisplayPriceEuros(product)} €
              </td>
            </tr>
          ))}
        </tbody>
      </HinnastoTable>
    )
  })

const HinnastoTable = styled.table`
  font-size: 16px;
  width: 100%;

  caption {
    font-weight: 600;
    text-align: start;
    margin-bottom: ${suomifiDesignTokens.spacing.m};
  }

  tr {
    border: 1px solid ${suomifiDesignTokens.colors.depthLight1};
  }

  th {
    font-weight: 600;
  }

  th,
  td {
    padding: ${suomifiDesignTokens.spacing.insetM}
      ${suomifiDesignTokens.spacing.insetXxl};
  }
  th,
  td.price {
    white-space: nowrap;
  }

  thead tr:nth-child(odd),
  tbody tr:nth-child(even) {
    background-color: ${suomifiDesignTokens.colors.whiteBase};
  }

  thead tr:nth-child(even),
  tbody tr:nth-child(odd) {
    background-color: ${suomifiDesignTokens.colors.depthLight3};
  }
`

const PaatostenHinnatAriaHiddenSmallScreen: React.FC<{
  products: AsiointiLupaProduct[]
}> = observer(({ products }) => {
  const [t] = useTranslation()

  return (
    <div aria-hidden="true">
      {products.map((product) => (
        <HinnastoListRow key={product.billingClassId}>
          <Block>
            <Text variant="bold" smallScreen>
              {t('lupatyyppi')}
            </Text>
          </Block>
          <Block>
            <Text smallScreen>
              {t(`billingClass-${product.billingClassId}`)}
            </Text>
          </Block>
          <Block mt="s" />
          <Block>
            <Text variant="bold" smallScreen>
              {t('hinta')}
            </Text>
          </Block>
          <Block>
            <Text smallScreen>{getDisplayPriceEuros(product)} €</Text>
          </Block>
        </HinnastoListRow>
      ))}
    </div>
  )
})

const HinnastoListRow = styled.div`
  padding: ${suomifiDesignTokens.spacing.m} ${suomifiDesignTokens.spacing.s};
  border: 1px solid ${suomifiDesignTokens.colors.depthLight1};

  &:not(:first-child) {
    border-top: none;
  }

  &:nth-child(odd) {
    background-color: ${suomifiDesignTokens.colors.depthLight3};
  }
  &:nth-child(even) {
    background-color: ${suomifiDesignTokens.colors.whiteBase};
  }
`

const numberFormat = new Intl.NumberFormat(['fi-FI', 'sv-FI'], {
  style: 'decimal',
  maximumFractionDigits: 2,
})
const getDisplayPriceEuros = (
  product: Pick<AsiointiLupaProduct, 'priceEurocents'>
) => numberFormat.format(product.priceEurocents / 100)

const SubmitNotification: React.FC = observer(() => {
  const isTablet = useDeviceContext().tablet
  const { batch } = getStore()

  useEffect(() => {
    return action(() => (batch.submitError = undefined))
  }, [batch])

  return (
    <Block mx={isTablet ? '0' : 's'}>
      {batch.submitStatus === 'in-progress' && <SubmitLoadingAlert />}
      {batch.submitStatus === 'none' && batch.submitError !== undefined && (
        <SubmitErrorAlert />
      )}
    </Block>
  )
})

const SubmitLoadingAlert: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    ref.current?.scrollIntoView()
  }, [])

  // InlineAlert does not seem to set ref.current. Bug?
  return (
    <InlineAlert
      status="neutral"
      smallScreen={!isTablet}
      data-test-id={mkYhteenvetoTestId('hakemusta-lahetetaan')}
    >
      <div ref={ref} tabIndex={-1}>
        <LoadingSpinner
          status={'loading'}
          text={t('hakemustaLahetetaan')}
          textAlign="right"
          variant="small"
        />
      </div>
    </InlineAlert>
  )
}

const SubmitErrorAlert: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    ref.current?.scrollIntoView()
  }, [])

  return (
    <InlineAlert
      status="error"
      smallScreen={!isTablet}
      data-test-id={mkYhteenvetoTestId('virhe-lahetyksessa')}
    >
      <div ref={ref} tabIndex={-1}>
        {t('lahetysEpaonnistuiYritaUudelleen')}
      </div>
    </InlineAlert>
  )
}
