import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import { SpecifierType } from 'asia-common/src/vtj/koodisto/specifier-enums'
import {
  AsiaPersonRole,
  AsiaPersonType,
  RatkaisuType,
  AsiaStatus,
  AsiaHandlerRole,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import { defaultAsiakirjaMarkingMetadata } from 'asia-common/src/vtj/koodisto/marking-enums'
import {
  InternalToimenpideType,
  ToimenpideHuomioPhase,
  ToimenpideType,
} from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import { Retention } from 'asia-common/src/vtj/koodisto/retention-enums'
import { PublicityClass } from 'asia-common/src/vtj/koodisto/restriction-enums'

export const ensirekisterointiMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    {
      asiakirjaTypeId: AsiakirjaType.HENKILOTUNNUSPYYNTO,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['54f34d01-2977-44c0-a58d-4874a79efe55', 'b9f7c4c7-353e-4acc-b25a-90029495fd16'],
      defaultToivoAsiakirjaTypeId: '54f34d01-2977-44c0-a58d-4874a79efe55',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.KOPIO_HENKILOLLISYYSASIAKIRJASTA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['2955978c-9b1e-4fc8-9ae0-ec249e467d87'],
      defaultToivoAsiakirjaTypeId: '2955978c-9b1e-4fc8-9ae0-ec249e467d87',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.PERUSTEET_REKISTEROINNILLE,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['5935f561-85ca-44df-9099-21ded8ef645a'],
      defaultToivoAsiakirjaTypeId: '5935f561-85ca-44df-9099-21ded8ef645a',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_ASIAKIRJA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['c633b5c6-184a-4069-a80f-27dd9ee5b904'],
      defaultToivoAsiakirjaTypeId: 'c633b5c6-184a-4069-a80f-27dd9ee5b904',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYSASIAKIRJA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['03a7bedf-e7d1-4f99-9202-ecba89d1aa75'],
      defaultToivoAsiakirjaTypeId: '03a7bedf-e7d1-4f99-9202-ecba89d1aa75',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.ILMOITUS_REKISTEROINNISTA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['48ad3bef-22e0-4cf6-9b87-ab5ff69ac1ac'],
      defaultToivoAsiakirjaTypeId: '48ad3bef-22e0-4cf6-9b87-ab5ff69ac1ac',
    },
    {
      asiakirjaTypeId: AsiakirjaType.PAATOS_LIITTEINEEN,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['0e6b4c35-1dca-4552-9454-c089c6c4985c'],
      defaultToivoAsiakirjaTypeId: '0e6b4c35-1dca-4552-9454-c089c6c4985c',
    },
  ],
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.ODOTTAA_TUNNISTAUTUMISTA,
      postDueDateHuomioTypeId: HuomioType.TUNNISTAUTUMISEN_MAARAPAIVA_YLITTYNYT,
    },
    {
      huomioTypeId: HuomioType.TUNNISTAUTUMISEN_MAARAPAIVA_YLITTYNYT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_TAYDENNYSTA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_KUULEMISTA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.KUULEMISEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.KUULEMISEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_LAUSUNTOA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.LAUSUNNON_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.LAUSUNNON_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_SELVITYSTA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.SELVITYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.SELVITYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KIIREELLINEN,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [],
    organizationTypeIds: [],
  },
  personRoleMetadata: [
    AsiaPersonRole.ASIAKAS,
    AsiaPersonRole.ILMOITTAJA,
    AsiaPersonRole.HAKIJA,
    AsiaPersonRole.HUOLLETTAVA,
  ],
  personTypeMetadata: [AsiaPersonType.IHMINEN],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.REKISTERIIN_MERKINTA,
    manualRatkaisuTypeIds: [
      RatkaisuType.REKISTERIIN_MERKINTA,
      RatkaisuType.OSITTAIN_KIELTEINEN,
      RatkaisuType.KIELTEINEN_PAATOS,
      RatkaisuType.TUTKIMATTAJATTAMISPAATOS,
      RatkaisuType.RAUENNUT,
      RatkaisuType.SILLENSA_JATETTY,
      RatkaisuType.AIHEETON_VIENTI,
      RatkaisuType.ASIAN_SIIRTO,
    ],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: true,
    mandatorySpecifier: false,
    specifierTypeIds: [
      SpecifierType.VIRANOMAISALOITTEINEN,
      SpecifierType.HETU_NORMAALI_KASITTELY,
      SpecifierType.HETU_NOPEUTETTU_KASITTELY,
    ],
  },
  toimenpideMetadata: [
    {
      toimenpideTypeId: ToimenpideType.LYKKAYS,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      huomioMetadata: {
        [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_LYKKAYSTA,
        [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.LYKKAYKSEN_MAARAAIKA_YLITTYNYT,
        showInHuomioFilter: true,
      },
    },
  ],
  vireilletulokanavaMetadata: [
    Vireilletulokanava.ELOMAKE_KANSALAINEN,
    Vireilletulokanava.ELSA,
    Vireilletulokanava.KELA_LUOVA,
  ],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.KASITTELYSSA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.KASITTELYSSA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
}
