import { ChildTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Block,
  Button,
  ExpanderGroup,
  IconPlus,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  Text,
} from 'suomifi-ui-components'
import { runInAction } from 'mobx'
import { ASIOINTI_ROOT_ELEMENT_ID } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/constants'
import { FormProvider, useForm } from 'react-hook-form'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import {
  injectLedgerNumbers,
  LedgerNumberInjectable,
} from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-utility.util'
import { AsiointiLedgerAccountCategory } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'
import styled from 'styled-components'
import { saveDraft } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'

export interface FormElementProps<T extends object> {
  entry: T
  onEdit: () => void
  onRemove: (deleteModalText: string) => void
  createDataTestId: (...arg: ChildTestId[]) => string
}

export interface FormModalProps<T extends object> {
  entry: T
  setEntry: (entry: T) => void
  createDataTestId: (...arg: ChildTestId[]) => string
}

export interface FormListProps<T extends object> {
  translationPrefix: string
  ModalContentComponent: React.FC<FormModalProps<T & { id: string }>>
  ExpanderComponent: React.FC<FormElementProps<T & { id: string }>>
  state: (T & { id: string; tyyppi?: string; tiliointinumero?: string })[] // observable array
  newEntry: () => T & { id: string }
  dataTestIdPrefix: string
  asiointiLedgerAccountCategory?: AsiointiLedgerAccountCategory
}

export const FormListInput = observer(
  <T extends object>({
    translationPrefix,
    ModalContentComponent,
    ExpanderComponent,
    newEntry,
    dataTestIdPrefix,
    state,
    asiointiLedgerAccountCategory,
  }: FormListProps<T>) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const [modalVisible, setModalVisible] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [deleteModalText, setDeleteModalText] = useState('')
    const [deleteModalOnClick, setDeleteModalOnClick] = useState<() => void>(
      () => () => null
    )
    const [nextNewEntryState, setNextNewEntryState] = useState(newEntry())
    const [modalEntryState, setModalEntryState] = useState(nextNewEntryState)
    const createListDataTestId = (...arg: ChildTestId[]) =>
      `${[dataTestIdPrefix, ...arg].join('-')}`

    const isEditing = () => state.find(({ id }) => id === modalEntryState.id)

    const modalForm = useForm()

    const openModalForEdit = (entry: T & { id: string }) => {
      setModalEntryState({ ...entry })
      modalForm.reset()
      setModalVisible(true)
    }

    const openModalForAddNew = () => {
      const entry = { ...nextNewEntryState }
      setModalEntryState(entry)
      if (modalEntryState.id !== entry.id) {
        modalForm.reset()
      }
      setModalVisible(true)
    }

    const onModalAbort = () => {
      setModalVisible(false)
      if (!isEditing()) {
        setNextNewEntryState(modalEntryState) // save data to continue later
      }
    }

    const onModalSubmit = () => {
      if (!isEditing()) {
        runInAction(() => {
          state.push(modalEntryState)
        })
        setNextNewEntryState(newEntry())
      } else {
        runInAction(() =>
          state.forEach((item) => {
            if (item.id === modalEntryState.id) {
              Object.assign(item, modalEntryState)
              if (asiointiLedgerAccountCategory) {
                item.tiliointinumero = ''
              }
            }
          })
        )
      }
      if (asiointiLedgerAccountCategory) {
        runInAction(() => {
          injectLedgerNumbers(
            asiointiLedgerAccountCategory,
            state as LedgerNumberInjectable[]
          )
        })
      }
      void saveDraft()
      setModalVisible(false)
    }
    // todo korjaa välistykset
    return (
      <>
        {state.length > 0 && (
          <ExpanderGroup
            openAllText={t('formListAvaaKaikki')}
            ariaOpenAllText={t('formListAvaaKaikkiScreenReader')}
            closeAllText={t('formListSuljeKaikki')}
            ariaCloseAllText={t('formListSuljeKaikkiScreenReader')}
            data-test-id={createListDataTestId('expander-group')}
          >
            {state.map((entry, index) => {
              const createFieldTestId = (...id: ChildTestId[]) =>
                createListDataTestId(index, ...id)

              return (
                <ExpanderComponent
                  key={index}
                  entry={entry}
                  onEdit={() => openModalForEdit({ ...entry })}
                  onRemove={(deleteModalText: string) => {
                    setDeleteModalText(deleteModalText)
                    setDeleteModalOnClick(() => () => {
                      runInAction(() => state.splice(index, 1))
                      setDeleteModalVisible(false)
                    })
                    setDeleteModalVisible(true)
                  }}
                  createDataTestId={createFieldTestId}
                />
              )
            })}
          </ExpanderGroup>
        )}
        <Block mt="xl" />

        <Button
          icon={<IconPlus />}
          variant="secondary"
          data-test-id={dataTestIdPrefix + '-add-button'}
          onClick={openModalForAddNew}
        >
          {t(`${translationPrefix}-ADD-BUTTON`)}
        </Button>
        <Modal
          visible={modalVisible}
          appElementId={ASIOINTI_ROOT_ELEMENT_ID}
          variant={isTablet ? 'default' : 'smallScreen'}
          onEscKeyDown={onModalAbort}
        >
          <FormProvider {...modalForm}>
            <ModalContent data-test-id={createListDataTestId('modal-content')}>
              <ModalTitle>
                {!isEditing()
                  ? t(`${translationPrefix}-ADD-HEADER`)
                  : t(`${translationPrefix}-EDIT-HEADER`)}
              </ModalTitle>
              <ModalContentComponent
                entry={modalEntryState}
                setEntry={setModalEntryState}
                createDataTestId={createListDataTestId}
              />
            </ModalContent>
            <ModalFooterWrapper>
              <ModalFooter data-test-id={createListDataTestId('modal-footer')}>
                <Button
                  data-test-id={createListDataTestId('modal-save-button')}
                  onClick={async () =>
                    modalForm.handleSubmit(() => onModalSubmit())()
                  }
                >
                  {t('save')}
                </Button>
                <Button
                  data-test-id={createListDataTestId('modal-cancel-button')}
                  onClick={onModalAbort}
                  variant="secondary"
                >
                  {t('cancel')}
                </Button>
              </ModalFooter>
            </ModalFooterWrapper>
          </FormProvider>
        </Modal>
        <Modal
          visible={deleteModalVisible}
          appElementId={ASIOINTI_ROOT_ELEMENT_ID}
          variant={isTablet ? 'default' : 'smallScreen'}
          onEscKeyDown={() => setDeleteModalVisible(false)}
        >
          <ModalContent
            data-test-id={createListDataTestId('delete-modal-content')}
          >
            <ModalTitle>{t('vahvistaPoisto')}</ModalTitle>
            <Text>{deleteModalText}</Text>
          </ModalContent>
          <ModalFooter
            data-test-id={createListDataTestId('delete-modal-footer')}
          >
            <Button
              data-test-id={createListDataTestId('delete-modal-confirm-button')}
              onClick={() => deleteModalOnClick()}
            >
              {t('vahvistaPoisto')}
            </Button>
            <Button
              data-test-id={createListDataTestId('delete-modal-cancel-button')}
              onClick={() => setDeleteModalVisible(false)}
              variant="secondary"
            >
              {t('cancel')}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
)

const ModalFooterWrapper = styled.div`
  .fi-modal_footer_content-gradient-overlay {
    pointer-events: none;
  } // hax, this allows clicking through gradient overlay, will be removed after design system update
`
