import { EdvardAsiointiAccountBase } from 'tilintarkastus-common/src/vtj/types/edvard-account-data.type'
import { Tilirivit } from 'tilintarkastus-common/src/vtj/types/asiointi-account-rows.type'
import { MimeType } from 'common/src/vtj/MimeType.enum'
import { AsiointiLedgerAccountIncomeType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-income-enum'
import keyMirror from 'keymirror'
import {
  AsiointiHabitationPeriodType,
  AsiointiHabitationType,
} from 'tilintarkastus-common/src/vtj/asiointi-account-enums'

export interface AsiointiAccountDataInput extends EdvardAsiointiAccountBase {
  seurantaAsiaVaatimusId: number
  isMuutMerkittavatToimet: boolean
  muutMerkittavatToimet?: string
  isAnnettuKayttovaroja: boolean
  kayttovaraKaytanto: string
  asuinpaikat: Asuinpaikka[]
  saannollisetTulot: SaannollinenTulo[]
  verotustiedot: Verotustieto[]
  palkkio: Palkkio
  isHallintaoikeuksia: boolean
  mitenHallintaoikeuksistaOnSovittu?: string
  hallintaoikeusSopimukset: AsiakirjaLiite[]
  sopimukset: Sopimus[]
  vakuutukset: Vakuutus[]
  tilirivit?: Tilirivit
  edunvalvojanValtakirja?: AsiakirjaLiite[]
  toimintakyky?: string
  isAsuinpaikkaTuntematon?: boolean
  kaytossaOlevaOmaisuus?: KayttoOmaisuus[]
  hasKaytossaOlevaOmaisuus?: string // 'true' 'false'
}

export type KayttoOmaisuus = { value: string }

export interface AsiointiPropertyInventoryDataInput {
  seurantaAsiaVaatimusId: number
  isMuutMerkittavatToimet: boolean
  muutMerkittavatToimet?: string
  isAnnettuKayttovaroja: boolean
  kayttovaraKaytanto: string
  asuinpaikat: Asuinpaikka[]
  verotustiedot: Verotustieto[]
  isHallintaoikeuksia: boolean
  mitenHallintaoikeuksistaOnSovittu?: string
  hallintaoikeusSopimukset: AsiakirjaLiite[]
  sopimukset: Sopimus[]
  vakuutukset: Vakuutus[]
  tilirivit?: Pick<Tilirivit, 'varat' | 'velat'>
  edunvalvojanValtakirja?: AsiakirjaLiite[]
  guardianshipBeginDate: string
  toimintakyky?: string
  kaytossaOlevaOmaisuus?: KayttoOmaisuus[]
  hasKaytossaOlevaOmaisuus?: string // 'true' 'false'
}

export interface Asuinpaikka {
  streetAddress: string
  streetAddressExtra?: string
  postalCode: string
  postOffice: string
  countryCode: string
  beginDate?: string
  endDate?: string
  habitationType: AsiointiHabitationType
  habitationPeriodType: AsiointiHabitationPeriodType
}

export interface SaannollinenTulo {
  tyyppi: AsiointiLedgerAccountIncomeType
  maksaja: string
  nettosumma: number
  beginDate?: string
  endDate?: string
  asiakirjat?: AsiakirjaLiite[]
}

export const TiliAsiointiAsiakirjatyyppi = keyMirror({
  MUU_LIITE: null,
})

export type TiliAsiointiAsiakirjatyyppiId =
  (typeof TiliAsiointiAsiakirjatyyppi)[keyof typeof TiliAsiointiAsiakirjatyyppi]

export type AsiakirjaLiite = {
  sourceFileId: string
  filename: string
  sizeBytes: number
  mimeType: MimeType
  asiakirjatyyppi: TiliAsiointiAsiakirjatyyppiId
}

export type Verotustieto = AsiakirjaLiite

export type Palkkio = {
  veloitatkoPalkkiota: boolean
  palkkioMaara?: number
  veloitatkoKulukorvauksia: boolean
  kulukorvausMaara?: number
  kululaskelma: AsiakirjaLiite[]
}

export interface Sopimus {
  sopimustyyppi: string
  toimittaja: string
  beginDate?: string
  endDate?: string
  asiakirjat?: AsiakirjaLiite[]
}

export interface Vakuutus {
  vakuutustyyppi: string
  toimittaja: string
  beginDate?: string
  endDate?: string
  asiakirjat?: AsiakirjaLiite[]
}
