import { AsiakirjaLiite } from 'tilintarkastus-common/src/vtj/types/asiointi-account-data.type'
import { AsiointiLedgerAccountType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'

export type Tilirivi = {
  tiliointinumero: string
  tyyppi: AsiointiLedgerAccountType
}

export type Omaisuus = Tilirivi & {
  arvoTilikaudenAlussaEur: number
  arvoTilikaudenLopussaEur: number
  asiakirjat: AsiakirjaLiite[]
}

export type TalletusTaiVakuutus = Omaisuus & {
  myontajanNimi: string
  vakuusTaiTilinumero: string
}

export type Arvopaperi = Omaisuus & {
  yhtionNimi: string
  /* maksimissaan 5 desimaalin tarkkuudella */
  kappalemaaraTilikaudenLopussa: number
}

export type RahastoOsuus = Arvopaperi

export type YhtioTaiYritysOsuus = Omaisuus & {
  yhtionNimi: string
  osakkeidenMaara: number
  omistusosuusProsentteina: number
}

export type KuolinpesaOsakkuus = Omaisuus & {
  vainajanEtunimi: string
  vainajanSukunimi: string
  vainajanKuolinpaiva: string // IsoDateString
  osakkuudenOsuusProsentteina: number
}

export enum Kayttotarkoitus {
  OMASSA_KAYTOSSA = 'OMASSA_KAYTOSSA',
  TYHJILLAAN = 'TYHJILLAAN',
  VUOKRATTUNA = 'VUOKRATTUNA',
}

/**
 * arvoTilikaudenAlussaEur, arvoTilikaudenLopussaEur
 * EI SAA OLLA DESIMAALEJA
 */
export type Huoneisto = Omaisuus & {
  kayttotarkoitus: Kayttotarkoitus
  yhtionNimi: string
  osakkeidenNumerot: string
  omistusosuusProsentteina: number
  vuokrasopimukset: AsiakirjaLiite[]
}

/**
 * arvoTilikaudenAlussaEur, arvoTilikaudenLopussaEur
 * EI SAA OLLA DESIMAALEJA
 */
export type Kiinteisto = Omaisuus & {
  kayttotarkoitus: Kayttotarkoitus
  kiinteistonNimi: string
  kiinteistonTunnus: string
  omistusosuusProsentteina: number
  vuokrasopimukset: AsiakirjaLiite[]
}

export type Irtaimisto = Omaisuus & {
  nimi: string
}

export type Saatava = Omaisuus & {
  velallisenEtunimi: string
  velallisenSukunimi: string
}

export type MuuVara = Omaisuus & {
  nimi: string
}

export type Varat = Partial<{
  talletukset: TalletusTaiVakuutus[]
  arvopaperit: Arvopaperi[]
  rahastoOsuudet: RahastoOsuus[]
  osuudetHenkiloYhtiossaJaYrityksissa: YhtioTaiYritysOsuus[]
  huoneistot: Huoneisto[]
  irtaimistot: Irtaimisto[]
  kiinteistot: Kiinteisto[]
  muutVarat: MuuVara[]
  saatavat: Saatava[]
  osuudetKuolinpesissa: KuolinpesaOsakkuus[]
}>

export type Pankkilaina = Omaisuus & {
  lainanNumero: string
  pankinNimi: string
}

export type Takaus = Omaisuus & {
  velallisenEtunimi: string
  velallisenSukunimi: string
  vakuus: string
  onkoTullutHenkilonMaksettavaksi: boolean
}

export type MuuVelka = Omaisuus

export type Velat = Partial<{
  pankkilainat: Pankkilaina[]
  takaukset: Takaus[]
  muutVelat: MuuVelka[]
}>

export type TuloTaiMeno = Tilirivi & {
  yhteensa: number
}

export type Tulo = TuloTaiMeno & {
  maksaja: string
}

export type Meno = TuloTaiMeno

export type Elake = Tulo

export type Palkka = Tulo & {
  suostumus: AsiakirjaLiite[]
}

export type Etuus = Tulo

export type Tyottomyysturva = Tulo

export type Vuokratulo = TuloTaiMeno & {
  nimi: string
  tunnus: string
}

export type Osinkotulo = Tulo

export type Korkotulo = Tulo

export type Myyntitulo = TuloTaiMeno & {
  tositteet: AsiakirjaLiite[]
}

export type MuuTulo = Tulo & {
  tositteet: AsiakirjaLiite[]
}

export type Tulot = Partial<{
  elakkeet: Elake[]
  palkat: Palkka[]
  etuudet: Etuus[]
  tyottomyysturva: Tyottomyysturva[]
  vuokratulot: Vuokratulo[]
  osinkotulot: Osinkotulo[]
  korkotulot: Korkotulo[]
  myyntitulot: Myyntitulo[]
  muutTulot: MuuTulo[]
}>

export type Kayttovara = Meno & {
  tositteet: AsiakirjaLiite[]
}

export type Elinkustannus = Meno

export type Palvelumaksu = Meno

export type VuokraTaiVastike = Meno & {
  nimi?: string
  tunnus?: string
}

export type Elatusapu = Meno

export type Pankkimaksu = Meno

export type Vero = Meno

export type Tyonantajakulu = Meno

export type Velanhoitomeno = Meno

export type MuuMeno = Meno & {
  tositteet: AsiakirjaLiite[]
}

export type Edunvalvonnankulu = Meno & {
  tositteet: AsiakirjaLiite[]
}

export type Menot = Partial<{
  kayttovarat: Kayttovara[]
  elinkustannukset: Elinkustannus[]
  palvelumaksut: Palvelumaksu[]
  vuokratVastikkeet: VuokraTaiVastike[]
  elatusavut: Elatusapu[]
  pankinPerimatMaksut: Pankkimaksu[]
  verot: Vero[]
  tyonantajakulut: Tyonantajakulu[]
  velanhoitomenot: Velanhoitomeno[]
  edunvalvonnankulut: Edunvalvonnankulu[]
  muutMenot: MuuMeno[]
}>

export type Tilirivit = Partial<{
  varat: Varat
  velat: Velat
  tulot: Tulot
  menot: Menot
}>
