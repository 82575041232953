/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkOmaisuusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block, Heading, Paragraph, Text } from 'suomifi-ui-components'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import { getTilirivit } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { FrontIrtaimisto } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { FormListInputExpander } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonEuroWithDecimalsOrUndefinedValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/react-form-validation.util'
import { observable } from 'mobx'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import { AsiointiLedgerAccountCategory } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'
import { AsiointiLedgerAccountAssetsType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-assets-enum'
import { TiliAsiointiAsiakirjatyyppi } from 'tilintarkastus-common/src/vtj/types/asiointi-account-data.type'

const IrtaimistotForm: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const omaisuus = getTilirivit()

  const mkNewEntry = (): FrontIrtaimisto =>
    observable({
      id: crypto.randomUUID(),
      tiliointinumero: '',
      tyyppi: AsiointiLedgerAccountAssetsType.IRTAIMISTO,
      nimi: '',
      arvoTilikaudenAlussaEur: undefined,
      arvoTilikaudenLopussaEur: undefined,
      asiakirjat: [],
    })

  return (
    <section data-test-id={mkOmaisuusTestId('irtaimistot-form')}>
      <Heading variant="h3">{t('irtaimistotHeading')}</Heading>
      <Block mt={verticalMarginToken} />
      <Paragraph>{t('ohjeIlmoitaIrtaimistot')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={IrtaimistoListItem}
        state={omaisuus.varat.irtaimistot}
        asiointiLedgerAccountCategory={AsiointiLedgerAccountCategory.VARAT}
        dataTestIdPrefix={mkOmaisuusTestId('irtaimistot-form')}
        ModalContentComponent={IrtaimistoModal}
        newEntry={mkNewEntry}
        translationPrefix="irtaimistot"
      />
    </section>
  )
}

const IrtaimistoModal: FC<FormModalProps<FrontIrtaimisto>> = observer(
  ({ createDataTestId, entry, setEntry }) => {
    const [t] = useTranslation()

    return (
      <div>
        <FormTextInput
          data-test-id={createDataTestId('modal-irtaimistonTyyppi')}
          labelText={t('irtaimistonTyyppi')}
          hintText={t('irtaimistonTyyppiHintText')}
          value={entry.nimi}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) => setEntry({ ...entry, nimi: value })}
        />

        <FormNumberInput
          data-test-id={createDataTestId('modal-arvoTilikaudenAlussa')}
          labelText={t('arvoTilikaudenAlussaEuroina')}
          digitMode="two"
          required
          value={entry.arvoTilikaudenAlussaEur}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) =>
            setEntry({
              ...entry,
              arvoTilikaudenAlussaEur: value,
            })
          }
        />

        <FormNumberInput
          data-test-id={createDataTestId('modal-arvoTilikaudenLopussa')}
          labelText={t('arvoTilikaudenLopussaEuroina')}
          digitMode="two"
          required
          value={entry.arvoTilikaudenLopussaEur}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) => {
            setEntry({
              ...entry,
              arvoTilikaudenLopussaEur: value,
            })
          }}
        />

        <FormAttachmentFileBox
          observableAttachments={entry.asiakirjat}
          asiakirjaTypeId={TiliAsiointiAsiakirjatyyppi.MUU_LIITE}
          data-test-id={createDataTestId('modal-asiakirja')}
          text={t('irtaimistotAsiakirjaInfo')}
          title={t('asiakirjat')}
        />
      </div>
    )
  }
)

const IrtaimistoListItem: FC<FormElementProps<FrontIrtaimisto>> = observer(
  ({ createDataTestId, entry, onEdit, onRemove }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'

    const arvoLopussaEur = `${formatNumberWithComma(
      entry.arvoTilikaudenLopussaEur!
    )} €`
    const arvoAlussaEur = `${formatNumberWithComma(
      entry.arvoTilikaudenAlussaEur!
    )} €`

    return (
      <FormListInputExpander
        heading={entry.nimi}
        headingRight={arvoLopussaEur}
        createDataTestId={createDataTestId}
        onEdit={onEdit}
        onRemove={onRemove}
        showSubHeadingWhileOpen
      >
        <Heading variant="h5">{t('arvoTilikaudenAlussaEuroina')}</Heading>
        <Text>{arvoAlussaEur}</Text>
        <Block mt={verticalMarginToken} />

        {entry.asiakirjat.length > 0 && (
          <>
            <Block mt={verticalMarginToken} />
            <Heading variant="h5">{t('asiakirjat')}</Heading>
            <AttachmentList attachments={entry.asiakirjat} />
            <Block mt={verticalMarginToken} />
          </>
        )}
        <Block mt={verticalMarginToken} />
      </FormListInputExpander>
    )
  }
)

export default observer(IrtaimistotForm)
