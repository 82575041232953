import React from 'react'
import { observer } from 'mobx-react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  RouterLink,
  suomifiDesignTokens,
  WizardNavigation,
  WizardNavigationItem,
} from 'suomifi-ui-components'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import { BorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import {
  PAATOSTILI_ROUTES,
  PaatostiliRoutePath,
  OMAISUUSLUETTELO_ROUTES,
  TilintarkastusFormState,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import {
  getTilintarkastusStore,
  runTilintarkastusStoreAction,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { mkTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'

// values from suomifi-ui-components WizardNavigationItemProps
export type NavigationStepStatus =
  | 'default'
  | 'current'
  | 'current-completed'
  | 'completed'
  | 'coming'
  | 'disabled'

export type NavigationState = Record<PaatostiliRoutePath, NavigationStepStatus>

const stepTranslationByKey: Record<string, string> = {
  TEE_PAATOSTILI: 'aloitus',
  YLEISKATSAUS: 'yleiskatsaus',
  OMAISUUS_JA_VELAT: 'omaisuusJaVelat',
  TULOT_JA_MENOT: 'tulotJaMenot',
  PALKKIO: 'palkkio',
  YHTEYSTIEDOT: 'yhteystiedot',
  YHTEENVETO: 'yhteenvetoJaLahetys',
  VALMIS: 'valmis',
  TEE_OMAISUUSLUETTELO: 'aloitus',
  TOIMINTAKYKY_JA_ASUMISTIEDOT: 'toimintakykyJaAsumistiedot',
  OMASSA_KAYTOSSA_OLEVA_OMAISUUS: 'omassaKaytossaOlevaOmaisuus',
  VARAT: 'varat',
  VELAT: 'velat',
  ELAKKEET_JA_ETUUDET: 'elakkeetJaEtuudet',
  KAYTTOVARAT: 'kayttovarat',
  SOPIMUKSET_JA_VAKUUTUKSET: 'sopimuksetJaVakuutukset',
  MUUT_MERKITTAVAT_TOIMET: 'muutMerkittavatToimet',
}

interface FormWizardNavigationProps<
  T extends typeof PAATOSTILI_ROUTES | typeof OMAISUUSLUETTELO_ROUTES,
  V extends T[keyof T] & string
> {
  routes: T
  steps: V[]
  forms: Partial<Record<V, TilintarkastusFormState>>
}

const FormNavigationWizard = observer(
  <
    T extends typeof PAATOSTILI_ROUTES | typeof OMAISUUSLUETTELO_ROUTES,
    V extends T[keyof T] & string
  >({
    forms,
    routes,
    steps,
  }: FormWizardNavigationProps<T, V>) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const store = getTilintarkastusStore()
    const location = useLocation()
    const currentStep = location.pathname

    runTilintarkastusStoreAction((store) => {
      store.visitedSteps.add(currentStep)
    })

    const evaluateStep = (
      currentStep: V,
      evalStep: V
    ): NavigationStepStatus => {
      const currentIndex = steps.indexOf(currentStep)
      const evalIndex = steps.indexOf(evalStep)
      const completed = evaluateStepCompleted(evalStep)
      if (currentIndex === evalIndex) {
        return completed ? 'current-completed' : 'current'
      } else if (
        !!store?.visitedSteps.has(evalStep) &&
        store.submitStatus !== 'submitted'
      ) {
        return completed ? 'completed' : 'default'
      } else {
        return 'coming'
      }
    }

    const evaluateStepCompleted = (step: V): boolean => {
      const currentStepIndex = steps.indexOf(step)
      const nextStep = steps.at(currentStepIndex + 1) as PaatostiliRoutePath
      const stepFormState = (
        forms as Record<string, TilintarkastusFormState | undefined>
      )[step]

      return (
        store.visitedSteps.has(step) &&
        store.visitedSteps.has(nextStep) &&
        (!stepFormState || stepFormState.isComplete)
      )
    }

    const evaluateNavigationState = (currentStep: string): NavigationState =>
      steps.reduce((navState, evalStep: V) => {
        return {
          ...navState,
          [evalStep]: evaluateStep(currentStep as V, evalStep),
        }
      }, {} as NavigationState)

    const navState = evaluateNavigationState(currentStep)

    return (
      <NavBlock>
        <WizardNavigation
          heading={t('Vaiheet')}
          aria-label={t('vaiheet')}
          variant={isTablet ? 'default' : 'smallScreen'}
          initiallyExpanded={false}
        >
          {steps.map((path, index) => {
            const [[key, value]] = Object.entries(routes).filter(
              ([_, value]) => value === path
            )
            return (
              <NavItem
                key={key}
                status={navState[value as V]}
                href={value}
                text={`${index + 1}. ${t(stepTranslationByKey[key])}`}
                data-test-id={mkTestId('wizard-navigation', path)}
              />
            )
          })}
        </WizardNavigation>
      </NavBlock>
    )
  }
)

const NavBlock = styled(BorderedContentBlock)`
  @media ${device.tablet} {
    max-width: 366px;
    padding: ${suomifiDesignTokens.spacing.s} 0;
  }
`

const NavItem: React.FC<{
  status: NavigationStepStatus
  text: string
  href: string
  'data-test-id': string
}> = observer(({ status, text, href, 'data-test-id': dataTestId }) => {
  const [t] = useTranslation()
  const ariaLabel =
    status === 'current-completed' || status === 'completed'
      ? `${text}. ${t('vaiheValmis')}`
      : undefined
  return (
    <WizardNavigationItem
      status={status}
      aria-current={status === 'current' ? 'step' : undefined}
      data-test-id={dataTestId}
    >
      <RouterLink
        to={href}
        asComponent={Link}
        aria-label={ariaLabel}
        data-test-id={`${dataTestId}-link`}
      >
        {text}
      </RouterLink>
    </WizardNavigationItem>
  )
})

export default FormNavigationWizard
