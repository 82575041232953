import styled from 'styled-components'
import React, { useId, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import {
  Block,
  Button,
  Dropdown,
  DropdownItem,
  Heading,
  Paragraph,
  RadioButton,
  RadioButtonGroup,
  Text,
  TextInput,
  ExternalLink,
} from 'suomifi-ui-components'
import { IconRemove } from 'suomifi-icons'
import {
  AsiointiPerson,
  TemporaryId,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/asiointi-batch.type'
import {
  removePerson,
  setPaamiesOpinion,
  setPersonValues,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/actions/person-actions'
import { Country } from 'holhous-common/src/vtj/country/country.type'
import {
  LupaApplicationRole,
  OpinionType,
  OpinionTypeId,
} from 'lupa-backend/src/vtj/elsa/person/person-enums'
import { useTranslation } from 'react-i18next'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/holhous-asiointi-user-store'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import {
  mkLupaAsiointiPersonActionTestId,
  mkLupaAsiointiPersonFieldTestId,
  mkLupaAsiointiPersonHeadingTestId,
  mkLupaAsiointiPersonTestId,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-asiointi-test-id'
import {
  LupaAsiointiAsiakirjaType,
  LupaAsiointiAsiakirjaTypeId,
} from 'lupa-backend/src/vtj/asiointi/asiakirja/asiointi-asiakirja-enums'
import PersonAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/persons/PersonAttachmentFileBox'
import { resolveFieldStatusProps } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/validation/validation.util'
import { useValidationErrorFocus } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/validation/validation-error-focus.util'
import {
  FocusableDiv,
  focusOutline,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { lomakkeetUrl } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/links'
import { trimEventTargetValue } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/input.utils'

const resolveCountryShortNamesInDisplayOrder = (
  countries: Country[],
  lang: HolhousAsiointiLanguageCode
): string[] => countries.map((c) => c.shortName[lang]).sort()

const resolveCountryShortName = (
  countries: Country[],
  countryId: string | null,
  lang: HolhousAsiointiLanguageCode
): string | undefined =>
  countries.find((c) => c.countryId === countryId)?.shortName[lang]

const isOpinionType = (
  value: string | null | undefined
): value is OpinionTypeId =>
  !!value && Object.keys(OpinionType).indexOf(value) >= 0

const isLupaAsiointiAsiakirjaTypeId = (
  value: string | null | undefined
): value is LupaAsiointiAsiakirjaTypeId =>
  !!value && Object.keys(LupaAsiointiAsiakirjaType).indexOf(value) >= 0

const PersonFormTextInput = styled(TextInput)`
  width: 100%;
  max-width: 290px;

  > * {
    margin-bottom: 25px;
  }
`

const PersonFormRadioButtonGroup = styled(RadioButtonGroup)`
  > * {
    margin-bottom: 25px;
  }
`

const PersonFormDropdown = styled(function PersonFormDropdown(props) {
  return (
    <Dropdown {...props} ref={useValidationErrorFocus(props.fieldTestId)} />
  )
})`
  width: 100%;
  max-width: 290px;

  > * {
    margin-bottom: 25px;
  }
`

const PersonFormReadOnlyText = React.forwardRef<
  HTMLElement,
  {
    labelText: string
    defaultValue: string
    'data-test-id': string
  }
>(function PersonFormReadOnlyText(props, ref) {
  return (
    <>
      <Paragraph mb="xxs">
        <Text smallScreen variant="bold">
          {props.labelText}
        </Text>
      </Paragraph>
      <Paragraph mb="m">
        <Text
          smallScreen
          data-test-id={props['data-test-id'] + '-readonly'}
          ref={ref}
        >
          {props.defaultValue}
        </Text>
      </Paragraph>
    </>
  )
})

const HakijaValtakirjaAttachment: React.FC<{
  asiointiPerson: Pick<
    AsiointiPerson,
    'personId' | 'attachments' | 'validationErrors'
  >
  'data-test-id': string
}> = observer((props) => {
  const [t] = useTranslation()
  const fileBoxId = useId()

  return (
    <FocusableDiv
      ref={useValidationErrorFocus<HTMLDivElement>(props['data-test-id'])}
      tabIndex={-1}
    >
      <Paragraph mb="xs" htmlFor={fileBoxId}>
        <Text smallScreen variant="bold">
          {t('asiointiAsiakirjaType-VALTAKIRJA')}
        </Text>
      </Paragraph>
      <PersonAttachmentFileBox
        asiakirjaTypeId={LupaAsiointiAsiakirjaType.VALTAKIRJA}
        htmlId={fileBoxId}
        asiointiPerson={props.asiointiPerson}
        data-test-id={props['data-test-id']}
      />
    </FocusableDiv>
  )
})

const PaamiesOpinionAttachment: React.FC<{
  asiointiPerson: Pick<
    AsiointiPerson,
    | 'personId'
    | 'attachments'
    | 'opinionTypeId'
    | 'validationErrors'
    | 'opinionAttachmentAsiakirjaTypeId'
  >
  createDataTestId: (...arg: string[]) => string
}> = observer(({ asiointiPerson, createDataTestId }) => {
  const [t] = useTranslation()
  const fileBoxId = useId()
  const { lang } = useAsiointiUserStore()

  const existingAsiakirjaTypeId = asiointiPerson.attachments.length
    ? asiointiPerson.attachments[0].asiakirjaTypeId
    : null
  const [opinionAttachmentTypeForYes, setOpinionAttachmentTypeForYes] =
    useState<LupaAsiointiAsiakirjaTypeId>(() => {
      if (
        existingAsiakirjaTypeId ===
        LupaAsiointiAsiakirjaType.MUU_SELVITYS_PAAMIEHEN_MIELIPITEEN_SELVITTAMISESTA
      ) {
        return LupaAsiointiAsiakirjaType.MUU_SELVITYS_PAAMIEHEN_MIELIPITEEN_SELVITTAMISESTA
      } else {
        return LupaAsiointiAsiakirjaType.PAAMIEHEN_LAUSUNTO
      }
    })
  const [opinionAttachmentTypeForNo, setOpinionAttachmentTypeForNo] =
    useState<LupaAsiointiAsiakirjaTypeId>(() => {
      if (
        existingAsiakirjaTypeId === LupaAsiointiAsiakirjaType.LAAKARINLAUSUNTO
      ) {
        return LupaAsiointiAsiakirjaType.LAAKARINLAUSUNTO
      } else {
        return LupaAsiointiAsiakirjaType.MUU_SELVITYS_PERUSTEESTA_OLLA_KUULEMATTA_PAAMIESTA
      }
    })
  const opinionAttachmentsRef = useValidationErrorFocus<HTMLDivElement>(
    createDataTestId('attachments')
  ) // Attachment box rendered conditionally, but hooks must not be rendered conditionally.

  const resolvePaamiesAttachmentType = (
    value: OpinionTypeId
  ): LupaAsiointiAsiakirjaTypeId | null => {
    return value === OpinionType.HAKIJA_ON_SELVITTANYT_PAAMIEHEN_MIELIPITEEN
      ? opinionAttachmentTypeForYes
      : value === OpinionType.PAAMIES_EI_VOI_ANTAA_MIELIPIDETTAAN
      ? opinionAttachmentTypeForNo
      : null
  }

  return (
    <>
      <PersonFormRadioButtonGroup
        data-test-id={createDataTestId('opinionTypeId')}
        value={asiointiPerson.opinionTypeId}
        labelText={t('ymmartaakoPaamiesAsianMerkityksen')}
        name={createDataTestId('opinionAttachmentType')}
        onChange={(value) => {
          if (isOpinionType(value)) {
            void setPaamiesOpinion(
              asiointiPerson.personId,
              value,
              resolvePaamiesAttachmentType(value)
            )
          }
        }}
      >
        <RadioButton
          data-test-id={createDataTestId(
            'opinionType',
            OpinionType.HAKIJA_ON_SELVITTANYT_PAAMIEHEN_MIELIPITEEN
          )}
          value={OpinionType.HAKIJA_ON_SELVITTANYT_PAAMIEHEN_MIELIPITEEN}
          variant="small"
        >
          {t('lupaPersonOpinionTypeKylla')}
        </RadioButton>
        <RadioButton
          data-test-id={createDataTestId(
            'opinionType',
            OpinionType.PAAMIES_EI_VOI_ANTAA_MIELIPIDETTAAN
          )}
          value={OpinionType.PAAMIES_EI_VOI_ANTAA_MIELIPIDETTAAN}
          variant="small"
        >
          {t('lupaPersonOpinionTypeEi')}
        </RadioButton>
        <RadioButton
          data-test-id={createDataTestId(
            'opinionType',
            OpinionType.PAAMIES_ALLE_15V_MIELIPIDETTA_EI_SELVITETTY
          )}
          value={OpinionType.PAAMIES_ALLE_15V_MIELIPIDETTA_EI_SELVITETTY}
          variant="small"
        >
          {t('lupaPersonOpinionTypeAlle15vuotias')}
        </RadioButton>
      </PersonFormRadioButtonGroup>
      {asiointiPerson.opinionTypeId ===
        OpinionType.HAKIJA_ON_SELVITTANYT_PAAMIEHEN_MIELIPITEEN && (
        <PersonFormRadioButtonGroup
          data-test-id={createDataTestId('opinionAttachmentType')}
          value={opinionAttachmentTypeForYes}
          labelText={t('lupaPersonOpinionType-YES-selectAttachmentTypeHeader')}
          name={createDataTestId(
            'opinionAttachmentType',
            OpinionType.HAKIJA_ON_SELVITTANYT_PAAMIEHEN_MIELIPITEEN
          )}
          onChange={(value) => {
            if (isLupaAsiointiAsiakirjaTypeId(value)) {
              setOpinionAttachmentTypeForYes(value)
              void setPaamiesOpinion(
                asiointiPerson.personId,
                OpinionType.HAKIJA_ON_SELVITTANYT_PAAMIEHEN_MIELIPITEEN,
                value
              )
            }
          }}
        >
          <RadioButton
            data-test-id={createDataTestId(
              'opinionAttachmentType',
              LupaAsiointiAsiakirjaType.PAAMIEHEN_LAUSUNTO
            )}
            value={LupaAsiointiAsiakirjaType.PAAMIEHEN_LAUSUNTO}
            variant="small"
          >
            {t('asiointiAsiakirjaType-PAAMIEHEN_LAUSUNTO')}
          </RadioButton>
          <RadioButton
            data-test-id={createDataTestId(
              'opinionAttachmentType',
              LupaAsiointiAsiakirjaType.MUU_SELVITYS_PAAMIEHEN_MIELIPITEEN_SELVITTAMISESTA
            )}
            value={
              LupaAsiointiAsiakirjaType.MUU_SELVITYS_PAAMIEHEN_MIELIPITEEN_SELVITTAMISESTA
            }
            variant="small"
          >
            {t(
              'asiointiAsiakirjaType-MUU_SELVITYS_PAAMIEHEN_MIELIPITEEN_SELVITTAMISESTA'
            )}
          </RadioButton>
        </PersonFormRadioButtonGroup>
      )}
      {asiointiPerson.opinionTypeId ===
        OpinionType.PAAMIES_EI_VOI_ANTAA_MIELIPIDETTAAN && (
        <PersonFormRadioButtonGroup
          data-test-id={createDataTestId('opinionAttachmentType')}
          value={opinionAttachmentTypeForNo}
          labelText={t('lupaPersonOpinionType-NO-selectAttachmentTypeHeader')}
          name={createDataTestId(
            'opinionAttachmentType',
            OpinionType.PAAMIES_EI_VOI_ANTAA_MIELIPIDETTAAN
          )}
          onChange={(value) => {
            if (isLupaAsiointiAsiakirjaTypeId(value)) {
              setOpinionAttachmentTypeForNo(value)
              void setPaamiesOpinion(
                asiointiPerson.personId,
                OpinionType.PAAMIES_EI_VOI_ANTAA_MIELIPIDETTAAN,
                value
              )
            }
          }}
        >
          <RadioButton
            data-test-id={createDataTestId(
              'opinionAttachmentType',
              LupaAsiointiAsiakirjaType.MUU_SELVITYS_PERUSTEESTA_OLLA_KUULEMATTA_PAAMIESTA
            )}
            value={
              LupaAsiointiAsiakirjaType.MUU_SELVITYS_PERUSTEESTA_OLLA_KUULEMATTA_PAAMIESTA
            }
            variant="small"
          >
            {t(
              'asiointiAsiakirjaType-MUU_SELVITYS_PERUSTEESTA_OLLA_KUULEMATTA_PAAMIESTA'
            )}
          </RadioButton>
          <RadioButton
            data-test-id={createDataTestId(
              'opinionAttachmentType',
              LupaAsiointiAsiakirjaType.LAAKARINLAUSUNTO
            )}
            value={LupaAsiointiAsiakirjaType.LAAKARINLAUSUNTO}
            variant="small"
          >
            {t('asiointiAsiakirjaType-LAAKARINLAUSUNTO')}
          </RadioButton>
        </PersonFormRadioButtonGroup>
      )}
      {asiointiPerson.opinionTypeId !==
        OpinionType.PAAMIES_ALLE_15V_MIELIPIDETTA_EI_SELVITETTY && (
        <FocusableDiv ref={opinionAttachmentsRef} tabIndex={-1}>
          <Paragraph
            mb="xs"
            data-test-id={createDataTestId('opinionAttachmentAdviceHeader')}
            htmlFor={fileBoxId}
          >
            <Text smallScreen variant="bold">
              {asiointiPerson.opinionAttachmentAsiakirjaTypeId ===
                LupaAsiointiAsiakirjaType.PAAMIEHEN_LAUSUNTO &&
                t('asiointiAsiakirjaType-PAAMIEHEN_LAUSUNTO')}
              {asiointiPerson.opinionAttachmentAsiakirjaTypeId ===
                LupaAsiointiAsiakirjaType.MUU_SELVITYS_PAAMIEHEN_MIELIPITEEN_SELVITTAMISESTA &&
                t(
                  'lupaPersonOpinionType-YES-adviceFor-MUU_SELVITYS_PAAMIEHEN_MIELIPITEEN_SELVITTAMISESTA'
                )}
              {asiointiPerson.opinionAttachmentAsiakirjaTypeId ===
                LupaAsiointiAsiakirjaType.MUU_SELVITYS_PERUSTEESTA_OLLA_KUULEMATTA_PAAMIESTA &&
                t(
                  'asiointiAsiakirjaType-MUU_SELVITYS_PERUSTEESTA_OLLA_KUULEMATTA_PAAMIESTA'
                )}
              {asiointiPerson.opinionAttachmentAsiakirjaTypeId ===
                LupaAsiointiAsiakirjaType.LAAKARINLAUSUNTO &&
                t('asiointiAsiakirjaType-LAAKARINLAUSUNTO')}
            </Text>
          </Paragraph>
          {asiointiPerson.opinionAttachmentAsiakirjaTypeId ===
            LupaAsiointiAsiakirjaType.PAAMIEHEN_LAUSUNTO && (
            <>
              <Paragraph
                data-test-id={createDataTestId('opinionAttachmentAdviceText')}
              >
                <Text smallScreen>
                  {t('lupaPersonOpinionType-YES-adviceFor-PAAMIEHEN_LAUSUNTO')}
                </Text>
              </Paragraph>
              <Paragraph
                data-test-id={createDataTestId('opinionAttachmentAdviceLink')}
              >
                <ExternalLink
                  href={lomakkeetUrl(lang)}
                  labelNewWindow={t('avautuuUuteenValilehteen')}
                  smallScreen
                >
                  {t('avaaDvvLomakkeetSivu')}
                </ExternalLink>
              </Paragraph>
            </>
          )}
          {asiointiPerson.opinionAttachmentAsiakirjaTypeId && (
            <PersonAttachmentFileBox
              asiakirjaTypeId={asiointiPerson.opinionAttachmentAsiakirjaTypeId}
              asiointiPerson={asiointiPerson}
              data-test-id={createDataTestId('attachments')}
              htmlId={fileBoxId}
            />
          )}
        </FocusableDiv>
      )}
    </>
  )
})

const AsiointiPersonForm: React.FC<{
  asiointiPerson: AsiointiPerson
  index: number
  countries: Country[]
}> = observer(({ asiointiPerson, index, countries }) => {
  const createFieldTestId = (...id: string[]) =>
    mkLupaAsiointiPersonFieldTestId(
      asiointiPerson.applicationRoleId,
      index,
      ...id
    )
  const [t] = useTranslation()
  const { lang } = useAsiointiUserStore()
  const {
    personId,
    firstnames,
    lastname,
    hetu,
    phone,
    streetAddress,
    streetAddressExtra,
    postalCode,
    postOffice,
    applicationRoleId,
    countryId,
    email,
    emailRepeated,
  } = asiointiPerson

  const [countryShortNamesInDisplayOrder, setCountryShortNamesInDisplayOrder] =
    useState(resolveCountryShortNamesInDisplayOrder(countries, lang))
  useEffect(() => {
    setCountryShortNamesInDisplayOrder(
      resolveCountryShortNamesInDisplayOrder(countries, lang)
    )
  }, [countries, lang])

  const [currentCountryShortName, setCurrentCountryShortName] = useState(
    resolveCountryShortName(countries, countryId, lang)
  )
  useEffect(() => {
    setCurrentCountryShortName(
      resolveCountryShortName(countries, countryId, lang)
    )
  }, [lang, countries, countryId])

  const HetuComponent = asiointiPerson.isAuthenticatedUser
    ? PersonFormReadOnlyText
    : PersonFormTextInput
  const FirstnamesComponent = asiointiPerson.isAuthenticatedUser
    ? PersonFormReadOnlyText
    : PersonFormTextInput
  const LastnameComponent = asiointiPerson.isAuthenticatedUser
    ? PersonFormReadOnlyText
    : PersonFormTextInput

  const handleRemovePersonClick = async (
    personId: TemporaryId,
    applicationRoleId: AsiointiPerson['applicationRoleId']
  ) => {
    await removePerson(personId)
    setTimeout(() => {
      const addButton = document.getElementById(
        mkLupaAsiointiPersonActionTestId(applicationRoleId, 'addPerson')
      )
      if (addButton) {
        addButton.focus()
      }
    })
  }

  return (
    <Block
      data-test-id={mkLupaAsiointiPersonTestId(
        asiointiPerson.applicationRoleId,
        index
      )}
    >
      <PersonHeading
        variant="h4"
        tabIndex={-1}
        id={mkLupaAsiointiPersonHeadingTestId(applicationRoleId, index)}
        data-test-id={mkLupaAsiointiPersonHeadingTestId(
          applicationRoleId,
          index
        )}
      >{`${index + 1}. ${
        applicationRoleId === LupaApplicationRole.HAKIJA
          ? t('hakijanTiedot')
          : t('paamiehenTiedot')
      }`}</PersonHeading>
      <Block mt="xs" />
      <FirstnamesComponent
        ref={useValidationErrorFocus<HTMLInputElement>(
          createFieldTestId('firstnames')
        )}
        data-test-id={createFieldTestId('firstnames')}
        labelText={t('lupaPersonEtunimi')}
        defaultValue={firstnames || ''}
        onBlur={(event) =>
          void setPersonValues(personId, {
            firstnames: trimEventTargetValue(event),
          })
        }
        {...resolveFieldStatusProps(asiointiPerson, 'firstnames', t)}
      />
      <LastnameComponent
        ref={useValidationErrorFocus<HTMLInputElement>(
          createFieldTestId('lastname')
        )}
        data-test-id={createFieldTestId('lastname')}
        labelText={t('lupaPersonSukunimi')}
        defaultValue={lastname || ''}
        onBlur={(event) =>
          void setPersonValues(personId, {
            lastname: trimEventTargetValue(event),
          })
        }
        {...resolveFieldStatusProps(asiointiPerson, 'lastname', t)}
      />
      <HetuComponent
        ref={useValidationErrorFocus<HTMLInputElement>(
          createFieldTestId('hetu')
        )}
        data-test-id={createFieldTestId('hetu')}
        labelText={t('lupaPersonHetu')}
        defaultValue={hetu || ''}
        onBlur={(event) =>
          void setPersonValues(personId, { hetu: trimEventTargetValue(event) })
        }
        {...resolveFieldStatusProps(asiointiPerson, 'hetu', t)}
      />
      <PersonFormTextInput
        ref={useValidationErrorFocus(createFieldTestId('phone'))}
        data-test-id={createFieldTestId('phone')}
        labelText={t('lupaPersonPuhelinnumero')}
        optionalText={t('valinnainen')}
        hintText={t('phoneNumberFormatHintText')}
        type="tel"
        defaultValue={phone || ''}
        onBlur={(event) =>
          setPersonValues(personId, { phone: trimEventTargetValue(event) })
        }
        {...resolveFieldStatusProps(asiointiPerson, 'phone', t)}
      />
      <PersonFormTextInput
        ref={useValidationErrorFocus(createFieldTestId('email'))}
        data-test-id={createFieldTestId('email')}
        labelText={t('lupaPersonSahkoposti')}
        optionalText={t('valinnainen')}
        type="email"
        defaultValue={email || ''}
        onBlur={(event) =>
          void setPersonValues(personId, { email: trimEventTargetValue(event) })
        }
        {...resolveFieldStatusProps(asiointiPerson, 'email', t)}
      />
      <PersonFormTextInput
        ref={useValidationErrorFocus(createFieldTestId('emailRepeated'))}
        data-test-id={createFieldTestId('emailRepeated')}
        labelText={t('sahkopostiUudelleen')}
        optionalText={t('valinnainen')}
        type="email"
        defaultValue={emailRepeated || ''}
        onBlur={(event) =>
          void setPersonValues(personId, {
            emailRepeated: trimEventTargetValue(event),
          })
        }
        {...resolveFieldStatusProps(asiointiPerson, 'emailRepeated', t)}
      />
      <PersonFormTextInput
        ref={useValidationErrorFocus(createFieldTestId('streetAddress'))}
        data-test-id={createFieldTestId('streetAddress')}
        labelText={t('lupaPersonKatuosoite')}
        defaultValue={streetAddress || ''}
        onBlur={(event) =>
          void setPersonValues(personId, {
            streetAddress: trimEventTargetValue(event),
          })
        }
        {...resolveFieldStatusProps(asiointiPerson, 'streetAddress', t)}
      />
      <PersonFormTextInput
        ref={useValidationErrorFocus(createFieldTestId('streetAddressExtra'))}
        data-test-id={createFieldTestId('streetAddressExtra')}
        labelText={t('lupaPersonOsoitteenLisatieto')}
        optionalText={t('valinnainen')}
        defaultValue={streetAddressExtra || ''}
        onBlur={(event) =>
          void setPersonValues(personId, {
            streetAddressExtra: trimEventTargetValue(event),
          })
        }
        {...resolveFieldStatusProps(asiointiPerson, 'streetAddressExtra', t)}
      />
      <PersonFormTextInput
        ref={useValidationErrorFocus(createFieldTestId('postalCode'))}
        data-test-id={createFieldTestId('postalCode')}
        labelText={t('lupaPersonPostinumero')}
        defaultValue={postalCode || ''}
        onBlur={(event) =>
          void setPersonValues(personId, {
            postalCode: trimEventTargetValue(event),
          })
        }
        {...resolveFieldStatusProps(asiointiPerson, 'postalCode', t)}
      />
      <PersonFormTextInput
        ref={useValidationErrorFocus(createFieldTestId('postOffice'))}
        data-test-id={createFieldTestId('postOffice')}
        labelText={t('lupaPersonPostitoimipaikka')}
        defaultValue={postOffice || ''}
        onBlur={(event) =>
          void setPersonValues(personId, {
            postOffice: trimEventTargetValue(event),
          })
        }
        {...resolveFieldStatusProps(asiointiPerson, 'postOffice', t)}
      />
      <PersonFormDropdown
        data-test-id={createFieldTestId('countryName')}
        labelText={t('lupaPersonMaa')}
        value={currentCountryShortName} // Using shortname for value to force very deep re-rendering when lang changes
        onChange={(value: string) => {
          const country = countries.find((c) => c.shortName[lang] === value)
          if (country) {
            void setPersonValues(personId, { countryId: country.countryId })
            setCurrentCountryShortName(value)
          }
        }}
        {...resolveFieldStatusProps(asiointiPerson, 'countryId', t)}
        fieldTestId={createFieldTestId('countryId')}
      >
        {countryShortNamesInDisplayOrder.map((name) => (
          <DropdownItem
            key={name}
            value={name}
            data-test-id={createFieldTestId('setCountry', name)}
          >
            {name}
          </DropdownItem>
        ))}
      </PersonFormDropdown>
      {applicationRoleId === LupaApplicationRole.HAKIJA && index > 0 && (
        <HakijaValtakirjaAttachment
          asiointiPerson={asiointiPerson}
          data-test-id={createFieldTestId('attachments')}
        />
      )}
      {applicationRoleId === LupaApplicationRole.PAAMIES && (
        <PaamiesOpinionAttachment
          asiointiPerson={asiointiPerson}
          createDataTestId={createFieldTestId}
        />
      )}
      <Block mt="xs" />
      {index > 0 && (
        <Button
          data-test-id={createFieldTestId('removePerson')}
          variant="secondaryNoBorder"
          icon={<IconRemove />}
          onClick={() => handleRemovePersonClick(personId, applicationRoleId)}
        >
          {applicationRoleId === LupaApplicationRole.HAKIJA
            ? t('poistaHakijaHakemukselta')
            : t('poistaPaamiesHakemukselta')}
        </Button>
      )}
    </Block>
  )
})

const PersonHeading = styled(Heading)`
  ${focusOutline}
`

export default AsiointiPersonForm
