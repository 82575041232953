/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useMemo } from 'react'
import { observer } from 'mobx-react'
import { TFunction, useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkOmaisuusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block, Heading, Paragraph, Text } from 'suomifi-ui-components'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import { getTilirivit } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { FrontTalletus } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { FormListInputExpander } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import FormDropdown from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDropdown'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonEuroWithDecimalsOrUndefinedValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/react-form-validation.util'
import { observable } from 'mobx'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import {
  formatNumberWithComma,
  formatTilinumeroInput,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import { TiliAsiointiAsiakirjatyyppi } from 'tilintarkastus-common/src/vtj/types/asiointi-account-data.type'
import { AsiointiTalletustyyppi } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import { AsiointiLedgerAccountAssetsType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-assets-enum'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import { AsiointiLedgerAccountCategory } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'

const PankkiTalletuksetForm: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const omaisuus = getTilirivit()

  const mkNewEntry = (): FrontTalletus =>
    observable({
      id: crypto.randomUUID(),
      tiliointinumero: '',
      tyyppi: undefined,
      myontajanNimi: '',
      vakuusTaiTilinumero: '',
      arvoTilikaudenAlussaEur: undefined,
      arvoTilikaudenLopussaEur: undefined,
      asiakirjat: [],
    })

  return (
    <section data-test-id={mkOmaisuusTestId('talletukset-form')}>
      <Heading variant="h3">{t('talletuksetHeading')}</Heading>
      <Block mt={verticalMarginToken} />
      <Paragraph>{t('ohjeIlmoitaTalletukset')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={TalletusListItem}
        state={omaisuus.varat.talletukset}
        asiointiLedgerAccountCategory={AsiointiLedgerAccountCategory.VARAT}
        dataTestIdPrefix={mkOmaisuusTestId('talletukset-form')}
        ModalContentComponent={TalletusModal}
        newEntry={mkNewEntry}
        translationPrefix="talletukset"
      />
    </section>
  )
}

const TalletusModal: FC<FormModalProps<FrontTalletus>> = observer(
  ({ createDataTestId, entry, setEntry }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'
    const tilityypit = useMemo(
      () =>
        Object.values(AsiointiTalletustyyppi).map((value) => ({
          name: t(`tilityyppi_${value}`),
          value,
        })),
      [t]
    )
    const { myontajanNimi, vakuusTaiTilinumero, asiakirjat, asiakirjaInfo } =
      useMemo(
        () => getTalletusTranslations(entry.tyyppi!, t),
        [t, entry.tyyppi]
      )

    const formattedVakuusTaiTilinumero = (
      tyyppi: string | undefined,
      value: string
    ) => {
      return tyyppi === AsiointiLedgerAccountAssetsType.VAKUUTUSSAASTOTILI
        ? value
        : formatTilinumeroInput(value)
    }

    return (
      <div>
        <FormDropdown
          labelText={t('valitseTilityyppi')}
          data-test-id={createDataTestId('modal-tyyppi')}
          value={entry.tyyppi}
          items={tilityypit}
          required
          updateValue={(value) => {
            setEntry({
              ...entry,
              tyyppi: value as AsiointiLedgerAccountAssetsType,
              vakuusTaiTilinumero: formattedVakuusTaiTilinumero(
                value,
                entry.vakuusTaiTilinumero
              ),
            })
          }}
        />
        <Block mt={verticalMarginToken} />

        <FormTextInput
          data-test-id={createDataTestId('modal-vakuusTaiTilinumero')}
          labelText={vakuusTaiTilinumero}
          value={formattedVakuusTaiTilinumero(
            entry.tyyppi,
            entry.vakuusTaiTilinumero
          )}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) =>
            setEntry({
              ...entry,
              vakuusTaiTilinumero: formattedVakuusTaiTilinumero(
                entry.tyyppi,
                value
              ),
            })
          }
        />
        <FormTextInput
          data-test-id={createDataTestId('modal-myontajanNimi')}
          labelText={myontajanNimi}
          value={entry.myontajanNimi}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) => setEntry({ ...entry, myontajanNimi: value })}
        />

        <FormNumberInput
          data-test-id={createDataTestId('modal-arvoTilikaudenAlussa')}
          labelText={t('arvoTilikaudenAlussaEuroina')}
          required
          value={entry.arvoTilikaudenAlussaEur}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) =>
            setEntry({
              ...entry,
              arvoTilikaudenAlussaEur: value,
            })
          }
        />

        <FormNumberInput
          data-test-id={createDataTestId('modal-arvoTilikaudenLopussa')}
          labelText={t('arvoTilikaudenLopussaEuroina')}
          required
          value={entry.arvoTilikaudenLopussaEur}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) => {
            setEntry({
              ...entry,
              arvoTilikaudenLopussaEur: value,
            })
          }}
        />

        <FormAttachmentFileBox
          attachmentsGroupId={entry.id}
          observableAttachments={entry.asiakirjat}
          required
          asiakirjaTypeId={TiliAsiointiAsiakirjatyyppi.MUU_LIITE}
          data-test-id={createDataTestId('modal-asiakirja')}
          text={asiakirjaInfo}
          title={asiakirjat}
        />
      </div>
    )
  }
)

interface TalletusFieldTranslations {
  myontajanNimi: string
  vakuusTaiTilinumero: string
  asiakirjat: string
  asiakirjaInfo: string
  tilityyppi: string
}

const getTalletusTranslations = (
  tilityyppi: AsiointiLedgerAccountAssetsType,
  t: TFunction
): TalletusFieldTranslations => {
  const translated = t(`tilityyppi_${tilityyppi}`)
  if (tilityyppi === AsiointiLedgerAccountAssetsType.VAKUUTUSSAASTOTILI) {
    return {
      myontajanNimi: t('vakuutusYhtionNimi'),
      vakuusTaiTilinumero: t('vakuutusNumero'),
      asiakirjat: t('tiliotteetJaSopimukset'),
      asiakirjaInfo: t('tiliotteetJaSopimuksetInfo'),
      tilityyppi: translated,
    }
  }

  return {
    myontajanNimi: t('pankinNimi'),
    vakuusTaiTilinumero: t('tilinumero'),
    asiakirjat: t('tiliotteet'),
    asiakirjaInfo: t('tiliotteetInfo'),
    tilityyppi: translated,
  }
}

const TalletusListItem: FC<FormElementProps<FrontTalletus>> = observer(
  ({ createDataTestId, entry, onEdit, onRemove }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'

    const { myontajanNimi, asiakirjat, tilityyppi } = useMemo(
      () => getTalletusTranslations(entry.tyyppi!, t),
      [t, entry.tyyppi]
    )

    const arvoLopussaEur = `${formatNumberWithComma(
      entry.arvoTilikaudenLopussaEur!
    )} €`
    const arvoAlussaEur = `${formatNumberWithComma(
      entry.arvoTilikaudenAlussaEur!
    )} €`

    return (
      <FormListInputExpander
        heading={tilityyppi}
        headingRight={arvoLopussaEur}
        subHeading={entry.vakuusTaiTilinumero}
        createDataTestId={createDataTestId}
        onEdit={onEdit}
        onRemove={onRemove}
        showSubHeadingWhileOpen
      >
        <Heading variant="h5">{myontajanNimi}</Heading>
        <Text>{entry.myontajanNimi}</Text>
        <Block mt={verticalMarginToken} />

        <Heading variant="h5">{t('arvoTilikaudenAlussaEuroina')}</Heading>
        <Text>{arvoAlussaEur}</Text>
        <Block mt={verticalMarginToken} />

        {entry.asiakirjat.length > 0 && (
          <>
            <Block mt={verticalMarginToken} />
            <Heading variant="h5">{asiakirjat}</Heading>
            <AttachmentList attachments={entry.asiakirjat} />
            <Block mt={verticalMarginToken} />
          </>
        )}
        <Block mt={verticalMarginToken} />
      </FormListInputExpander>
    )
  }
)

export default observer(PankkiTalletuksetForm)
