import { TFunction } from 'react-i18next'
import { getCountryById } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/country.store'
import { formatDateAsLocal } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import {
  YhteenvetoSubBlock,
  YhteenvetoSubBlockContent,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/yhteenveto/yhteenveto.util'
import { Paatostili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'

const getPaivamaaraString = (
  beginDate: string | undefined,
  endDate: string | undefined
): string =>
  beginDate || endDate
    ? `${beginDate ? formatDateAsLocal(beginDate) : ''} - ${
        endDate ? formatDateAsLocal(endDate) : ''
      }`
    : ''

export const getAsuinpaikatContent = (
  account: Paatostili,
  t: TFunction,
  lang: HolhousAsiointiLanguageCode
): YhteenvetoSubBlock => {
  const { asuinpaikat } = account

  const content: YhteenvetoSubBlockContent[] = asuinpaikat.map(
    ({
      streetAddress,
      streetAddressExtra,
      postalCode,
      postOffice,
      countryCode,
      habitationType,
      habitationPeriodType,
      beginDate,
      endDate,
    }) => {
      const paivamaarat =
        beginDate || endDate
          ? `${beginDate ? formatDateAsLocal(beginDate) : ''} - ${
              endDate ? formatDateAsLocal(endDate) : ''
            }`
          : ''
      return {
        heading: `${streetAddress}${
          streetAddressExtra ? ` ${streetAddressExtra}` : ''
        }, ${postalCode} ${postOffice}, ${
          getCountryById(countryCode).shortName[lang]
        }`,
        contentRows: `${t(`habitationType_${habitationType}`)}, ${t(
          `habitationPeriodType_${habitationPeriodType}`
        )} ${paivamaarat}`,
      }
    }
  )

  return {
    heading: t('asuminenHeading'),
    content,
  }
}

export const getKayttovaratContent = (
  account: Paatostili,
  t: TFunction
): YhteenvetoSubBlock => {
  const { isAnnettuKayttovaroja, kayttovaraKaytanto } = account

  const content: YhteenvetoSubBlockContent[] = [
    {
      heading: t('onkoHenkilolleAnnettuKayttovarojaTallaTilikaudella'),
      contentRows: isAnnettuKayttovaroja ? t('kylla') : t('ei'),
    },
  ]

  if (isAnnettuKayttovaroja) {
    content.push({
      heading: t('mitenKayttovarojaOnHenkilolleAnnettu'),
      contentRows: kayttovaraKaytanto,
    })
  }

  return {
    heading: t('kayttovaratHeading'),
    content,
  }
}

export const getSaannollisetTulot = (
  account: Paatostili,
  t: TFunction
): YhteenvetoSubBlock => {
  const { saannollisetTulot } = account

  const content: YhteenvetoSubBlockContent[] = saannollisetTulot.map((st) => ({
    heading: `${t('incomeType_' + (st.tyyppi || ''))}: ${st.nettosumma} €/kk`,
    contentRows: `${st.maksaja} ${getPaivamaaraString(
      st.beginDate,
      st.endDate
    )}`,
    attachments: st.asiakirjat,
  }))

  return {
    heading: t('saannollisetTulotHeading'),
    content,
  }
}

export const getSopimukset = (
  account: Paatostili,
  t: TFunction
): YhteenvetoSubBlock => {
  const { sopimukset } = account

  const content: YhteenvetoSubBlockContent[] = sopimukset.map((s) => ({
    heading: `${s.sopimustyyppi}`,
    contentRows: `${s.toimittaja} ${getPaivamaaraString(
      s.beginDate,
      s.endDate
    )}`,
    attachments: s.asiakirjat,
  }))

  return {
    heading: t('sopimuksetHeading'),
    content,
  }
}

export const getVakuutukset = (
  account: Paatostili,
  t: TFunction
): YhteenvetoSubBlock => {
  const { vakuutukset } = account

  const content: YhteenvetoSubBlockContent[] = vakuutukset.map((v) => ({
    heading: `${v.vakuutustyyppi}`,
    contentRows: `${v.toimittaja} ${getPaivamaaraString(
      v.beginDate,
      v.endDate
    )}`,
    attachments: v.asiakirjat,
  }))

  return {
    heading: t('vakuutuksetHeading'),
    content,
  }
}

export const getVerotiedot = (
  account: Paatostili,
  t: TFunction
): YhteenvetoSubBlock => {
  const { verotustiedot } = account

  const content: YhteenvetoSubBlock = {
    heading: t('verotiedot'),
    content: verotustiedot.length
      ? [
          {
            attachments: verotustiedot,
          },
        ]
      : [],
  }
  return content
}

export const getHallintaoikeudetContent = (
  account: Paatostili,
  t: TFunction
): YhteenvetoSubBlock => {
  const {
    isHallintaoikeuksia,
    mitenHallintaoikeuksistaOnSovittu,
    hallintaoikeusSopimukset,
  } = account

  const content: YhteenvetoSubBlockContent[] = [
    {
      heading: t('onkoOllutSovittujaHallintaoikeuksia'),
      contentRows: isHallintaoikeuksia ? t('kylla') : t('ei'),
    },
  ]

  if (isHallintaoikeuksia || hallintaoikeusSopimukset) {
    content.push({
      heading: t('mitenHallintaoikeuksistaOnSovittu'),
      contentRows: mitenHallintaoikeuksistaOnSovittu,
      attachments: hallintaoikeusSopimukset,
    })
  }

  return {
    heading: t('hallintaoikeudet'),
    content,
  }
}

export const getMuutMerkittavatToimetContent = (
  account: Paatostili,
  t: TFunction
): YhteenvetoSubBlock => {
  const { isMuutMerkittavatToimet, muutMerkittavatToimet } = account

  const content: YhteenvetoSubBlockContent[] = [
    {
      heading: t('onkoMuitaMerkittaviaToimia'),
      contentRows: isMuutMerkittavatToimet ? t('kylla') : t('ei'),
    },
  ]

  if (isMuutMerkittavatToimet) {
    content.push({
      heading: t('mitaMuitaMerkittaviaToimia'),
      contentRows: muutMerkittavatToimet,
    })
  }

  return {
    heading: t('muutMerkittavatToimet'),
    content,
  }
}
