import { Block, Button, Heading, Text } from 'suomifi-ui-components'
import React from 'react'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { useAutoFocus } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/auto-focus'
import AsuminenForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/AsuminenForm'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import FormTextArea from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextArea'
import { commonXssValidator } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/react-form-validation.util'
import { FormProvider } from 'react-hook-form'
import { mkToimintakykyAsuminenTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { action } from 'mobx'
import { submitOmaisuusluettelo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/actions/submit-tilintarkastus-actions'
import {
  OMAISUUSLUETTELO_ROUTES,
  TilintarkastusForm,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { useNavigate } from 'react-router-dom'
import { getOmaisuusluettelo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/omaisuusluettelo.store'

const ToimintakykyAsuminenPage: React.FC<{ form: TilintarkastusForm }> =
  observer(({ form }) => {
    const [t] = useTranslation()
    const account = getOmaisuusluettelo()
    const navigate = useNavigate()

    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'm' : 's'

    const asuminenHeadingRef = useAutoFocus<HTMLHeadingElement>()

    return (
      <FormProvider {...form}>
        <ResponsiveBorderedContentBlock>
          <Block mt="s" />
          <Heading variant="h2" ref={asuminenHeadingRef}>
            {t('toimintakykyJaAsuminen')}
          </Heading>
          <Block mt="xs" />
          <DividerLine mt={verticalMarginToken} />
          <Block mt={verticalMarginToken} />
          <section>
            <Heading variant="h3">{t('toimintakyky')}</Heading>
            <Text> {t('toimintakykyInfoText')} </Text>
            <FormTextArea
              labelText={t('toimintakykyTextAreaLabel')}
              data-test-id={mkToimintakykyAsuminenTestId('toimintakyky')}
              maxLength={1000}
              validate={commonXssValidator(t)}
              value={account.toimintakyky}
              updateValue={action((value: string) => {
                account.toimintakyky = value
              })}
            />
          </section>
          <AsuminenForm />

          <Button
            onClick={async () => {
              const response = await submitOmaisuusluettelo()
              if (response.isOk) {
                navigate(OMAISUUSLUETTELO_ROUTES.VALMIS)
              }
            }}
          >
            {t('send')}
          </Button>
        </ResponsiveBorderedContentBlock>
      </FormProvider>
    )
  })

export default ToimintakykyAsuminenPage
