import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import {
  AsiaPersonRole,
  AsiaPersonType,
  RatkaisuType,
  AsiaStatus,
  AsiaHandlerRole,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { OrganizationType } from 'asia-common/src/vtj/koodisto/organization-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import {
  InternalToimenpideType,
  ToimenpideHuomioPhase,
  ToimenpideType,
} from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import { ViestintaType } from 'asia-common/src/vtj/koodisto/viestinta-enums'
import { Retention, RetentionReason } from 'asia-common/src/vtj/koodisto/retention-enums'
import {
  PersonalDataClass,
  PublicityClass,
  SecurityClass,
  SecurityPeriod,
  SecurityReason,
} from 'asia-common/src/vtj/koodisto/restriction-enums'
import { Viranomainen } from 'asia-common/src/vtj/koodisto/viranomainen-enums'

export const paatostiliMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    {
      asiakirjaTypeId: AsiakirjaType.ARVIO_OMAISUUDESTA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '78d133c9-7e28-454e-9346-385424eb6898',
      toivoAsiakirjaTypeIds: ['78d133c9-7e28-454e-9346-385424eb6898'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.LAAKARINLAUSUNTO,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '7211d6c9-a144-4640-930d-d96bd44619a0',
      toivoAsiakirjaTypeIds: ['7211d6c9-a144-4640-930d-d96bd44619a0'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_LIITE,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        ],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23, SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: 'e7fd43f7-a485-4f8c-a3e3-b7f9fd1724bc',
      toivoAsiakirjaTypeIds: ['e7fd43f7-a485-4f8c-a3e3-b7f9fd1724bc'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_VIRASTON_LAATIMA_ASIAKIRJA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        ],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23, SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      defaultToivoAsiakirjaTypeId: '2e06ba8d-cb21-45fa-928d-1503e7706992',
      toivoAsiakirjaTypeIds: ['2e06ba8d-cb21-45fa-928d-1503e7706992'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.OIKEUSOHJEET,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.JULKINEN,
        publicityClassIds: [PublicityClass.JULKINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.EI_SISALLA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.EI_SISALLA_HENKILOTIETOJA],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_10_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      defaultToivoAsiakirjaTypeId: '0c1044fe-83a6-4fc9-8d11-8d5a7bd089b2',
      toivoAsiakirjaTypeIds: ['0c1044fe-83a6-4fc9-8d11-8d5a7bd089b2'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.PERUKIRJA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: 'aebece47-107d-4948-b7ab-427d70e1c58d',
      toivoAsiakirjaTypeIds: ['aebece47-107d-4948-b7ab-427d70e1c58d'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.PAATOSTILIN_TIEDOKSIANTO_LIITTEINEEN,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        ],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23, SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.KANSALLISARKISTON_PAATOS,
        retentionReasonIds: [RetentionReason.KANSALLISARKISTON_PAATOS],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      defaultToivoAsiakirjaTypeId: '609fb1f2-9ca7-447c-bbc8-7eb745314762',
      toivoAsiakirjaTypeIds: ['609fb1f2-9ca7-447c-bbc8-7eb745314762', '10fcdc5a-2d74-4bdd-82f7-b8dc776aca3b'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.PAATOSTILITULOSTE,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        ],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23, SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_4_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: 'cb5d6e77-bd6c-4ce5-8189-d1e0c341475e',
      toivoAsiakirjaTypeIds: ['cb5d6e77-bd6c-4ce5-8189-d1e0c341475e'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.SALDOTODISTUS_TAI_OMAISUUSOTE,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '0a4009bb-5dae-4523-9da1-efca516eb7ae',
      toivoAsiakirjaTypeIds: ['0a4009bb-5dae-4523-9da1-efca516eb7ae'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYS_OMAISUUDEN_MYYNNISTA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '6012157d-1a50-4911-b5f2-ef5aa3c57fc2',
      toivoAsiakirjaTypeIds: ['6012157d-1a50-4911-b5f2-ef5aa3c57fc2'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.SOPIMUS,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: 'fa9d180a-8a83-4234-82e8-cdfb64823e56',
      toivoAsiakirjaTypeIds: ['fa9d180a-8a83-4234-82e8-cdfb64823e56'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.TESTAMENTTI_JA_TIEDOKSIANTOTODISTUS,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: 'f6ca525e-ce25-46d8-8943-6ec0bded1f5e',
      toivoAsiakirjaTypeIds: ['f6ca525e-ce25-46d8-8943-6ec0bded1f5e'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.TILIOTE,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: 'c29ec3cf-42de-4b91-baed-60e01ad34e42',
      toivoAsiakirjaTypeIds: ['c29ec3cf-42de-4b91-baed-60e01ad34e42'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.PAATOSTILI_PAAKIRJA_PALKKIOLASKELMA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        ],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23, SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_0_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '430a68c9-079d-404c-87d7-f2f41560c701',
      toivoAsiakirjaTypeIds: ['430a68c9-079d-404c-87d7-f2f41560c701'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.VALTAKIRJA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.JULKINEN,
        publicityClassIds: [PublicityClass.JULKINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: 'df97fbed-aa70-4023-88ee-5f29f755bc34',
      toivoAsiakirjaTypeIds: ['df97fbed-aa70-4023-88ee-5f29f755bc34'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.EDUNVALVONTASUUNNITELMA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        ],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23, SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '236fc504-4be1-41b4-a49e-5a9b941f7700',
      toivoAsiakirjaTypeIds: ['236fc504-4be1-41b4-a49e-5a9b941f7700'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.TOSITE,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '97083459-7a04-4919-b1f0-ecb75360c086',
      toivoAsiakirjaTypeIds: ['97083459-7a04-4919-b1f0-ecb75360c086'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.PALKKIO_JA_KULULASKELMA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: 'd319f21a-134e-4540-9958-90cd48b83a40',
      toivoAsiakirjaTypeIds: ['d319f21a-134e-4540-9958-90cd48b83a40'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.TAYDENNYSPYYNTO,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        ],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23, SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      defaultToivoAsiakirjaTypeId: '4a80a766-80d3-4016-a912-60072bd07337',
      toivoAsiakirjaTypeIds: ['4a80a766-80d3-4016-a912-60072bd07337'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.VASTAUS_TAYDENNYS_TAI_SELVITYSPYYNTOON,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        ],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23, SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '0e65e577-aed6-47b4-b22e-8bfd483527f9',
      toivoAsiakirjaTypeIds: ['0e65e577-aed6-47b4-b22e-8bfd483527f9'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.TARKASTETTU_PAATOSTILI_PAAKIRJA_JA_PALKKIOLASKELMA_TILIN_TARKASTUSMERKINNAT,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        ],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23, SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
        defaultRetentionReasonId: RetentionReason.KANSALLISARKISTON_PAATOS,
        retentionReasonIds: [RetentionReason.KANSALLISARKISTON_PAATOS],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      defaultToivoAsiakirjaTypeId: '59440a4d-0ab1-4c02-b2b0-54bab3373742',
      toivoAsiakirjaTypeIds: ['59440a4d-0ab1-4c02-b2b0-54bab3373742'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.VAATIMUS_VIRHEEN_KORJAAMISESTA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        ],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23, SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
        defaultRetentionReasonId: RetentionReason.KANSALLISARKISTON_PAATOS,
        retentionReasonIds: [RetentionReason.KANSALLISARKISTON_PAATOS],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '4517d3e0-6dfd-41fd-827c-2ea2d5835b74',
      toivoAsiakirjaTypeIds: ['4517d3e0-6dfd-41fd-827c-2ea2d5835b74'],
    },
  ],
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KIIREELLINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KIIREHDITTY,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.MAARAAIKA,
      postDueDateHuomioTypeId: HuomioType.MAARAAIKA_YLITTYNYT,
    },
    {
      huomioTypeId: HuomioType.MAARAAIKA_YLITTYNYT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_KUULEMISTA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.KUULEMISEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.KUULEMISEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_TAYDENNYSTA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [AsiaPersonRole.HAKIJA],
    organizationTypeIds: [
      OrganizationType.OSTOPALVELU_EDUNVALVONTATOIMISTO,
      OrganizationType.VALTION_EDUNVALVONTATOIMISTO,
    ],
  },
  personRoleMetadata: [AsiaPersonRole.ASIAKAS, AsiaPersonRole.HAKIJA, AsiaPersonRole.HUOLLETTAVA],
  personTypeMetadata: [AsiaPersonType.IHMINEN, AsiaPersonType.ORGANISAATIO],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.TARKASTETTU,
    manualRatkaisuTypeIds: [RatkaisuType.TARKASTETTU, RatkaisuType.PALAUTTETTU_TARKASTAMATTOMANA],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: true,
    mandatorySpecifier: false,
    specifierTypeIds: [],
  },
  toimenpideMetadata: [
    {
      toimenpideTypeId: ToimenpideType.TAYDENTAMINEN,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [ViestintaType.EDVARD, ViestintaType.MANUAALINEN],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.TAYDENNYSPYYNTO],
      prioritizedSaapunutAsiakirjaTypeIds: [AsiakirjaType.VASTAUS_TAYDENNYS_TAI_SELVITYSPYYNTOON],
      huomioMetadata: {
        [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_TAYDENNYSTA,
        [ToimenpideHuomioPhase.SAAPUNUT]: HuomioType.TAYDENNYS_SAAPUNUT,
        [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT,
        showInHuomioFilter: true,
      },
    },
    {
      toimenpideTypeId: ToimenpideType.TIEDOKSIANTO,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [ViestintaType.TOIVO_POSTITUS],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.PAATOSTILIN_TIEDOKSIANTO_LIITTEINEEN],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      huomioMetadata: null,
    },
  ],
  vireilletulokanavaMetadata: [Vireilletulokanava.EDVARD, Vireilletulokanava.ITSEPALVELU, Vireilletulokanava.TOIVO],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.KASITTELYSSA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.KASITTELYSSA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
  restrictionMetadata: {
    defaultPublicityClassId: PublicityClass.SALAINEN,
    publicityClassIds: [PublicityClass.SALAINEN],
    defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
    securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
    defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA,
    personalDataClassIds: [PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA],
    defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
    securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
    defaultSecurityReasonIds: [
      SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
      SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
    ],
    securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23, SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
    defaultViranomainenId: Viranomainen.DVV,
    viranomainenIds: [Viranomainen.DVV],
  },
  retentionMetadata: {
    defaultRetentionId: Retention.PYSYVA,
    retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
    defaultRetentionReasonId: RetentionReason.KANSALLISARKISTON_PAATOS,
    retentionReasonIds: [RetentionReason.KANSALLISARKISTON_PAATOS, RetentionReason.TOIMINTATAPA],
  },
}
