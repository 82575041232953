import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import fp from 'lodash/fp'
import styled from 'styled-components'
import {
  Block,
  Heading,
  suomifiDesignTokens,
  Text,
} from 'suomifi-ui-components'
import { getPaatostili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import {
  Contents,
  YhteenvetoSubBlock,
  YhteenvetoSubBlockContents,
  YhteenvetoSubBlocks,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/yhteenveto/yhteenveto.util'
import {
  getHuoneistotContent,
  getIrtaimistoContent,
  getKiinteistotContent,
  getKuolinpesatContent,
  getMuutVaratContent,
  getMuutVelatContent,
  getOsuudetHenkiloyhtioissaContent,
  getPankkilainatContent,
  getPankkitalletuksetContent,
  getSaatavatVaratContent,
  getTakauksetContent,
  getVaratContent,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/yhteenveto/omaisuus.util'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'

const OmaisuusBlocks = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${suomifiDesignTokens.spacing.l};
`

const OmaisuusBlock: React.FC<{
  heading: string
  subBlocks: YhteenvetoSubBlock[]
}> = ({ heading, subBlocks }) => {
  const [t] = useTranslation()

  return (
    <YhteenvetoSubBlocks>
      <Heading variant="h4">{t(heading)}</Heading>
      {subBlocks.map((subBlock) => (
        <YhteenvetoSubBlockContents key={subBlock.heading}>
          <Heading
            variant="h5"
            data-test-id={mkYhteenvetoTestId(
              `omaisuus-block-${subBlock.heading}`
            )}
          >
            {subBlock.heading}
          </Heading>
          {subBlock.content.map((c, idx) => (
            <Block
              data-test-id={mkYhteenvetoTestId(
                `omaisuus-content-${subBlock.heading}-${idx}`
              )}
              key={`${c.heading}-c-${idx}`}
            >
              <Contents>
                {c.heading && <Text variant="bold">{c.heading}</Text>}
                {typeof c.contentRows === 'string' && (
                  <Text>{c.contentRows}</Text>
                )}
                {Array.isArray(c.contentRows) &&
                  (fp.flow(fp.compact)(c.contentRows) as string[]).map(
                    (el: string, idx) => <Text key={`${el}-${idx}`}>{el}</Text>
                  )}
                {c.attachments && (
                  <AttachmentList attachments={c.attachments} />
                )}
              </Contents>
            </Block>
          ))}
        </YhteenvetoSubBlockContents>
      ))}
    </YhteenvetoSubBlocks>
  )
}

export const Omaisuus: React.FC = observer(() => {
  const [t] = useTranslation()
  const account = getPaatostili()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const varatContents: YhteenvetoSubBlock[] = [
    getPankkitalletuksetContent(account, t),
    getVaratContent(account, t),
    getOsuudetHenkiloyhtioissaContent(account, t),
    getHuoneistotContent(account, t),
    getKiinteistotContent(account, t),
    getIrtaimistoContent(account, t),
    getSaatavatVaratContent(account, t),
    getMuutVaratContent(account, t),
  ].filter((el) => el && el.content.length)

  const velatContents: YhteenvetoSubBlock[] = [
    getPankkilainatContent(account, t),
    getTakauksetContent(account, t),
    getMuutVelatContent(account, t),
  ].filter((el) => el && el.content.length)

  const osakkuudetKuolinpesissaContents: YhteenvetoSubBlock[] = [
    getKuolinpesatContent(account, t),
  ].filter((el) => el && el.content.length)

  if (
    !varatContents.length &&
    !velatContents.length &&
    !osakkuudetKuolinpesissaContents.length
  )
    return null

  return (
    <Block mt={verticalMarginToken}>
      <OmaisuusBlocks data-test-id={mkYhteenvetoTestId('omaisuus')}>
        <Heading variant="h3">{t('omaisuusJaVelat')}</Heading>
        {!!varatContents.length && (
          <OmaisuusBlock heading="varat" subBlocks={varatContents} />
        )}
        {!!velatContents.length && (
          <OmaisuusBlock heading="velat" subBlocks={velatContents} />
        )}
        {!!osakkuudetKuolinpesissaContents.length && (
          <OmaisuusBlock
            heading="kuolinpesaOsakkuudetHeading"
            subBlocks={osakkuudetKuolinpesissaContents}
          />
        )}
      </OmaisuusBlocks>
    </Block>
  )
})
