import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import {
  Block,
  Heading,
  Text,
  suomifiDesignTokens,
  InlineAlert,
  Paragraph,
} from 'suomifi-ui-components'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import {
  IsoDatetimeToUiDate,
  useValittuEvtvVaatimus,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/evtv-asiointi-store'
import { getPaatostili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'

const PerustiedotOsiot = styled.div<{ isTablet: boolean }>`
  display: grid;
  grid-template-columns: ${({ isTablet }) => (isTablet ? '1fr 1fr' : 'auto')};
  gap: ${suomifiDesignTokens.spacing.m};
`

export const Perustiedot: React.FC<{ showValtakirja?: boolean }> = observer(
  ({ showValtakirja }) => {
    const [t] = useTranslation()
    const account = getPaatostili()
    const vaatimus = useValittuEvtvVaatimus()
    const formattedAlkuPvm = IsoDatetimeToUiDate(
      vaatimus.accountingPeriodStartDate
    )
    const formattedLoppuPvm = IsoDatetimeToUiDate(
      vaatimus.accountingPeriodEndDate
    )
    const formattedMaaraPvm = IsoDatetimeToUiDate(vaatimus.dueDate)
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'm' : 's'

    return (
      <Block mt={verticalMarginToken}>
        <Heading variant="h3">
          {/* TODO: Translations */}
          {t('Perustiedot')}
        </Heading>
        <Block mt={verticalMarginToken} />
        <PerustiedotOsiot
          isTablet={isTablet}
          data-test-id={mkYhteenvetoTestId('perustiedot')}
        >
          <Block
            data-test-id={mkYhteenvetoTestId('perustiedot-block-paamies-name')}
          >
            <Text variant="bold">{t('Päämies')}</Text>
            <Block mt="xxs" />
            <Text>
              {vaatimus.lastname}, {vaatimus.firstnames}
            </Text>
          </Block>
          <Block
            data-test-id={mkYhteenvetoTestId('perustiedot-block-tilikausi')}
          >
            <Text variant="bold">{t('tilikausi')}</Text>
            <Block mt="xxs" />
            <Text>
              {formattedAlkuPvm} - {formattedLoppuPvm}
            </Text>
          </Block>
          <Block
            data-test-id={mkYhteenvetoTestId(
              'perustiedot-block-tilivelvollinen'
            )}
          >
            <Text variant="bold">{t('tilivelvollinen')}</Text>
            <Block mt="xxs" />
            <Text>
              {vaatimus.guardianLastname}, {vaatimus.guardianFirstnames}
            </Text>
          </Block>
          {!!account.edunvalvojanValtakirja.length && !!showValtakirja && (
            <Block
              data-test-id={mkYhteenvetoTestId('perustiedot-block-valtakirja')}
            >
              <Text variant="bold">{t('paatostiliValtakirjaHeading')}</Text>
              <Block mt="xxs" />
              <AttachmentList attachments={account.edunvalvojanValtakirja} />
            </Block>
          )}
        </PerustiedotOsiot>
        <Block mt={verticalMarginToken} />
        <InlineAlert
          data-test-id={mkYhteenvetoTestId('lahetys-maarapaiva-text')}
        >
          <Paragraph>
            {t('lahetaPaatostiliMennessa', { paivamaara: formattedMaaraPvm })}
          </Paragraph>

          <Paragraph>{t('tarkistaPaatostilinTiedotInfoText')}</Paragraph>
        </InlineAlert>
      </Block>
    )
  }
)
