import { holhousAsiointiAppPathPrefix } from 'edunvalvonta-asiointi/src/vtj/asiointi/holhous-asiointi-apps'
import { FieldValues, UseFormReturn } from 'react-hook-form'

export const TILINTARKASTUS_ASIOINTI_UI_ROUTE_PREFIX =
  holhousAsiointiAppPathPrefix.TILINTARKASTUS

export const TEE_PAATOSTILI_ROUTE_PREFIX = `${TILINTARKASTUS_ASIOINTI_UI_ROUTE_PREFIX}/tee-paatostili`

export const PAATOSTILI_ROUTES = {
  TEE_PAATOSTILI: `${TEE_PAATOSTILI_ROUTE_PREFIX}`,
  YLEISKATSAUS: `${TEE_PAATOSTILI_ROUTE_PREFIX}/yleiskatsaus`,
  OMAISUUS_JA_VELAT: `${TEE_PAATOSTILI_ROUTE_PREFIX}/omaisuus-ja-velat`,
  TULOT_JA_MENOT: `${TEE_PAATOSTILI_ROUTE_PREFIX}/tulot-ja-menot`,
  PALKKIO: `${TEE_PAATOSTILI_ROUTE_PREFIX}/palkkio`,
  YHTEYSTIEDOT: `${TEE_PAATOSTILI_ROUTE_PREFIX}/yhteystiedot`,
  YHTEENVETO: `${TEE_PAATOSTILI_ROUTE_PREFIX}/yhteenveto`,
  VALMIS: `${TEE_PAATOSTILI_ROUTE_PREFIX}/valmis`,
} as const

export const PAATOSTILI_STEPS: PaatostiliRoutePath[] = [
  PAATOSTILI_ROUTES.TEE_PAATOSTILI,
  PAATOSTILI_ROUTES.YLEISKATSAUS,
  PAATOSTILI_ROUTES.OMAISUUS_JA_VELAT,
  PAATOSTILI_ROUTES.TULOT_JA_MENOT,
  PAATOSTILI_ROUTES.PALKKIO,
  PAATOSTILI_ROUTES.YHTEYSTIEDOT,
  PAATOSTILI_ROUTES.YHTEENVETO,
] as const

export type PaatostiliRoutePath =
  (typeof PAATOSTILI_ROUTES)[keyof typeof PAATOSTILI_ROUTES]

export type TilintarkastusForm = UseFormReturn<FieldValues, unknown>
export type TilintarkastusFormState = { isComplete: boolean }

export type PaatostiliFormStates = Record<
  Exclude<
    PaatostiliRoutePath,
    | (typeof PAATOSTILI_ROUTES)['VALMIS']
    | (typeof PAATOSTILI_ROUTES)['YHTEENVETO']
  >,
  TilintarkastusFormState
>

export type PaatostiliForms = Record<
  keyof PaatostiliFormStates,
  TilintarkastusForm
>

export const paatostiliFormsToStates = (
  forms: PaatostiliForms
): PaatostiliFormStates => {
  return {
    [PAATOSTILI_ROUTES.TEE_PAATOSTILI]: formToState(
      forms[PAATOSTILI_ROUTES.TEE_PAATOSTILI]
    ),
    [PAATOSTILI_ROUTES.YLEISKATSAUS]: formToState(
      forms[PAATOSTILI_ROUTES.YLEISKATSAUS]
    ),
    [PAATOSTILI_ROUTES.OMAISUUS_JA_VELAT]: formToState(
      forms[PAATOSTILI_ROUTES.OMAISUUS_JA_VELAT]
    ),
    [PAATOSTILI_ROUTES.TULOT_JA_MENOT]: formToState(
      forms[PAATOSTILI_ROUTES.TULOT_JA_MENOT]
    ),
    [PAATOSTILI_ROUTES.PALKKIO]: formToState(forms[PAATOSTILI_ROUTES.PALKKIO]),
    [PAATOSTILI_ROUTES.YHTEYSTIEDOT]: formToState(
      forms[PAATOSTILI_ROUTES.YHTEYSTIEDOT]
    ),
  }
}

const formToState = ({
  formState,
}: TilintarkastusForm): TilintarkastusFormState => {
  return {
    isComplete:
      formState.isSubmitted &&
      (formState.isValid || Object.entries(formState.errors).length === 0),
  }
}

export const TEE_OMAISUUSLUETTELO_ROUTE_PREFIX = `${TILINTARKASTUS_ASIOINTI_UI_ROUTE_PREFIX}/tee-omaisuusluettelo`

export const OMAISUUSLUETTELO_ROUTES = {
  TEE_OMAISUUSLUETTELO: TEE_OMAISUUSLUETTELO_ROUTE_PREFIX,
  TOIMINTAKYKY_JA_ASUMISTIEDOT: `${TEE_OMAISUUSLUETTELO_ROUTE_PREFIX}/toimintakyky-ja-asumistiedot`,
  OMASSA_KAYTOSSA_OLEVA_OMAISUUS: `${TEE_OMAISUUSLUETTELO_ROUTE_PREFIX}/omassa-kaytossa-oleva-omaisuus`,
  VARAT: `${TEE_OMAISUUSLUETTELO_ROUTE_PREFIX}/varat`,
  VELAT: `${TEE_OMAISUUSLUETTELO_ROUTE_PREFIX}/velat`,
  ELAKKEET_JA_ETUUDET: `${TEE_OMAISUUSLUETTELO_ROUTE_PREFIX}/elakkeet-ja-etuudet`,
  KAYTTOVARAT: `${TEE_OMAISUUSLUETTELO_ROUTE_PREFIX}/kayttovarat`,
  SOPIMUKSET_JA_VAKUUTUKSET: `${TEE_OMAISUUSLUETTELO_ROUTE_PREFIX}/sopimukset-ja-vakuutukset`,
  MUUT_MERKITTAVAT_TOIMET: `${TEE_OMAISUUSLUETTELO_ROUTE_PREFIX}/muut-merkittavat-toimet`,
  YHTEENVETO: `${TEE_OMAISUUSLUETTELO_ROUTE_PREFIX}/yhteenveto`,
  VALMIS: `${TEE_OMAISUUSLUETTELO_ROUTE_PREFIX}/valmis`,
} as const

export const OMAISUUSLUETTELO_STEPS: OmaisuusluetteloRoutePath[] = [
  OMAISUUSLUETTELO_ROUTES.TEE_OMAISUUSLUETTELO,
  OMAISUUSLUETTELO_ROUTES.TOIMINTAKYKY_JA_ASUMISTIEDOT,
  OMAISUUSLUETTELO_ROUTES.OMASSA_KAYTOSSA_OLEVA_OMAISUUS,
  OMAISUUSLUETTELO_ROUTES.VARAT,
  OMAISUUSLUETTELO_ROUTES.VELAT,
  OMAISUUSLUETTELO_ROUTES.ELAKKEET_JA_ETUUDET,
  OMAISUUSLUETTELO_ROUTES.KAYTTOVARAT,
  OMAISUUSLUETTELO_ROUTES.SOPIMUKSET_JA_VAKUUTUKSET,
  OMAISUUSLUETTELO_ROUTES.MUUT_MERKITTAVAT_TOIMET,
  OMAISUUSLUETTELO_ROUTES.YHTEENVETO,
] as const

export type OmaisuusluetteloRoutePath =
  (typeof OMAISUUSLUETTELO_ROUTES)[keyof typeof OMAISUUSLUETTELO_ROUTES]

export type OmaisuusluetteloFormStates = Record<
  Extract<
    OmaisuusluetteloRoutePath,
    | (typeof OMAISUUSLUETTELO_ROUTES)['TOIMINTAKYKY_JA_ASUMISTIEDOT']
    | (typeof OMAISUUSLUETTELO_ROUTES)['OMASSA_KAYTOSSA_OLEVA_OMAISUUS']
  >,
  TilintarkastusFormState
>

export type OmaisuusluetteloForms = Record<
  keyof OmaisuusluetteloFormStates,
  TilintarkastusForm
>

export const omaisuusluetteloFormsToStates = (
  forms: OmaisuusluetteloForms
): OmaisuusluetteloFormStates => {
  return {
    [OMAISUUSLUETTELO_ROUTES.TOIMINTAKYKY_JA_ASUMISTIEDOT]: formToState(
      forms[OMAISUUSLUETTELO_ROUTES.TOIMINTAKYKY_JA_ASUMISTIEDOT]
    ),
    [OMAISUUSLUETTELO_ROUTES.OMASSA_KAYTOSSA_OLEVA_OMAISUUS]: formToState(
      forms[OMAISUUSLUETTELO_ROUTES.OMASSA_KAYTOSSA_OLEVA_OMAISUUS]
    ),
  }
}

export type TilintarkastusRoutePath =
  | PaatostiliRoutePath
  | OmaisuusluetteloRoutePath
