import { StaticChip, suomifiDesignTokens } from 'suomifi-ui-components'
import styled from 'styled-components'

export const ChipForStatusNotStarted = styled(StaticChip)`
  &&& {
    margin-top: ${suomifiDesignTokens.spacing.m};
    margin-left: ${suomifiDesignTokens.spacing.xxs};
    background-color: ${suomifiDesignTokens.colors.warningLight1};
    color: ${suomifiDesignTokens.colors.blackBase};
    font-size: 14px;
  }
`

export const ChipForStatusSent = styled(StaticChip)`
  &&& {
    margin-top: ${suomifiDesignTokens.spacing.m};
    margin-left: ${suomifiDesignTokens.spacing.xxs};
    background: ${suomifiDesignTokens.colors.successSecondary};
    color: ${suomifiDesignTokens.colors.blackBase};
    font-size: 14px;
  }
`

export const ChipForStatusSaved = styled(StaticChip)`
  &&& {
    margin-top: ${suomifiDesignTokens.spacing.m};
    margin-left: ${suomifiDesignTokens.spacing.xxs};
    background: ${suomifiDesignTokens.colors.infoLight1};
    color: ${suomifiDesignTokens.colors.blackBase};
    font-size: 14px;
  }
`
