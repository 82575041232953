import type {
  EvtvVaatimus,
  LongIsoDateString,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/evtv-asiointi/evtv-api.type'
import { observable, runInAction } from 'mobx'
import * as evtvApi from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/api/evtv-api-client'
import * as DateFns from 'date-fns/fp'
import {
  AsiointiFeatureFlag,
  isAsiointiUiFeatureEnabled,
} from 'holhous-common/src/vtj/asiointi-feature-flag'

export interface EvtvVaatimusStore {
  evtv?: EvtvVaatimus[]
  valittuVaatimus?: EvtvVaatimus
}

const evtvVaatimusStore: EvtvVaatimusStore = observable({
  evtv: undefined,
  valittuVaatimus: undefined,
})

export function createEvtvAsiointiStore(
  data: EvtvVaatimusStore
): EvtvVaatimusStore {
  evtvVaatimusStore.evtv = data.evtv
  evtvVaatimusStore.valittuVaatimus = data.valittuVaatimus
  return evtvVaatimusStore
}

export const useEvtv = (): EvtvVaatimus[] | null | undefined => {
  return evtvVaatimusStore.evtv
}

export const useValittuEvtvVaatimus = (): EvtvVaatimus => {
  const vaatimus = evtvVaatimusStore.valittuVaatimus
  if (!vaatimus) {
    throw new Error('evtvVaatimusStore.valittuVaatimus not initialized')
  }
  return vaatimus
}

export const selectEvtvVaatimus = (
  seurantaAsiaVaatimusId: number
): EvtvVaatimus => {
  if (!evtvVaatimusStore.evtv) {
    throw new Error('Missing store evtv data')
  }
  const vaatimus = evtvVaatimusStore.evtv.find(
    (vaatimus) => vaatimus.seurantaAsiavaatimusId === seurantaAsiaVaatimusId
  )
  if (!vaatimus) {
    throw new Error(`Missing evtv vaatimus ${seurantaAsiaVaatimusId}`)
  }
  runInAction(() => {
    evtvVaatimusStore.valittuVaatimus = vaatimus
  })
  return vaatimus
}

export const fetchEvtvDataIntoStore = async (): Promise<void> => {
  const vaatimukset = await evtvApi.getEvtvData()
  runInAction(() => {
    evtvVaatimusStore.evtv = vaatimukset.filter(
      ({ asiaType }) =>
        asiaType !== 'OMAISUUSLUETTELO' ||
        isAsiointiUiFeatureEnabled(AsiointiFeatureFlag.OMAISUUSLUETTELO)
    )
    evtvVaatimusStore.valittuVaatimus = undefined
  })
}

// TODO refactor these date utils here below do not belong here
//  -> move somewhere else
export const IsoDatetimeToUiDate = (
  date: LongIsoDateString | null | undefined
): string => {
  if (date) {
    const parsedDate = DateFns.parseISO(date)
    return DateFns.format('d.M.yyyy', parsedDate)
  }
  return ''
}

export const IsoDatetimeToUiDatetime = (
  date: LongIsoDateString | null | undefined
): string => {
  if (date) {
    const parsedDate = DateFns.parseISO(date)
    return DateFns.format('d.M.yyyy HH:mm', parsedDate)
  }
  return ''
}

export const displayDateToIsoString = (
  value: string | null
): LongIsoDateString => {
  const date = DateFns.parse(new Date(), 'd.M.yyyy', value)
  return date.toISOString() as LongIsoDateString
}
