// Allows for awaiting promises without try-catch-blocks
// Inspired by https://blog.grossman.io/how-to-write-async-await-without-try-catch-blocks-in-javascript/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const to = async <E = any, T = any>(
  promise: Promise<T>
): Promise<[E | null, T?]> => {
  try {
    const data: T = await promise
    return [null, data]
  } catch (err) {
    return [err] as [E | null, T?]
  }
}
