/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useMemo } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkOmaisuusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block, Heading, Paragraph, Text } from 'suomifi-ui-components'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import { getTilirivit } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { FrontPankkilaina } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { FormListInputExpander } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import { observable } from 'mobx'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import { TiliAsiointiAsiakirjatyyppi } from 'tilintarkastus-common/src/vtj/types/asiointi-account-data.type'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import { AsiointiPankkilainaTyyppi } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import { AsiointiLedgerAccountDebtType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-debt-enum'
import FormDropdown from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDropdown'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonEuroWithDecimalsOrUndefinedValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/react-form-validation.util'
import { AsiointiLedgerAccountCategory } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'

const PankkilainatForm: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const omaisuus = getTilirivit()

  const mkNewEntry = (): FrontPankkilaina =>
    observable({
      id: crypto.randomUUID(),
      tiliointinumero: '',
      tyyppi: undefined,
      pankinNimi: '',
      lainanNumero: '',
      arvoTilikaudenAlussaEur: undefined,
      arvoTilikaudenLopussaEur: undefined,
      asiakirjat: [],
    })

  return (
    <section data-test-id={mkOmaisuusTestId('pankkilainat-form')}>
      <Heading variant="h3">{t('pankkilainatHeading')}</Heading>
      <Block mt={verticalMarginToken} />
      <Paragraph>{t('ohjeIlmoitaPankkilainat')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={PankkilainaListItem}
        state={omaisuus.velat.pankkilainat}
        asiointiLedgerAccountCategory={AsiointiLedgerAccountCategory.VELAT}
        dataTestIdPrefix={mkOmaisuusTestId('pankkilainat-form')}
        ModalContentComponent={PankkilainaModal}
        newEntry={mkNewEntry}
        translationPrefix="pankkilainat"
      />
    </section>
  )
}

const PankkilainaModal: FC<FormModalProps<FrontPankkilaina>> = observer(
  ({ createDataTestId, entry, setEntry }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'

    const lainatyypit = useMemo(
      () =>
        Object.values(AsiointiPankkilainaTyyppi).map((value) => ({
          name: t(`lainatyyppi_${value}`),
          value,
        })),
      [t]
    )

    return (
      <div>
        <FormDropdown
          labelText={t('lainanTyyppi')}
          data-test-id={createDataTestId('modal-tyyppi')}
          value={entry.tyyppi}
          items={lainatyypit}
          required
          updateValue={(value) => {
            setEntry({
              ...entry,
              tyyppi: value as AsiointiLedgerAccountDebtType,
            })
          }}
        />
        <Block mt={verticalMarginToken} />

        <FormTextInput
          data-test-id={createDataTestId('modal-lainanNumero')}
          labelText={t('lainanNumero')}
          value={entry.lainanNumero}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) => setEntry({ ...entry, lainanNumero: value })}
        />

        <FormTextInput
          data-test-id={createDataTestId('modal-pankinNimi')}
          labelText={t('pankki')}
          value={entry.pankinNimi}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) => setEntry({ ...entry, pankinNimi: value })}
        />

        <FormNumberInput
          data-test-id={createDataTestId('modal-arvoTilikaudenAlussa')}
          labelText={t('arvoTilikaudenAlussaEuroina')}
          digitMode="two"
          required
          value={entry.arvoTilikaudenAlussaEur}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) =>
            setEntry({
              ...entry,
              arvoTilikaudenAlussaEur: value,
            })
          }
        />

        <FormNumberInput
          data-test-id={createDataTestId('modal-arvoTilikaudenLopussa')}
          labelText={t('arvoTilikaudenLopussaEuroina')}
          digitMode="two"
          required
          value={entry.arvoTilikaudenLopussaEur}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) => {
            setEntry({
              ...entry,
              arvoTilikaudenLopussaEur: value,
            })
          }}
        />

        <FormAttachmentFileBox
          observableAttachments={entry.asiakirjat}
          asiakirjaTypeId={TiliAsiointiAsiakirjatyyppi.MUU_LIITE}
          data-test-id={createDataTestId('modal-asiakirja')}
          text={t('pankkilainatAsiakirjaInfo')}
          title={t('tiliotteetJaSopimukset')}
        />
      </div>
    )
  }
)

const PankkilainaListItem: FC<FormElementProps<FrontPankkilaina>> = observer(
  ({ createDataTestId, entry, onEdit, onRemove }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'

    const arvoLopussaEur = `${formatNumberWithComma(
      entry.arvoTilikaudenLopussaEur!
    )} €`
    const arvoAlussaEur = `${formatNumberWithComma(
      entry.arvoTilikaudenAlussaEur!
    )} €`

    return (
      <FormListInputExpander
        heading={t(`lainatyyppi_${entry.tyyppi}`)}
        headingRight={arvoLopussaEur}
        subHeading={t('lainanNumero')}
        createDataTestId={createDataTestId}
        onEdit={onEdit}
        onRemove={onRemove}
        showSubHeadingWhileOpen
      >
        <Heading variant="h5">{t('lainanNumero')}</Heading>
        <Text>{entry.lainanNumero}</Text>
        <Block mt={verticalMarginToken} />

        <Heading variant="h5">{t('pankinNimi')}</Heading>
        <Text>{entry.pankinNimi}</Text>
        <Block mt={verticalMarginToken} />

        <Heading variant="h5">{t('arvoTilikaudenAlussaEuroina')}</Heading>
        <Text>{arvoAlussaEur}</Text>
        <Block mt={verticalMarginToken} />

        {entry.asiakirjat.length > 0 && (
          <>
            <Block mt={verticalMarginToken} />
            <Heading variant="h5">{t('tiliotteetJaSopimukset')}</Heading>
            <AttachmentList attachments={entry.asiakirjat} />
            <Block mt={verticalMarginToken} />
          </>
        )}
        <Block mt={verticalMarginToken} />
      </FormListInputExpander>
    )
  }
)

export default observer(PankkilainatForm)
