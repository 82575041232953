import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import { SpecifierType } from 'asia-common/src/vtj/koodisto/specifier-enums'
import {
  AsiaPersonRole,
  AsiaPersonType,
  RatkaisuType,
  AsiaStatus,
  AsiaHandlerRole,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import { InternalToimenpideType } from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import { Retention } from 'asia-common/src/vtj/koodisto/retention-enums'
import { PublicityClass } from 'asia-common/src/vtj/koodisto/restriction-enums'

export const avioliittotodistusMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    {
      asiakirjaTypeId: AsiakirjaType.TODISTUSTILAUS,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
        publicityClassIds: [PublicityClass.JULKINEN, PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['d377299d-ee78-4cf6-bc07-12508817fb75'],
      defaultToivoAsiakirjaTypeId: 'd377299d-ee78-4cf6-bc07-12508817fb75',
    },
    {
      asiakirjaTypeId: AsiakirjaType.OMIEN_TIETOJEN_TARKASTUSOIKEUDEN_KAYTTAMINEN_TILAUS,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
        publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['9524af2e-8874-4848-8b9b-70eb0f60bd31'],
      defaultToivoAsiakirjaTypeId: '9524af2e-8874-4848-8b9b-70eb0f60bd31',
    },
    {
      asiakirjaTypeId: AsiakirjaType.HALLINTOOIKEUDEN_PAATOS,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
        publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['35789b1b-b713-4977-a824-97a096e2e9ee'],
      defaultToivoAsiakirjaTypeId: '35789b1b-b713-4977-a824-97a096e2e9ee',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_ASIAKIRJA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
        publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['1512dded-d5ab-4da7-8247-43c998b25626'],
      defaultToivoAsiakirjaTypeId: '1512dded-d5ab-4da7-8247-43c998b25626',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYSASIAKIRJA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
        publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT, AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['0a14ea35-000b-4754-8b47-7e3cc015a134'],
      defaultToivoAsiakirjaTypeId: '0a14ea35-000b-4754-8b47-7e3cc015a134',
    },
    {
      asiakirjaTypeId: AsiakirjaType.TODISTUS_VAESTOTIETOJARJESTELMASTA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
        publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['c9e65eb7-df78-4d5e-9469-b03d609cd9f3'],
      defaultToivoAsiakirjaTypeId: 'c9e65eb7-df78-4d5e-9469-b03d609cd9f3',
    },
    {
      asiakirjaTypeId: AsiakirjaType.EU_VAKIOLOMAKE,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
        publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['673da113-978f-467f-99b3-c97f493bee95'],
      defaultToivoAsiakirjaTypeId: '673da113-978f-467f-99b3-c97f493bee95',
    },
    {
      asiakirjaTypeId: AsiakirjaType.APOSTILLE,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
        publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['7a81dc88-e201-4aaf-a909-25551992ef83'],
      defaultToivoAsiakirjaTypeId: '7a81dc88-e201-4aaf-a909-25551992ef83',
    },
    {
      asiakirjaTypeId: AsiakirjaType.PAATOS,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
        publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['1ddb9507-6fe9-44d8-ba33-5e337c6663bf'],
      defaultToivoAsiakirjaTypeId: '1ddb9507-6fe9-44d8-ba33-5e337c6663bf',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUUTOKSENHAKUOHJE,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
        publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['eba13857-3eb6-47b8-958b-b4b57703a06c'],
      defaultToivoAsiakirjaTypeId: 'eba13857-3eb6-47b8-958b-b4b57703a06c',
    },
    {
      asiakirjaTypeId: AsiakirjaType.OIKAISUVAATIMUSOHJE,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
        publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['beb63852-a952-442e-a718-162ba058cba6'],
      defaultToivoAsiakirjaTypeId: 'beb63852-a952-442e-a718-162ba058cba6',
    },
    {
      asiakirjaTypeId: AsiakirjaType.ILMOITUS_ETTEI_TIETOJA_VOIDA_LUOVUTTAA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
        publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['a7ceac7e-46a7-46eb-bc1f-df9b243c4bf2'],
      defaultToivoAsiakirjaTypeId: 'a7ceac7e-46a7-46eb-bc1f-df9b243c4bf2',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SAATE_TAI_LAHETE,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
        publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['27c498b2-e6b1-4742-903c-ccd687267f9f'],
      defaultToivoAsiakirjaTypeId: '27c498b2-e6b1-4742-903c-ccd687267f9f',
    },
  ],
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [],
    organizationTypeIds: [],
  },
  personRoleMetadata: [AsiaPersonRole.ASIAKAS, AsiaPersonRole.HAKIJA, AsiaPersonRole.HUOLLETTAVA],
  personTypeMetadata: [AsiaPersonType.IHMINEN],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.RATKAISTU,
    manualRatkaisuTypeIds: [RatkaisuType.RATKAISTU],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: true,
    mandatorySpecifier: false,
    specifierTypeIds: [SpecifierType.EIDAS, SpecifierType.APOSTILLE],
  },
  toimenpideMetadata: [],
  vireilletulokanavaMetadata: [Vireilletulokanava.ELOMAKE_KANSALAINEN, Vireilletulokanava.ITSEPALVELU],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.KASITTELYSSA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.KASITTELYSSA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
}
