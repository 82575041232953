import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import {
  Block,
  InlineAlert,
  Paragraph,
  RadioButton,
  RadioButtonGroup,
} from 'suomifi-ui-components'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { FormProvider } from 'react-hook-form'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import { mkPalkkioTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import {
  getPaatostili,
  updatePalkkioValues,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { commonEuroWithDecimalsOrUndefinedValidator } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/react-form-validation.util'
import { useValittuEvtvVaatimus } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/evtv-asiointi-store'
import { EdunvalvontasuhdeType } from 'tilintarkastus-common/src/vtj/types/edunvalvontasuhde/edunvalvontasuhde-enums'
import { TiliAsiointiAsiakirjatyyppi } from 'tilintarkastus-common/src/vtj/types/asiointi-account-data.type'
import {
  PAATOSTILI_ROUTES,
  PAATOSTILI_STEPS,
  TilintarkastusForm,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'

const PalkkioPage: React.FC<{
  form: TilintarkastusForm
}> = observer(({ form }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const veloitatkoPalkkiotaTestId = mkPalkkioTestId(
    'veloitatko-palkkiota-radio-group'
  )
  const veloitatkoKulukorvauksiaTestId = mkPalkkioTestId(
    'veloitatko-kulukorvauksia-radio-group'
  )
  const palkkioMaaraTestId = mkPalkkioTestId('palkkio-maara')
  const kulukorvausMaaraTestId = mkPalkkioTestId('kulukorvaus-maara')
  const { palkkio } = getPaatostili()

  const lakimaarainenEliHolhoaja =
    EdunvalvontasuhdeType.EDUNVALVONTA_LAKIMAARAINEN ===
    useValittuEvtvVaatimus().guardianshipType

  if (lakimaarainenEliHolhoaja) {
    updatePalkkioValues({ veloitatkoPalkkiota: false })
  }

  // Unregister form fields so form isValid is tracked correctly
  const { unregister, getFieldState } = form
  useEffect(() => {
    if (
      !palkkio.veloitatkoKulukorvauksia &&
      getFieldState(kulukorvausMaaraTestId) !== undefined
    ) {
      unregister(kulukorvausMaaraTestId)
    }
    if (
      !palkkio.veloitatkoPalkkiota &&
      getFieldState(palkkioMaaraTestId) !== undefined
    ) {
      unregister(palkkioMaaraTestId)
    }
  }, [
    palkkio.veloitatkoKulukorvauksia,
    palkkio.veloitatkoPalkkiota,
    palkkioMaaraTestId,
    kulukorvausMaaraTestId,
    unregister,
    getFieldState,
  ])

  return (
    <>
      <FormProvider {...form}>
        <ResponsiveBorderedContentBlock
          data-test-id={mkPalkkioTestId('container')}
        >
          <PageStepHeading
            heading={t('palkkio')}
            steps={PAATOSTILI_STEPS}
            createDataTestId={mkPalkkioTestId}
          />
          <Block mt={verticalMarginToken} />
          {lakimaarainenEliHolhoaja && (
            <>
              <InlineAlert
                status="warning"
                data-test-id={mkPalkkioTestId('huoltaja-warning')}
              >
                {t('ohjeLakimaarainenEdunvalvojaEliHuoltaja')}
              </InlineAlert>
              <Block mt={verticalMarginToken} />
            </>
          )}

          <Paragraph>{t('ohjeIlmoitaVeloitatko')}</Paragraph>
          <Block mt={verticalMarginToken} />
          <Paragraph>{t('ohjeVoitMaksaaSumma')}</Paragraph>
          <Block mt={verticalMarginToken} />
          <Paragraph>{t('ohjeIlmoitaPalkkioTulorekisteriin')}</Paragraph>
          <DividerLine mt={verticalMarginToken} />
          <Block mt={verticalMarginToken} />
          {!lakimaarainenEliHolhoaja && (
            <>
              <RadioButtonGroup
                data-test-id={veloitatkoPalkkiotaTestId}
                value={Boolean(palkkio.veloitatkoPalkkiota).toString()}
                labelText={t('veloitatkoPalkkiotaTaltaTilikaudelta')}
                name={veloitatkoPalkkiotaTestId}
                onChange={(value) =>
                  updatePalkkioValues({ veloitatkoPalkkiota: value === 'true' })
                }
              >
                <RadioButton
                  data-test-id={veloitatkoPalkkiotaTestId + '-true'}
                  value={'true'}
                  variant="small"
                >
                  {t('kylla')}
                </RadioButton>
                <RadioButton
                  data-test-id={veloitatkoPalkkiotaTestId + '-false'}
                  value={'false'}
                  variant="small"
                >
                  {t('eiLuovunPalkkiostaTilikaudella')}
                </RadioButton>
              </RadioButtonGroup>
              <Block mt={verticalMarginToken} />
              {palkkio.veloitatkoPalkkiota ? (
                <FormNumberInput
                  data-test-id={palkkioMaaraTestId}
                  labelText={t('palkkioEuroina')}
                  value={palkkio.palkkioMaara}
                  validate={commonEuroWithDecimalsOrUndefinedValidator(
                    t,
                    'two'
                  )}
                  required={palkkio.veloitatkoPalkkiota}
                  updateValue={(value) =>
                    updatePalkkioValues({
                      palkkioMaara: value,
                    })
                  }
                />
              ) : (
                <InlineAlert // TODO selvitä miksi niin iso väli
                  data-test-id={mkPalkkioTestId('luovut-palkkiosta-info')}
                >
                  {t('ohjeLuovutPalkkiosta')}
                </InlineAlert>
              )}
              <Block mt={verticalMarginToken} />
            </>
          )}
          <RadioButtonGroup
            data-test-id={veloitatkoKulukorvauksiaTestId}
            value={Boolean(palkkio.veloitatkoKulukorvauksia).toString()}
            labelText={t('veloitatkoKulukorvauksiaTaltaTilikaudelta')}
            name={veloitatkoKulukorvauksiaTestId}
            onChange={(value) =>
              updatePalkkioValues({
                veloitatkoKulukorvauksia: value === 'true',
              })
            }
          >
            <RadioButton
              data-test-id={veloitatkoKulukorvauksiaTestId + '-true'}
              value={'true'}
              variant="small"
            >
              {t('kylla')}
            </RadioButton>
            <RadioButton
              data-test-id={veloitatkoKulukorvauksiaTestId + '-false'}
              value={'false'}
              variant="small"
            >
              {t('eiLuovunkorvauksistaTilikaudella')}
            </RadioButton>
          </RadioButtonGroup>
          <Block mt={verticalMarginToken} />
          {palkkio.veloitatkoKulukorvauksia ? (
            <FormNumberInput
              data-test-id={kulukorvausMaaraTestId}
              labelText={t('kulutEuroina')}
              value={palkkio.kulukorvausMaara}
              validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
              required={palkkio.veloitatkoKulukorvauksia}
              updateValue={(value) =>
                updatePalkkioValues({
                  kulukorvausMaara: value,
                })
              }
            />
          ) : (
            <InlineAlert>{t('ohjeLuovutKulukorvauksesta')}</InlineAlert>
          )}
          {(palkkio.veloitatkoPalkkiota ||
            palkkio.veloitatkoKulukorvauksia) && (
            <>
              <Block mt={verticalMarginToken} />
              <FormAttachmentFileBox
                observableAttachments={palkkio.kululaskelma}
                asiakirjaTypeId={TiliAsiointiAsiakirjatyyppi.MUU_LIITE}
                data-test-id={mkPalkkioTestId('palkkio-laskelma')}
                title={t('palkkioJaKululaskelma')}
                text={t('ohjePalkkioJaKululaskelmaLomakepohja')}
              />
            </>
          )}
          <DividerLine mt={verticalMarginToken} />
          <FormNavigationBar
            previous={PAATOSTILI_ROUTES.TULOT_JA_MENOT}
            next={PAATOSTILI_ROUTES.YHTEYSTIEDOT}
          />
        </ResponsiveBorderedContentBlock>
      </FormProvider>
    </>
  )
})

export default PalkkioPage
