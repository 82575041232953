import React from 'react'
import { Navigate, Outlet, Route } from 'react-router-dom'
import { observer } from 'mobx-react'
import LupaApplicationPage from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/LupaApplicationPage'
import * as ApplicationRoutes from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-application-routes'
import {
  LupaApplicationStep,
  LupaApplicationView,
  pathByViewId,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-application-routes'
import { LUPA_ASIOINTI_UI_ROUTE_PREFIX } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-asiointi-ui-route.util'
import { initLupaAsiointiI18n } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/localization/i18next'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/holhous-asiointi-user-store'
import { I18nextProvider } from 'react-i18next'

const LupaAsiointiApp: React.FC = observer(() => {
  const { lang } = useAsiointiUserStore()
  const i18n = initLupaAsiointiI18n(lang)
  return (
    <I18nextProvider i18n={i18n}>
      <Outlet />
    </I18nextProvider>
  )
})

export const lupaAsiointiRoutes: React.ReactNode = (
  <>
    <Route path={LUPA_ASIOINTI_UI_ROUTE_PREFIX} Component={LupaAsiointiApp}>
      <Route path={ApplicationRoutes.mainPath} Component={LupaApplicationPage}>
        {Object.entries(ApplicationRoutes.viewComponentByViewId).map(
          ([routeId, Component]) => (
            <Route
              key={routeId}
              path={pathByViewId[routeId as LupaApplicationView]}
              Component={Component}
            />
          )
        )}
        <Route
          path={ApplicationRoutes.mainPath}
          element={
            <Navigate
              to={
                ApplicationRoutes.pathByViewId[
                  LupaApplicationStep.LUPA_TYPE_SELECT
                ]
              }
            />
          }
        />
      </Route>
      <Route
        path={LUPA_ASIOINTI_UI_ROUTE_PREFIX}
        element={
          <Navigate
            to={
              ApplicationRoutes.pathByViewId[
                LupaApplicationStep.LUPA_TYPE_SELECT
              ]
            }
          />
        }
      />
    </Route>
  </>
)

export default lupaAsiointiRoutes
