/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useMemo } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkOmaisuusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block, Heading, Paragraph, Text } from 'suomifi-ui-components'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import { getTilirivit } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { FrontArvopaperi } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { FormListInputExpander } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import FormDropdown from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDropdown'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonAmountWithDecimalsOrUndefinedValidator,
  commonEuroWithDecimalsOrUndefinedValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/react-form-validation.util'
import { observable } from 'mobx'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import { TiliAsiointiAsiakirjatyyppi } from 'tilintarkastus-common/src/vtj/types/asiointi-account-data.type'
import { AsiointiArvopaperiTyyppi } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import { AsiointiLedgerAccountAssetsType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-assets-enum'
import { AsiointiLedgerAccountCategory } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'

const ArvopaperitForm: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const omaisuus = getTilirivit()

  const mkNewEntry = (): FrontArvopaperi =>
    observable({
      id: crypto.randomUUID(),
      tiliointinumero: '',
      tyyppi: undefined,
      yhtionNimi: '',
      kappaleMaaraTilikaudenLopussa: undefined,
      arvoTilikaudenAlussaEur: undefined,
      arvoTilikaudenLopussaEur: undefined,
      asiakirjat: [],
    })

  return (
    <section data-test-id={mkOmaisuusTestId('arvopaperit-form')}>
      <Heading variant="h3">{t('arvopaperitHeading')}</Heading>
      <Block mt={verticalMarginToken} />
      <Paragraph>{t('ohjeIlmoitaArvopaperit')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={ArvopaperiListItem}
        state={omaisuus.varat.arvopaperit}
        asiointiLedgerAccountCategory={AsiointiLedgerAccountCategory.VARAT}
        dataTestIdPrefix={mkOmaisuusTestId('arvopaperit-form')}
        ModalContentComponent={ArvopaperiModal}
        newEntry={mkNewEntry}
        translationPrefix="arvopaperit"
      />
    </section>
  )
}

const ArvopaperiModal: FC<FormModalProps<FrontArvopaperi>> = observer(
  ({ createDataTestId, entry, setEntry }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'
    const arvopaperityypit = useMemo(
      () =>
        Object.values(AsiointiArvopaperiTyyppi).map((value) => ({
          name: t(`arvopaperityyppi_${value}`),
          value,
        })),
      [t]
    )

    return (
      <div>
        <FormDropdown
          labelText={t('valitseArvopaperityyppi')}
          data-test-id={createDataTestId('modal-tyyppi')}
          value={entry.tyyppi}
          items={arvopaperityypit}
          required
          updateValue={(value) => {
            setEntry({
              ...entry,
              tyyppi: value as AsiointiLedgerAccountAssetsType,
            })
          }}
        />
        <Block mt={verticalMarginToken} />

        <FormTextInput
          data-test-id={createDataTestId('modal-yhtionNimi')}
          labelText={t('yhtionNimi')}
          value={entry.yhtionNimi}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) => setEntry({ ...entry, yhtionNimi: value })}
        />

        <FormNumberInput
          data-test-id={createDataTestId('modal-kappalemaaraTilikaudenLopussa')}
          labelText={t('kappalemaaraTilikaudenLopussa')}
          required
          digitMode="five"
          value={entry.kappalemaaraTilikaudenLopussa}
          validate={commonAmountWithDecimalsOrUndefinedValidator(t)}
          updateValue={(value) =>
            setEntry({
              ...entry,
              kappalemaaraTilikaudenLopussa: value,
            })
          }
        />

        <FormNumberInput
          data-test-id={createDataTestId('modal-arvoTilikaudenAlussa')}
          labelText={t('arvoTilikaudenAlussaEuroina')}
          digitMode="two"
          required
          value={entry.arvoTilikaudenAlussaEur}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) =>
            setEntry({
              ...entry,
              arvoTilikaudenAlussaEur: value,
            })
          }
        />

        <FormNumberInput
          data-test-id={createDataTestId('modal-arvoTilikaudenLopussa')}
          labelText={t('arvoTilikaudenLopussaEuroina')}
          digitMode="two"
          required
          value={entry.arvoTilikaudenLopussaEur}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) => {
            setEntry({
              ...entry,
              arvoTilikaudenLopussaEur: value,
            })
          }}
        />

        <FormAttachmentFileBox
          attachmentsGroupId={entry.id}
          observableAttachments={entry.asiakirjat}
          required={entry.tyyppi !== AsiointiLedgerAccountAssetsType.OSUUDET}
          asiakirjaTypeId={TiliAsiointiAsiakirjatyyppi.MUU_LIITE}
          data-test-id={createDataTestId('modal-asiakirja')}
          text={t('arvopaperitAsiakirjaInfo')}
          title={t('asiakirjat')}
        />
      </div>
    )
  }
)

const ArvopaperiListItem: FC<FormElementProps<FrontArvopaperi>> = observer(
  ({ createDataTestId, entry, onEdit, onRemove }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'

    const arvoLopussaEur = `${formatNumberWithComma(
      entry.arvoTilikaudenLopussaEur!
    )} €`
    const arvoAlussaEur = `${formatNumberWithComma(
      entry.arvoTilikaudenAlussaEur!
    )} €`

    return (
      <FormListInputExpander
        heading={entry.yhtionNimi}
        headingRight={arvoLopussaEur}
        createDataTestId={createDataTestId}
        onEdit={onEdit}
        onRemove={onRemove}
        showSubHeadingWhileOpen
      >
        <Heading variant="h5">{t('valitseArvopaperityyppi')}</Heading>
        <Text>{t(`arvopaperityyppi_${entry.tyyppi}`)}</Text>
        <Block mt={verticalMarginToken} />

        <Heading variant="h5">{t('kappalemaaraTilikaudenLopussa')}</Heading>
        <Text>{entry.kappalemaaraTilikaudenLopussa}</Text>
        <Block mt={verticalMarginToken} />

        <Heading variant="h5">{t('arvoTilikaudenAlussaEuroina')}</Heading>
        <Text>{arvoAlussaEur}</Text>
        <Block mt={verticalMarginToken} />

        {entry.asiakirjat.length > 0 && (
          <>
            <Block mt={verticalMarginToken} />
            <Heading variant="h5">{t('asiakirjat')}</Heading>
            <AttachmentList attachments={entry.asiakirjat} />
            <Block mt={verticalMarginToken} />
          </>
        )}
        <Block mt={verticalMarginToken} />
      </FormListInputExpander>
    )
  }
)

export default observer(ArvopaperitForm)
