import { TiliDraft } from 'edunvalvonta-asiointi/src/vtj/asiointi/draft/draft-api.type'
import { flow, observable, runInAction, toJS } from 'mobx'
import * as draftApi from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/api/draft-api-client'
import { LongIsoDateString } from 'edunvalvonta-asiointi/src/vtj/asiointi/evtv-asiointi/evtv-api.type'
import {
  AsiointiFeatureFlag,
  isAsiointiUiFeatureEnabled,
} from 'holhous-common/src/vtj/asiointi-feature-flag'
import { Lomake } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'

export interface DraftStore {
  tilit: TiliDraft[]
}

const draftStore: DraftStore = observable({ tilit: [] })

export function useDraftStore(): DraftStore {
  return draftStore
}

// Overrides local state by the state from the server
export const fetchDraftsIntoStore = async (): Promise<void> => {
  const { tilit } = await draftApi.getDrafts()
  runInAction(() => {
    draftStore.tilit = observable(tilit)
  })
}

export const getTiliDraftBySeurantaasiavaatimus = (
  seurantaasiavaatimusId: number
): TiliDraft | undefined => {
  return draftStore.tilit.find(
    ({ tili }) => getTiliSeurantaAsiaVaatimusId(tili) === seurantaasiavaatimusId
  )
}

export const getTiliSeurantaAsiaVaatimusId = (
  tili: TiliDraft['tili']
): null | number => {
  if (tili?.tili?.seurantaAsiaVaatimusId) {
    return tili.tili.seurantaAsiaVaatimusId
  }
  return null
}

export const saveTiliAsDraft = async (
  maybeObservableLomake: Lomake
): Promise<void> =>
  flow(function* () {
    if (!isAsiointiUiFeatureEnabled(AsiointiFeatureFlag.TALLENNUS)) {
      return
    }
    const tili = toJS(maybeObservableLomake)
    const tiliDraftToSave = observable({
      tili, // a non-observable copy of the original
      updatedAt: new Date().toISOString() as LongIsoDateString, // user specific date to avoid flickering re-rendering ui
    })
    let found = false
    for (const tiliDraft of draftStore.tilit) {
      if (hasMatchingSeurantaAsiaVaatimusId(tiliDraft, tiliDraftToSave)) {
        tiliDraft.tili = tiliDraftToSave.tili
        tiliDraft.updatedAt = tiliDraftToSave.updatedAt
        found = true
        break
      }
    }

    if (!found) {
      draftStore.tilit.push(observable(tiliDraftToSave))
    }
    return yield draftApi.saveTiliDraft(tiliDraftToSave)
  })()

const hasMatchingSeurantaAsiaVaatimusId = (
  tiliDraft: TiliDraft,
  tiliDraftToSave: {
    tili: Lomake
    updatedAt: LongIsoDateString
  }
): boolean => {
  return (
    tiliDraft.tili.tili.seurantaAsiaVaatimusId ===
    tiliDraftToSave.tili.tili.seurantaAsiaVaatimusId
  )
}
