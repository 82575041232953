import { action, flow, observable } from 'mobx'
import { CancellablePromise } from 'mobx/dist/api/flow'
import { AsiointiStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store.type'
import {
  getPreviousSteps,
  isApplicationStep,
  LupaApplicationOtherView,
  LupaApplicationStep,
  lupaApplicationStepOrder,
  LupaApplicationView,
  pathByViewId,
  resolveStepByPath,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-application-routes'
import {
  NavigationState,
  NavigationStepStatus,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/LupaNavigation'
import { AsiointiBatch } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/asiointi-batch.type'
import { AsiointiUser } from 'edunvalvonta-asiointi/src/vtj/asiointi/authentication/holhous-asiointi-user.type'
import { addPerson } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/actions/person-actions'
import { getCountries } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/api/country-api'
import { Country } from 'holhous-common/src/vtj/country/country.type'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import { LupaApplicationRole } from 'lupa-backend/src/vtj/elsa/person/person-enums'
import { getCurrentProductCatalog } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/api/product-catalog-api'
import { AsiointiProductCatalog } from 'lupa-backend/src/vtj/asiointi/product-catalog/asiointi-product-catalog-api.type'
import { isValidMunicipality } from 'common/src/vtj/municipality.util'
import { COUNTRY_CODE_FINLAND } from 'holhous-common/src/vtj/country/country.util'
import { validateBatch } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/actions/validation-actions'

let store: AsiointiStore | undefined

export const initAsiointiStore = async (
  user: AsiointiUser,
  lang: HolhousAsiointiLanguageCode
): Promise<AsiointiStore> => {
  const [countries, productCatalog]: [Country[], AsiointiProductCatalog] =
    await Promise.all([getCountries(), getCurrentProductCatalog()])
  store = observable({
    batch: initBatch(),
    countries,
    productCatalog,
    visitedSteps: new Set<LupaApplicationStep>(),
    validationFailedForSteps: new Set<LupaApplicationStep>(),
    showValidationFailedForSteps: new Set<LupaApplicationStep>(),
    showValidationErrorsForSteps: new Set<LupaApplicationStep>(),
    validationErrorFocus: null,
  })
  await populateInitialHakija(user, lang)
  await populateInitialPaamies()
  await validateBatch()
  return store
}

export const initAsiointiStoreWithData = (
  data: AsiointiStore
): AsiointiStore => {
  store = observable(data)
  return store
}

export const getStore = (): AsiointiStore => {
  if (store) {
    return store
  } else {
    throw new Error('AsiointiStore not initialized')
  }
}

export const isStoreInitialized = (): boolean => Boolean(store)

export const runAsiointiStoreAction = <T>(
  fn: (store: AsiointiStore) => T
): T => {
  if (store) {
    return action(fn)(store)
  } else {
    throw new Error('AsiointiStore not initialized')
  }
}

export const runAsiointiStoreFlow = <T>(
  fn: (store: AsiointiStore) => Generator<any, T, any> // eslint-disable-line @typescript-eslint/no-explicit-any
): CancellablePromise<T> => {
  if (store) {
    return flow<T, [AsiointiStore]>(fn)(store)
  } else {
    throw new Error('AsiointiStore not initialized')
  }
}

export const canAccessPath = (pathname: string): boolean => {
  const store = isStoreInitialized() ? getStore() : undefined
  if (store?.batch.submitStatus === 'submitted') {
    return (
      pathname === pathByViewId[LupaApplicationOtherView.DONE] ||
      pathname === pathByViewId[LupaApplicationOtherView.PRINTABLE_SUMMARY]
    )
  } else if (store?.batch.submitStatus === 'in-progress') {
    return pathname === pathByViewId[LupaApplicationStep.SUMMARY]
  } else if (pathname === pathByViewId[LupaApplicationStep.LUPA_TYPE_SELECT]) {
    return true
  } else if (isApplicationStep(pathname)) {
    const step = resolveStepByPath(pathname)
    const hasVisitedStep = !!store?.visitedSteps.has(step)
    if (hasVisitedStep) {
      return true
    }
    for (const previousStep of getPreviousSteps(step)) {
      if (store?.validationFailedForSteps.has(previousStep)) {
        return false
      }
    }
    return true
  } else {
    return false
  }
}

export const getDefaultView = (): LupaApplicationView => {
  const store = isStoreInitialized() ? getStore() : undefined
  if (store?.batch.submitStatus === 'submitted') {
    return LupaApplicationOtherView.DONE
  } else if (store?.batch.submitStatus === 'in-progress') {
    return LupaApplicationStep.SUMMARY
  } else {
    return LupaApplicationStep.LUPA_TYPE_SELECT
  }
}

const evaluateStepCompleted = (step: LupaApplicationStep): boolean => {
  const store = getStore()

  if (
    step === LupaApplicationStep.LUPA_TYPE_SELECT ||
    step === LupaApplicationStep.NEEDED_ATTACHMENTS
  ) {
    return !store.validationFailedForSteps.has(
      LupaApplicationStep.LUPA_TYPE_SELECT
    )
  }

  if (step === LupaApplicationStep.INPUT_PERSONS) {
    return !store.validationFailedForSteps.has(
      LupaApplicationStep.INPUT_PERSONS
    )
  }

  if (step === LupaApplicationStep.INPUT_INFO) {
    return !store.validationFailedForSteps.has(LupaApplicationStep.INPUT_INFO)
  }

  if (step === LupaApplicationStep.SUMMARY) {
    return !store.validationFailedForSteps.size
  }

  return false
}

const evaluateStep = (
  currentStep: LupaApplicationStep,
  evalStep: LupaApplicationStep
): NavigationStepStatus => {
  const currentIndex = lupaApplicationStepOrder.indexOf(currentStep)
  const evalIndex = lupaApplicationStepOrder.indexOf(evalStep)
  const completed = evaluateStepCompleted(evalStep)
  if (currentIndex === evalIndex) {
    return completed ? 'current-completed' : 'current'
  } else if (canAccessPath(pathByViewId[evalStep])) {
    return completed ? 'completed' : 'default'
  } else {
    return 'coming'
  }
}

export const evaluateNavigationState = (
  currentStep: LupaApplicationStep
): NavigationState =>
  Object.values(LupaApplicationStep).reduce((navState, evalStep) => {
    return {
      ...navState,
      [evalStep]: evaluateStep(currentStep, evalStep),
    }
  }, {} as NavigationState)

const initBatch = (): AsiointiBatch => ({
  sourceBatchId: crypto.randomUUID(),
  description: null,
  arguments: null,
  applications: [],
  persons: [],
  submitStatus: 'none',
  submitError: undefined,
  validationErrors: [],
})

const populateInitialHakija = (
  user: AsiointiUser,
  lang: HolhousAsiointiLanguageCode
): Promise<void> =>
  runAsiointiStoreFlow(function* (store: AsiointiStore) {
    const { countries } = store
    const countryId =
      user?.countryCode ??
      (isValidMunicipality(user?.municipality) ? COUNTRY_CODE_FINLAND : null)
    const country = countries.find((c) => c.countryId === countryId)

    yield addPerson({
      applicationRoleId: LupaApplicationRole.HAKIJA,
      countryId: country?.countryId || null,
      postOffice: user?.postOffice?.[lang] || null,
      postalCode: user?.postalCode || null,
      streetAddress: user?.streetAddress?.[lang] || null,
      firstnames: user.firstnames,
      lastname: user.lastname,
      hetu: user.hetu,
      email: user.email,
      emailRepeated: user.email,
      isAuthenticatedUser: true,
    })
  })

const populateInitialPaamies = async (): Promise<void> =>
  await addPerson({ applicationRoleId: LupaApplicationRole.PAAMIES })
