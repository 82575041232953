import keyMirror from 'keymirror'
import * as DateFns from 'date-fns'
import { ViranomainenId } from 'asia-common/src/vtj/koodisto/viranomainen-enums'

export const PublicityClass = keyMirror({
  JULKINEN: null,
  SALAINEN: null,
  OSITTAIN_SALASSAPIDETTAVA: null,
})
export type PublicityClassId = (typeof PublicityClass)[keyof typeof PublicityClass]

export const SecurityClass = keyMirror({
  EI_TURVALLISUUSLUOKITELTU: null,
})
export type SecurityClassId = (typeof SecurityClass)[keyof typeof SecurityClass]

export const PersonalDataClass = keyMirror({
  EI_SISALLA_HENKILOTIETOJA: null,
  SISALTAA_HENKILOTIETOJA: null,
  SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA: null,
})
export type PersonalDataClassId = (typeof PersonalDataClass)[keyof typeof PersonalDataClass]

export const SecurityPeriod = keyMirror({
  SALASSAPITOAIKA_25_VUOTTA: null,
  SALASSAPITOAIKA_50_VUOTTA: null,
  SALASSAPITOAIKA_100_VUOTTA: null,
})
export type SecurityPeriodId = (typeof SecurityPeriod)[keyof typeof SecurityPeriod]
export const SecurityPeriodInYears: { [key in SecurityPeriodId]: number } = {
  [SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA]: 25,
  [SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA]: 50,
  [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA]: 100,
}

export const SecurityReason = keyMirror({
  JULKL_621_1999_24_1_KOHTA_20: null,
  JULKL_621_1999_24_1_KOHTA_22: null,
  JULKL_621_1999_24_1_KOHTA_23: null,
  JULKL_621_1999_24_1_KOHTA_24: null,
  JULKL_621_1999_24_1_KOHTA_25: null,
  JULKL_621_1999_24_1_KOHTA_26: null,
  JULKL_621_1999_24_1_KOHTA_27: null,
  JULKL_621_1999_24_1_KOHTA_31: null,
  JULKL_621_1999_24_1_KOHTA_31_C: null,
  JULKL_621_1999_24_1_KOHTA_32: null,
})
export type SecurityReasonId = (typeof SecurityReason)[keyof typeof SecurityReason]

export interface RestrictionMetadata {
  defaultPublicityClassId: PublicityClassId
  publicityClassIds: PublicityClassId[]
  defaultSecurityClassId?: SecurityClassId
  securityClassIds?: SecurityClassId[]
  defaultPersonalDataClassId?: PersonalDataClassId
  personalDataClassIds?: PersonalDataClassId[]
  defaultSecurityPeriodId?: SecurityPeriodId
  securityPeriodIds?: SecurityPeriodId[]
  defaultSecurityReasonIds?: SecurityReasonId[]
  securityReasonIds?: SecurityReasonId[]
  defaultViranomainenId?: ViranomainenId
  viranomainenIds?: ViranomainenId[]
}

export interface SecurityDates {
  securityStartDate: string | null
  securityEndDate: string | null
}

export const getSecurityDates = (
  securityStartTimestamp: Date,
  securityPeriodId: SecurityPeriodId | null
): SecurityDates => {
  if (securityPeriodId !== null) {
    const start = DateFns.startOfDay(securityStartTimestamp)
    const end = DateFns.addYears(start, SecurityPeriodInYears[securityPeriodId])
    return {
      securityStartDate: DateFns.format(start, 'yyyy-MM-dd'),
      securityEndDate: DateFns.format(end, `yyyy-MM-dd`),
    }
  } else {
    return {
      securityStartDate: null,
      securityEndDate: null,
    }
  }
}
