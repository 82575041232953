import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import {
  getPaatostili,
  updateAccountValues,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import {
  Block,
  Heading,
  Paragraph,
  RadioButton,
  RadioButtonGroup,
} from 'suomifi-ui-components'
import { mkYleiskatsausTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { commonXssValidator } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/react-form-validation.util'
import FormTextArea from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextArea'

const KayttovaratForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const { isAnnettuKayttovaroja, kayttovaraKaytanto } = getPaatostili()

  const kayttovaraKaytantoInputTestId = mkYleiskatsausTestId(
    'miten-annettu-kayttovaroja-input'
  )

  return (
    <section data-test-id={mkYleiskatsausTestId('kayttovarat-container')}>
      <Heading variant="h3">{t('kayttovarat')}</Heading>
      <Block mt={verticalMarginToken} />
      <Paragraph>{t('ohjeIlmoitaMitaKayttovarojaHenkilolle')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <RadioButtonGroup
        data-test-id={mkYleiskatsausTestId(
          'onko-annettu-kayttovaroja-radio-group'
        )}
        value={Boolean(isAnnettuKayttovaroja).toString()}
        labelText={t('onkoHenkilolleAnnettuKayttovarojaTallaTilikaudella')}
        name={mkYleiskatsausTestId('onko-annettu-kayttovaroja-radio-group')}
        onChange={(value) =>
          updateAccountValues({ isAnnettuKayttovaroja: value === 'true' })
        }
      >
        <RadioButton
          data-test-id={mkYleiskatsausTestId(
            'onko-annettu-kayttovaroja-radio-ei'
          )}
          value={'false'}
          variant="small"
        >
          {t('ei')}
        </RadioButton>
        <RadioButton
          data-test-id={mkYleiskatsausTestId(
            'onko-annettu-kayttovaroja-radio-kylla'
          )}
          value={'true'}
          variant="small"
        >
          {t('kylla')}
        </RadioButton>
      </RadioButtonGroup>
      <Block mt={verticalMarginToken} />
      <FormTextArea
        data-test-id={kayttovaraKaytantoInputTestId}
        labelText={
          isAnnettuKayttovaroja
            ? t('mitenKayttovarojaOnHenkilolleAnnettu')
            : t('miksiEiKayttovaroja')
        }
        hintText={
          isAnnettuKayttovaroja ? t('ohjeKuvaileKayttovarojenKaytontoja') : ''
        }
        maxLength={1000}
        required
        value={kayttovaraKaytanto}
        validate={commonXssValidator(t)}
        updateValue={(value) => {
          updateAccountValues({ kayttovaraKaytanto: value })
        }}
      />
    </section>
  )
})

export default KayttovaratForm
