import { TFunction } from 'react-i18next'
import fp from 'lodash/fp'
import {
  YhteenvetoSubBlock,
  YhteenvetoSubBlockContent,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/yhteenveto/yhteenveto.util'
import { Paatostili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import {
  formatDateAsLocal,
  formatNumberWithComma,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'

// Todo: Translations
const getArvoTilikaudenAlussaString = (
  arvoTilikaudenAlussaEur: number | undefined,
  t: TFunction
): string | undefined =>
  arvoTilikaudenAlussaEur
    ? `${t('Arvo tilikauden alussa')}: ${formatNumberWithComma(
        arvoTilikaudenAlussaEur
      )} €`
    : undefined

// Todo: Translations
const getArvoTilikaudenLopussaString = (
  arvoTilikaudenLopussaEur: number | undefined,
  t: TFunction
): string | undefined =>
  arvoTilikaudenLopussaEur
    ? `${t('Arvo tilikauden lopussa')}: ${formatNumberWithComma(
        arvoTilikaudenLopussaEur
      )} €`
    : undefined

export const getPankkitalletuksetContent = (
  account: Paatostili,
  t: TFunction
): YhteenvetoSubBlock => {
  const {
    tilirivit: { varat },
  } = account

  const content: YhteenvetoSubBlockContent[] = varat.talletukset.map((tal) => {
    return {
      heading: `${t('tilityyppi_' + (tal.tyyppi || ''))}, ${
        tal.vakuusTaiTilinumero
      }, ${tal.myontajanNimi}`,
      contentRows: [
        getArvoTilikaudenAlussaString(tal.arvoTilikaudenAlussaEur, t),
        getArvoTilikaudenLopussaString(tal.arvoTilikaudenLopussaEur, t),
      ],
      attachments: tal.asiakirjat,
    }
  })

  return {
    heading: t('talletuksetHeading'),
    content,
  }
}

export const getVaratContent = (
  account: Paatostili,
  t: TFunction
): YhteenvetoSubBlock => {
  const {
    tilirivit: { varat },
  } = account

  const content: YhteenvetoSubBlockContent[] = varat.arvopaperit.map((ap) => {
    const content = [
      ap.kappalemaaraTilikaudenLopussa &&
        `${t('kappalemaaraTilikaudenLopussa')} ${
          ap.kappalemaaraTilikaudenLopussa
        } kpl`,
      getArvoTilikaudenAlussaString(ap.arvoTilikaudenAlussaEur, t),
      getArvoTilikaudenLopussaString(ap.arvoTilikaudenLopussaEur, t),
    ]

    return {
      heading: `${t('arvopaperityyppi_' + (ap.tyyppi || ''))}, ${
        ap.yhtionNimi
      }`,
      contentRows: fp.flow(fp.compact, fp.join(', '))(content),
      attachments: ap.asiakirjat,
    }
  })

  return {
    heading: t('arvopaperitHeading'),
    content,
  }
}

export const getOsuudetHenkiloyhtioissaContent = (
  account: Paatostili,
  t: TFunction
): YhteenvetoSubBlock => {
  const {
    tilirivit: { varat },
  } = account

  const content: YhteenvetoSubBlockContent[] =
    varat.osuudetHenkiloYhtiossaJaYrityksissa.map((os) => {
      return {
        heading: `${os.yhtionNimi}`,
        // TODO: Translations
        // TODO: Millä tavalla halutaan toimia, jos arvoja puuttuu? Pois koko kohta vai näytetään tyhjä tai viiva tms?
        contentRows: [
          `${t('osakkeidenMaara')} ${os.osakkeidenMaara} kpl, ${t(
            'Omistusosuus'
          )} ${os.omistusosuusProsentteina} %`,
          getArvoTilikaudenAlussaString(os.arvoTilikaudenAlussaEur, t),
          getArvoTilikaudenLopussaString(os.arvoTilikaudenLopussaEur, t),
        ],
        attachments: os.asiakirjat,
      }
    })

  return {
    heading: t('osuudetYhtioissaYrityksissaHeading'),
    content,
  }
}

export const getHuoneistotContent = (
  account: Paatostili,
  t: TFunction
): YhteenvetoSubBlock => {
  const {
    tilirivit: { varat },
  } = account

  const content: YhteenvetoSubBlockContent[] = varat.huoneistot.map((h) => {
    return {
      heading: `${h.yhtionNimi},  ${t('osakkeidenNumerot')} ${
        h.osakkeidenNumerot
      }`,
      // TODO: Translations
      contentRows: [
        `${t('kayttotarkoitus_' + (h.kayttotarkoitus || ''))}, ${t(
          'omistusosuus'
        )} ${h.omistusosuusProsentteina} %`,
        getArvoTilikaudenAlussaString(h.arvoTilikaudenAlussaEur, t),
        getArvoTilikaudenLopussaString(h.arvoTilikaudenLopussaEur, t),
      ],
      attachments: h.asiakirjat,
    }
  })

  return {
    heading: t('huoneistotHeading'),
    content,
  }
}

export const getKiinteistotContent = (
  account: Paatostili,
  t: TFunction
): YhteenvetoSubBlock => {
  const {
    tilirivit: { varat },
  } = account

  const content: YhteenvetoSubBlockContent[] = varat.kiinteistot.map((k) => {
    return {
      heading: `${k.kiinteistonNimi},  ${k.kiinteistonTunnus}`,
      // TODO: Translations
      contentRows: [
        `${t('kayttotarkoitus_' + (k.kayttotarkoitus || ''))}, ${t(
          'omistusosuus'
        )} ${k.omistusosuusProsentteina} %`,
        getArvoTilikaudenAlussaString(k.arvoTilikaudenAlussaEur, t),
        getArvoTilikaudenLopussaString(k.arvoTilikaudenLopussaEur, t),
      ],
      attachments: k.asiakirjat,
    }
  })

  return {
    heading: t('kiinteistotHeading'),
    content,
  }
}

export const getIrtaimistoContent = (
  account: Paatostili,
  t: TFunction
): YhteenvetoSubBlock => {
  const {
    tilirivit: { varat },
  } = account

  const content: YhteenvetoSubBlockContent[] = varat.irtaimistot.map((i) => {
    return {
      heading: `${i.nimi}`,
      contentRows: [
        getArvoTilikaudenAlussaString(i.arvoTilikaudenAlussaEur, t),
        getArvoTilikaudenLopussaString(i.arvoTilikaudenLopussaEur, t),
      ],
      attachments: i.asiakirjat,
    }
  })

  return {
    heading: t('irtaimistotHeading'),
    content,
  }
}

export const getSaatavatVaratContent = (
  account: Paatostili,
  t: TFunction
): YhteenvetoSubBlock => {
  const {
    tilirivit: { varat },
  } = account

  const content: YhteenvetoSubBlockContent[] = varat.saatavat.map((s) => {
    return {
      heading: `${t('velat')}`,
      contentRows: [
        `${s.velallisenEtunimi}  ${s.velallisenSukunimi}`,
        getArvoTilikaudenAlussaString(s.arvoTilikaudenAlussaEur, t),
        getArvoTilikaudenLopussaString(s.arvoTilikaudenLopussaEur, t),
      ],
      attachments: s.asiakirjat,
    }
  })

  return {
    heading: t('saatavatHeading'),
    content,
  }
}

export const getMuutVaratContent = (
  account: Paatostili,
  t: TFunction
): YhteenvetoSubBlock => {
  const {
    tilirivit: { varat },
  } = account

  const content: YhteenvetoSubBlockContent[] = varat.muutVarat.map((mv) => {
    return {
      heading: `${mv.nimi}`,
      contentRows: [
        getArvoTilikaudenAlussaString(mv.arvoTilikaudenAlussaEur, t),
        getArvoTilikaudenLopussaString(mv.arvoTilikaudenLopussaEur, t),
      ],
      attachments: mv.asiakirjat,
    }
  })

  return {
    heading: t('muutVaratHeading'),
    content,
  }
}

export const getPankkilainatContent = (
  account: Paatostili,
  t: TFunction
): YhteenvetoSubBlock => {
  const {
    tilirivit: { velat },
  } = account

  const content: YhteenvetoSubBlockContent[] = velat.pankkilainat.map((pl) => {
    return {
      heading: `${pl.lainanNumero}, ${pl.pankinNimi}`,
      contentRows: [
        getArvoTilikaudenAlussaString(pl.arvoTilikaudenAlussaEur, t),
        getArvoTilikaudenLopussaString(pl.arvoTilikaudenLopussaEur, t),
      ],
      attachments: pl.asiakirjat,
    }
  })

  return {
    heading: t('pankkilainatHeading'),
    content,
  }
}

export const getTakauksetContent = (
  account: Paatostili,
  t: TFunction
): YhteenvetoSubBlock => {
  const {
    tilirivit: { velat },
  } = account

  const content: YhteenvetoSubBlockContent[] = velat.takaukset.map((tak) => {
    return {
      heading: `${tak.velallisenEtunimi}, ${tak.velallisenSukunimi}`,
      contentRows: [
        `${t('vakuus')}: ${tak.vakuus}`,
        getArvoTilikaudenAlussaString(tak.arvoTilikaudenAlussaEur, t),
        getArvoTilikaudenLopussaString(tak.arvoTilikaudenLopussaEur, t),
      ],
      attachments: tak.asiakirjat,
    }
  })

  return {
    heading: t('takauksetHeading'),
    content,
  }
}

export const getMuutVelatContent = (
  account: Paatostili,
  t: TFunction
): YhteenvetoSubBlock => {
  const {
    tilirivit: { velat },
  } = account

  const content: YhteenvetoSubBlockContent[] = velat.muutVelat.map((mv) => {
    return {
      heading: `${t('velkatyyppi_' + (mv.tyyppi || ''))}`,
      contentRows: [
        getArvoTilikaudenAlussaString(mv.arvoTilikaudenAlussaEur, t),
        getArvoTilikaudenLopussaString(mv.arvoTilikaudenLopussaEur, t),
      ],
      attachments: mv.asiakirjat,
    }
  })

  return {
    heading: t('muutVelatHeading'),
    content,
  }
}

export const getKuolinpesatContent = (
  account: Paatostili,
  t: TFunction
): YhteenvetoSubBlock => {
  const {
    tilirivit: { varat },
  } = account

  const content: YhteenvetoSubBlockContent[] = varat.osuudetKuolinpesissa.map(
    (os) => {
      return {
        heading: fp.flow(
          fp.compact,
          fp.join(', ')
        )([
          `${os.vainajanEtunimi} ${os.vainajanSukunimi}`,
          os.vainajanKuolinpaiva && formatDateAsLocal(os.vainajanKuolinpaiva),
        ]),
        contentRows: [
          // TODO: Translation
          `${t('Osakkuuden osuus')} ${os.osakkuudenOsuusProsentteina} %`,
          getArvoTilikaudenAlussaString(os.arvoTilikaudenAlussaEur, t),
          getArvoTilikaudenLopussaString(os.arvoTilikaudenLopussaEur, t),
        ],
        attachments: os.asiakirjat,
      }
    }
  )

  return {
    // TODO: Translation
    heading: t('Kuolinpesa'),
    content,
  }
}
