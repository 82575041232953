/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useMemo } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkTulotMenotTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block, Heading, Paragraph } from 'suomifi-ui-components'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import { getPaatostiliTilirivit } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { FrontElinkustannus } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { FormListInputExpander } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import { commonEuroWithDecimalsOrUndefinedValidator } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/react-form-validation.util'
import { observable } from 'mobx'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import { AsiointiMenoTyyppiBySubcategory } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import FormDropdown from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDropdown'
import _ from 'lodash'
import {
  AsiointiLedgerAccountCategory,
  AsiointiLedgerAccountSubCategory,
} from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'
import { AsiointiLedgerAccountExpenseType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-expense-enum'

const ElinkustannuksetForm: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const tilirivit = getPaatostiliTilirivit()

  const mkNewElement = (): FrontElinkustannus =>
    observable({
      id: crypto.randomUUID(),
      tiliointinumero: '',
      tyyppi: undefined,
      yhteensa: undefined,
    })

  return (
    <section data-test-id={mkTulotMenotTestId('elinkustannukset-form')}>
      <Heading variant="h3">{t('elinkustannuksetHeading')}</Heading>
      <Block mt={verticalMarginToken} />
      <Paragraph>{t('ohjeIlmoitaElinkustannukset')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={ElinkustannusListItem}
        state={tilirivit.menot.elinkustannukset}
        asiointiLedgerAccountCategory={AsiointiLedgerAccountCategory.MENOT}
        dataTestIdPrefix={mkTulotMenotTestId('elinkustannukset-form')}
        ModalContentComponent={ElinkustannusModal}
        newEntry={mkNewElement}
        translationPrefix="elinkustannukset"
      />
    </section>
  )
}

const ElinkustannusModal: FC<FormModalProps<FrontElinkustannus>> = observer(
  ({ createDataTestId, entry, setEntry }) => {
    const [t] = useTranslation()

    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'

    const elinkustannustyypit = useMemo(
      () =>
        _.sortBy(
          Object.values(
            AsiointiMenoTyyppiBySubcategory(
              AsiointiLedgerAccountSubCategory.ELINKUSTANNUKSET
            )
          ).map((value) => ({
            name: t(`expenseType_${value}`),
            value,
          })),
          'name'
        ),
      [t]
    )

    return (
      <div>
        <FormDropdown
          labelText={t('elinkustannuksenTyyppi')}
          data-test-id={createDataTestId('modal-tyyppi')}
          value={entry.tyyppi}
          items={elinkustannustyypit}
          required
          updateValue={(value) => {
            setEntry({
              ...entry,
              tyyppi: value as AsiointiLedgerAccountExpenseType,
            })
          }}
        />
        <Block mt={verticalMarginToken} />

        <FormNumberInput
          data-test-id={createDataTestId('modal-menotYhteensa')}
          labelText={t('menotTilikaudenAikanaYhteensaEuroina')}
          digitMode="two"
          required
          value={entry.yhteensa}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) =>
            setEntry({
              ...entry,
              yhteensa: value,
            })
          }
        />
      </div>
    )
  }
)

const ElinkustannusListItem: FC<FormElementProps<FrontElinkustannus>> =
  observer(({ createDataTestId, entry, onEdit, onRemove }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'

    const yhteensaEur = `${formatNumberWithComma(entry.yhteensa!)} €`

    return (
      <FormListInputExpander
        heading={t(`expenseType_${entry.tyyppi}`)}
        headingRight={yhteensaEur}
        createDataTestId={createDataTestId}
        onEdit={onEdit}
        onRemove={onRemove}
        showSubHeadingWhileOpen
      >
        <Block mt={verticalMarginToken} />
      </FormListInputExpander>
    )
  })

export default observer(ElinkustannuksetForm)
