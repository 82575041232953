import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Block, Heading, Paragraph, Text } from 'suomifi-ui-components'
import { mkYleiskatsausTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { FormListInputExpander } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import { getPaatostili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonDateValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/react-form-validation.util'
import FormDateInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDateInput'
import { FrontVakuutukset } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { observable } from 'mobx'
import {
  formatDateAsLocal,
  parseLocalDate,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import { TiliAsiointiAsiakirjatyyppi } from 'tilintarkastus-common/src/vtj/types/asiointi-account-data.type'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'

const VakuutuksetForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const account = getPaatostili()

  return (
    <section data-test-id={mkYleiskatsausTestId('vakuutukset-form')}>
      <Heading variant="h3">{t('vakuutuksetHeading')}</Heading>
      <Block mt={verticalMarginToken} />
      <Paragraph>{t('ohjeIlmoitaVakuutukset')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={VakuutuksetListItemContent}
        state={account.vakuutukset}
        dataTestIdPrefix={mkYleiskatsausTestId('vakuutukset-form')}
        ModalContentComponent={VakuutuksetModalContent}
        newEntry={() =>
          observable({
            id: crypto.randomUUID(),
            vakuutustyyppi: '',
            toimittaja: '',
            beginDate: undefined,
            endDate: undefined,
            asiakirjat: [],
          } as FrontVakuutukset)
        }
        translationPrefix="vakuutukset"
      />
    </section>
  )
})

export default VakuutuksetForm

const VakuutuksetModalContent = ({
  entry,
  setEntry,
  createDataTestId,
}: FormModalProps<FrontVakuutukset>) => {
  const [t] = useTranslation()

  return (
    <>
      <FormTextInput
        data-test-id={createDataTestId('modal-vakuutustyyppi')}
        labelText={t('vakuutustyyppi')}
        value={entry.vakuutustyyppi}
        required
        validate={commonXssValidator(t)}
        updateValue={(value) => setEntry({ ...entry, vakuutustyyppi: value })}
      />

      <FormTextInput
        data-test-id={createDataTestId('modal-toimittaja')}
        labelText={t('toimittaja')}
        value={entry.toimittaja}
        required
        validate={commonXssValidator(t)}
        updateValue={(value) => setEntry({ ...entry, toimittaja: value })}
      />

      <FormDateInput
        labelText={t('alkamispaivamaara')}
        data-test-id={createDataTestId('modal-alkamispaivamaara')}
        value={
          entry.beginDate ? formatDateAsLocal(entry.beginDate) : entry.beginDate
        }
        updateValue={(value) => {
          setEntry({
            ...entry,
            beginDate: value ? parseLocalDate(value) : value,
          })
        }}
        validate={commonDateValidator(t)}
      />
      <FormDateInput
        labelText={t('paattymispaivamaara')}
        data-test-id={createDataTestId('modal-paattymispaivamaara')}
        value={entry.endDate ? formatDateAsLocal(entry.endDate) : entry.endDate}
        updateValue={(value) => {
          setEntry({ ...entry, endDate: value ? parseLocalDate(value) : value })
        }}
        validate={commonDateValidator(t)}
      />
      <FormAttachmentFileBox
        observableAttachments={entry.asiakirjat}
        asiakirjaTypeId={TiliAsiointiAsiakirjatyyppi.MUU_LIITE}
        data-test-id={createDataTestId('modal-asiakirja')}
        title={t('asiakirjat')}
        text={t('ohjeVakuutusAsiakirjat')}
      />
    </>
  )
}

const VakuutuksetListItemContent = ({
  entry,
  createDataTestId,
  onEdit,
  onRemove,
}: FormElementProps<FrontVakuutukset>) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  return (
    <FormListInputExpander
      heading={entry.vakuutustyyppi}
      headingRight={
        entry.endDate && entry.beginDate
          ? `${formatDateAsLocal(entry.beginDate)} - ${formatDateAsLocal(
              entry.endDate
            )}`
          : t('toistaiseksiVoimassaoleva')
      }
      createDataTestId={createDataTestId}
      onEdit={onEdit}
      onRemove={onRemove}
    >
      <Heading variant="h5">{t('toimittaja')}</Heading>
      <Text>{entry.toimittaja}</Text>
      <Block mt={verticalMarginToken} />
      {entry.asiakirjat.length > 0 && (
        <>
          <Block mt={verticalMarginToken} />
          <Heading variant="h5">{t('sopimusasiakirja')}</Heading>
          <AttachmentList attachments={entry.asiakirjat} />
          <Block mt={verticalMarginToken} />
        </>
      )}
    </FormListInputExpander>
  )
}
