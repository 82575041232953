import { executeBackendClient } from 'holhous-common/src/vtj/ui/api/microfrontend-backend-api-call'
import type { EvtvVaatimus } from 'edunvalvonta-asiointi/src/vtj/asiointi/evtv-asiointi/evtv-api.type'

export const getEvtvData = async (): Promise<EvtvVaatimus[]> => {
  const apiResponse = await executeBackendClient((client) =>
    client.get('/evtv-asiointi/api/v1/vaatimukset')
  )

  if (apiResponse.isOk) {
    return apiResponse.data
  } else {
    throw new Error(
      'Failed loading Evtv data: ' + JSON.stringify(apiResponse.errorData)
    )
  }
}
