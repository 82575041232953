import { ApiResponse } from 'holhous-common/src/vtj/ui/api/microfrontend-backend-api-call'
import { runTilintarkastusStoreFlow } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { TilintarkastusApiClient } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/api/tilintarkastusAsiointiClient'

export const submitPaatostili = async (): Promise<ApiResponse<unknown>> =>
  runTilintarkastusStoreFlow(function* (store) {
    store.submitStatus = 'in-progress'
    store.submitError = undefined
    const response: ApiResponse<unknown> =
      yield TilintarkastusApiClient.postPaatostili(store.lomake)
    if (response.isOk) {
      store.submitStatus = 'submitted'
    } else {
      store.submitStatus = 'none'
      store.submitError = response.errorData ?? {} // On network error there is no error data
    }
    return response
  })

export const submitOmaisuusluettelo = async (): Promise<ApiResponse<unknown>> =>
  runTilintarkastusStoreFlow(function* (store) {
    store.submitStatus = 'in-progress'
    store.submitError = undefined
    const response: ApiResponse<unknown> =
      yield TilintarkastusApiClient.postOmaisuusluettelo(store.lomake)
    if (response.isOk) {
      store.submitStatus = 'submitted'
    } else {
      store.submitStatus = 'none'
      store.submitError = response.errorData ?? {} // On network error there is no error data
    }
    return response
  })
