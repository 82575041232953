import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import {
  Block,
  Heading,
  Paragraph,
  RadioButton,
  RadioButtonGroup,
} from 'suomifi-ui-components'
import { mkYleiskatsausTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { commonXssValidator } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/react-form-validation.util'
import {
  updateAccountValues,
  getPaatostili,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import FormTextArea from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextArea'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import { TiliAsiointiAsiakirjatyyppi } from 'tilintarkastus-common/src/vtj/types/asiointi-account-data.type'

const HallintaoikeudetForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const { isHallintaoikeuksia, mitenHallintaoikeuksistaOnSovittu } =
    getPaatostili()

  return (
    <section data-test-id={mkYleiskatsausTestId('hallintaoikeudet-container')}>
      <Heading variant="h3">{t('hallintaoikeudet')}</Heading>
      <Block mt={verticalMarginToken} />
      <Paragraph>{t('ohjeIlmoitaMitaHallintaoikeuksia')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <RadioButtonGroup
        data-test-id={mkYleiskatsausTestId(
          'onko-hallintaoikeuksia-radio-group'
        )}
        value={Boolean(isHallintaoikeuksia).toString()}
        labelText={t('onkoOllutSovittujaHallintaoikeuksia')}
        name={mkYleiskatsausTestId('onko-hallintaoikeuksia-radio-group')}
        onChange={(value) =>
          updateAccountValues({ isHallintaoikeuksia: value === 'true' })
        }
      >
        <RadioButton
          data-test-id={mkYleiskatsausTestId('onko-hallintaoikeuksia-radio-ei')}
          value={'false'}
          variant="small"
        >
          {t('ei')}
        </RadioButton>
        <RadioButton
          data-test-id={mkYleiskatsausTestId(
            'onko-hallintaoikeuksia-radio-kylla'
          )}
          value={'true'}
          variant="small"
        >
          {t('kylla')}
        </RadioButton>
      </RadioButtonGroup>

      {isHallintaoikeuksia && (
        <>
          <Block mt={verticalMarginToken} />
          <FormTextArea
            data-test-id={mkYleiskatsausTestId(
              'miten-hallintaoikeuksista-on-sovittu'
            )}
            labelText={t('mitenHallintaoikeuksistaOnSovittu')}
            maxLength={1000}
            required
            value={mitenHallintaoikeuksistaOnSovittu}
            validate={commonXssValidator(t)}
            updateValue={(value) => {
              updateAccountValues({
                mitenHallintaoikeuksistaOnSovittu: value,
              })
            }}
          />
          <Block mt={verticalMarginToken} />
          <FormAttachmentFileBox
            observableAttachments={getPaatostili().hallintaoikeusSopimukset}
            asiakirjaTypeId={TiliAsiointiAsiakirjatyyppi.MUU_LIITE}
            data-test-id={mkYleiskatsausTestId('hallintaoikeus-sopimukset')}
            title={t('sopimusHallintaoikeudesta')}
          />
        </>
      )}
    </section>
  )
})

export default HallintaoikeudetForm
