import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import {
  AsiaPersonRole,
  AsiaPersonType,
  RatkaisuType,
  AsiaStatus,
  AsiaHandlerRole,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { OrganizationType } from 'asia-common/src/vtj/koodisto/organization-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import {
  InternalToimenpideType,
  ToimenpideHuomioPhase,
  ToimenpideType,
} from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import { ViestintaType } from 'asia-common/src/vtj/koodisto/viestinta-enums'
import { Retention, RetentionReason } from 'asia-common/src/vtj/koodisto/retention-enums'
import {
  PersonalDataClass,
  PublicityClass,
  SecurityClass,
  SecurityPeriod,
  SecurityReason,
} from 'asia-common/src/vtj/koodisto/restriction-enums'
import { Viranomainen } from 'asia-common/src/vtj/koodisto/viranomainen-enums'

export const vuositiliMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    {
      asiakirjaTypeId: AsiakirjaType.ARVIO_OMAISUUDESTA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '4d01b5a0-78d0-4098-9fdd-5414d2f96ba7',
      toivoAsiakirjaTypeIds: ['4d01b5a0-78d0-4098-9fdd-5414d2f96ba7'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.LAAKARINLAUSUNTO,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '23b3de31-380a-4097-97bc-92b060b1b659',
      toivoAsiakirjaTypeIds: ['23b3de31-380a-4097-97bc-92b060b1b659'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_LIITE,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        ],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23, SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: 'ab7dbe71-81dc-4895-a133-4e4940cbd77d',
      toivoAsiakirjaTypeIds: ['ab7dbe71-81dc-4895-a133-4e4940cbd77d'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_VIRASTON_LAATIMA_ASIAKIRJA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        ],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23, SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      defaultToivoAsiakirjaTypeId: '20dc162d-7ca9-4f18-9d82-d60de804b2cd',
      toivoAsiakirjaTypeIds: ['20dc162d-7ca9-4f18-9d82-d60de804b2cd'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.PERUKIRJA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: 'f4593889-29d3-4edc-97c7-7d4f60cfc600',
      toivoAsiakirjaTypeIds: ['f4593889-29d3-4edc-97c7-7d4f60cfc600'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.SALDOTODISTUS_TAI_OMAISUUSOTE,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '3871d21c-3fde-4ad0-bf6f-454f786dea8f',
      toivoAsiakirjaTypeIds: ['3871d21c-3fde-4ad0-bf6f-454f786dea8f'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYS_OMAISUUDEN_MYYNNISTA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '49fadadb-4086-476a-8331-982d29688ff7',
      toivoAsiakirjaTypeIds: ['49fadadb-4086-476a-8331-982d29688ff7'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.SOPIMUS,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '2d501312-c0ad-483a-afc2-68e385614ed0',
      toivoAsiakirjaTypeIds: ['2d501312-c0ad-483a-afc2-68e385614ed0'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.TESTAMENTTI_JA_TIEDOKSIANTOTODISTUS,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '8ed7215e-a0fe-4c68-b915-ba9269f5a520',
      toivoAsiakirjaTypeIds: ['8ed7215e-a0fe-4c68-b915-ba9269f5a520'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.TILIOTE,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '68e49240-fb01-45a8-994e-e8a58976ca00',
      toivoAsiakirjaTypeIds: ['68e49240-fb01-45a8-994e-e8a58976ca00'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.VUOSITILI_PAAKIRJA_PALKKIOLASKELMA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        ],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23, SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_0_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: 'a328b4e4-447d-4d09-a242-42d7f66c7096',
      toivoAsiakirjaTypeIds: ['a328b4e4-447d-4d09-a242-42d7f66c7096'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.VALTAKIRJA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.JULKINEN,
        publicityClassIds: [PublicityClass.JULKINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: 'bee3d953-67bf-4f51-8ba5-9e0a2790ba35',
      toivoAsiakirjaTypeIds: ['bee3d953-67bf-4f51-8ba5-9e0a2790ba35'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.EDUNVALVONTASUUNNITELMA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        ],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23, SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: 'a612b726-a7fa-4941-adce-e0fbb8c42e1d',
      toivoAsiakirjaTypeIds: ['a612b726-a7fa-4941-adce-e0fbb8c42e1d'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.TOSITE,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '2ca9de56-5aa0-4788-b33d-9dbbffa4aeec',
      toivoAsiakirjaTypeIds: ['2ca9de56-5aa0-4788-b33d-9dbbffa4aeec'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.PALKKIO_JA_KULULASKELMA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '51a26fd9-c63d-4012-980b-b9ad6198f092',
      toivoAsiakirjaTypeIds: ['51a26fd9-c63d-4012-980b-b9ad6198f092'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.TAYDENNYSPYYNTO,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        ],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23, SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      defaultToivoAsiakirjaTypeId: '94a63e2b-82cb-4123-9402-14dae87cc2e9',
      toivoAsiakirjaTypeIds: ['94a63e2b-82cb-4123-9402-14dae87cc2e9'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.VASTAUS_TAYDENNYS_TAI_SELVITYSPYYNTOON,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        ],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23, SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
        defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
        retentionReasonIds: [RetentionReason.TOIMINTATAPA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '5748c939-61ad-4529-a5c7-383aecad5b7a',
      toivoAsiakirjaTypeIds: ['5748c939-61ad-4529-a5c7-383aecad5b7a'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.TARKASTETTU_VUOSITILI_JA_PALKKIOLASKELMA_TILIN_TARKASTUSMERKINNAT,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        ],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23, SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
        defaultRetentionReasonId: RetentionReason.KANSALLISARKISTON_PAATOS,
        retentionReasonIds: [RetentionReason.KANSALLISARKISTON_PAATOS],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      defaultToivoAsiakirjaTypeId: 'a86ac22f-f5e1-4a20-85ae-a335ba47a8e7',
      toivoAsiakirjaTypeIds: ['a86ac22f-f5e1-4a20-85ae-a335ba47a8e7'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.VAATIMUS_VIRHEEN_KORJAAMISESTA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
        defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
        securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        ],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23, SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
        defaultViranomainenId: Viranomainen.DVV,
        viranomainenIds: [Viranomainen.DVV],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
        defaultRetentionReasonId: RetentionReason.KANSALLISARKISTON_PAATOS,
        retentionReasonIds: [RetentionReason.KANSALLISARKISTON_PAATOS],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '349563f8-62f8-44c7-90ed-2a98a27f6d19',
      toivoAsiakirjaTypeIds: ['349563f8-62f8-44c7-90ed-2a98a27f6d19'],
    },
  ],
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KIIREELLINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KIIREHDITTY,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.MAARAAIKA,
      postDueDateHuomioTypeId: HuomioType.MAARAAIKA_YLITTYNYT,
    },
    {
      huomioTypeId: HuomioType.MAARAAIKA_YLITTYNYT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_KUULEMISTA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.KUULEMISEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.KUULEMISEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_TAYDENNYSTA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [AsiaPersonRole.HAKIJA],
    organizationTypeIds: [
      OrganizationType.OSTOPALVELU_EDUNVALVONTATOIMISTO,
      OrganizationType.VALTION_EDUNVALVONTATOIMISTO,
    ],
  },
  personRoleMetadata: [AsiaPersonRole.ASIAKAS, AsiaPersonRole.HAKIJA, AsiaPersonRole.HUOLLETTAVA],
  personTypeMetadata: [AsiaPersonType.IHMINEN, AsiaPersonType.ORGANISAATIO],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.TARKASTETTU,
    manualRatkaisuTypeIds: [RatkaisuType.TARKASTETTU, RatkaisuType.PALAUTTETTU_TARKASTAMATTOMANA],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: true,
    mandatorySpecifier: false,
    specifierTypeIds: [],
  },
  toimenpideMetadata: [
    {
      toimenpideTypeId: ToimenpideType.TAYDENTAMINEN,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [ViestintaType.EDVARD, ViestintaType.MANUAALINEN],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.TAYDENNYSPYYNTO],
      prioritizedSaapunutAsiakirjaTypeIds: [AsiakirjaType.VASTAUS_TAYDENNYS_TAI_SELVITYSPYYNTOON],
      huomioMetadata: {
        [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_TAYDENNYSTA,
        [ToimenpideHuomioPhase.SAAPUNUT]: HuomioType.TAYDENNYS_SAAPUNUT,
        [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT,
        showInHuomioFilter: true,
      },
    },
    // Vuositili probably don't have tiedoksianto, only paatostili
    {
      toimenpideTypeId: ToimenpideType.TIEDOKSIANTO,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [ViestintaType.TOIVO_POSTITUS],
      prioritizedLaadittuAsiakirjaTypeIds: [],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      huomioMetadata: null,
    },
  ],
  vireilletulokanavaMetadata: [Vireilletulokanava.EDVARD, Vireilletulokanava.ITSEPALVELU, Vireilletulokanava.TOIVO],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.KASITTELYSSA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.KASITTELYSSA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
  restrictionMetadata: {
    defaultPublicityClassId: PublicityClass.SALAINEN,
    publicityClassIds: [PublicityClass.SALAINEN],
    defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
    securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
    defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA,
    personalDataClassIds: [PersonalDataClass.SISALTAA_ARKALUONTOISIA_HENKILOTIETOJA],
    defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
    securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
    defaultSecurityReasonIds: [
      SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
      SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
    ],
    securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23, SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
    defaultViranomainenId: Viranomainen.DVV,
    viranomainenIds: [Viranomainen.DVV],
  },
  retentionMetadata: {
    defaultRetentionId: Retention.PYSYVA,
    retentionIds: [Retention.PYSYVA, Retention.SAILYTYSAIKA_50_VUOTTA],
    defaultRetentionReasonId: RetentionReason.KANSALLISARKISTON_PAATOS,
    retentionReasonIds: [RetentionReason.KANSALLISARKISTON_PAATOS, RetentionReason.TOIMINTATAPA],
  },
}
