import { useLocation, useSearchParams } from 'react-router-dom'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/holhous-asiointi-user-store'
import QueryParam from 'common/src/vtj/QueryParam.enum'
import { SESSION_EXPIRED_SEARCH_PARAM } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/constants'
const useGetLoginRedirectToParam = (): string => {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  searchParams.delete(QueryParam.SUOMIFI_AUTH_FAILURE)
  searchParams.delete(QueryParam.SUOMIFI_AUTH_CANCELED)
  searchParams.delete(SESSION_EXPIRED_SEARCH_PARAM)
  let search = searchParams.toString()
  if (search) {
    search = '?' + search
  }

  return [location.pathname, search, location.hash].join('')
}

export const useGetLoginUri = (): string => {
  const redirectTo = useGetLoginRedirectToParam()
  return `/auth/suomifi/login?RelayState=${encodeURIComponent(redirectTo)}`
}

export const useRedirectUnauthenticatedToLogIn = (): boolean => {
  const userStore = useAsiointiUserStore()
  const loginUri = useGetLoginUri()

  if (!userStore.user) {
    window.location.href = loginUri
    return true
  } else {
    return false
  }
}

export const useGetLogoutUri = (): string => {
  return `/auth/suomifi/logout`
}
