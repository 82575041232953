import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Block,
  ExternalLink,
  Heading,
  IconSupport,
  Paragraph,
  suomifiDesignTokens,
  Text,
} from 'suomifi-ui-components'
import {
  getOverviewAdultHelpUrl,
  getOverviewEvTasksHelpUrl,
  getOverviewMinorHelpUrl,
  getOverviewWhenLupaHelpUrl,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/links'
import styled from 'styled-components'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/holhous-asiointi-user-store'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import { ColoredInlineAlert } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/common/ColoredInlineAlert'
import { observer } from 'mobx-react'

export const HelpSection: React.FC = observer(() => {
  const [t] = useTranslation()
  const userStore = useAsiointiUserStore()
  const isTablet = useDeviceContext().tablet

  return (
    <HelpInlineAlert
      status="neutral"
      ariaLiveMode="off"
      smallScreen={!isTablet}
    >
      <HelpContent>
        <TarvitsetkoApua>
          <SupportIcon />
          <Heading variant="h3" as="h2">
            {t('overviewHelpHeader')}
          </Heading>
          <Block mt="m" />
          <Paragraph mb="m">
            <Text>{t('overviewHelpDescription')}</Text>
          </Paragraph>
          <Paragraph mb="xs">
            <ExternalLink
              href={getOverviewAdultHelpUrl(userStore.lang)}
              variant="accent"
              toNewWindow
              labelNewWindow={t('avautuuUuteenValilehteen')}
            >
              {t('overviewHelpAdultLinkTitle')}
            </ExternalLink>
          </Paragraph>
          <Paragraph mb="xs">
            <ExternalLink
              href={getOverviewMinorHelpUrl(userStore.lang)}
              variant="accent"
              toNewWindow
              labelNewWindow={t('avautuuUuteenValilehteen')}
            >
              {t('overviewHelpMinorLinkTitle')}
            </ExternalLink>
          </Paragraph>
          <Paragraph mb="xs">
            <ExternalLink
              href={getOverviewEvTasksHelpUrl(userStore.lang)}
              variant="accent"
              toNewWindow
              labelNewWindow={t('avautuuUuteenValilehteen')}
            >
              {t('overviewHelpTasksLinkTitle')}
            </ExternalLink>
          </Paragraph>
          <Paragraph>
            <ExternalLink
              href={getOverviewWhenLupaHelpUrl(userStore.lang)}
              variant="accent"
              toNewWindow
              labelNewWindow={t('avautuuUuteenValilehteen')}
            >
              {t('overviewHelpWhenLupaLinkTitle')}
            </ExternalLink>
          </Paragraph>
        </TarvitsetkoApua>
        <Puhelinnumerot>
          <Paragraph mb="m">
            <Text variant="bold">{t('overviewHelpSupportNumbersHeader')}</Text>
          </Paragraph>

          <Paragraph mb="m">
            <Text>{t('overviewHelpSupportNumbersDescription')}</Text>
          </Paragraph>

          <Paragraph mb="m">
            <Text variant="bold">
              {t('overviewHelpSupportNumbersFinishHeader')}
            </Text>
            <br />
            <Text>0295 536 256</Text>
          </Paragraph>

          <Paragraph>
            <Text variant="bold">
              {t('overviewHelpSupportNumbersSwedishHeader')}
            </Text>
            <br />
            <Text>0295 536 305</Text>
          </Paragraph>
        </Puhelinnumerot>
      </HelpContent>
    </HelpInlineAlert>
  )
})

const SupportIcon = styled(IconSupport).attrs(() => ({
  highlightColor: dvvGreen,
}))`
  height: 70px;
  width: 70px;
`

const HelpContent = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and ${device.tablet} {
    flex-direction: row;
  }
`

const TarvitsetkoApua = styled.div`
  flex: 1;
  padding-bottom: ${suomifiDesignTokens.spacing.xl};

  @media screen and ${device.tablet} {
    padding-bottom: 0;
    padding-right: ${suomifiDesignTokens.spacing.xl};
  }
`

const Puhelinnumerot = styled.div`
  flex: 1;
  padding-top: ${suomifiDesignTokens.spacing.xl};
  border-top: 1px solid #d9d9d9;

  @media screen and ${device.tablet} {
    padding-top: 0;
    border-top: none;
    padding-left: ${suomifiDesignTokens.spacing.xl};
    border-left: 1px solid #d9d9d9;
  }
`

const dvvGreen = '#007770'
const HelpInlineAlert = ColoredInlineAlert(dvvGreen)
