import React from 'react'
import { i18n } from 'i18next'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import {
  enableStaticRendering,
  observer,
  Provider as MobxProvider,
} from 'mobx-react'
import { ErrorBoundary } from 'react-error-boundary'
import styled from 'styled-components'
import RobotOnMountDetector from 'common/src/vtj/test/RobotOnMountDetector'
import { SettingsData } from 'common/src/vtj/settings/settings-api'
import { isInsideBrowser } from 'common/src/vtj/browser/browser-util'
import settingsSingleton from 'asia-common/src/vtj/settings'
import { AsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/holhous-asiointi-user-store'
import { GlobalStyle } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/global-style'
import HolhousAsiointiTheme from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/HolhousAsiointiTheme'
import Header from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/header/Header'
import DeviceContextProvider from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/DeviceContextProvider'
import SessionHandling from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/session/SessionHandling'
import AsiointiAppRoutes from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/AsiointiAppRoutes'
import CookieBanner from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/cookies/CookieBanner'
import UnexpectedError from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/UnexpectedError'

enableStaticRendering(!isInsideBrowser())

const AsiointiApp: React.FC<{
  asiointiUserStore: AsiointiUserStore
  location?: string
  publicSettingsData: SettingsData
  isTestAuthenticatorEnabled: boolean
  i18n: i18n
  isCookieBannerDismissed?: boolean
}> = observer(
  ({
    asiointiUserStore,
    location,
    publicSettingsData,
    isTestAuthenticatorEnabled,
    i18n,
    isCookieBannerDismissed,
  }) => {
    const Router = getRouter()
    settingsSingleton.setAll(publicSettingsData)

    // The error boundary only catches errors from the main app, not from microfrontends.
    // They must implement their own error boundaries. A boundary is provided to catch single-spa
    // mount errors.
    return (
      <>
        <GlobalStyle />
        <HolhousAsiointiTheme>
          <I18nextProvider i18n={i18n}>
            <ErrorBoundary FallbackComponent={UnexpectedError}>
              <DeviceContextProvider>
                <MobxProvider asiointiUserStore={asiointiUserStore}>
                  <Router location={location}>
                    <LayoutGrid>
                      <CookieBanner isDismissed={isCookieBannerDismissed} />
                      <Header
                        isTestAuthenticatorEnabled={isTestAuthenticatorEnabled}
                      />
                      <SessionHandling
                        aboutToExpireTimeLeftMs={
                          settingsSingleton.get(
                            'asiointiServer.session.ui.aboutToExpireTimeLeftMs'
                          ) as number
                        }
                        renewalThrottleTimeMs={
                          settingsSingleton.get(
                            'asiointiServer.session.ui.sessionRenewalThrottleTimeMs'
                          ) as number
                        }
                      />
                      <AsiointiAppRoutes />
                    </LayoutGrid>
                  </Router>
                  <RobotOnMountDetector />
                </MobxProvider>
              </DeviceContextProvider>
            </ErrorBoundary>
          </I18nextProvider>
        </HolhousAsiointiTheme>
      </>
    )
  }
)

export default AsiointiApp

type Router = (props: {
  basename?: string
  children?: React.ReactNode
  location?: Partial<Location> | string
}) => React.ReactElement

const getRouter = (): Router => {
  if (isInsideBrowser()) {
    return BrowserRouter
  } else {
    // Prevent bundling server-side code into the browser bundle.
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const ReactDomServer = require('react-router-dom/server') // Ignored at bundling time by using webpack.IgnorePlugin
    return ReactDomServer.StaticRouter
  }
}

const LayoutGrid = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-areas:
    'banner'
    'header'
    'content';
  grid-template-rows: auto auto 1fr;
`
