import styled from 'styled-components'
import { suomifiDesignTokens } from 'suomifi-ui-components'
import { AsiakirjaLiite } from 'tilintarkastus-common/src/vtj/types/asiointi-account-data.type'

export const YhteenvetoSubBlocks = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${suomifiDesignTokens.spacing.s};
`

export const YhteenvetoSubBlockContents = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${suomifiDesignTokens.spacing.s};
`

export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${suomifiDesignTokens.spacing.xxs};
`

export interface YhteenvetoSubBlockContent {
  heading?: string
  contentRows?: string | Array<string | undefined>
  attachments?: AsiakirjaLiite[]
}

export interface YhteenvetoSubBlock {
  heading: string
  content: YhteenvetoSubBlockContent[]
}
