export type ChildTestId = string | number

export const mkFrameTestId = (...ids: ChildTestId[]): string => {
  return `asiointi-frame-${ids.join('-')}`
}

export const mkHeaderTestId = (...ids: ChildTestId[]): string =>
  mkFrameTestId('header', ...ids)

export const mkLandingTestId = (...ids: ChildTestId[]): string =>
  mkFrameTestId('landing', ...ids)

export const mkSessionTestId = (...ids: ChildTestId[]): string =>
  mkFrameTestId('istunto', ...ids)

export const mkTiliSectionId = (...ids: ChildTestId[]): string =>
  `asiointi-tili-${ids.join('-')}`
