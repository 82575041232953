import React from 'react'
import { observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'
import { Block, Heading, Paragraph, Text } from 'suomifi-ui-components'
import { useTranslation } from 'react-i18next'
import { useAutoFocus } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/auto-focus'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import {
  PAATOSTILI_STEPS,
  TilintarkastusRoutePath,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { ChildTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'

const PageStepHeading: React.FC<{
  heading: string
  steps: TilintarkastusRoutePath[]
  createDataTestId: (...arg: ChildTestId[]) => string
}> = observer(({ heading, steps, createDataTestId }) => {
  const [t] = useTranslation()
  const { pathname } = useLocation()
  const isTablet = useDeviceContext().tablet
  const step = pathname
  const currentStep = steps.indexOf(step as TilintarkastusRoutePath) + 1
  const numberOfSteps = PAATOSTILI_STEPS.length

  const ref = useAutoFocus<HTMLDivElement>()

  return (
    <Block
      id={'current-step-container'}
      tabIndex={-1}
      ref={ref}
      data-test-id={createDataTestId('current-step-container')}
    >
      <Paragraph mb={isTablet ? 'm' : 'xs'}>
        <Text
          smallScreen
          data-test-id={createDataTestId('current-step-display')}
        >
          {t('vaihe')} {currentStep}/{numberOfSteps}
        </Text>
      </Paragraph>
      <Heading variant="h2">{heading}</Heading>
    </Block>
  )
})

export default PageStepHeading
