import { observer } from 'mobx-react'
import React, { FC, useState } from 'react'
import styled from 'styled-components'
import {
  Block,
  Button,
  IconRemove,
  suomifiDesignTokens,
  Text,
  TextInput,
} from 'suomifi-ui-components'
import { ChildTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { useTranslation } from 'react-i18next'
import { IconPlus } from 'suomifi-icons'
import { useFormContext, useFormState } from 'react-hook-form'
import {
  commonXssValidator,
  createOnChangeEvent,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/react-form-validation.util'

export type SimpleListEntry = {
  value: string
}

export type SimpleListUniqueEntry = SimpleListEntry & {
  id: string
}

export interface FormSimpleListInputProps {
  dataTestIdPrefix: string
  heading: string
  description: string
  state: SimpleListEntry[]
}

let k = 0
const getKey = () => k++

const mkEmptyEntry = (): SimpleListUniqueEntry => {
  return { value: '', id: getKey().toString() }
}

const toListEntry = (entries: SimpleListEntry[]): SimpleListUniqueEntry[] => {
  return entries.map((e) => {
    return {
      id: getKey().toString(),
      value: e.value,
    }
  })
}

const FormSimpleListInput: FC<FormSimpleListInputProps> = observer(
  ({ dataTestIdPrefix, description, heading, state }) => {
    const mkTestId = (...arg: ChildTestId[]) =>
      `${[dataTestIdPrefix, ...arg].join('-')}`
    // Luodaan ensimmäinen rivi, jos listaa ei ole olemassa tai on tyhjä
    const [list, setList] = useState(
      state.length === 0 ? [mkEmptyEntry()] : toListEntry(state)
    )

    const { t } = useTranslation()
    const { register, unregister } = useFormContext()
    const { errors } = useFormState()
    const maxLength = 200

    const items = list.map(({ value, id }, index) => {
      const testId = mkTestId(`list-item-${id}`)
      const { onChange, ref, name } = register(testId, {
        required: {
          value: true,
          message: t('vaadittuOmaisuus'),
        },
        validate: commonXssValidator(t),
        maxLength: {
          value: maxLength,
          message: t('virheTekstinPituusYlittaaMerkkimaaran', {
            maxLength,
          }),
        },
      })

      return (
        <ListItem key={testId}>
          <StyledTextInput
            required
            fullWidth
            data-test-id={mkTestId(`list-item-input-${index}`)}
            labelText={'s'}
            status={errors[testId] ? 'error' : 'default'}
            data-test-error-message={(errors[testId]?.message || '') as string}
            defaultValue={value}
            statusText={(errors[testId]?.message || '') as string}
            ref={ref}
            name={name}
            aria-live="off"
            ariaCharactersRemainingText={(amount) =>
              `${maxLength - amount} / ${maxLength}`
            }
            ariaCharactersExceededText={(_amount) =>
              t('virheTekstinPituusYlittaaMerkkimaaran', {
                maxLength,
              })
            }
            onChange={(value) => onChange(createOnChangeEvent(value, testId))}
          />
          <ButtonContainer>
            <StyledRemoveButton
              data-test-id={mkTestId(`list-remove-item-${index}`)}
              icon={<IconRemove />}
              variant="secondaryNoBorder"
              onClick={() => {
                unregister(testId)
                // Yhden elementin tapauksessa nollataan tietue, ei poisteta
                setList((prev) => {
                  let result
                  if (prev.length === 1 && index === 0) {
                    result = [mkEmptyEntry()]
                  } else {
                    result = prev.filter((p) => p.id !== id)
                  }
                  return result
                })
              }}
            >
              {t('poista')}
            </StyledRemoveButton>
          </ButtonContainer>
        </ListItem>
      )
    })

    return (
      <>
        <Container data-test-id={mkTestId('form-container')}>
          <Content>
            <Text variant="bold">{heading}</Text>
            <Block mt="s" />
            <Text>{description}</Text>
            <Block mt="s" />
            <List>{items}</List>
            <Block mt="l" />
            <div>
              <Button
                data-test-id={mkTestId('list-add-item')}
                icon={<IconPlus />}
                variant="secondary"
                onClick={() => {
                  setList((prevState) => {
                    return [...prevState, mkEmptyEntry()]
                  })
                }}
              >
                {t('lisaaRivi')}
              </Button>
            </div>
          </Content>
        </Container>
      </>
    )
  }
)

export default FormSimpleListInput

const Container = styled.div`
  background-color: ${suomifiDesignTokens.colors.highlightLight4};
  padding: 25px;
  border: 1px solid ${suomifiDesignTokens.colors.depthLight1};
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const List = styled.ul`
  display: flex;
  gap: ${suomifiDesignTokens.spacing.l};
  flex-direction: column;
`

const ButtonContainer = styled.div`
  width: 125px;
  margin-left: ${suomifiDesignTokens.spacing.xs};
`

const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StyledTextInput = styled(TextInput)`
  .fi-label {
    display: none;
  }
`

const StyledRemoveButton = styled(Button)`
  height: 40px;
`
