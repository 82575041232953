import { Block, Heading, suomifiDesignTokens } from 'suomifi-ui-components'
import React from 'react'
import { centeredWidthLimited } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { useAutoFocus } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/auto-focus'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import styled from 'styled-components'
import { Outlet } from 'react-router-dom'
import FormNavigationWizard from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationWizard'
import {
  OMAISUUSLUETTELO_ROUTES,
  OMAISUUSLUETTELO_STEPS,
  OmaisuusluetteloFormStates,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'

const OmaisuusluetteloApplicationPage: React.FC<{
  forms: OmaisuusluetteloFormStates
}> = observer(({ forms }) => {
  const [t] = useTranslation()

  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const juurisivuHeadingRef = useAutoFocus<HTMLHeadingElement>()

  return (
    <Main>
      <Heading variant="h1" ref={juurisivuHeadingRef} tabIndex={-1}>
        {t('omaisuusluettelo')}
      </Heading>
      <MainContent>
        <NavigationAndNotifications>
          <FormNavigationWizard
            forms={forms}
            routes={OMAISUUSLUETTELO_ROUTES}
            steps={OMAISUUSLUETTELO_STEPS}
          />
        </NavigationAndNotifications>
        <Block mt={verticalMarginToken} />
        <ApplicationDetails>
          <Outlet />
        </ApplicationDetails>
      </MainContent>
    </Main>
  )
})

export default OmaisuusluetteloApplicationPage

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${suomifiDesignTokens.spacing.m};

  @media screen and ${device.tablet} {
    flex-direction: row;
    gap: ${suomifiDesignTokens.spacing.s};
  }
`

const Main = styled.main`
  ${centeredWidthLimited};

  padding: ${suomifiDesignTokens.spacing.s} 0;

  @media ${device.tablet} {
    padding: ${suomifiDesignTokens.spacing.xl} ${suomifiDesignTokens.spacing.xl};
  }
`

const ApplicationDetails = styled.section`
  flex: 1;
`
const NavigationAndNotifications = styled.div`
  margin: 0 ${suomifiDesignTokens.spacing.s};

  @media ${device.tablet} {
    max-width: 330px;
    margin: 0;
  }
`
