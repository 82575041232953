import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import {
  Block,
  Button,
  InlineAlert,
  ModalTitle,
  Modal,
  ModalFooter,
  ModalContent,
  LoadingSpinner,
  suomifiDesignTokens,
  IconArrowLeft,
  IconRemove,
} from 'suomifi-ui-components'
import { ASIOINTI_ROOT_ELEMENT_ID } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/constants'
import {
  OmaisuusluetteloFormStates,
  PAATOSTILI_ROUTES,
  PaatostiliFormStates,
  TilintarkastusRoutePath,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { mkLahetaTiliTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import {
  getTilintarkastusStore,
  saveDraft,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { submitPaatostili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/actions/submit-tilintarkastus-actions'
import {
  NavButtonContainer,
  NavButtonGroupContainer,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import {
  AsiointiFeatureFlag,
  isAsiointiUiFeatureEnabled,
} from 'holhous-common/src/vtj/asiointi-feature-flag'
import AbortDialog from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/AbortDialog'

export const LahetaTiliVahvistusModal: React.FC<{
  visible: boolean
  onSubmit: () => unknown
  onClose: () => unknown
}> = ({ visible, onSubmit, onClose }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet

  return (
    <Modal
      visible={visible}
      appElementId={ASIOINTI_ROOT_ELEMENT_ID}
      onEscKeyDown={onClose}
      scrollable={false}
      variant={isTablet ? 'default' : 'smallScreen'}
    >
      <ModalContent>
        <ModalTitle aria-live="polite" aria-atomic="true">
          {t('lahetaPaatostili')}
        </ModalTitle>
        {t('haluatkoLahettaaPaatostilin')}
      </ModalContent>
      <ModalFooter>
        <Button
          onClick={onSubmit}
          data-test-id={mkLahetaTiliTestId('modal-laheta-painike')}
        >
          {t('laheta')}
        </Button>
        <Button
          onClick={onClose}
          variant="secondary"
          data-test-id={mkLahetaTiliTestId('modal-peruuta-painike')}
        >
          {t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export const LahetaTiliNotifications: React.FC<{
  validationErrors: ValidointiVirhe[]
}> = observer(({ validationErrors }) => {
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const store = getTilintarkastusStore()
  const showLoading = store.submitStatus === 'in-progress'
  const showError =
    store.submitStatus === 'none' && store.submitError !== undefined

  return (
    <>
      {showLoading && <LahetaTiliLoading />}
      {showError && <LahetaTiliError />}
      {validationErrors.length > 0 && (
        <LahetaTiliValidointiError errors={validationErrors} />
      )}
      {(showLoading || showError || validationErrors.length > 0) && (
        <Block mt={verticalMarginToken} />
      )}
    </>
  )
})

export const LahetaTiliLoading: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    ref.current?.scrollIntoView()
  }, [])

  return (
    <InlineAlert
      status="neutral"
      smallScreen={!isTablet}
      data-test-id={mkLahetaTiliTestId('tilia-lahetetaan')}
    >
      <div ref={ref} tabIndex={-1}>
        <LoadingSpinner
          status={'loading'}
          text={t('tiliaLahetetaan')}
          textAlign="right"
          variant="small"
        />
      </div>
    </InlineAlert>
  )
}

const LahetaTiliError: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    ref.current?.scrollIntoView()
  }, [])

  return (
    <InlineAlert
      status="error"
      smallScreen={!isTablet}
      data-test-id={mkLahetaTiliTestId('virhe-lahetyksessa')}
      tabIndex={0}
    >
      <div ref={ref} tabIndex={-1}>
        {t('lahetysEpaonnistuiYritaUudelleen')}
      </div>
    </InlineAlert>
  )
}

const LahetaTiliValidointiVirheViesti = styled.div`
  ul {
    padding-left: ${suomifiDesignTokens.spacing.insetS};
  }
  li {
    list-style: disc;
    list-style-position: inside;
  }
`

const LahetaTiliValidointiError: React.FC<{ errors: ValidointiVirhe[] }> = ({
  errors,
}) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    ref.current?.scrollIntoView()
  }, [])

  return (
    <InlineAlert
      status="error"
      smallScreen={!isTablet}
      data-test-id={mkLahetaTiliTestId('validointi-virhe')}
      tabIndex={0}
    >
      <LahetaTiliValidointiVirheViesti ref={ref} tabIndex={-1}>
        {t('pakollisiaTietojaPuuttuu')}:
        <ul>
          {errors.map((error) => (
            <li key={error}>{t(error as string)}</li>
          ))}
        </ul>
      </LahetaTiliValidointiVirheViesti>
    </InlineAlert>
  )
}

export const FormSubmitBar: React.FC<{
  previous?: TilintarkastusRoutePath
  next: TilintarkastusRoutePath
  forms: PaatostiliFormStates | OmaisuusluetteloFormStates
}> = observer(({ forms, next, previous }) => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const [lahetaTiliVahvistusModalVisible, setLahetaTiliVahvistusModalVisible] =
    useState(false)
  const [validationErrors, setValidationErrors] = useState(
    [] as ValidointiVirhe[]
  )
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  const { handleSubmit } = useFormContext()
  const store = getTilintarkastusStore()
  const openFrontPage = () => window.location.assign('/?tili=true') // Hard reload to remove application batch from memory
  const [isAbortDialogVisible, setAbortDialogVisible] = useState(false)

  const onAbort = () => {
    setAbortDialogVisible(false)
    openFrontPage()
  }
  return (
    <>
      <LahetaTiliNotifications validationErrors={validationErrors} />
      <LahetaTiliVahvistusModal
        onSubmit={async () => {
          if (store.submitStatus === 'none') {
            setLahetaTiliVahvistusModalVisible(false)
            const response = await submitPaatostili()
            if (response.isOk) {
              navigate(next)
            }
          }
        }}
        onClose={() => setLahetaTiliVahvistusModalVisible(false)}
        visible={lahetaTiliVahvistusModalVisible}
      />
      <AbortDialog
        visible={isAbortDialogVisible}
        onAbort={onAbort}
        onClose={() => {
          setAbortDialogVisible(false)
        }}
      />
      <Block mt={verticalMarginToken}>
        <NavButtonContainer>
          {isAsiointiUiFeatureEnabled(AsiointiFeatureFlag.TALLENNUS) ? (
            <Button
              onClick={() => {
                void saveDraft()
                openFrontPage()
              }}
              variant="secondary"
            >
              {t('tallennaJaPalaaEtusivulle')}
            </Button>
          ) : (
            <Button
              data-test-id={mkLahetaTiliTestId('abort-button')}
              variant="secondaryLight"
              icon={<IconRemove />}
              onClick={() => setAbortDialogVisible(true)}
            >
              {t('keskeyta')}
            </Button>
          )}

          <NavButtonGroupContainer>
            {previous && (
              <Button
                variant="secondary"
                icon={<IconArrowLeft />}
                onClick={() => {
                  void saveDraft()
                  navigate(previous)
                }}
              >
                {t('previous')}
              </Button>
            )}
            <Block ml="s" />
            <Button
              data-test-id={mkLahetaTiliTestId('send-button')}
              onClick={async () => {
                await handleSubmit(() => {
                  void saveDraft()
                  const errors = Object.entries(forms).flatMap(([k, v]) =>
                    resolveValidationErrors([k as TilintarkastusRoutePath, v])
                  )
                  setValidationErrors(errors)
                  if (errors.length === 0) {
                    setLahetaTiliVahvistusModalVisible(true)
                  }
                })()
              }}
            >
              {t('laheta')}
            </Button>
          </NavButtonGroupContainer>
        </NavButtonContainer>
      </Block>
    </>
  )
})

const RouteVirhe: Partial<Record<TilintarkastusRoutePath, string>> = {
  [PAATOSTILI_ROUTES.YLEISKATSAUS]: 'yleiskatsaus',
  [PAATOSTILI_ROUTES.PALKKIO]: 'palkkio',
  [PAATOSTILI_ROUTES.YHTEYSTIEDOT]: 'yhteystiedot',
  [PAATOSTILI_ROUTES.TEE_PAATOSTILI]: 'aloitus',
} as const

type ValidointiVirhe = (typeof RouteVirhe)[keyof typeof RouteVirhe]

const resolveValidationErrors = ([key, { isComplete }]: [
  TilintarkastusRoutePath,
  (
    | PaatostiliFormStates[keyof PaatostiliFormStates]
    | OmaisuusluetteloFormStates[keyof OmaisuusluetteloFormStates]
  )
]): ValidointiVirhe[] => {
  if (isComplete) {
    return []
  } else {
    const virhe = RouteVirhe[key]
    if (virhe) {
      return [virhe]
    } else {
      return []
    }
  }
}
