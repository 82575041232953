import React from 'react'
import { Outlet, Route, Routes, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/holhous-asiointi-user-store'
import UserLandingPage from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/UserLandingPage'
import GuestLandingPage from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/GuestLandingPage'
import MainFooter from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/MainFooter'
import CookieInfoPage from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/cookies/CookieInfoPage'
import { cookiePageUrl } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/links'
import PageNotFound from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/PageNotFound'
import lupaAsiointiRoutes from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/LupaAsiointiRoutes'
import { LUPA_ASIOINTI_UI_ROUTE_PREFIX } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-asiointi-ui-route.util'
import {
  omaisuusluetteloAsiointiRoutes,
  paatostiliAsiointiRoutes,
  TilintarkastusAsiointiApp,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/TilintarkastusAsiointiRoutes'
import { isTiliAsiointiEnabled } from 'holhous-common/src/vtj/asiointi-feature-flag'
import { useForm } from 'react-hook-form'
import {
  OMAISUUSLUETTELO_ROUTES,
  PAATOSTILI_ROUTES,
  TILINTARKASTUS_ASIOINTI_UI_ROUTE_PREFIX,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'

const AsiointiAppRoutes: React.FC = observer(() => {
  const { user } = useAsiointiUserStore()

  const paatostiliForms = {
    [PAATOSTILI_ROUTES.TEE_PAATOSTILI]: useForm(),
    [PAATOSTILI_ROUTES.YLEISKATSAUS]: useForm(),
    [PAATOSTILI_ROUTES.PALKKIO]: useForm(),
    [PAATOSTILI_ROUTES.TULOT_JA_MENOT]: useForm(),
    [PAATOSTILI_ROUTES.OMAISUUS_JA_VELAT]: useForm(),
    [PAATOSTILI_ROUTES.YHTEYSTIEDOT]: useForm(),
  }

  const omaisuusluetteloForms = {
    [OMAISUUSLUETTELO_ROUTES.OMASSA_KAYTOSSA_OLEVA_OMAISUUS]: useForm(),
    [OMAISUUSLUETTELO_ROUTES.TOIMINTAKYKY_JA_ASUMISTIEDOT]: useForm(),
  }

  return (
    <Routes>
      <Route Component={Layout}>
        <Route
          path="/"
          element={user ? <UserLandingPage /> : <GuestLandingPage />}
        />
        <Route path={cookiePageUrl} Component={CookieInfoPage} />
        {lupaAsiointiRoutes}
        {isTiliAsiointiEnabled() ? (
          <Route
            path={TILINTARKASTUS_ASIOINTI_UI_ROUTE_PREFIX}
            Component={TilintarkastusAsiointiApp}
          >
            {paatostiliAsiointiRoutes(paatostiliForms)}
            {omaisuusluetteloAsiointiRoutes(omaisuusluetteloForms)}
          </Route>
        ) : null}
        <Route path="*" Component={PageNotFound} />
      </Route>
    </Routes>
  )
})

const Layout: React.FC = () => {
  const { pathname } = useLocation()
  const isFooterVisible = !pathname.startsWith(LUPA_ASIOINTI_UI_ROUTE_PREFIX)

  return (
    <LayoutGrid>
      <main>
        <Outlet />
      </main>
      {isFooterVisible && <MainFooter />}
    </LayoutGrid>
  )
}

const LayoutGrid = styled.div`
  grid-area: content;
  display: grid;
  grid-template-rows: 1fr auto;
`

export default AsiointiAppRoutes
