/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkOmaisuusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import {
  Block,
  Heading,
  Paragraph,
  RadioButton,
  RadioButtonGroup,
  Text,
} from 'suomifi-ui-components'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import { getTilirivit } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { FrontTakaus } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { FormListInputExpander } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import { observable } from 'mobx'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import { TiliAsiointiAsiakirjatyyppi } from 'tilintarkastus-common/src/vtj/types/asiointi-account-data.type'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonEuroWithDecimalsOrUndefinedValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/react-form-validation.util'
import { AsiointiLedgerAccountCategory } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'
import { AsiointiLedgerAccountDebtType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-debt-enum'

const TakauksetForm: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const omaisuus = getTilirivit()

  const mkNewEntry = (): FrontTakaus =>
    observable({
      id: crypto.randomUUID(),
      tiliointinumero: '',
      tyyppi:
        AsiointiLedgerAccountDebtType.TAKAUKSET_JA_VASTUUT_MUIDEN_VELOISTA,
      lainanTyyppi: undefined,
      velallisenEtunimi: '',
      velallisenSukunimi: '',
      vakuus: '',
      onkoTullutHenkilonMaksettavaksi: false,
      arvoTilikaudenAlussaEur: undefined,
      arvoTilikaudenLopussaEur: undefined,
      asiakirjat: [],
    })

  return (
    <section data-test-id={mkOmaisuusTestId('takaukset-form')}>
      <Heading variant="h3">{t('takauksetHeading')}</Heading>
      <Block mt={verticalMarginToken} />
      <Paragraph>{t('ohjeIlmoitaTakaukset')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={TakausListItem}
        state={omaisuus.velat.takaukset}
        asiointiLedgerAccountCategory={AsiointiLedgerAccountCategory.VELAT}
        dataTestIdPrefix={mkOmaisuusTestId('takaukset-form')}
        ModalContentComponent={TakausModal}
        newEntry={mkNewEntry}
        translationPrefix="takaukset"
      />
    </section>
  )
}

const TakausModal: FC<FormModalProps<FrontTakaus>> = observer(
  ({ createDataTestId, entry, setEntry }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'

    return (
      <div>
        <FormTextInput
          data-test-id={createDataTestId('modal-velallisenEtunimi')}
          labelText={t('velallisenEtunimi')}
          value={entry.velallisenEtunimi}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) =>
            setEntry({ ...entry, velallisenEtunimi: value })
          }
        />
        <FormTextInput
          data-test-id={createDataTestId('modal-velallisenSukunimi')}
          labelText={t('velallisenSukunimi')}
          value={entry.velallisenSukunimi}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) =>
            setEntry({ ...entry, velallisenSukunimi: value })
          }
        />

        <FormTextInput
          data-test-id={createDataTestId('modal-vakuus')}
          labelText={t('vakuus')}
          value={entry.vakuus}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) => setEntry({ ...entry, vakuus: value })}
        />

        <FormNumberInput
          data-test-id={createDataTestId('modal-arvoTilikaudenAlussa')}
          labelText={t('arvoTilikaudenAlussaEuroina')}
          digitMode="two"
          required
          value={entry.arvoTilikaudenAlussaEur}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) =>
            setEntry({
              ...entry,
              arvoTilikaudenAlussaEur: value,
            })
          }
        />

        <FormNumberInput
          data-test-id={createDataTestId('modal-arvoTilikaudenLopussa')}
          labelText={t('arvoTilikaudenLopussaEuroina')}
          digitMode="two"
          required
          value={entry.arvoTilikaudenLopussaEur}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) => {
            setEntry({
              ...entry,
              arvoTilikaudenLopussaEur: value,
            })
          }}
        />

        <RadioButtonGroup
          data-test-id={createDataTestId(
            'modal-onkoTullutHenkilonMaksettavaksi'
          )}
          value={Boolean(entry.onkoTullutHenkilonMaksettavaksi).toString()}
          labelText={t('onkoTullutHenkilonMaksettavaksi')}
          name={createDataTestId('modal-onkoTullutHenkilonMaksettavaksi')}
          onChange={(value) =>
            setEntry({
              ...entry,
              onkoTullutHenkilonMaksettavaksi: value === 'true',
            })
          }
        >
          <RadioButton
            data-test-id={createDataTestId(
              'modal-onkoTullutHenkilonMaksettavaksi-ei'
            )}
            value={'false'}
            variant="small"
          >
            {t('ei')}
          </RadioButton>
          <RadioButton
            data-test-id={createDataTestId(
              'modal-onkoTullutHenkilonMaksettavaksi-kylla'
            )}
            value={'true'}
            variant="small"
          >
            {t('kylla')}
          </RadioButton>
        </RadioButtonGroup>
        <Block mt={verticalMarginToken} />

        <FormAttachmentFileBox
          observableAttachments={entry.asiakirjat}
          asiakirjaTypeId={TiliAsiointiAsiakirjatyyppi.MUU_LIITE}
          data-test-id={createDataTestId('modal-asiakirja')}
          text={t('takauksetAsiakirjaInfo')}
          title={t('asiakirjat')}
        />
      </div>
    )
  }
)

const TakausListItem: FC<FormElementProps<FrontTakaus>> = observer(
  ({ createDataTestId, entry, onEdit, onRemove }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'

    const arvoLopussaEur = `${formatNumberWithComma(
      entry.arvoTilikaudenLopussaEur!
    )} €`
    const arvoAlussaEur = `${formatNumberWithComma(
      entry.arvoTilikaudenAlussaEur!
    )} €`

    return (
      <FormListInputExpander
        heading={`${entry.velallisenEtunimi} ${entry.velallisenSukunimi}`}
        headingRight={arvoLopussaEur}
        createDataTestId={createDataTestId}
        onEdit={onEdit}
        onRemove={onRemove}
        showSubHeadingWhileOpen
      >
        <Heading variant="h5">{t('vakuus')}</Heading>
        <Text>{entry.vakuus}</Text>
        <Block mt={verticalMarginToken} />

        <Heading variant="h5">{t('arvoTilikaudenAlussaEuroina')}</Heading>
        <Text>{arvoAlussaEur}</Text>
        <Block mt={verticalMarginToken} />

        <Heading variant="h5">{t('onkoTullutHenkilonMaksettavaksi')}</Heading>
        <Text>
          {entry.onkoTullutHenkilonMaksettavaksi ? t('kylla') : t('ei')}
        </Text>
        <Block mt={verticalMarginToken} />

        {entry.asiakirjat.length > 0 && (
          <>
            <Block mt={verticalMarginToken} />
            <Heading variant="h5">{t('asiakirjat')}</Heading>
            <AttachmentList attachments={entry.asiakirjat} />
            <Block mt={verticalMarginToken} />
          </>
        )}
        <Block mt={verticalMarginToken} />
      </FormListInputExpander>
    )
  }
)

export default observer(TakauksetForm)
