import keyMirror from 'keymirror'
import fp from 'lodash/fp'

export const AccountStatus = keyMirror({
  AVOIN: null,
  PALAUTETTU_TARKASTAMATTOMANA: null,
  HYVAKSYTTY: null,
})
export type AccountStatusId = (typeof AccountStatus)[keyof typeof AccountStatus]

export const TiliPersonRole = keyMirror({
  PAAMIES: null,
  YLEINEN_EDUNVALVOJA: null,
  YLEINEN_EDUNVALVOJA_SIJAINEN: null,
  YKSITYINEN_EDUNVALVOJA: null,
  YKSITYINEN_EDUNVALVOJA_SIJAINEN: null,
  EDUNVALVONTAVALTUUTETTU: null,
  YKSITYISHENKILO: null,
  YRITYS: null,
})
export type TiliPersonRoleId =
  (typeof TiliPersonRole)[keyof typeof TiliPersonRole]

export const EdvardCaretakerRole = fp.pick(
  ['YLEINEN_EDUNVALVOJA', 'YLEINEN_EDUNVALVOJA_SIJAINEN'],
  TiliPersonRole
)
export type EdvardCaretakerRoleId =
  (typeof EdvardCaretakerRole)[keyof typeof EdvardCaretakerRole]
export const edvardCaretakerRoles: TiliPersonRoleId[] =
  Object.values(EdvardCaretakerRole)

export const AsiointiCaretakerRole = fp.pick(
  [
    'YKSITYINEN_EDUNVALVOJA',
    'YKSITYINEN_EDUNVALVOJA_SIJAINEN',
    'EDUNVALVONTAVALTUUTETTU',
  ],
  TiliPersonRole
)
export type AsiointiCaretakerRoleId =
  (typeof AsiointiCaretakerRole)[keyof typeof AsiointiCaretakerRole]

export const asiointiCaretakerRoles: TiliPersonRoleId[] = Object.values(
  AsiointiCaretakerRole
)

export const EdvardPrivatePersonRole = fp.pick(
  [
    'YKSITYINEN_EDUNVALVOJA',
    'YKSITYINEN_EDUNVALVOJA_SIJAINEN',
    'EDUNVALVONTAVALTUUTETTU',
    'YKSITYISHENKILO',
  ],
  TiliPersonRole
)
export type EdvardPrivatePersonRoleId =
  (typeof EdvardPrivatePersonRole)[keyof typeof EdvardPrivatePersonRole]
export const edvardPrivatePersonRoles: TiliPersonRoleId[] = Object.values(
  EdvardPrivatePersonRole
)

export const AsiointiPrivatePersonRole = fp.pick(
  [
    'YKSITYINEN_EDUNVALVOJA',
    'YKSITYINEN_EDUNVALVOJA_SIJAINEN',
    'EDUNVALVONTAVALTUUTETTU',
    'YKSITYISHENKILO',
  ],
  TiliPersonRole
)
export type AsiointiPrivatePersonRoleId =
  (typeof AsiointiPrivatePersonRole)[keyof typeof AsiointiPrivatePersonRole]
export const asiointiPrivatePersonRoles: TiliPersonRoleId[] = Object.values(
  EdvardPrivatePersonRole
)

export const GuardianshipEndReason = keyMirror({
  EDUNVALVONNAN_ERITYISTEHTAVAN_LAKKAAMINEN: null,
  EDUNVALVONNAN_MAARAJAN_PAATTYMINEN: null,
  EDUNVALVOJAN_TEHTAVAN_LAKKAAMINEN: null,
  EDUNVALVOJAN_TOIMINTAKELPOISUUDEN_MUUTOS: null,
  EDUNVALVOJAN_VAIHDOS: null,
  PAAMIEHEN_KUOLEMA: null,
  PAAMIEHEN_TAYSI_IKAISYYS: null,
  TUNTEMATON: null,
})
export type GuardianshipEndReasonId =
  (typeof GuardianshipEndReason)[keyof typeof GuardianshipEndReason]

export const TiliBillingDiscountType = keyMirror({
  ALAIKAINEN_PAAMIES: null,
  LYHYT_TILIKAUSI: null,
  MUU: null,
})
export type TiliBillingDiscountTypeId =
  (typeof TiliBillingDiscountType)[keyof typeof TiliBillingDiscountType]

export const HabitationType = keyMirror({
  KIINTEISTO: null,
  OSAKEHUONEISTO: null,
  VUOKRA_ASUNTO: null,
  LAITOS: null,
  ASUNTOLA: null,
  ASUMISPALVELU: null,
  MUU_ASUNTO: null,
  EI_TIEDOSSA: null,
})
export type HabitationTypeId =
  (typeof HabitationType)[keyof typeof HabitationType]

export const HabitationPeriodType = keyMirror({
  VAKINAINEN: null,
  TILAPAINEN: null,
})
export type HabitationPeriodTypeId =
  (typeof HabitationPeriodType)[keyof typeof HabitationPeriodType]

export const PaymentFrequency = keyMirror({
  VIIKOITTAIN: null,
  KUUKAUSITTAIN: null,
  VUOSITTAIN: null,
})
export type PaymentFrequencyId =
  (typeof PaymentFrequency)[keyof typeof PaymentFrequency]

export const AssetType = keyMirror({
  TILI: null,
  MUU_OMAISUUS: null,
})
export type AssetTypeId = (typeof AssetType)[keyof typeof AssetType]

export const AssetDebtType = keyMirror({
  OMAISUUS: null,
  VELKA: null,
})
export type AssetDebtTypeId = (typeof AssetDebtType)[keyof typeof AssetDebtType]

export const AssetDebtGroup = keyMirror({
  // For debts
  bankLoanLines: null,
  otherDebtLines: null,
  externalDebtLines: null,

  // For assets
  bankDepositLines: null,
  otherBondLines: null,
  equityLines: null,
  fundShareLines: null,
  shareInCondominiumLines: null,
  realEstateLines: null,
  deathEstateShareLines: null,
  otherClaimLines: null,
  personalPropertyLines: null,
  otherAssetLines: null,
})
export type AssetDebtGroupId =
  (typeof AssetDebtGroup)[keyof typeof AssetDebtGroup]

export const IncomeGroup = keyMirror({
  salaryLines: null,
  rentalIncomeLines: null,
  dividendLines: null,
  interestIncomeLines: null,
  otherIncomeLines: null,
})
export type IncomeGroupId = (typeof IncomeGroup)[keyof typeof IncomeGroup]

export const ExpenseGroup = keyMirror({
  allowanceLines: null,
  costOfLivingLines: null,
  caretakingExpenseLines: null,
  rentLines: null,
  debtServicingLines: null,
  taxLines: null,
  otherExpenseLines: null,
  caretakerFeeLines: null,
  caretakerExpenseLines: null,
})
export type ExpenseGroupId = (typeof ExpenseGroup)[keyof typeof ExpenseGroup]

export const IncomeExpenseGroup = {
  ...IncomeGroup,
  ...ExpenseGroup,
}
export type IncomeExpenseGroupId =
  (typeof IncomeExpenseGroup)[keyof typeof IncomeExpenseGroup]
