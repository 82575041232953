export type ChildTestId = string | number

export const mkTestId = (...ids: ChildTestId[]): string => {
  return `${['tilinpaatos-asiointi', ...ids].join('-')}`
}

export const mkJuurisivuTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('juurisivu', ...ids)
}

export const mkKeskeytaHakemusTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('keskeyta-modal', ...ids)
}

export const mkTallennaModalTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('tallenna-modal', ...ids)
}

export const mkLahetaTiliTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('laheta-tili', ...ids)
}

export const mkEdunvalvontaValtakirjaTestId = (
  ...ids: ChildTestId[]
): string => {
  return mkTestId('edunvalvonta-valtakirja', ...ids)
}

export const mkYleiskatsausTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('yleiskatsaus', ...ids)
}

export const mkTiedoksiantoTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('tiedoksianto', ...ids)
}

export const mkOmatTiedotTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('omat-tiedot', ...ids)
}

export const mkPalkkioTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('palkkio', ...ids)
}

export const mkOmaisuusTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('omaisuus', ...ids)
}

export const mkYhteystiedotTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('yhteystiedot', ...ids)
}

export const mkTulotMenotTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('tulotMenot', ...ids)
}

export const mkYhteenvetoTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('yhteenveto', ...ids)
}

export const mkValmisTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('valmis', ...ids)
}

export const mkAlasivuNavigationTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('alasivu-navigation', ...ids)
}

export const mkToimintakykyAsuminenTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('toimintakyky-asuminen', ...ids)
}

export const mkAsuminenFormTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('asuminen-form', ...ids)
}
export const mkVarat = (...ids: ChildTestId[]): string => {
  return mkTestId('varat-form', ...ids)
}
