import React, { ForwardedRef, HTMLProps } from 'react'
import { Block, Heading, Link, Paragraph, Text } from 'suomifi-ui-components'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  CenteredWidthLimitedDiv,
  focusOutline,
  ResponsiveBorderedContentBlock,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { useAutoFocus } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/auto-focus'
import { mkFrameTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-frame-test-id'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'

const PageNotFound: React.FC<
  Omit<HTMLProps<HTMLDivElement>, 'as'> & { ref?: ForwardedRef<HTMLDivElement> }
> = (props) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const headingRef = useAutoFocus<HTMLHeadingElement>()

  return (
    <CenteredWidthLimitedDiv
      paddingX
      paddingY
      {...props}
      data-test-id={mkFrameTestId('page-not-found')}
    >
      <MainHeading
        variant="h1hero"
        smallScreen={!isTablet}
        ref={headingRef}
        tabIndex={-1}
      >
        {t('sivuaEiLoytynyt')}
      </MainHeading>
      <Block mb={isTablet ? 'xl' : 'm'} />
      <ResponsiveBorderedContentBlock>
        <Paragraph mb={isTablet ? 'l' : 'm'}>
          <Text smallScreen={!isTablet}>
            {t('tarkistaSivunOsoiteJaYritaUudelleen')}.
          </Text>
        </Paragraph>
        <Link href="/" variant="accent" smallScreen={!isTablet}>
          {t('siirryEdunvalvonnanAsiointipalvelunEtusivulle')}
        </Link>
      </ResponsiveBorderedContentBlock>
    </CenteredWidthLimitedDiv>
  )
}

const MainHeading = styled(Heading)`
  ${focusOutline};
`

export default PageNotFound
