import { Block, Paragraph, Text } from 'suomifi-ui-components'
import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { getTili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import { mkEdunvalvontaValtakirjaTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { LupaAsiointiAsiakirjaType } from 'lupa-backend/src/vtj/asiointi/asiakirja/asiointi-asiakirja-enums'

export const Valtakirja: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  return (
    <>
      <Block mt={verticalMarginToken} />
      <Paragraph>
        <Text variant="bold">{t('paatostiliValtakirjaHeading')}</Text>
      </Paragraph>
      <Paragraph data-test-id={mkEdunvalvontaValtakirjaTestId('container')}>
        <Text>{t('paatostiliValtakirjaText')}</Text>
        <Block mt={verticalMarginToken} />
        <FormAttachmentFileBox
          observableAttachments={getTili().edunvalvojanValtakirja}
          asiakirjaTypeId={LupaAsiointiAsiakirjaType.MUU_LIITE}
          data-test-id={mkEdunvalvontaValtakirjaTestId('liite')}
          title={t('paatostiliValtakirjaHeading')}
          hideTitle
          required
        />
      </Paragraph>
    </>
  )
})
