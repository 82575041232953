import {
  Block,
  Heading,
  InlineAlert,
  Paragraph,
  Text,
} from 'suomifi-ui-components'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { observer } from 'mobx-react'
import { OMAISUUSLUETTELO_ROUTES } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { mkJuurisivuTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { useTranslation } from 'react-i18next'
import {
  IsoDatetimeToUiDatetime,
  IsoDatetimeToUiDate,
  useValittuEvtvVaatimus,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/evtv-asiointi-store'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Valtakirja } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/Valtakirja'
import { TilivelvollisuusType } from 'tilintarkastus-common/src/vtj/types/edunvalvontasuhde/edunvalvontasuhde-enums'
import { useAutoFocus } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/auto-focus'
import { ChipForStatusSaved } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/chips'
import { getTiliDraftBySeurantaasiavaatimus } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/draft-store'
import {
  AsiointiFeatureFlag,
  isAsiointiUiFeatureEnabled,
} from 'holhous-common/src/vtj/asiointi-feature-flag'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'

const OmaisuusluetteloLandingPage: React.FC = observer(() => {
  const [t] = useTranslation()
  const evtvVaatimus = useValittuEvtvVaatimus()
  const tiliDraft = getTiliDraftBySeurantaasiavaatimus(
    evtvVaatimus.seurantaAsiavaatimusId
  )

  const juuriForm = useForm()

  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const formattedAlkuPvm = IsoDatetimeToUiDate(
    evtvVaatimus.accountingPeriodStartDate
  )
  const formattedLoppuPvm = IsoDatetimeToUiDate(
    evtvVaatimus.accountingPeriodEndDate
  )
  const juurisivuHeadingRef = useAutoFocus<HTMLHeadingElement>()

  return (
    <>
      <Block mt={verticalMarginToken} />
      {!isAsiointiUiFeatureEnabled(AsiointiFeatureFlag.TALLENNUS) && (
        <>
          <InlineAlert status="warning" labelText={t('eiVoiTallentaaHeading')}>
            {t('eiVoiTallentaaInfotext')}
          </InlineAlert>
          <Block mt={verticalMarginToken} />
        </>
      )}
      <FormProvider {...juuriForm}>
        <ResponsiveBorderedContentBlock
          data-test-id={mkJuurisivuTestId('general-info-container')}
        >
          <Heading variant="h1" ref={juurisivuHeadingRef} tabIndex={-1}>
            {t('aloitus')}
          </Heading>
          <Block mt={verticalMarginToken} />
          <Paragraph data-test-id={mkJuurisivuTestId('tilikausi-text')}>
            <Heading variant="h3" as="h2">
              {t('tilikausi')} {formattedAlkuPvm} - {formattedLoppuPvm}
              {!!tiliDraft && (
                <ChipForStatusSaved>
                  {t('tiliStatus_TALLENNETTU')}&nbsp;
                  {IsoDatetimeToUiDatetime(tiliDraft.updatedAt)}
                </ChipForStatusSaved>
              )}
            </Heading>
          </Paragraph>
          <Block mt="xs" />
          <Paragraph data-test-id={mkJuurisivuTestId('paamies-name')}>
            <Heading variant="h4" as="h3">
              {evtvVaatimus.lastname}, {evtvVaatimus.firstnames}
            </Heading>
          </Paragraph>
          <Block mt={verticalMarginToken} />
          <Paragraph>{t('paatostiliJuurisivuOhje')}</Paragraph>
          <Block mt={verticalMarginToken} />
          <Paragraph>
            <Text variant="bold">{t('tilivelvollinen')}</Text>
          </Paragraph>
          <Paragraph data-test-id={mkJuurisivuTestId('tilivelvollinen-name')}>
            {evtvVaatimus.guardianLastname}, {evtvVaatimus.guardianFirstnames}
          </Paragraph>
          {evtvVaatimus.tilivelvollisuusType ===
            TilivelvollisuusType.TILIVELVOLLISUUS_YHDESSA && <Valtakirja />}

          <FormNavigationBar
            next={OMAISUUSLUETTELO_ROUTES.TOIMINTAKYKY_JA_ASUMISTIEDOT}
          />
        </ResponsiveBorderedContentBlock>
        <Block mt={verticalMarginToken} />

        <Block mt={verticalMarginToken} />
      </FormProvider>
    </>
  )
})

export default OmaisuusluetteloLandingPage
