import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import {
  Block,
  Heading,
  Paragraph,
  RadioButton,
  RadioButtonGroup,
} from 'suomifi-ui-components'
import { mkYleiskatsausTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { commonXssValidator } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/react-form-validation.util'
import {
  updateAccountValues,
  getPaatostili,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import FormTextArea from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextArea'

const MuutToimetForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const mitaMuitaMerkittaviaToimiaTestId = mkYleiskatsausTestId(
    'muut-merkittavat-toimet-input'
  )

  const { isMuutMerkittavatToimet, muutMerkittavatToimet } = getPaatostili()

  return (
    <section data-test-id={mkYleiskatsausTestId('muut-toimet-container')}>
      <Heading variant="h3">{t('muutMerkittavatToimet')}</Heading>
      <Block mt={verticalMarginToken} />
      <Paragraph>{t('ohjeIlmoitaMerkittavatToimetHenkilolle')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <RadioButtonGroup
        data-test-id={mkYleiskatsausTestId('onko-muita-toimia-radio-group')}
        value={Boolean(isMuutMerkittavatToimet).toString()}
        labelText={t('onkoMuitaMerkittaviaToimia')}
        name={mkYleiskatsausTestId('onko-muita-toimia-radio-group')}
        onChange={(value) =>
          updateAccountValues({ isMuutMerkittavatToimet: value === 'true' })
        }
      >
        <RadioButton
          data-test-id={mkYleiskatsausTestId('onko-muita-toimia-radio-ei')}
          value={'false'}
          variant="small"
        >
          {t('ei')}
        </RadioButton>
        <RadioButton
          data-test-id={mkYleiskatsausTestId('onko-muita-toimia-radio-kylla')}
          value={'true'}
          variant="small"
        >
          {t('kylla')}
        </RadioButton>
      </RadioButtonGroup>

      {isMuutMerkittavatToimet && (
        <>
          <Block mt={verticalMarginToken} />
          <FormTextArea
            data-test-id={mitaMuitaMerkittaviaToimiaTestId}
            labelText={t('mitaMuitaMerkittaviaToimia')}
            maxLength={1000}
            required
            value={muutMerkittavatToimet}
            validate={commonXssValidator(t)}
            updateValue={(value) => {
              updateAccountValues({
                muutMerkittavatToimet: value,
              })
            }}
          />
        </>
      )}
    </section>
  )
})

export default MuutToimetForm
