/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkTulotMenotTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block, Heading, Paragraph, Text } from 'suomifi-ui-components'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import { getPaatostiliTilirivit } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { FrontVuokratulo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { FormListInputExpander } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonEuroWithDecimalsOrUndefinedValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/react-form-validation.util'
import { observable } from 'mobx'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import { AsiointiLedgerAccountCategory } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'
import { AsiointiLedgerAccountIncomeType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-income-enum'

const VuokratulotForm: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const tilirivit = getPaatostiliTilirivit()

  const mkNewElement = (): FrontVuokratulo =>
    observable({
      id: crypto.randomUUID(),
      tiliointinumero: '',
      tyyppi: AsiointiLedgerAccountIncomeType.VUOKRATULOT,
      nimi: '',
      tunnus: '',
      yhteensa: undefined,
    })

  return (
    <section data-test-id={mkTulotMenotTestId('vuokratulot-form')}>
      <Heading variant="h3">{t('vuokratulotHeading')}</Heading>
      <Block mt={verticalMarginToken} />
      <Paragraph>{t('ohjeIlmoitaVuokratulot')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={VuokratuloListItem}
        state={tilirivit.tulot.vuokratulot}
        asiointiLedgerAccountCategory={AsiointiLedgerAccountCategory.TULOT}
        dataTestIdPrefix={mkTulotMenotTestId('vuokratulot-form')}
        ModalContentComponent={VuokratuloModal}
        newEntry={mkNewElement}
        translationPrefix="vuokratulot"
      />
    </section>
  )
}

const VuokratuloModal: FC<FormModalProps<FrontVuokratulo>> = observer(
  ({ createDataTestId, entry, setEntry }) => {
    const [t] = useTranslation()

    return (
      <div>
        <FormTextInput
          data-test-id={createDataTestId('modal-nimi')}
          labelText={t('kiinteistonTaiYhtionNimi')}
          value={entry.nimi}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) => setEntry({ ...entry, nimi: value })}
        />

        <FormTextInput
          data-test-id={createDataTestId('modal-tunnus')}
          labelText={t('kiinteistonTunnusTaiOsakkeidenNumerot')}
          value={entry.tunnus}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) => setEntry({ ...entry, tunnus: value })}
        />

        <FormNumberInput
          data-test-id={createDataTestId('modal-tulotYhteensa')}
          labelText={t('tulotTilikaudenAikanaYhteensaEuroina')}
          hintText={t('vuokratulotYhteensaHintText')}
          digitMode="two"
          required
          value={entry.yhteensa}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) =>
            setEntry({
              ...entry,
              yhteensa: value,
            })
          }
        />
      </div>
    )
  }
)

const VuokratuloListItem: FC<FormElementProps<FrontVuokratulo>> = observer(
  ({ createDataTestId, entry, onEdit, onRemove }) => {
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'
    const [t] = useTranslation()

    const yhteensaEur = `${formatNumberWithComma(entry.yhteensa!)} €`

    return (
      <FormListInputExpander
        heading={entry.nimi}
        headingRight={yhteensaEur}
        createDataTestId={createDataTestId}
        onEdit={onEdit}
        onRemove={onRemove}
        showSubHeadingWhileOpen
      >
        <Heading variant="h5">
          {t('kiinteistonTunnusTaiOsakkeidenNumerot')}
        </Heading>
        <Text>{entry.tunnus}</Text>
        <Block mt={verticalMarginToken} />

        <Block mt={verticalMarginToken} />
      </FormListInputExpander>
    )
  }
)

export default observer(VuokratulotForm)
