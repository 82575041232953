import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/holhous-asiointi-user-store'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { useAutoFocus } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/auto-focus'
import {
  BulletList,
  focusOutline,
  ResponsiveBorderedContentBlock,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import {
  Block,
  Button,
  ExternalLink,
  Heading,
  IconCheckCircleFilled,
  suomifiDesignTokens,
  Text,
} from 'suomifi-ui-components'
import {
  getFeedbackUrl,
  kasittelyAjatUrl,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/links'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import styled from 'styled-components'
import { mkValmisTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'

const ValmisPage: React.FC = observer(() => {
  const [t, i18n] = useTranslation()
  const userStore = useAsiointiUserStore()
  const headingRef = useAutoFocus<HTMLHeadingElement>()
  // TODO remove hashbang once isTiliAsiointiEnabled() is back
  const openFrontPage = () => window.location.assign('/?tili=true') // Hard reload to remove application batch from memory

  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  return (
    <ResponsiveBorderedContentBlock data-test-id={mkValmisTestId('container')}>
      <ValmisBlock mb={verticalMarginToken}>
        <IconCheckCircleFilled fill={suomifiDesignTokens.colors.successDark1} />{' '}
        {t('valmis')}
      </ValmisBlock>
      <FocusableHeading
        variant="h2"
        smallScreen={!isTablet}
        ref={headingRef}
        tabIndex={-1}
      >
        {t('kiitosPaatostiliOnLahetetty')}
      </FocusableHeading>
      <Block variant="section" mt={verticalMarginToken}>
        <Heading variant="h3" smallScreen={!isTablet}>
          {t('mitaSeuraavaksiTapahtuu')}
        </Heading>
        <Block mt="xs" />

        <BulletList>
          <li>
            <Text smallScreen={!isTablet}>
              {t('virkailijaOttaaHakemuksenKasittelyyn')}
            </Text>
          </li>
          <li>
            <Text smallScreen={!isTablet}>
              {t('virkailijaOnSinuunYhteydessaJosTarvitaanLisatietoja')}{' '}
            </Text>
          </li>
          <li>
            <Text smallScreen={!isTablet}>
              {t('virkailijaTekeePaatoksenJaSeLahetetaan')}{' '}
            </Text>
          </li>
        </BulletList>
      </Block>
      <Block variant="section" mt={verticalMarginToken}>
        <Heading variant="h3" smallScreen={!isTablet}>
          {t('kasittelyaika')}
        </Heading>
        <Block mt="xs">
          <ExternalLink
            href={kasittelyAjatUrl(
              i18n.language as HolhousAsiointiLanguageCode
            )}
            variant="accent"
            underline="initial"
            smallScreen={!isTablet}
            toNewWindow
            labelNewWindow={t('avautuuUuteenValilehteen')}
          >
            {t('tarkistaHenkiloasiakkaidenKasittelyajatDvvnSivuilta')}
          </ExternalLink>
        </Block>
      </Block>
      <ButtonBlock variant="section" mt={verticalMarginToken}>
        <Button
          onClick={openFrontPage}
          role="link"
          data-test-id={mkValmisTestId('asiointi-front-page-link-button')}
        >
          {t('siirryEtusivulle')}
        </Button>
      </ButtonBlock>
      <DividerLine mt={verticalMarginToken} />
      <Block mt={verticalMarginToken}>
        <ExternalLink
          href={getFeedbackUrl(userStore.lang)}
          variant="accent"
          underline="initial"
          smallScreen={!isTablet}
          toNewWindow
          labelNewWindow={t('avautuuUuteenValilehteen')}
        >
          {t('annaPalautettaPalvelusta')}
        </ExternalLink>
      </Block>
    </ResponsiveBorderedContentBlock>
  )
})

const ValmisBlock = styled(Block)`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${suomifiDesignTokens.spacing.insetL};
`

const FocusableHeading = styled(Heading)`
  ${focusOutline};
`

const ButtonBlock = styled(Block)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: ${suomifiDesignTokens.spacing.xs};
`
export default ValmisPage
