import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import {
  IsoDatetimeToUiDatetime,
  IsoDatetimeToUiDate,
  useEvtv,
  selectEvtvVaatimus,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/evtv-asiointi-store'
import {
  Block,
  Heading,
  Paragraph,
  Text,
  suomifiDesignTokens,
} from 'suomifi-ui-components'
import { mkTiliSectionId } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-frame-test-id'
import React, { FC, PropsWithChildren, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import {
  OMAISUUSLUETTELO_ROUTES,
  PAATOSTILI_ROUTES,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { OverviewButton } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/UserLandingPage'
import { HolhousAsiaType } from 'tilintarkastus-common/src/vtj/types/holhous-asia/holhous-asia-enums'
import { EvtvVaatimus } from 'edunvalvonta-asiointi/src/vtj/asiointi/evtv-asiointi/evtv-api.type'
import {
  fetchDraftsIntoStore,
  getTiliDraftBySeurantaasiavaatimus,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/draft-store'
import {
  ChipForStatusNotStarted,
  ChipForStatusSaved,
  ChipForStatusSent,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/chips'

const TiliVelvollisuus = observer(() => {
  const [t] = useTranslation()
  const evtvVaatimukset = useEvtv()

  useEffect(() => {
    const refresh = async () => {
      await fetchDraftsIntoStore()
    }
    void refresh()
  }, [evtvVaatimukset])

  if (!evtvVaatimukset || evtvVaatimukset.length === 0) {
    return (
      <>
        <StyledRuler />
        <Block mt="m" />
        <Paragraph>
          <ItalicText>{t('evEiTiliVelvollisuutta')}</ItalicText>
        </Paragraph>
      </>
    )
  }

  return (
    <TilitList>
      {evtvVaatimukset.map((asiointiTili) => {
        const tiliDraft = getTiliDraftBySeurantaasiavaatimus(
          asiointiTili.seurantaAsiavaatimusId
        )

        const selectTili = () =>
          selectEvtvVaatimus(asiointiTili.seurantaAsiavaatimusId)

        return (
          <PaamiesTiliSection key={asiointiTili.seurantaAsiavaatimusId}>
            <Heading
              variant="h3"
              color="highlightBase"
              data-test-id={mkTiliSectionId(
                'tee-tili-henkilo',
                asiointiTili.seurantaAsiavaatimusId
              )}
            >
              {asiointiTili.asiaType === HolhousAsiaType.PAATOSTILI
                ? t('paatostili')
                : t('omaisuusluettelo')}
              : {asiointiTili.lastname}, {asiointiTili.firstnames}
            </Heading>
            <Text smallScreen variant="bold">
              {t('tilikausi')}
              {': '}
              {IsoDatetimeToUiDate(asiointiTili.accountingPeriodStartDate)}
              {' - '}
              {IsoDatetimeToUiDate(asiointiTili.accountingPeriodEndDate)}
            </Text>
            {asiointiTili.sentDate && (
              <ChipForStatusSent>
                {t('tiliStatus_LAHETETTY')}&nbsp;{'✔'}
              </ChipForStatusSent>
            )}
            {tiliDraft && (
              <ChipForStatusSaved>
                {t('tiliStatus_TALLENNETTU')}&nbsp;
                {IsoDatetimeToUiDatetime(tiliDraft?.updatedAt)}
              </ChipForStatusSaved>
            )}
            {!asiointiTili.sentDate && !tiliDraft && (
              <ChipForStatusNotStarted>
                {t('tiliStatus_ALOITTAMATTA')}
              </ChipForStatusNotStarted>
            )}
            <Block mt="s" />
            <Paragraph>
              {t(
                asiointiTili.sentDate
                  ? 'paatostiliOnLahetetty'
                  : 'lahetaPaatostiliViimeistaan'
              )}{' '}
              {IsoDatetimeToUiDate(
                asiointiTili.sentDate ?? asiointiTili.dueDate
              )}
            </Paragraph>
            <Block mt="m" />
            {!asiointiTili.sentDate && (
              <NavigateToTilintarkastusButton
                onClick={selectTili}
                tili={asiointiTili}
              >
                {t('aloitaTayttaminen')}
              </NavigateToTilintarkastusButton>
            )}
          </PaamiesTiliSection>
        )
      })}
    </TilitList>
  )
})

const NavigateToTilintarkastusButton: FC<
  PropsWithChildren & {
    onClick: () => void
    tili: EvtvVaatimus
  }
> = ({ children, onClick, tili: { asiaType, seurantaAsiavaatimusId } }) => {
  const navigate = useNavigate()
  const navigateTo =
    asiaType === 'PAATOSTILI'
      ? PAATOSTILI_ROUTES.TEE_PAATOSTILI
      : OMAISUUSLUETTELO_ROUTES.TEE_OMAISUUSLUETTELO

  return (
    <OverviewButton
      data-test-id={mkTiliSectionId('tee-tili-nappi', seurantaAsiavaatimusId)}
      onClick={() => {
        onClick()
        navigate(navigateTo)
      }}
      fullWidth={false}
      role="link"
      variant="default"
    >
      {children}
    </OverviewButton>
  )
}

const PaamiesTiliSection = styled.div`
  border: 1px solid ${suomifiDesignTokens.colors.depthLight1};
  padding: ${suomifiDesignTokens.spacing.m};
`

const StyledRuler = styled.hr`
  color: ${suomifiDesignTokens.colors.depthLight1};
  opacity: 50%;
  height: 2px;
`

const TilitList = styled.div`
  gap: ${suomifiDesignTokens.spacing.m};
  display: flex;
  flex-direction: column;
`

const ItalicText = styled(Text)`
  font-style: italic;
`

export default TiliVelvollisuus
