import React from 'react'
import {
  Block,
  ExternalLink,
  InlineAlert,
  Paragraph,
  suomifiDesignTokens,
  Text,
} from 'suomifi-ui-components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/holhous-asiointi-user-store'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import { observer } from 'mobx-react'

const FeatureNotification: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const { lang, tilintarkastusActivated } = useAsiointiUserStore()
  // TODO replace tilintarkastusActivated with isTiliAsiointiEnabled()

  return (
    <InlineAlert status="neutral" ariaLiveMode="off" smallScreen={!isTablet}>
      <Paragraph mb="xxs">
        <Text variant="bold">{t('landingFeatureKehitammeHeader')}</Text>
      </Paragraph>
      <FeatureList>
        <FeatureItem>
          <Text>{t('landingFeatureLuvatPitch')}</Text>
        </FeatureItem>
        {tilintarkastusActivated && (
          <FeatureItem>
            <Text>{t('landingFeatureTilitPitch')}</Text>
          </FeatureItem>
        )}
      </FeatureList>

      <Block mt="m" />
      <Text>
        {t('muutPalvelutFooter')}{' '}
        <ExternalLink
          variant="default"
          toNewWindow
          labelNewWindow={t('avautuuUuteenValilehteen')}
          href={getLinkToDvvForms(lang)}
        >
          {t('muutPalvelutLink')}
        </ExternalLink>
        .
      </Text>
    </InlineAlert>
  )
})

const getLinkToDvvForms = (lang: HolhousAsiointiLanguageCode): string => {
  return lang === 'sv'
    ? 'https://dvv.fi/sv/formyndarskap'
    : 'https://dvv.fi/edunvalvonta-ja-toisen-asioiden-hoitaminen'
}

const FeatureList = styled.ul`
  list-style: disc outside;
  margin: 0 0 0 ${suomifiDesignTokens.spacing.l};
`

const FeatureItem = styled.li`
  margin: ${suomifiDesignTokens.spacing.xxs} 0 0 0;
  padding: 0;
`

export default FeatureNotification
