import React, { RefObject, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useFormContext } from 'react-hook-form'
import { createOnChangeEvent } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/react-form-validation.util'
import { Dropdown, DropdownItem, DropdownProps } from 'suomifi-ui-components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { lowerFirst } from 'lodash'

export const StyledDropdown = styled(
  React.forwardRef<HTMLButtonElement, DropdownProps>(function StyledDropdown(
    props,
    ref
  ) {
    return (
      <Dropdown {...props} forwardedRef={ref as RefObject<HTMLButtonElement>} />
    )
  })
)`
  width: 100%;
  min-width: 290px;
  max-width: 450px;
`

export const FormDropdown: React.FC<{
  'data-test-id': string
  value?: string
  required?: boolean
  labelText: string
  items: { value: string; name: string }[]
  updateValue: (value: string) => void
}> = observer(
  ({
    'data-test-id': dataTestId,
    value,
    required = false,
    labelText,
    items,
    updateValue,
  }) => {
    if (!dataTestId) {
      throw new Error('Missing data test id')
    }
    const [t] = useTranslation()
    const {
      setValue,
      register,
      formState: { errors },
    } = useFormContext()

    const valitseError = `${t('valitse')} ${lowerFirst(labelText)}`

    const { onChange, ref, name } = register(dataTestId, {
      required: {
        value: required,
        message: valitseError,
      },
      validate: (inputValue) => {
        if (!inputValue) {
          return true
        } else {
          if (items.find(({ value }) => value && value === inputValue)) {
            return true
          } else {
            return valitseError
          }
        }
      },
      onChange: (event) => {
        updateValue(event.target.value)
      },
    })

    useEffect(() => {
      // hack to get around the Dropdown value not resetting when we want clear the selected value.
      // i.e. useful when we want to reuse existing modal with new data
      setValue(dataTestId, value, { shouldValidate: value !== undefined })
    }, [value, setValue, dataTestId])

    return (
      <StyledDropdown
        data-test-id={dataTestId}
        labelText={labelText}
        aria-live="off"
        value={value}
        status={errors[dataTestId] ? 'error' : 'default'}
        data-test-error-message={errors[dataTestId]?.message || ''}
        statusText={(errors[dataTestId]?.message || '') as string}
        onChange={(value: string) =>
          onChange(createOnChangeEvent(value, dataTestId))
        }
        ref={ref}
        name={name}
      >
        {items.map((itemValue) => (
          <DropdownItem
            key={itemValue.value}
            value={itemValue.value}
            data-test-id={dataTestId + '-set-' + itemValue.value}
          >
            {itemValue.name}
          </DropdownItem>
        ))}
      </StyledDropdown>
    )
  }
)

export default FormDropdown
