import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Block, Heading, Paragraph, Text } from 'suomifi-ui-components'
import { FormListInputExpander } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonDateValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/react-form-validation.util'
import FormDateInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDateInput'
import FormDropdown from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDropdown'
import FormCountryDropdown from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormCountryDropdown'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/holhous-asiointi-user-store'
import { COUNTRY_CODE_FINLAND } from 'holhous-common/src/vtj/country/country.util'
import { getCountryById } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/country.store'
import { FrontAsuinpaikka } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { observable } from 'mobx'
import {
  formatDateAsLocal,
  parseLocalDate,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import { IsoDatetimeToUiDate } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/evtv-asiointi-store'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import {
  AsiointiHabitationPeriodType,
  AsiointiHabitationType,
} from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import { mkAsuminenFormTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { isOmaisuusluettelo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/omaisuusluettelo.store'

const AsuminenForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 's'
  const {
    lomake: { tili },
  } = getTilintarkastusStore()
  const isOmaisuusluetteloTili = isOmaisuusluettelo(tili)
  const alkupvm = getTilintarkastusStore().accountingPeriodStartDate
  const omaisuusHeading = `${t('asumistilanne')} ${IsoDatetimeToUiDate(
    alkupvm
  )}`
  const asuminenHeading = t('asuminenHeading')

  return (
    <section data-test-id={mkAsuminenFormTestId()}>
      <Heading variant="h3">
        {isOmaisuusluetteloTili ? omaisuusHeading : asuminenHeading}
      </Heading>
      <Block mt={verticalMarginToken} />
      <Paragraph>
        {isOmaisuusluetteloTili
          ? t('ohjeOmaisuusluetteloAsuinpaikat')
          : t('ohjeIlmoitaAsuinpaikat')}
      </Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={AsuinpaikkaListItemContent}
        state={tili.asuinpaikat}
        dataTestIdPrefix={mkAsuminenFormTestId()}
        ModalContentComponent={AsuinpaikkaModalContent}
        newEntry={() =>
          observable({
            id: crypto.randomUUID(),
            habitationType: undefined,
            habitationPeriodType: undefined,
            streetAddress: '',
            streetAddressExtra: '',
            postalCode: '',
            postOffice: '',
            beginDate: undefined,
            endDate: undefined,
            countryCode: COUNTRY_CODE_FINLAND,
          } as FrontAsuinpaikka)
        }
        translationPrefix="asuminen"
      />
      <Block mt={verticalMarginToken} />
    </section>
  )
})

export default AsuminenForm

const AsuinpaikkaModalContent = ({
  entry,
  setEntry,
  createDataTestId,
}: FormModalProps<FrontAsuinpaikka>) => {
  const [t] = useTranslation()

  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const asumistyypit = Object.values(AsiointiHabitationType)
    .filter((v) => v !== AsiointiHabitationType.UNKNOWN)
    .reduce(
      (
        result: {
          name: string
          value: AsiointiHabitationType
        }[],
        asumistyyppi
      ) => {
        return [
          ...result,
          { name: t(`habitationType_${asumistyyppi}`), value: asumistyyppi },
        ]
      },
      []
    )

  const asumisjaksotyypit = Object.values(AsiointiHabitationPeriodType).reduce(
    (
      result: {
        name: string
        value: AsiointiHabitationPeriodType
      }[],
      asumisjaksotyyppi
    ) => {
      return [
        ...result,
        {
          name: t(`habitationPeriodType_${asumisjaksotyyppi}`),
          value: asumisjaksotyyppi,
        },
      ]
    },
    []
  )

  const lang = useAsiointiUserStore().lang
  const countryId = entry.countryCode

  return (
    <>
      <FormDropdown
        labelText={t('habitationType')}
        data-test-id={createDataTestId('modal-asumistyyppi')}
        value={entry.habitationType}
        items={asumistyypit}
        required
        updateValue={(value) => {
          setEntry({
            ...entry,
            habitationType: value as AsiointiHabitationType,
          })
        }}
      />
      <Block mt={verticalMarginToken} />

      <FormDropdown
        labelText={t('habitationPeriodType')}
        data-test-id={createDataTestId('modal-asumisjaksotyyppi')}
        value={entry.habitationPeriodType}
        items={asumisjaksotyypit}
        required
        updateValue={(value) => {
          setEntry({
            ...entry,
            habitationPeriodType: value as AsiointiHabitationPeriodType,
          })
        }}
      />
      <Block mt={verticalMarginToken} />

      <FormTextInput
        data-test-id={createDataTestId('modal-katuosoite')}
        labelText={t('katuosoite')}
        value={entry.streetAddress}
        required
        validate={commonXssValidator(t)}
        updateValue={(value) => setEntry({ ...entry, streetAddress: value })}
      />
      <FormTextInput
        data-test-id={createDataTestId('modal-katuosoite-lisatieto')}
        labelText={t('osoitteenLisarivi')}
        value={entry.streetAddressExtra}
        validate={commonXssValidator(t)}
        updateValue={(value) =>
          setEntry({ ...entry, streetAddressExtra: value })
        }
      />
      <FormTextInput
        data-test-id={createDataTestId('modal-postinumero')}
        labelText={t('postinumero')}
        value={entry.postalCode}
        required
        validate={commonXssValidator(t)}
        updateValue={(value) => setEntry({ ...entry, postalCode: value })}
      />
      <FormTextInput
        data-test-id={createDataTestId('modal-postitoimipaikka')}
        labelText={t('postitoimipaikka')}
        value={entry.postOffice}
        required
        validate={commonXssValidator(t)}
        updateValue={(value) => setEntry({ ...entry, postOffice: value })}
      />
      <FormDateInput
        labelText={t('alkamispaivamaara')}
        data-test-id={createDataTestId('modal-alkamispaivamaara')}
        value={
          entry.beginDate ? formatDateAsLocal(entry.beginDate) : entry.beginDate
        }
        updateValue={(value) => {
          setEntry({
            ...entry,
            beginDate: value ? parseLocalDate(value) : value,
          })
        }}
        validate={commonDateValidator(t)}
      />
      <FormDateInput
        labelText={t('paattymispaivamaara')}
        data-test-id={createDataTestId('modal-paattymispaivamaara')}
        value={entry.endDate ? formatDateAsLocal(entry.endDate) : entry.endDate}
        updateValue={(value) => {
          setEntry({ ...entry, endDate: value ? parseLocalDate(value) : value })
        }}
        validate={commonDateValidator(t)}
      />
      <FormCountryDropdown
        data-test-id={createDataTestId('modal-maa')}
        value={getCountryById(countryId).shortName[lang]}
        updateValue={(value) => {
          setEntry({ ...entry, countryCode: value })
        }}
      />
    </>
  )
}

const AsuinpaikkaListItemContent = ({
  entry,
  createDataTestId,
  onEdit,
  onRemove,
}: FormElementProps<FrontAsuinpaikka>) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const lang = useAsiointiUserStore().lang
  const countryId = entry.countryCode

  return (
    <FormListInputExpander
      heading={entry.streetAddress}
      headingRight={`${
        (entry.beginDate && formatDateAsLocal(entry.beginDate)) ||
        t('alkamispaivamaaraEiTiedossa')
      } - ${
        entry.endDate
          ? formatDateAsLocal(entry.endDate)
          : t('paattymispaivamaaraEiTiedossa')
      }`}
      subHeading={entry.postOffice}
      createDataTestId={createDataTestId}
      onEdit={onEdit}
      onRemove={onRemove}
    >
      {entry.streetAddressExtra && (
        <>
          <Heading variant="h5">{t('osoitteenLisarivi')}</Heading>
          <Text>{entry.streetAddressExtra}</Text>
          <Block mt={verticalMarginToken} />
        </>
      )}
      <Heading variant="h5">{t('habitationType')}</Heading>
      <Text>{t(`habitationType_${String(entry.habitationType)}`)}</Text>
      <Block mt={verticalMarginToken} />

      <Heading variant="h5">{t('habitationPeriodType')}</Heading>
      <Text>
        {t(`habitationPeriodType_${String(entry.habitationPeriodType)}`)}
      </Text>
      <Block mt={verticalMarginToken} />

      <Heading variant="h5">{t('postinumero')}</Heading>
      <Text>{entry.postalCode}</Text>
      <Block mt={verticalMarginToken} />

      <Heading variant="h5">{t('postitoimipaikka')}</Heading>
      <Text>{entry.postOffice}</Text>
      <Block mt={verticalMarginToken} />

      <Heading variant="h5">{t('maa')}</Heading>
      <Text>{getCountryById(countryId).shortName[lang]}</Text>
    </FormListInputExpander>
  )
}
