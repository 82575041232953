import keyMirror from 'keymirror'

import { AsiaType } from 'asia-common/src/vtj/koodisto/asia-enums'

export const HolhousAsiaType = keyMirror({
  ALAIKAISEN_EDUNVALVONNAN_POISTAMINEN: null,
  ALAIKAISEN_REKISTEROINTI: null,
  ALAIKAISTA_VELALLISTA_KOSKEVA_ILMOITUS: null,
  EDUNVALVOJAN_MAARAAMINEN: null,
  EDUNVALVOJAN_MAARAAMINEN_VALTUUTETUN_TEHTAVIIN: null,
  EDUNVALVOJAN_SIJAISEN_MAARAAMINEN: null,
  EDUNVALVOJAN_VAIHTAMINEN: null,
  EDUNVALVOJAN_VAPAUTTAMINEN: null,
  EDUNVALVONNAN_JATKAMINEN: null,
  EDUNVALVONNAN_LAAJENTAMINEN: null,
  EDUNVALVONNAN_LAKKAUTTAMINEN: null,
  EDUNVALVONNAN_SEURANTA: null,
  EDUNVALVONNAN_SIIRTO: null,
  EDUNVALVOJIEN_VALISEN_ERIMIELISYYDEN_TAI_RIIDAN_RATKAISEMINEN: null,
  [AsiaType.EDUNVALVONTAVALTUUTUKSEN_VAHVISTAMINEN]: null,
  [AsiaType.EDUNVALVONTAVALTUUTUKSEN_PERUUTTAMISEN_VAHVISTAMINEN]: null,
  KANTELU: null,
  TUNTEMATON: null,
  LAUSUNTOPYYNTO: null,
  LAUSUNTOPYYNTO_KARAJAOIKEUDELTA: null,
  LAUSUNTOPYYNTO_KARAJAOIKEUDELTA_TAI_TUOMIOISTUIMELTA: null,
  LUPA_ASIA: null,
  LUPA_ASIA_KONVERTOITU: null,
  MAKSUA_KOSKEVA_OIKAISUVAATIMUS_HAKEMUS: null,
  METSASUUNNITELMAN_HYVAKSYMINEN_OMAISUUDEN_HOITOSUUNNITELMAKSI: null,
  MUUT_LUOKITTELEMATTOMAT_ASIATYYPIT: null,
  NELIVUOTISSELVITYS_KONVERTOITU: null,
  OIKAISUPYYNTO_SUORITEMAKSUUN: null,
  OMAISUUDEN_HOITOSUUNNITELMAN_HYVAKSYMINEN_MUUT_KUIN_METSA: null,
  PAKKOKEINOASIA: null,
  RATKAISUPYYNTO_KARAJAOIKEUDELLE: null,
  SAHKOPOSTILLA_SAAPUNEET_OMAISUUSLUETTELOT_JA_TILIT: null,
  TILIASIA: null,
  TOIMINTAKELPOISUUDEN_RAJOITTAMINEN: null,
  TOIMINTAKELPOISUUDEN_RAJOITUKSEN_MUUTTAMINEN_TAI_POISTAMINEN: null,
  VAJAAVALTAISEKSI_JULISTAMINEN: null,
  VALITUS_HOLHOUSASIASSA: null,
  VALITUS_SUORITEMAKSUSTA: null,
  VALTUUKSIEN_LAKKAAMINEN: null,
  VALVONNAN_LOPETTAMINEN: null,
  VARAVALTUUTETUN_EDUNVALVONTAVALTUUTUKSEN_VAHVISTAMINEN: null,
  PERINNONJAKO: null,
  VUOSITILI: null,
  PAATOSTILI: null,
  MUU_LUPA: null,
  ENSITILI: null,
  MYYNTILUPA: null,
  OMAISUUSLUETTELO: null,
  VERKKOLOMAKKEELLA_SAAPUNEET_OMAISUUSLUETTELOT_JA_TILIT: null,
  VALIPAATOSTILI: null,
  [AsiaType.SELVITYSTEHTAVA]: null,
  [AsiaType.EDUNVALVOJAN_MAARAAMINEN_ILMOITUKSELLA]: null,
})

export type HolhousAsiaTypeId =
  (typeof HolhousAsiaType)[keyof typeof HolhousAsiaType]
