import React from 'react'
import { hydrateRoot } from 'react-dom/client'
import 'common/src/vtj/server/create-nonce'
import AsiointiApp from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/AsiointiApp'
import { createAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/holhous-asiointi-user-store'
import { initEdunvalvontaAsiointiI18n } from 'edunvalvonta-asiointi/src/vtj/asiointi/localization/i18next'
import {
  ASIOINTI_ROOT_ELEMENT_ID,
  SESSION_EXPIRED_SEARCH_PARAM,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/constants'
import { AsiointiUiInitialData } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-ui-initial-data.type'
import actions from 'asia-common/src/vtj/action-singleton'

const initialData = window as unknown as AsiointiUiInitialData
const asiointiUserStore = createAsiointiUserStore(
  initialData.INITIAL_STATE.asiointiUserStore
)
const i18n = initEdunvalvontaAsiointiI18n({
  language: asiointiUserStore.lang,
})
actions.setAll({
  handleSessionExpiration() {
    window.location.assign(`/?${SESSION_EXPIRED_SEARCH_PARAM}`)
  },
})

const renderTarget = document.getElementById(
  ASIOINTI_ROOT_ELEMENT_ID
) as HTMLElement
hydrateRoot(
  renderTarget,
  <AsiointiApp
    asiointiUserStore={asiointiUserStore}
    publicSettingsData={initialData.APP_SETTINGS}
    isTestAuthenticatorEnabled={
      initialData.INITIAL_STATE.isTestAuthenticatorEnabled === true
    }
    i18n={i18n}
  />
)
